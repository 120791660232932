import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    HR_ISSUES_PATH,
    HR_ISSUES_PATH_EXACT,
    HR_ORG_CHART_PATH,
    HR_PROFILE_PATH,
    HR_REQUESTS_PATH,
    HR_REQUESTS_PATH_EXACT,
    HR_TIME_OFF_MANAGEMENT_PATH,
    HR_TIME_OFF_MANAGEMENT_PATH_EXACT,
    HR_TIME_OFF_PATH,
    HR_TIME_OFF_PATH_EXACT
} from "../../../newShared/constants";
import {HrProfile} from "./pages/hrProfile";
import {HrRequests} from "./pages/hrRequests";
import {EmployeesTree} from "./pages/employeesTree";
import {HrIssues} from "./pages/hrIssues";
import {HrMyTimeOff} from "./pages/hrTimeOff";
import {HrTimeOffManagement} from "./pages/hrTimeOffManagement";

export const Routes: FC = () => {

    return (
        <Switch>
            <Route exact path={HR_PROFILE_PATH} component={HrProfile}/>
            <Route exact path={HR_ORG_CHART_PATH} component={EmployeesTree}/>
            <Route exact path={[HR_REQUESTS_PATH, HR_REQUESTS_PATH_EXACT]} component={HrRequests}/>
            <Route exact path={[HR_ISSUES_PATH, HR_ISSUES_PATH_EXACT]} component={HrIssues}/>
            <Route exact path={[HR_TIME_OFF_PATH, HR_TIME_OFF_PATH_EXACT]} component={HrMyTimeOff}/>
            <Route exact path={[HR_TIME_OFF_MANAGEMENT_PATH, HR_TIME_OFF_MANAGEMENT_PATH_EXACT]} component={HrTimeOffManagement}/>
            <Redirect to={HR_PROFILE_PATH}/>
        </Switch>
    );
};
