import {PortalTrainingContentModel, TrainingContentStatus} from "../../../../../../../../newShared/GQLTypes";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

type TrainingInfoPropsType = {
    sections: PortalTrainingContentModel[]
}
export const TrainingInfoPreview = ({sections}: TrainingInfoPropsType) => {

    return(
        <Flex
            w={'279px'}
            direction={'column'}
            p={'20px'}
            gap={'20px'}
            br={'8px'}
            border={`1px solid ${colors.stroke.table}`}
        >
            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{('Information')}</Typography>

            <Flex ai={'center'} gap={'12px'}>
                <CheckCircleOutlineOutlinedIcon sx={{color: colors.primary.blue}}/>
                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{(`Progress ${sections.filter(e => e.status === TrainingContentStatus.Done).length}/${sections.length}`)}</Typography>
            </Flex>
        </Flex>
    )
}