import React, {FC} from 'react';
import {Flex} from '../../../newShared/components/Layouts';
import {useWorkspaceSpace} from "./hooks/useWorkerSpace";
import {LocalSpinner} from "../../../newShared/components/Spinner";
import {SelectionBar} from "./components/selectionBar";
import {DOCUMENT_AUTOMATION_TYPE} from "./constants";
import {DocumentAutomationComponent} from "./components/DocumentAutomationComponent";
import {DocumentCampaignReviewComponent} from "./components/DocumentCampaignReviewComponent";
import {Typo} from "../../../newShared/components/Typography";


export const WorkerSpace:FC = () => {
    const {
        pageInfo,
        stages,
        selectedStage,
        isLoadingCampaignReviewStage
    } = useWorkspaceSpace();
    return (
        <Flex w={'100%'} h={'100%'} jc={'center'}>
            <Flex w={'100%'} p={'0 50px'} h={'100%'} jc={'space-between'}>
                <SelectionBar stages={stages} pageInfo={pageInfo}/>

                {selectedStage && selectedStage.stage.automationType === DOCUMENT_AUTOMATION_TYPE ?
                    <DocumentAutomationComponent />
                    :
                    <DocumentCampaignReviewComponent />
                }

                {isLoadingCampaignReviewStage && <Flex w={'74%'} jc={'center'} p={'50px 0 0 0'}>
                    <LocalSpinner />
                </Flex>}

                {!selectedStage && <Flex w={'74%'} jc={'center'} p={'50px 0 0 0'}>
                    {<Typo>{'Select stage'}</Typo>}
                </Flex>}
            </Flex>
        </Flex>
    )
}
