import React, {FC} from "react";
import {Divider, IconButton, Menu, Typography} from "@mui/material";
import {useTopBar} from "../../hooks/useTopBar";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory} from "react-router";
import {PATH_LOCAL_SETTINGS} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {MoreVert} from "@mui/icons-material";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {getName} from "../../../../../newShared/utils/text";
import {AvatarMenuTitle} from "../avatarMenuTitle";
import {CommonAvatar} from "../../../../../newShared/components/Basic/CommonAvatar";
import {MenuItemHoverBlue} from "../styled";
import {ChangeLanguageDialog} from "../../../../../newShared/components/changeLanguageDialog/hook";
import {LanguageChangeDialog} from "../../../../../newShared/components/changeLanguageDialog";
import {SETTINGS_PROFILE_PATH, SETTINGS_LANGUAGE_PATH} from "../../../../authWorkspacesCookies/settings/constants";
import {MobileMenuDialog} from "../mobileMenuDialog";
import {useDispatch} from "react-redux";
import {ContactUsDialog} from "../../../../components/contactUs/contactUsDialog";
import {setContactUsDialogAction} from "../../../../components/contactUs/store/slice";
import {SHOW_CHANGE_LANGUAGE_IN_TOPBAR} from "../../../../../newShared/constants";

export const ThreeDotsTopBar: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'TopBar.threeDots'});
    const history = useHistory();
    const {toggleVisibility, isOpen} = ChangeLanguageDialog();
    const {currData: {currentUser}, logout} = UseManageWorkspacesAndOrganizations();
    const {
        anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose,
        handleOpenSelectWorkspaceDialog
    } = useTopBar();

    const handleSelect = (path: string) => {
        history.push(path);
        handleThreeDotsClose()
    }
    const dispatch = useDispatch();
    const openContactUsDialog = () => {
        dispatch(setContactUsDialogAction({isOpen: true}))
        handleThreeDotsClose();
    }
    const {isMobile} = useMedia();
    return currentUser ? (
        <>
            <IconButton
                onClick={handleThreeDotsOpen}
                size={"large"}
                sx={{color: Boolean(anchorThreeDotsEl) ? colors.primary.blue : colors.text.grey_dark}}
            >
                <MoreVert/>
            </IconButton>
            <Menu
                keepMounted
                elevation={1}
                anchorEl={anchorThreeDotsEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorThreeDotsEl) && !isMobile}
                onClose={handleThreeDotsClose}
                style={{top: '6px'}}
                MenuListProps={{sx: {py: '0'}}}
            >
                {/*<MenuItem onClick={handleGoToMain}>*/}
                {/*    {t('Go to Main app')}*/}
                {/*</MenuItem>*/}
                <AvatarMenuTitle name={getName(currentUser.firstName, currentUser.lastName)} email={currentUser.email} isDesktop={false} handleClose={handleThreeDotsClose}>
                    <CommonAvatar name={getName(currentUser.firstName, currentUser.lastName)}
                                  size={'d28'} style={{cursor: 'default'}}/>
                </AvatarMenuTitle>
                <Divider sx={{margin: '0 !important'}}/>
                <MenuItemHoverBlue onClick={() => {handleOpenSelectWorkspaceDialog(); handleThreeDotsClose();}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Change workspace')}</Typography>
                </MenuItemHoverBlue>
                <MenuItemHoverBlue onClick={() => {handleSelect(PATH_LOCAL_SETTINGS)}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Settings')}</Typography>
                </MenuItemHoverBlue>
                <MenuItemHoverBlue onClick={openContactUsDialog}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Contact us')}</Typography>
                </MenuItemHoverBlue>
                <MenuItemHoverBlue onClick={() => {handleSelect(SETTINGS_PROFILE_PATH)}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Profile')}</Typography>
                </MenuItemHoverBlue>
                <MenuItemHoverBlue onClick={() => {handleSelect(SETTINGS_LANGUAGE_PATH)}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Language')}</Typography>
                </MenuItemHoverBlue>
                {SHOW_CHANGE_LANGUAGE_IN_TOPBAR &&
                    <MenuItemHoverBlue onClick={() => {
                        toggleVisibility(true);
                        handleThreeDotsClose();
                    }}>
                        <Typography color={colors.text.dark} variant={'*bodyText2'}>{t('Change language')}</Typography>
                    </MenuItemHoverBlue>
                }

                <Divider sx={{margin: '0 !important'}}/>

                <MenuItemHoverBlue onClick={() => {handleThreeDotsClose(); logout()}}>
                    <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('Logout')}</Typography>
                </MenuItemHoverBlue>
            </Menu>
            <LanguageChangeDialog isOpen={isOpen} handleClose={toggleVisibility}/>
            <MobileMenuDialog isOpen={Boolean(anchorThreeDotsEl) && isMobile} handleClose={handleThreeDotsClose}/>
            <ContactUsDialog />
        </>
    ) : null
}