import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    getPortalHrIssueByIdAction,
    getPortalHrRequestByIdAction,
    getPortalHrRequestsWithFilterPaginationAction,
    portalHrApproveTimeOffRequestAction,
    portalHrCancelMyTimeOffRequestAction,
    portalHrCancelRequestAction,
    portalHrCreateEditRequestAction,
    portalHrCreateIssueAction,
    portalHrCreateTimeOffRequestAction,
    portalHrGetAndChangeStepStatusTimeOffRequestAction,
    portalHrGetIssuesWithFilterPaginationAction,
    portalHrGetMyTimeOffRequestsWithFilterPaginationAction,
    portalHrGetOrgTreeAction,
    portalHrGetProfileAction,
    portalHrGetProfileJobHistoryAction,
    portalHrGetSettingsCountriesAction,
    portalHrGetTimeOffRequestByIdAction,
    portalHrGetTimeOffRequestsSettingsAction,
    portalHrGetTimeOffRequestsWithFilterPaginationAction,
    portalHrRejectTimeOffRequestAction,
    portalHrUpdateMyTimeOffRequestAction
} from "./actions";
import {THrProfile, THrProfileJobHistory, TSmallEmployee} from "../types";
import {TPageInfo, TSetDialogAction} from "../../../../newShared/types";
import {setPaginationInStore} from "../../../../newShared/hooks/useScroll/helpers";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {
    HrRequestStatus,
    HrTimeOffStatus,
    PortalHrIssueModel,
    PortalHrRequestModel,
    PortalHrTimeOffRequestModel,
    PortalHrTimeOffRequestSettingsModel
} from "../../../../newShared/GQLTypes";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {defaultPageInfo} from "../constants";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {getErrorsByCode} from "../../../../newShared/utils/asyncThunk/helpers";
import {DownloadFile} from "../../workerSpace/store/actions";

export type THrSlice = {
    myProfile: THrProfile | null,
    jobHistory: {
        data: (THrProfileJobHistory | null)[],
        pageInfo: TPageInfo,
    },

    myRequests: {
        requests: PortalHrRequestModel[],
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType,
        selected: PortalHrRequestModel | null,
    },

    myIssues: {
        issues: PortalHrIssueModel[],
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType,
        selected: PortalHrIssueModel | null,
    },

    tree: {
        employees: TSmallEmployee[],
        fullView: boolean,
        direction: 'TB' | 'LR' | 'RL',
    };

    myTimeOff: {
        requests: PortalHrTimeOffRequestModel[],
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType,
        selected: PortalHrTimeOffRequestModel | null,
    },

    incomingTimeOff: {
        requests: PortalHrTimeOffRequestModel[],
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType,
        selected: PortalHrTimeOffRequestModel | null,
    },

    timeOffSettings: PortalHrTimeOffRequestSettingsModel | null,

    isLoading: {
        isLoadingMyProfile: boolean,
        isLoadingJobHistory: boolean,
        isLoadingTree: boolean,
        requestExact: boolean,
        requestList: boolean,
        cancelRequest: boolean,
        issueList: boolean,
        myTimeOffList: boolean,
        timeOffSettings: boolean,
        incomingTimeOffList: boolean,
        incomingTimeOffExact: boolean,
        downloadingFile: string[],
        uploadingFile: string[],
        creatingTimeOff: boolean,
        myTimeOffRequest: boolean,
        editMyTimeOffRequest: boolean,
        cancelMyTimeOffRequest: boolean,
        approveIncomingTimeOffRequest: boolean,
        rejectIncomingTimeOffRequest: boolean,
    },

    dialogs: {
        createEditRequest: {
            isOpen: boolean,
            isLoading: boolean,

            countryOptions: string[],
            isCountryOptionsLoading: boolean,
        },
        requestView: {
            isOpen: boolean,
            selectedId: string | null,
        },
        cancelRequest: {
            isOpen: boolean,
            selectedId: string | null,
        },
        createIssue: {
            isOpen: boolean,
            isLoading: boolean,
        },
        issueView: {
            isOpen: boolean,
            isLoading: boolean,
        },
        requestError: {
            title: string,
            message: string[],
        },

        createTimeOff: {
            isOpen: boolean,
            selected: PortalHrTimeOffRequestModel | null,
        },
        cancelMyTimeOff: {
            isOpen: boolean,
            isLoading: boolean,
            selectedId: string | null,
        },
        approveIncomingTimeOff: {
            isOpen: boolean,
            isLoading: boolean,
        },
        rejectIncomingTimeOff: {
            isOpen: boolean,
            isLoading: boolean,
        },
        timeOffHistory: {
            isOpen: boolean,
            selected: PortalHrTimeOffRequestModel | null,
        }
    }
}

export const initialState: THrSlice = {
    myProfile: null,
    jobHistory: {
        data: [],
        pageInfo: {
            page: 0,
            count: 999999,
            total: 0,
        },
    },

    myRequests: {
        requests: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selected: null,
    },

    myIssues: {
        issues: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selected: null,
    },

    tree: {
        employees: [],
        fullView: false,
        direction: 'TB'
    },
    myTimeOff: {
        requests: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selected: null,
    },

    incomingTimeOff: {
        requests: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selected: null,
    },

    timeOffSettings: null,

    isLoading: {
        isLoadingMyProfile: false,
        isLoadingJobHistory: false,
        isLoadingTree: false,
        requestExact: false,
        requestList: false,
        cancelRequest: false,
        issueList: false,
        myTimeOffList: false,
        timeOffSettings: false,
        incomingTimeOffList: false,
        incomingTimeOffExact: false,
        downloadingFile: [],
        uploadingFile: [],
        creatingTimeOff: false,
        myTimeOffRequest: false,
        editMyTimeOffRequest: false,
        cancelMyTimeOffRequest: false,
        approveIncomingTimeOffRequest: false,
        rejectIncomingTimeOffRequest: false,
    },

    dialogs: {
        createEditRequest: {
            isOpen: false,
            isLoading: false,

            countryOptions: [],
            isCountryOptionsLoading: false,
        },
        requestView: {
            isOpen: false,
            selectedId: null,
        },
        cancelRequest: {
            isOpen: false,
            selectedId: null,
        },
        createIssue: {
            isOpen: false,
            isLoading: false,
        },
        issueView: {
            isOpen: false,
            isLoading: false,
        },
        requestError: {
            title: '',
            message: [],
        },
        createTimeOff: {
            isOpen: false,
            selected: null,
        },
        cancelMyTimeOff: {
            isOpen: false,
            isLoading: false,
            selectedId: null,
        },
        approveIncomingTimeOff: {
            isOpen: false,
            isLoading: false,
        },
        rejectIncomingTimeOff: {
            isOpen: false,
            isLoading: false,
        },
        timeOffHistory: {
            isOpen: false,
            selected: null
        }
    },
}

export const HrSlice = createSlice({
    name: 'hr',
    initialState,
    reducers: {
        // clearList: (slice) => {
        //     slice.documents = [];
        //     slice.pageInfo = initialState.pageInfo;
        // },
        replaceRequestMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.myRequests.minMaxLoadedPage = payload;
        },
        eraseRequestList: (slice) => {
            slice.myRequests.requests = [];
            slice.myRequests.pageInfo = defaultPageInfo;
        },
        setCreateEditRequestDialogAction: (slice, {payload}: {payload: TSetDialogAction<THrSlice["dialogs"]["createEditRequest"]>}) => {
            if (payload.clear) {
                slice.dialogs.createEditRequest = initialState.dialogs.createEditRequest;
            } else {
                slice.dialogs.createEditRequest = {
                    ...slice.dialogs.createEditRequest,
                    ...payload
                }
            }
        },
        openRequestView: (slice, {payload}:{payload: string}) => {
            slice.dialogs.requestView.isOpen = true;
            slice.dialogs.requestView.selectedId = payload;
        },
        hideRequestView: (slice) => {
            slice.dialogs.requestView = initialState.dialogs.requestView;
            slice.myRequests.selected = null;
        },
        openCancelRequest: (slice, {payload}:{payload: string}) => {
            slice.dialogs.cancelRequest.isOpen = true;
            slice.dialogs.cancelRequest.selectedId = payload;
        },
        hideCancelRequest: (slice) => {
            slice.dialogs.cancelRequest = initialState.dialogs.cancelRequest;
        },
        openCreateIssue: (slice) => {
            slice.dialogs.createIssue.isOpen = true;
        },
        hideCreateIssue: (slice) => {
            slice.dialogs.createIssue = initialState.dialogs.createIssue;
        },
        openIssueView: (slice) => {
            slice.dialogs.issueView.isOpen = true;
        },
        hideIssueView: (slice) => {
            slice.dialogs.issueView = initialState.dialogs.issueView;
            slice.myIssues.selected = null;
        },

        //tree
        setTreeAction: (slice: THrSlice, {payload}: {payload: Partial<THrSlice["tree"] & {clear?: boolean}>}) => {
            if (payload.fullView !== undefined) slice.tree.fullView = payload.fullView;
            if (payload.direction !== undefined) slice.tree.direction = payload.direction;

            if (payload.clear !== undefined) slice.tree = initialState.tree;
        },
        //issues
        replaceIssueMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.myIssues.minMaxLoadedPage = payload;
        },
        setRequestErrorAction: (slice, {payload}: {payload: Partial<THrSlice["dialogs"]["requestError"]> & {clear?: boolean}}) => {
            if (payload.message) slice.dialogs.requestError.message = payload.message;
            if (payload.title !== undefined) slice.dialogs.requestError.title = payload.title;
            if (payload.clear) slice.dialogs.requestError = initialState.dialogs.requestError;
        },
        eraseIssueList: (slice) => {
            slice.myIssues.issues = [];
            slice.myIssues.pageInfo = defaultPageInfo;
        },
        //myTimeOff
        replaceMyTimeOffMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.myTimeOff.minMaxLoadedPage = payload;
        },
        eraseMyTimeOffList: (slice) => {
            slice.myTimeOff.requests = [];
            slice.myTimeOff.pageInfo = defaultPageInfo;
        },

        //incoming timeoff
        setCancelMyTimeOffAction: (slice: THrSlice, {payload}: {payload: Partial<THrSlice["dialogs"]["cancelMyTimeOff"] & {clear?: boolean}>}) => {
            if (payload.isOpen !== undefined) slice.dialogs.cancelMyTimeOff.isOpen = payload.isOpen;
            if (payload.selectedId !== undefined) slice.dialogs.cancelMyTimeOff.selectedId = payload.selectedId;
            if (payload.clear !== undefined) slice.dialogs.cancelMyTimeOff = initialState.dialogs.cancelMyTimeOff;
        },
        replaceIncomingTimeOffMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.incomingTimeOff.minMaxLoadedPage = payload;
        },
        eraseIncomingTimeOffList: (slice) => {
            slice.incomingTimeOff.requests = [];
            slice.incomingTimeOff.pageInfo = defaultPageInfo;
        },

        setCreateTimeOffDialogAction: (slice: THrSlice, {payload}: {payload: Partial<THrSlice["dialogs"]["createTimeOff"] & {clear?: boolean}>}) => {
            if (payload.isOpen !== undefined) slice.dialogs.createTimeOff.isOpen = payload.isOpen;
            if (payload.selected !== undefined) slice.dialogs.createTimeOff.selected = payload.selected;

            if (payload.clear !== undefined) slice.dialogs.createTimeOff = initialState.dialogs.createTimeOff;
        },
        setTimeOffHistoryDialogAction: (slice: THrSlice, {payload}: {payload: Partial<THrSlice["dialogs"]["timeOffHistory"] & {clear?: boolean}>}) => {
            if (payload.isOpen !== undefined) slice.dialogs.timeOffHistory.isOpen = payload.isOpen;
            if (payload.selected !== undefined) slice.dialogs.timeOffHistory.selected = payload.selected;
            if (payload.clear !== undefined) slice.dialogs.timeOffHistory = initialState.dialogs.timeOffHistory;
        },


        clearSelectedMyTimeOffRequestAction: (slice) => {
            slice.myTimeOff.selected = null;
        },
        clearSelectedIncomingTimeOffRequestAction: (slice) => {
            slice.incomingTimeOff.selected = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(portalHrGetProfileAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.isLoading.isLoadingMyProfile = true;

                if (clean) {
                    slice.myProfile = initialState.myProfile;
                    slice.jobHistory = initialState.jobHistory;
                }
            })
            .addCase(portalHrGetProfileAction.rejected, (slice) => {
                slice.isLoading.isLoadingMyProfile = false;
                slice.myProfile = null;
            })
            .addCase(portalHrGetProfileAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingMyProfile = false;
                slice.myProfile = payload;
            })

            .addCase(portalHrGetProfileJobHistoryAction.pending, (slice) => {
                slice.isLoading.isLoadingJobHistory = true;
            })
            .addCase(portalHrGetProfileJobHistoryAction.rejected, (slice) => {
                slice.isLoading.isLoadingJobHistory = false;
            })
            .addCase(portalHrGetProfileJobHistoryAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingJobHistory = false;

                const {array, pageInfo} = setPaginationInStore<THrProfileJobHistory>(
                    slice.jobHistory.pageInfo,
                    slice.jobHistory.data,
                    payload.pageInfo,
                    payload.result,
                );

                slice.jobHistory = {
                    data: array,
                    pageInfo,
                }
            })

            .addCase(portalHrCreateEditRequestAction.pending, (slice) => {
                slice.dialogs.createEditRequest.isLoading = true;
            })
            .addCase(portalHrCreateEditRequestAction.rejected, (slice) => {
                slice.dialogs.createEditRequest.isLoading = false;
            })
            .addCase(portalHrCreateEditRequestAction.fulfilled, (slice, {payload}) => {
                slice.dialogs.createEditRequest.isLoading = false;
                slice.dialogs.createEditRequest = initialState.dialogs.createEditRequest;
            })

            .addCase(portalHrGetSettingsCountriesAction.pending, (slice) => {
                slice.dialogs.createEditRequest.isCountryOptionsLoading = true;
            })
            .addCase(portalHrGetSettingsCountriesAction.rejected, (slice) => {
                slice.dialogs.createEditRequest.isCountryOptionsLoading = false;
            })
            .addCase(portalHrGetSettingsCountriesAction.fulfilled, (slice, {payload}) => {
                slice.dialogs.createEditRequest.isCountryOptionsLoading = false;
                slice.dialogs.createEditRequest.countryOptions = payload;
            })

            //GetPortalHrRequestsWithFilterPagination
            .addCase(getPortalHrRequestsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.isLoading.requestList = true;

                if (clean) {
                    slice.myRequests.requests = initialState.myRequests.requests;
                    slice.myRequests.pageInfo = initialState.myRequests.pageInfo;
                    slice.myRequests.minMaxLoadedPage = initialState.myRequests.minMaxLoadedPage;
                }
            })
            .addCase(getPortalHrRequestsWithFilterPaginationAction.rejected, (slice) => {
                slice.isLoading.requestList = false;
            })
            .addCase(getPortalHrRequestsWithFilterPaginationAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.requestList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<PortalHrRequestModel>(
                    slice.myRequests.requests,
                    slice.myRequests.pageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.myRequests.minMaxLoadedPage.minLoadedPage,
                    slice.myRequests.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.myRequests.requests = results;
                slice.myRequests.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.myRequests.pageInfo = payload.pageInfo;

            })
            // GetPortalHrRequestById
            .addCase(getPortalHrRequestByIdAction.pending, (slice) => {
                slice.isLoading.requestExact = true;
            })
            .addCase(getPortalHrRequestByIdAction.rejected, (slice) => {
                slice.isLoading.requestExact = false;
            })
            .addCase(getPortalHrRequestByIdAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.requestExact = false;
                slice.myRequests.selected = payload;
            })
        //PortalHrCancelRequest
            .addCase(portalHrCancelRequestAction.pending, (slice) => {
                slice.isLoading.cancelRequest = true;
            })
            .addCase(portalHrCancelRequestAction.rejected, (slice, {payload, meta}) => {
                slice.isLoading.cancelRequest = false;

                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length) {
                    slice.dialogs.requestError = {
                        title: `Error cancelling request ${meta.arg.data.requestId}`,
                        message: errors409,
                    }
                }
            })
            .addCase(portalHrCancelRequestAction.fulfilled, (slice, {payload, meta}) => {
                slice.isLoading.cancelRequest = false;
                slice.myRequests.requests = slice.myRequests.requests.map(e => e.id === meta.arg.data.requestId ? {...e, status: HrRequestStatus.Cancelled} : e);
                slice.dialogs.requestView = initialState.dialogs.requestView;
                slice.dialogs.cancelRequest = initialState.dialogs.cancelRequest;
            })
        //portalHrGetOrgTreeAction
            .addCase(portalHrGetOrgTreeAction.pending, (slice) => {
                slice.isLoading.isLoadingTree = true;
            })
            .addCase(portalHrGetOrgTreeAction.rejected, (slice) => {
                slice.isLoading.isLoadingTree = false;
            })
            .addCase(portalHrGetOrgTreeAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.isLoadingTree = false;
                slice.tree.employees = payload;
            })
            //GetPortalHrIssuesWithFilterPagination
            .addCase(portalHrGetIssuesWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.isLoading.issueList = true;

                if (clean) {
                    slice.myIssues.issues = initialState.myIssues.issues;
                    slice.myIssues.pageInfo = initialState.myIssues.pageInfo;
                    slice.myIssues.minMaxLoadedPage = initialState.myIssues.minMaxLoadedPage;
                }
            })
            .addCase(portalHrGetIssuesWithFilterPaginationAction.rejected, (slice) => {
                slice.isLoading.issueList = false;
            })
            .addCase(portalHrGetIssuesWithFilterPaginationAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.issueList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<PortalHrIssueModel>(
                    slice.myIssues.issues,
                    slice.myIssues.pageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.myIssues.minMaxLoadedPage.minLoadedPage,
                    slice.myIssues.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.myIssues.issues = results;
                slice.myIssues.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.myIssues.pageInfo = payload.pageInfo;

            })
        //portalHrCreateIssueAction
            .addCase(portalHrCreateIssueAction.pending, (slice) => {
                slice.dialogs.createIssue.isLoading = true;
            })
            .addCase(portalHrCreateIssueAction.rejected, (slice) => {
                slice.dialogs.createIssue.isLoading = false;
            })
            .addCase(portalHrCreateIssueAction.fulfilled, (slice, {payload}) => {
                slice.dialogs.createIssue.isLoading = false;
                slice.dialogs.createIssue = initialState.dialogs.createIssue;
            })
            .addCase(getPortalHrIssueByIdAction.pending, (slice) => {
                slice.dialogs.issueView.isLoading = true;
            })
            .addCase(getPortalHrIssueByIdAction.rejected, (slice) => {
                slice.dialogs.issueView.isLoading = false;
            })
            .addCase(getPortalHrIssueByIdAction.fulfilled, (slice, {payload}) => {
                slice.dialogs.issueView.isLoading = false;
                slice.myIssues.selected = payload;
            })

            //myTimeOff
            .addCase(portalHrGetMyTimeOffRequestsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.isLoading.myTimeOffList = true;

                if (clean) {
                    slice.myTimeOff.requests = initialState.myTimeOff.requests;
                    slice.myTimeOff.pageInfo = initialState.myTimeOff.pageInfo;
                    slice.myTimeOff.minMaxLoadedPage = initialState.myTimeOff.minMaxLoadedPage;
                }
            })
            .addCase(portalHrGetMyTimeOffRequestsWithFilterPaginationAction.rejected, (slice) => {
                slice.isLoading.myTimeOffList = false;
            })
            .addCase(portalHrGetMyTimeOffRequestsWithFilterPaginationAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.myTimeOffList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<PortalHrTimeOffRequestModel>(
                    slice.myTimeOff.requests,
                    slice.myTimeOff.pageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.myTimeOff.minMaxLoadedPage.minLoadedPage,
                    slice.myTimeOff.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.myTimeOff.requests = results;
                slice.myTimeOff.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.myTimeOff.pageInfo = payload.pageInfo;

            })

            .addCase(portalHrGetTimeOffRequestsSettingsAction.pending, (slice) => {
                slice.isLoading.timeOffSettings = true;
            })
            .addCase(portalHrGetTimeOffRequestsSettingsAction.rejected, (slice) => {
                slice.isLoading.timeOffSettings = false;
            })
            .addCase(portalHrGetTimeOffRequestsSettingsAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.timeOffSettings = false;
                slice.timeOffSettings = payload
            })

            //incoming timeOff
            .addCase(portalHrGetTimeOffRequestsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.isLoading.incomingTimeOffList = true;

                if (clean) {
                    slice.incomingTimeOff.requests = initialState.incomingTimeOff.requests;
                    slice.incomingTimeOff.pageInfo = initialState.incomingTimeOff.pageInfo;
                    slice.incomingTimeOff.minMaxLoadedPage = initialState.incomingTimeOff.minMaxLoadedPage;
                }
            })
            .addCase(portalHrGetTimeOffRequestsWithFilterPaginationAction.rejected, (slice) => {
                slice.isLoading.incomingTimeOffList = false;
            })
            .addCase(portalHrGetTimeOffRequestsWithFilterPaginationAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.incomingTimeOffList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<PortalHrTimeOffRequestModel>(
                    slice.incomingTimeOff.requests,
                    slice.incomingTimeOff.pageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.incomingTimeOff.minMaxLoadedPage.minLoadedPage,
                    slice.incomingTimeOff.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.incomingTimeOff.requests = results;
                slice.incomingTimeOff.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.incomingTimeOff.pageInfo = payload.pageInfo;

            })
            .addCase(portalHrGetTimeOffRequestByIdAction.pending, (slice) => {
                slice.isLoading.myTimeOffRequest = true;
            })
            .addCase(portalHrGetTimeOffRequestByIdAction.rejected, (slice, {payload, meta}) => {
                slice.isLoading.myTimeOffRequest = false;

                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length) {
                    slice.dialogs.requestError = {
                        title: 'Time off request not found',
                        message: [`Time off request ${meta.arg.data.requestId} not found.`]
                    }
                }
            })
            .addCase(portalHrGetTimeOffRequestByIdAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.myTimeOffRequest = false;
                slice.myTimeOff.selected = payload;
            })

            .addCase(portalHrCancelMyTimeOffRequestAction.pending, (slice) => {
                slice.dialogs.cancelMyTimeOff.isLoading = true;
            })
            .addCase(portalHrCancelMyTimeOffRequestAction.rejected, (slice, { payload, meta: {arg}}) => {
                slice.dialogs.cancelMyTimeOff = initialState.dialogs.cancelMyTimeOff;

                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length) {
                    slice.dialogs.requestError = {
                        title: `Error cancelling time off request ${arg.data.timeOffId}`,
                        message: errors409
                    }
                }
            })
            .addCase(portalHrCancelMyTimeOffRequestAction.fulfilled, (slice, {payload, meta}) => {
                slice.dialogs.cancelMyTimeOff.isLoading = false;
                slice.dialogs.cancelMyTimeOff = initialState.dialogs.cancelMyTimeOff;
                slice.myTimeOff.requests = slice.myTimeOff.requests.map(e => e.id === meta.arg.data.timeOffId ? ({...e, status: HrTimeOffStatus.Cancelled}) : e)
            })

            .addCase(portalHrCreateTimeOffRequestAction.pending, (slice) => {
                slice.isLoading.creatingTimeOff = true;
            })
            .addCase(portalHrCreateTimeOffRequestAction.rejected, (slice, {payload, meta: {arg}}) => {
                slice.isLoading.creatingTimeOff = false;

                const error409 = getErrorsByCode<string>(409, payload?.all || []);
                if (error409.length) {
                    slice.dialogs.requestError = {
                        title: `Error creating time off request`,
                        message: error409,
                    };
                }
            })
            .addCase(portalHrCreateTimeOffRequestAction.fulfilled, (slice, {payload}) => {
                slice.isLoading.creatingTimeOff = false;
                slice.dialogs.createTimeOff = initialState.dialogs.createTimeOff;
            })

            .addCase(portalHrUpdateMyTimeOffRequestAction.pending, (slice) => {
                slice.isLoading.creatingTimeOff = true;
            })
            .addCase(portalHrUpdateMyTimeOffRequestAction.rejected, (slice, {payload, meta: {arg}}) => {
                slice.isLoading.creatingTimeOff = false;

                const error409 = getErrorsByCode<string>(409, payload?.all || []);
                if (error409.length) {
                    slice.dialogs.requestError = {
                        title: `Error updating time off request ${arg.data.timeOffId}`,
                        message: error409,
                    };
                }
            })
            .addCase(portalHrUpdateMyTimeOffRequestAction.fulfilled, (slice, {payload, meta: {arg: {data}}}) => {
                slice.isLoading.creatingTimeOff = false;
                slice.dialogs.createTimeOff = initialState.dialogs.createTimeOff;
                if (slice.myTimeOff.selected) {
                    slice.myTimeOff.selected = {
                        ...slice.myTimeOff.selected,
                        ...data.data,
                    }
                }

                slice.myTimeOff.requests = slice.myTimeOff.requests.map(e => e.id === data.timeOffId ? ({...e, ...data.data}) : e)
            })

        //time off management
            .addCase(portalHrGetAndChangeStepStatusTimeOffRequestAction.pending, (slice) => {
                slice.isLoading.incomingTimeOffExact = true;
            })
            .addCase(portalHrGetAndChangeStepStatusTimeOffRequestAction.rejected, (slice, {payload, meta}) => {
                slice.isLoading.incomingTimeOffExact = false;
                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length) {
                    slice.dialogs.requestError = {
                        title: 'Time off request not found',
                        message: [`Time off request ${meta.arg.data.requestId} not found.`]
                    }
                }
            })
            .addCase(portalHrGetAndChangeStepStatusTimeOffRequestAction.fulfilled, (slice, {payload, meta}) => {
                slice.isLoading.incomingTimeOffExact = false;

                if (payload.status === HrTimeOffStatus.Cancelled) {
                    if (!slice.incomingTimeOff.selected) {
                        slice.dialogs.requestError.message = ['Request was cancelled'];
                        slice.dialogs.requestError.title = `Time off request ${payload.id}`;
                    }
                } else {
                    slice.incomingTimeOff.selected = payload;
                }

                slice.incomingTimeOff.requests = slice.incomingTimeOff.requests.map(e => e.id === meta.arg.data.requestId ? ({...e, status: HrTimeOffStatus.Approval}) : e);
            })

            //approve
            .addCase(portalHrApproveTimeOffRequestAction.pending, (slice) => {
                slice.isLoading.approveIncomingTimeOffRequest = true;
            })
            .addCase(portalHrApproveTimeOffRequestAction.rejected, (slice, {payload}) => {
                slice.isLoading.approveIncomingTimeOffRequest = false;
                const error409 = getErrorsByCode<string>(409, payload?.all || []);
                if (error409.length) {
                    slice.dialogs.requestError = {
                        title: 'Error',
                        message: error409,
                    };
                }
            })
            .addCase(portalHrApproveTimeOffRequestAction.fulfilled, (slice, {payload, meta}) => {
                slice.isLoading.approveIncomingTimeOffRequest = false;
                slice.dialogs.approveIncomingTimeOff = initialState.dialogs.approveIncomingTimeOff;

                slice.incomingTimeOff.requests = slice.incomingTimeOff.requests.map(e => e.id === meta.arg.data.requestId ? ({...e, status: HrTimeOffStatus.Approval}) : e);
            })

            //reject
            .addCase(portalHrRejectTimeOffRequestAction.pending, (slice) => {
                slice.isLoading.rejectIncomingTimeOffRequest = true;
            })
            .addCase(portalHrRejectTimeOffRequestAction.rejected, (slice, {payload}) => {
                slice.isLoading.rejectIncomingTimeOffRequest = false;
                const error409 = getErrorsByCode<string>(409, payload?.all || []);
                if (error409.length) {
                    slice.dialogs.requestError = {
                        title: 'Error',
                        message: error409,
                    };
                }
            })
            .addCase(portalHrRejectTimeOffRequestAction.fulfilled, (slice, {payload, meta}) => {
                slice.isLoading.rejectIncomingTimeOffRequest = false;
                slice.dialogs.rejectIncomingTimeOff = initialState.dialogs.rejectIncomingTimeOff;

                slice.incomingTimeOff.requests = slice.incomingTimeOff.requests.map(e => e.id === meta.arg.data.requestId ? ({...e, status: HrTimeOffStatus.Rejected}) : e);
            })

            .addCase(DownloadFile.pending, (slice, {meta}) => {
                slice.isLoading.downloadingFile.push(meta.arg.fileId);
            })
            .addCase(DownloadFile.rejected, (slice, {meta}) => {
                slice.isLoading.downloadingFile = slice.isLoading.downloadingFile.filter(id => id !== meta.arg.fileId);
            })
            .addCase(DownloadFile.fulfilled, (slice, {meta}) => {
                slice.isLoading.downloadingFile = slice.isLoading.downloadingFile.filter(id => id !== meta.arg.fileId);
            })

    }
});

export const HRReducer = HrSlice.reducer;

export const {
    // clearList,
    openRequestView,
    hideRequestView,
    openCancelRequest,
    hideCancelRequest,
    eraseRequestList,
    replaceRequestMinMaxLoadedPage,
    openCreateIssue,
    hideCreateIssue,
    eraseIssueList,
    replaceIssueMinMaxLoadedPage,
    openIssueView,
    hideIssueView,
    setCreateEditRequestDialogAction,
    setTreeAction,
    setRequestErrorAction,
    replaceMyTimeOffMinMaxLoadedPage,
    eraseMyTimeOffList,
    replaceIncomingTimeOffMinMaxLoadedPage,
    eraseIncomingTimeOffList,

    setCreateTimeOffDialogAction,
    setCancelMyTimeOffAction,
    setTimeOffHistoryDialogAction,

    clearSelectedMyTimeOffRequestAction,
    clearSelectedIncomingTimeOffRequestAction,
} = HrSlice.actions;
const selectSelf = (state: AppState): THrSlice => state.hr;

export const hrMyProfileSelector = createSelector(selectSelf, state => state.myProfile);
export const hrMyProfileJobHistorySelector = createSelector(selectSelf, state => state.jobHistory);

export const hrMyRequestsSelector = createSelector(selectSelf, state => state.myRequests);
export const hrMyIssuesSelector = createSelector(selectSelf, state => state.myIssues);
export const hrMyTimeOffSelector = createSelector(selectSelf, state => state.myTimeOff);
export const hrIncomingTimeOffSelector = createSelector(selectSelf, state => state.incomingTimeOff);
export const hrTimeOffSettingsSelector = createSelector(selectSelf, state => state.timeOffSettings);
export const hrOrgTreeSelector = createSelector(selectSelf, state => state.tree);

export const hrIsLoadingSelector = createSelector(selectSelf, state => state.isLoading);
export const hrDialogsSelector = createSelector(selectSelf, state => state.dialogs);

