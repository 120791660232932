import {MouseEvent, useState} from "react";

export const useThreeDotsMenu = () => {

    const [anchorThreeDotsEl, setAnchorThreeDotsEl] = useState<null | HTMLElement>(null);
    const handleThreeDotsOpen = (event: MouseEvent<HTMLElement>) => {setAnchorThreeDotsEl(event.currentTarget);};
    const handleThreeDotsClose = () => {setAnchorThreeDotsEl(null);};

    return {
        anchorThreeDotsEl, setAnchorThreeDotsEl,
        handleThreeDotsOpen, handleThreeDotsClose,
        open: Boolean(anchorThreeDotsEl)
    }
}