import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {print} from "graphql";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    PortalTrainingGetAssignmentExamsAutocompleteQuery,
    PortalTrainingGetAssignmentExamsAutocompleteQueryVariables,
    PortalTrainingGetAssignmentRequestorsAutocompleteQuery,
    PortalTrainingGetAssignmentRequestorsAutocompleteQueryVariables,
    PortalTrainingGetAssignmentTrainingsAutocompleteQuery,
    PortalTrainingGetAssignmentTrainingsAutocompleteQueryVariables
} from "../../../../newShared/GQLTypes";
import {
    portalTrainingGetAssignmentExamsAutocomplete,
    portalTrainingGetAssignmentRequestorsAutocomplete,
    portalTrainingGetAssignmentTrainingsAutocomplete
} from "./query";

export const portalTrainingGetAssignmentRequestorsAutocompleteApi = async (data: PortalTrainingGetAssignmentRequestorsAutocompleteQueryVariables, signal?: AbortSignal): Promise<PortalTrainingGetAssignmentRequestorsAutocompleteQuery['portalTrainingGetAssignmentRequestorsAutocomplete']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(portalTrainingGetAssignmentRequestorsAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalTrainingGetAssignmentRequestorsAutocomplete;
    })
)


export const portalTrainingGetAssignmentTrainingsAutocompleteApi = async (data: PortalTrainingGetAssignmentTrainingsAutocompleteQueryVariables, signal?: AbortSignal): Promise<PortalTrainingGetAssignmentTrainingsAutocompleteQuery['portalTrainingGetAssignmentTrainingsAutocomplete']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(portalTrainingGetAssignmentTrainingsAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalTrainingGetAssignmentTrainingsAutocomplete;
    })
)

export const portalTrainingGetAssignmentExamsAutocompleteApi = async (data: PortalTrainingGetAssignmentExamsAutocompleteQueryVariables, signal?: AbortSignal): Promise<PortalTrainingGetAssignmentExamsAutocompleteQuery['portalTrainingGetAssignmentExamsAutocomplete']> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(portalTrainingGetAssignmentExamsAutocomplete),
                variables: {
                    ...data,
                }
            },
            {
                headers: buildCommonHeader(),
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalTrainingGetAssignmentExamsAutocomplete;
    })
)