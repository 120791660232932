import {PortalTrainingContentModel, TrainingContentStatus} from "../../../../../../../../newShared/GQLTypes";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

type TrainingInfoPropsType = {
    currentDuration: number;
    trainingLimitInH: number;
    sections: PortalTrainingContentModel[]
}
export const TrainingInfo = ({currentDuration, trainingLimitInH, sections}: TrainingInfoPropsType) => {
    const timeToEndInSecs = trainingLimitInH * 60 * 60 - currentDuration;
    let hours = Math.max(Math.floor(timeToEndInSecs / 3600), 0);
    let minutes = Math.max(Math.floor((timeToEndInSecs % 3600) / 60), 0);
    let remainingSeconds = Math.max(Math.floor(timeToEndInSecs % 60), 0);

    return(
        <Flex
            w={'279px'}
            direction={'column'}
            p={'20px'}
            gap={'20px'}
            br={'8px'}
            border={`1px solid ${colors.stroke.table}`}
        >
            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{('Information')}</Typography>

            {trainingLimitInH > 0 &&
                <Flex ai={'center'} gap={'12px'}>
                    <AccessTimeOutlinedIcon sx={{color: colors.primary.blue}}/>
                    <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{(`Minimum duration ${trainingLimitInH} hours`)}</Typography>
                </Flex>
            }

            <Flex ai={'center'} gap={'12px'}>
                <CheckCircleOutlineOutlinedIcon sx={{color: colors.primary.blue}}/>
                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{(`Progress ${sections.filter(e => e.status === TrainingContentStatus.Done).length}/${sections.length}`)}</Typography>
            </Flex>

            {trainingLimitInH > 0 &&
                <Flex direction={'column'}>
                    <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{('Count down')}</Typography>

                    <Flex gap={'20px'} ai={'center'}>
                        <Typography variant={'*h3'}>{`${hours <= 9 ? 0 : ''}${hours}:${minutes <= 9 ? 0 : ''}${minutes}:${remainingSeconds <= 9 ? 0 : ''}${remainingSeconds}`}</Typography>

                        <Flex w={'50%'}>
                            <Typography variant={'*bodySmall'} color={colors.text.grey_dark}>
                                {('You can finish this training when the count down time over.')}
                            </Typography>
                        </Flex>
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}