import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from "../styled";
import {TableCell} from "@mui/material";
import React from "react";
import {ColumnType} from "../../constants";

export const DynamicHeader = ({columnConfig, total}: {columnConfig: ColumnType[], total: number | string}) => {
    const {t} = useMainTranslation('', {keyPrefix: 'inboxPage'});

    return(
        <S.InboxTableHead>
            <S.InboxTotalTableRow>
                {/*Column actions is not in columnConfig - its generated by default at the end of row*/}
                <TableCell colSpan={columnConfig.length + 1}>
                    <S.InboxTotalTypography>{`${t('Total documents')}: ${total}`}</S.InboxTotalTypography>
                </TableCell>
            </S.InboxTotalTableRow>

            <S.InboxTableRow>
                {columnConfig.map((e, id) => {
                    return (
                        <S.InboxTableCell key={e.key}>
                            <S.InboxTableTypography>{t(e.normalizedName)}</S.InboxTableTypography>
                        </S.InboxTableCell>
                    )
                })}
                {/*<TableCell />*/}
            </S.InboxTableRow>
        </S.InboxTableHead>
    )
}