import React, {FC, MouseEvent, useCallback, useEffect, useState} from "react";
import ReactFlow, {
    Background,
    ControlButton,
    Controls,
    Edge,
    EdgeTypes,
    MiniMap,
    Node,
    OnConnectStart,
    Panel,
    useEdgesState,
    useNodesInitialized,
    useNodesState,
    useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {EmployeeTreeNode} from "../employeeTreeNode";
import {CloseFullscreen, OpenInFull, SwapHorizRounded, SwapVertRounded} from "@mui/icons-material";
import {EmployeeTreeEdgeSuccess} from "../employeeTreeEdge";
import {NodeTypes} from "@reactflow/core/dist/esm/types";

import {isEventEdgeConnectEnd, TSmallEmployee} from "../../../types";
import {OnConnectStartParams} from "@reactflow/core/dist/esm/types/general";
import {EmployeeTreeConnectionLine} from "../employeeTreeConnectionLine";
import {Autocomplete, Box, IconButton, TextField} from "@mui/material";
import {EmployeesBarUnderOrgTree} from "../employeesBarUnderOrgTree";
import {useEmployeesTree} from "../../../hooks/useEmployeesTree";
import {useParameters} from "../../../../../../newShared/hooks/useParameters";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {employeeTreeNodeHeight, employeeTreeNodeWidth, TEmployeeNodeData} from "../../../helpers";
import colors from "../../../../../../newShared/theme/colors";

const nodeTypes: NodeTypes = {
    employeeTreeNode: EmployeeTreeNode,
};
const edgeTypes: EdgeTypes = {
    edit: EmployeeTreeEdgeSuccess,
};

const deleted: string[] = [];
export const EmployeesTreeComponent: FC<{readonly?: boolean}> = ({readonly = false}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathTree'});
    const {matches_770Up} = useMedia();

    const {
        fullView, direction,
        isLoading: {isLoadingTree},
        setTree,
        employees,
        focusNode,
    } = useEmployeesTree();

    const {setCenter} = useReactFlow<TEmployeeNodeData>();
    const nodesInitialized = useNodesInitialized();
    const {filter} = useParameters();

    const {getNodes} = useReactFlow<TEmployeeNodeData>();
    const [nodes, , onNodesChange] = useNodesState<TEmployeeNodeData>([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [srcNode, setSrcNode] = useState<string | null>(null);

    const MIN_DISTANCE = direction === 'TB' ? employeeTreeNodeHeight : employeeTreeNodeWidth;
    const xPlus = direction === 'LR' ? employeeTreeNodeWidth*1.5 : direction === 'RL' ? -employeeTreeNodeWidth*1.5 : 0;
    const yPlus = direction === 'TB' ? employeeTreeNodeHeight*1.5 : 0;
    const getClosestEdge = useCallback((node: Node<TEmployeeNodeData>): Edge | null => {
        if (deleted.includes(node.id)) return null;

        const nodes = getNodes();
        const closestNode = nodes
            .reduce(
                (res, n) => {
                    if (n?.positionAbsolute && node?.positionAbsolute && n.id !== node.id && !deleted.includes(n.id)) {
                        const dx = (n.positionAbsolute.x + xPlus) - node.positionAbsolute.x;
                        const dy = (n.positionAbsolute.y + yPlus) - node.positionAbsolute.y;
                        const d = Math.sqrt(dx * dx + dy * dy);

                        if (d < res.distance && d < MIN_DISTANCE) {
                            res.distance = d;
                            res.node = n;
                        }
                    }

                    return res;
                },
                {
                    distance: Number.MAX_VALUE,
                    node: null,
                } as { distance: number, node: Node | null }
            );

        if (!closestNode.node) return null;

        const closeNodeIsSource = direction === 'TB'
            ? (closestNode?.node?.positionAbsolute?.y || 0) < (node?.positionAbsolute?.y || 0)
            : direction === 'LR'
                ? (closestNode?.node?.positionAbsolute?.x || 0) < (node?.positionAbsolute?.x || 0)
                : (closestNode?.node?.positionAbsolute?.x || 0) > (node?.positionAbsolute?.x || 0);

        if (!closeNodeIsSource) return null;

        return {
            id: `TMP::${node.id}::${closestNode.node.id}`,
            source: closestNode.node.id,
            target: node.id,
            animated: true,
            style: {stroke: colors.stroke.dark},
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [direction, deleted, nodes]);

    const onNodeDrag = useCallback(
        (_, node: Node) => {
            const closeEdge = getClosestEdge(node);

            setEdges((es) => {
                const nextEdges = es.filter((e) => e.className !== 'temp');

                if (
                    closeEdge &&
                    !nextEdges.find((ne) => ne.source === closeEdge.source && ne.target === closeEdge.target)
                ) {
                    closeEdge.className = 'temp';
                    nextEdges.push(closeEdge);
                }

                return nextEdges;
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getClosestEdge, setEdges]
    );

    const onNodeDragStop = useCallback(
        (_, node: Node<TEmployeeNodeData>) => {
            const closeEdge = getClosestEdge(node);
            const closeEdgeId = closeEdge ? `EDIT::${closeEdge.source}::${closeEdge.target}` : null;

            setEdges((es) => {
                const nextEdges = es.filter((e) => e.className !== 'temp');

                if (closeEdge && closeEdgeId && !nextEdges.some(e => e.target === closeEdge.target && e.source === closeEdge.source)) {
                    nextEdges.push({...closeEdge, id: closeEdgeId, type: 'edit', style: undefined});
                }

                return nextEdges.map((e, index, array) => array.filter(f => f.target === e.target).length > 1 ? ({
                    ...e,
                    type: 'edit',
                    animated: true
                }) : e);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getClosestEdge, direction, deleted]
    );

    const onConnectStart = useCallback(
        (event: MouseEvent, params: OnConnectStartParams) => {
            if (!deleted.includes(params.nodeId || '')) setSrcNode(params.nodeId);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deleted]
    );

    const onConnectEnd = useCallback(
        (event: unknown) => {
            if (isEventEdgeConnectEnd(event) && !deleted.includes(event.target?.dataset?.nodeid || '')) {
                const target = event.target?.dataset?.nodeid;
                const source = srcNode;

                if (target !== source && source && target) {
                    setTimeout(() => {
                        const oldEdge = edges.find(e => e.target === target && e.source === source);

                        if (!oldEdge) {
                            setEdges(edges => [...edges, {type: 'edit', animated: true, id: `EDIT::${source}::${target}`, source, target}])
                        }

                        setSrcNode(null)
                    }, 0)
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [srcNode, deleted]
    );

    const onEdgesDelete = useCallback(
        (edges: Edge[]) => {
            setEdges(edge => edge.filter(e => !edges.some(del => del.id === e.id)).map(edge => (
                {...edge, id: `EDIT::${edge.source}::${edge.target}`, type: 'edit', animated: true,}
            )))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [srcNode]
    );

    useEffect(() => {
        if (nodesInitialized && filter?.id?.[0]) {
            focusNode(nodes, filter?.id?.[0], setCenter)
        } else if (nodes.length > 100) setCenter(10000, 1000, {zoom: 0.1});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.id?.[0], nodesInitialized]);

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onEdgesDelete={onEdgesDelete}
            // onConnect={onConnect}

            onNodeDrag={onNodeDrag}
            onNodeDragStop={onNodeDragStop}
            onConnectStart={onConnectStart as OnConnectStart}
            onConnectEnd={onConnectEnd}

            fitView
            style={{display: isLoadingTree ? 'none' : undefined}}
            connectionLineComponent={EmployeeTreeConnectionLine}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}

            edgesUpdatable={!readonly}
            edgesFocusable={!readonly}
            nodesDraggable={!readonly}
            nodesConnectable={!readonly}
            nodesFocusable={!readonly}
            elementsSelectable={!readonly}

            dir={'ltr'}
        >
            <Controls>
                {direction === 'TB' && (
                    <ControlButton onClick={() => {setTree({direction: !revDir ? 'LR' : 'RL'})}}>
                        <SwapVertRounded style={{maxHeight: '30px', maxWidth: '30px'}}/>
                    </ControlButton>
                )}
                {(direction === 'RL' || direction === 'LR') && (
                    <ControlButton onClick={() => {setTree({direction: 'TB'})}}>
                        <SwapHorizRounded style={{maxHeight: '30px', maxWidth: '30px'}}/>
                    </ControlButton>
                )}
            </Controls>
            {matches_770Up && <MiniMap zoomable pannable/>}
            <Panel position={"top-left"} style={{margin: 0, padding: '12px 0', width: '100%', backgroundColor: colors.backgrounds.white}}>
                <Box display={'flex'} >
                    <Autocomplete<TSmallEmployee>
                        disabled={isLoadingTree}
                        options={employees}
                        getOptionLabel={e => e.name}
                        onChange={(_, value) => {value && focusNode(nodes, value.id, setCenter)}}

                        sx={{width: '100%', maxWidth: '320px', mr: !revDir ? '16px' : undefined, ml: revDir ? '16px' : undefined}} size={"small"} dir={'ltr'}
                        renderInput={(params) => <TextField {...params} label={t('Employees')} sx={{'& label': {top: '0 !important'}}} />}
                        renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
                    />
                    {fullView && <EmployeesBarUnderOrgTree/>}
                </Box>
            </Panel>
            <Panel position={"bottom-right"}>
                <IconButton size={"large"} sx={{
                    backgroundColor: colors.backgrounds.white,
                    borderRadius: '8px',
                    width: '42px',
                    height: '42px',
                    boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
                    '&:hover': { backgroundColor: colors.backgrounds.white },
                }} onClick={() => {setTree({fullView: !fullView})}}>
                    {fullView ? <CloseFullscreen/> : <OpenInFull/>}
                </IconButton>
            </Panel>
            <Background color={colors.backgrounds.white}/>
        </ReactFlow>
    )
}
