import {useDispatch, useSelector} from "react-redux";
import {loadingsSelector} from "../../../store/slice";
import {useTrainingsDialogs} from "../useTrainingsDialogs";
import {useEffect, useState} from "react";
import {
    PortalTrainingsAssignmentsExamQuestionModel,
    TrainingAssignmentExamAnswerStatus,
    TrainingAssignmentExamType,
    TrainingAssignmentStatus,
    TrainingExamQuestionType
} from "../../../../../../newShared/GQLTypes";
import {
    portalTrainingsExamSaveAnswerAction,
    portalTrainingsExamSkipAnswerAction,
    portalTrainingsGetExamByAssignmentIdAction
} from "../../../store/actions";
import moment from "moment/moment";
import {useCustomFormik} from "../../../../../../newShared/hooks/useCustomFormik";
import {ExamInfoTimerProps} from "../../../components/dialogs/passingExamDialog/components/examInfoTimer";
import {useValidationSchema} from "./useValidationSchema";
import {TRAININGS_ASSIGNMENTS_PATH} from "../../../../../../newShared/constants";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useHistory} from "react-router-dom";

export const usePassingExamDialog = ({refreshAssignment}: {refreshAssignment: () => void }) => {
    // const {t} = useMainTranslation();
    const dispatch = useDispatch();

    const {completingExam, savingAnswer, gettingExam} = useSelector(loadingsSelector);
    const {isOpen, exam, handleClose: _handleClose} = useTrainingsDialogs().passingExam;

    const handleReset = () => {
        setActiveQuestion(null);
        setStartTime(null);
        setTimeIsUp(false);
        setIsAbleToFinish(false);
    };

    const handleClose = () => {
        _handleClose();
        handleReset();
    };
    const {setMessage} = useMessageDialog();
    const history = useHistory();

    const [activeQuestion, setActiveQuestion] = useState<{question: PortalTrainingsAssignmentsExamQuestionModel, index: number} | null>(null);
    const [isChangingAnswer, setIsChangingAnswer] = useState(false);
    const [isAbleToFinish, setIsAbleToFinish] = useState(false);

    const getExam = (assignmentId: string, type: TrainingAssignmentExamType) => {
        dispatch(portalTrainingsGetExamByAssignmentIdAction({
            data: {assignmentId, type},
            onSuccess: (request, response, addictiveData) => {
                formik.resetForm({values: {questions: response.questions.slice().sort((a, b) => a.order - b.order)}});
                handleSelectActiveQuestion(response.questions[0], 0);

                setStartTime(moment());
            },
            onError: (request, errors, addictiveData) => {
                const e404 = errors.e404?.[0];
                const e409 = errors.e409?.[0];

                if (e404) {
                    setMessage({title: 'Start assignment exam', message: 'Assignment not found.'});
                    history.push(TRAININGS_ASSIGNMENTS_PATH);
                }

                if (e409?.type === 'STATUS_CHANGED') {
                    const value = e409?.values.find(e => e.parameter === 'status')?.value;
                    switch (value) {
                        case TrainingAssignmentStatus.Cancelled: {
                            setMessage({title: 'Start assignment exam', message: 'Assignment was cancelled.'});
                            refreshAssignment();
                            handleClose();
                            return;
                        }
                        default: {
                            refreshAssignment();
                            handleClose();
                        }
                    }
                }

                if (e409?.type === 'EXAM_NOT_EXISTS') {
                    setMessage({title: 'Start assignment exam', message: 'Exam not found.'});
                    refreshAssignment();
                    handleClose();
                }
            },
        }))
    };

    const finishExamDialog = useTrainingsDialogs().finishExam;
    const handleCompleteExam = (timeEnd: boolean, questions: PortalTrainingsAssignmentsExamQuestionModel[] = formik.values.questions) => {
        finishExamDialog.setIsOpen({
            total: questions.length,
            skipped: questions.filter(q => q.status === TrainingAssignmentExamAnswerStatus.Skipped).length,
            answered: questions.filter(q => q.status === TrainingAssignmentExamAnswerStatus.Answered).length,
            timeEnd,
            inputsCount: questions
                .filter(q =>
                    [TrainingExamQuestionType.Paragraph, TrainingExamQuestionType.ShortAnswer].includes(q.type)
                    && q.status === TrainingAssignmentExamAnswerStatus.Answered).length
            ,
        })
    };

    const handleSaveAnswer = (type: 'SKIP' | 'SAVE', goToNext?: boolean) => {
        if (!activeQuestion || !isOpen) return;

        if (type === 'SKIP') {
            dispatch(portalTrainingsExamSkipAnswerAction({
                data: {assignmentId: isOpen.assignmentId, type: isOpen.type, questionId: activeQuestion.question.questionId},
                onSuccess: (request, response, addictiveData) => {
                    formik.setFieldValue(`questions[${activeQuestion?.index}]status`, TrainingAssignmentExamAnswerStatus.Skipped);
                    formik.setFieldValue(`questions[${activeQuestion?.index}]answer`, []);
                    handleSelectActiveQuestion({...activeQuestion!.question, answer: [], status: TrainingAssignmentExamAnswerStatus.Skipped}, activeQuestion?.index, true);

                    let tmp = JSON.parse(JSON.stringify(formik.values.questions)) as PortalTrainingsAssignmentsExamQuestionModel[];
                    tmp = tmp.map((q, i) => q.questionId === activeQuestion.question.questionId ? {...q, answer: [], status: TrainingAssignmentExamAnswerStatus.Skipped} : q);

                    const nextQuestion = getNextQuestion() || {question: formik.values.questions[0], index: 0};
                    if (goToNext && formik.values.questions.length > 1) handleSelectActiveQuestion(nextQuestion.question, nextQuestion.index, true);
                    setIsAbleToFinish(response.readyToFinish);
                    response.readyToFinish && handleCompleteExam(timeIsUp, tmp);
                },
                onError: (request, errors, addictiveData) => {
                    // const e400 = errors.e400?.[0];
                    const e404 = errors.e404?.[0];
                    const e409 = errors.e409?.[0];

                    if (e404) {
                        setMessage({title: 'Assignment exam', message: 'Assignment not found.'});
                        handleClose();
                        history.push(TRAININGS_ASSIGNMENTS_PATH);
                    }

                    if (e409?.type === 'STATUS_CHANGED') {
                        const value = e409?.values.find(e => e.parameter === 'status')?.value;
                        if (value === TrainingAssignmentStatus.Cancelled) {
                            setMessage({title: 'Assignment exam', message: 'Assignment was cancelled.'});
                            handleClose();
                            history.push(TRAININGS_ASSIGNMENTS_PATH);
                        } else {
                            setMessage({title: 'Assignment exam', message: 'Assignment status was changed.'});
                            refreshAssignment();
                            handleClose();
                        }
                    }

                    if (e409?.type === 'EXAM_NOT_EXISTS') {
                        setMessage({title: 'Assignment exam', message: 'Exam not found.'});
                        refreshAssignment();
                        handleClose();
                    }

                    if (e409?.type === 'QUESTION_NOT_EXISTS') {
                        setMessage({title: 'Start assignment exam', message: 'Question not found. Try again.'});
                    }
                },
            }))
        }

        if (type === 'SAVE') {
            dispatch(portalTrainingsExamSaveAnswerAction({
                data: {assignmentId: isOpen.assignmentId, type: isOpen.type, questionId: activeQuestion.question.questionId, answer: formik.values.questions[activeQuestion.index].answer || []},
                onSuccess: (request, response, addictiveData) => {
                    formik.setFieldValue(`questions[${activeQuestion?.index}]status`, TrainingAssignmentExamAnswerStatus.Answered);
                    handleSelectActiveQuestion({...activeQuestion!.question, answer: request.answer as string[], status: TrainingAssignmentExamAnswerStatus.Answered}, activeQuestion?.index, true);

                    let tmp = JSON.parse(JSON.stringify(formik.values.questions)) as PortalTrainingsAssignmentsExamQuestionModel[];
                    tmp = tmp.map((q, i) => q.questionId === activeQuestion.question.questionId ? {...q, answer: request.answer as string[], status: TrainingAssignmentExamAnswerStatus.Answered} : q);

                    const nextQuestion = getNextQuestion() || {question: formik.values.questions[0], index: 0};
                    if (goToNext && formik.values.questions.length > 1) handleSelectActiveQuestion(nextQuestion.question, nextQuestion.index, true);
                    setIsAbleToFinish(response.readyToFinish);
                    response.readyToFinish && handleCompleteExam(timeIsUp, tmp);
                },
                onError: (request, errors, addictiveData) => {
                    // const e400 = errors.e400?.[0];
                    const e404 = errors.e404?.[0];
                    const e409 = errors.e409?.[0];

                    if (e404) {
                        setMessage({title: 'Assignment exam', message: 'Assignment not found.'});
                        handleClose();
                        history.push(TRAININGS_ASSIGNMENTS_PATH);
                    }

                    if (e409?.type === 'STATUS_CHANGED') {
                        const value = e409?.values.find(e => e.parameter === 'status')?.value;
                        if (value === TrainingAssignmentStatus.Cancelled) {
                            setMessage({title: 'Assignment exam', message: 'Assignment was cancelled.'});
                            handleClose();
                            history.push(TRAININGS_ASSIGNMENTS_PATH);
                        } else {
                            setMessage({title: 'Assignment exam', message: 'Assignment status was changed.'});
                            refreshAssignment();
                            handleClose();
                        }
                    }

                    if (e409?.type === 'EXAM_NOT_EXISTS') {
                        setMessage({title: 'Assignment exam', message: 'Exam not found.'});
                        refreshAssignment();
                        handleClose();
                    }

                    if (e409?.type === 'QUESTION_NOT_EXISTS') {
                        setMessage({title: 'Start assignment exam', message: 'Question not found. Try again.'});
                    }
                },
            }))
        }
    };

    const getNextQuestion = () => {
        const newIndex = (activeQuestion?.index ?? -1) + 1;
        const nextQuestion = formik.values.questions[newIndex];

        if (nextQuestion) return {question: nextQuestion, index: newIndex}
        else return null;
    };

    const handleChangeAnswer = (isChangingAnswer: boolean) => {
        setIsChangingAnswer(isChangingAnswer);
        if (!isChangingAnswer) {
            formik.setFieldValue(`questions[${activeQuestion?.index}]`, activeQuestion?.question);
        }
    };

    const handleNextQuestion = (withoutResetQuestionBefore?: boolean) => {
        const nextQuestion = getNextQuestion() || {question: formik.values.questions[0], index: 0};
        formik.values.questions.length > 1 && handleSelectActiveQuestion(nextQuestion.question, nextQuestion.index, withoutResetQuestionBefore);
    };

    const handleSelectActiveQuestion = (question: PortalTrainingsAssignmentsExamQuestionModel, questionIndex: number, withoutResetQuestionBefore?: boolean) => {
        if (isChangingAnswer || activeQuestion?.question?.status === TrainingAssignmentExamAnswerStatus.Todo) {
            setIsChangingAnswer(false);
            !withoutResetQuestionBefore && formik.setFieldValue(`questions[${activeQuestion?.index}]`, activeQuestion?.question, true);
        }
        formik.setFieldTouched(`questions[${activeQuestion?.index}]`, true, false);
        setActiveQuestion({question, index: questionIndex});
        setTimeout(() => {
            document.getElementById(`question` + question.questionId)?.scrollIntoView({behavior: "smooth", block: "center"});
        }, 50);
    };

    ////// Formik
    const validationSchema = useValidationSchema();
    const formik = useCustomFormik<{questions: PortalTrainingsAssignmentsExamQuestionModel[]}>(!!isOpen, {
        initialValues: {
            questions: exam?.questions || [],
        },
        validationSchema,
        onSubmit: (values) => {
            console.log('onSubmit', values)
            handleCompleteExam(false);
        }
    });

    // Timer
    const [startTime, setStartTime] = useState<moment.Moment | null>(null);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [timeIsUp, setTimeIsUp] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!startTime || !exam?.timeToPass || timeIsUp) return;

            const now = moment();
            const duration = moment.duration(now.diff(startTime)).asSeconds();
            setElapsedTime(duration);

            if (exam && exam.timeToPass > 0 && !isNaN(duration) && duration >= exam.timeToPass * 60) {
                setTimeIsUp(true);
                // handleCompleteExam(true, formik.getFieldMeta('questions').value);
            }
        }, 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, exam?.timeToPass, timeIsUp]);

    useEffect(() => {
        if (timeIsUp && exam) {
            handleCompleteExam(true, formik.getFieldMeta('questions').value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeIsUp]);

    // Get exam on open and prevent leaving page with uncompleted exam
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const message = 'You have uncompleted exam. Are you sure you want to leave?';
            event.returnValue = message;
            return message;
        };

        if (isOpen) {
            handleReset();
            getExam(isOpen.assignmentId, isOpen.type);
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    // Close dialog on unmount
    useEffect(() => {

        return () => {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isOpen: !!isOpen,
        loadings: {
            completingExam, savingAnswer, gettingExam
        },

        formik,
        isAbleToFinish,
        isChangingAnswer,
        activeQuestion,
        examName: exam?.examName ?? '',
        timeIsUp,

        actions: {
            handleClose,
            handleCompleteExam,
            handleSaveAnswer,
            handleChangeAnswer,
            handleSelectActiveQuestion,
            handleNextQuestion,
        },

        examInfoTimer: {
            currentDuration: isNaN(elapsedTime) ? 0 : elapsedTime,
            examLimitInMin: exam?.timeToPass ?? 0,
            totalQuestions: formik.values.questions.length,
            answeredQuestions: formik.values.questions.filter(q =>
                q.status === TrainingAssignmentExamAnswerStatus.Answered
                || q.status === TrainingAssignmentExamAnswerStatus.Skipped).length,
        } satisfies ExamInfoTimerProps,
    }
};