import * as yup from 'yup';
import {useFormik} from "formik";
import {setContactUsDialogAction, loadings, dialogs} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import React, {useEffect} from "react";
import {sendSupportMessageAction} from "../../store/actions";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";

export const useContactUsDialog = () => {
    const dispatch = useDispatch();
    const {contactUsDialog: {isOpen}} = useSelector(dialogs);
    const {isLoadingSend} = useSelector(loadings);
    const {t} = useMainTranslation('', {keyPrefix: 'ContactUs'});
    const {setMessage, isOpen: isOpenMessageDialog} = useMessageDialog();
    const handleClose = () => {
        dispatch(setContactUsDialogAction({isOpen: false}))
    }
    const handleSend = () => {
        dispatch(sendSupportMessageAction({
            data: {wid: '', data: message},
            onSuccess: (request, response) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: t('Your message has been successfully sent')},
                );
                handleClose();
            },
            onError: (request, response) => {
                setMessage({
                    title: t('Error occured'),
                    message: t('Unfortunately your message has not been sent. Please try again later')},
                );
            }
            }
        ))
    }

    const validationSchema = yup.object({
        message: yup.string()
            .nullable()
            .required(t('Message field is required'))
            .max(1000, t('Message should be less than 1000 symbols')),
        title: yup.string()
            .nullable()
            .required(t('Tilte field is required'))
            .max(50, t('Title should be less than 50 symbols')),
        subject: yup.string()
            .nullable()
            .required(t('Subject field is required'))
    });

    const formik = useFormik<{ subject: string, title: string, message: string }>({
        initialValues: {title: '', subject: '', message: ''},
        validationSchema,
        onSubmit: handleSend,
        isInitialValid: false,
    });
    const message = {
        subject: formik.values.subject,
        type: formik.values.title,
        message: formik.values.message
    }

    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    useEffect(() => {
        formik.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return {
        isOpen: isOpen && !isOpenMessageDialog,
        handleClose,
        handleSend,
        isLoadingSend,
        formik: {...formik, handleChange: formikOnChange},
    }
}