import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    styled,
    Table,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useTimeOffRequestExactView} from "../../../hooks/dialogs/useTimeOffRequestExactView";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {HrTimeOffStatus} from "../../../../../../newShared/GQLTypes";
import React from "react";
import {MicroTableWithoutBorder} from "../../../../../../newShared/components/microTableWithoutBorder";
import {getTimeOffStatusChip} from "../../../helpers";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import {FileDownloadOutlined} from "@mui/icons-material";
import colors from "../../../../../../newShared/theme/colors";
import {ReactComponent as FolderIcon} from "../../../../../../../src/newShared/images/other/Folder.svg";
import {useHrTimeOffHistory} from "../../../hooks/dialogs/useTimeOffHistory";
import {useCancelTimeOffDialog} from "../../../hooks/dialogs/useCancelTimeOff";
import {useCreateUpdateTimeOffDialog} from "../../../hooks/dialogs/useCreateUpdateTimeOffDialog";
import {useRequestError} from "../../../hooks/dialogs/useRequestError";

const NoPaddingTableCell = styled(TableCell)({
    paddingBlock: '4px !important',
})

export const TimeOffRequestExactView = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathTimeOff'})
    const {isOpen,
        handleClose,
        isLoadingRequest,
        isLoadingEditRequest,
        isLoadingCancelRequest,
        request,
        handleDownloadFile,
        handleOpenUpdate,
        downloadingFile,
        timeoffId
    } = useTimeOffRequestExactView();

    const {handleOpenTimeOffHistory, isOpen: isOpenHistory} = useHrTimeOffHistory();
    const {handleOpenCancelTimeOff, isOpen: isOpenCancel} = useCancelTimeOffDialog();
    const {isOpen: isOpenCreate} = useCreateUpdateTimeOffDialog();
    const {isOpen: isOpenError} = useRequestError()

    return (
        <Dialog open={isOpen && !isOpenHistory && !isOpenCancel && !isOpenCreate && !isOpenError} onClose={handleClose}>
            <DialogTitle>{t('Time off request {{id}}', {id: timeoffId})}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '26px'}}>
                <MicroTableWithoutBorder
                    isLoading={isLoadingRequest}
                    rows={[
                        {
                            label: `${t('Status')}: `,
                            text: request ? getTimeOffStatusChip(request.status) : '',
                        },
                        {
                            label: `${t('Time off type')}: `,
                            text: request ? request.type : '-',
                        },
                        {
                            label: `${t('Start date')}: `,
                            text: request?.startDate ? parseDateAuto(request?.startDate) : '-',
                        },
                        {
                            label: `${t('End date')}: `,
                            text: request?.endDate ? parseDateAuto(request?.endDate) : '-',
                        },
                        {
                            label: `${t('Comment')}: `,
                            text: request?.comment ? (request?.comment) : '-',
                        },
                        {
                            label: `${t('Attachments')}: `,
                            text: !!request?.attachments?.length ? (
                                <Table sx={{mt: '-10px'}}>
                                    {request?.attachments.map((item, index) => {
                                        const isLoading = downloadingFile.includes(item.fileId);
                                        return (
                                            <TableRow sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} key={item.fileId}>
                                                <NoPaddingTableCell sx={{display: 'flex', justifyContent: 'flex-start', gap: '10px'}}>
                                                    <FolderIcon/>
                                                    <Typography key={index}>{item.fileName}</Typography>
                                                </NoPaddingTableCell>
                                                <NoPaddingTableCell>
                                                    <IconButton size={"small"} sx={{color: colors.primary.blue}}
                                                                disabled={isLoading}
                                                                onClick={() => {handleDownloadFile(item.fileId, item.fileName)}}>
                                                        {isLoading ? <CircularProgress size={24}/> : <FileDownloadOutlined/>}
                                                    </IconButton>
                                                </NoPaddingTableCell>
                                            </TableRow>
                                        )
                                    })}
                                </Table>
                            ) : '-',
                        }

                    ]}

                    />
                {request?.status !== HrTimeOffStatus.Cancelled && (
                    <Button startIcon={ <GradingOutlinedIcon sx={{ color: colors.primary.blue }} />} sx={{width: 'fit-content'}} onClick={() => request && handleOpenTimeOffHistory(request)} variant={"text"} size={"small"} >
                        {t("View approval history")}
                    </Button>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'small'}>{(tCommon('Close'))}</Button>
                {request?.status === HrTimeOffStatus.Created &&
                    <>
                        <LoadingButton variant={"outlined"} onClick={() => handleOpenCancelTimeOff(request.id)} loading={isLoadingCancelRequest} size={'small'}>
                            {t('Cancel time off request')}
                        </LoadingButton>
                        <LoadingButton variant={"contained"} onClick={() => handleOpenUpdate(request)} loading={isLoadingEditRequest} size={'small'}>
                            {t('Edit')}
                        </LoadingButton>
                    </>
                }

            </DialogActions>
        </Dialog>
    )
}
