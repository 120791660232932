import {useDispatch, useSelector} from "react-redux";
import {
    portalHrCreateEditRequestAction,
    portalHrGetProfileAction,
    portalHrGetProfileJobHistoryAction
} from "../../store/actions";
import {hrIsLoadingSelector, hrMyProfileJobHistorySelector, hrMyProfileSelector, THrSlice} from "../../store/slice";
import {PortalHrEditRequestInputDto, SharedPaginationInput} from "../../../../../newShared/GQLTypes";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

type TResponse = {
    employee: THrSlice["myProfile"],
    jobHistory: THrSlice["jobHistory"],
    loading: THrSlice["isLoading"],
} & Record<string, any>
export const useHrProfile = () => {
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathHrProfile'})

    const employee = useSelector(hrMyProfileSelector);
    const jobHistory = useSelector(hrMyProfileJobHistorySelector);

    const loading = useSelector(hrIsLoadingSelector);

    const getMyHrProfile = (clean?: boolean) => {
        dispatch(portalHrGetProfileAction({data: {}, disableSnackOnApiError: true, checkLeftMenuItem: true, clean}))
    }

    const getJobHistory = (pageInfo: SharedPaginationInput) => {
        dispatch(portalHrGetProfileJobHistoryAction({data: {pageRequest: {page: pageInfo.page, count: pageInfo.count}}}))
    }

    const createEditRequest = (request: PortalHrEditRequestInputDto) => {
        dispatch(portalHrCreateEditRequestAction({
            data: {request},
            onSuccess: () => {
                setMessage({title: t('Completed successfully'), message: [t('Your request has been submitted.'), t('You can find this request at “My HR requests” section.')]})
            },
        }))
    }

    return {
        employee,
        jobHistory,
        loading,

        getMyHrProfile,
        getJobHistory,
        createEditRequest,
    } satisfies TResponse
}