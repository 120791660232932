import {Flex} from "../../../../../../../../newShared/components/Layouts";
import colors from "../../../../../../../../newShared/theme/colors";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const MenuIcon = ({isOpen, onClick}:{isOpen: boolean, onClick: () => void}) => {
    const {revDir} = useMainTranslation();
    return(
        <Flex
            w={'36px'}
            h={'36px'}
            ai={'center'}
            jc={'center'}
            p={'6px'}
            css={`
              border-top: 1px solid ${colors.stroke.table};
              border-right: 1px solid ${colors.stroke.table};
              border-bottom: 1px solid ${colors.stroke.table};
              //position: absolute;
            `}
            background={colors.backgrounds.optional}
            br={'0 4px 4px 0'}
            cursor={'pointer'}
            onClick={onClick}
        >
            {isOpen && !revDir ? <ArrowLeftIcon sx={{color: colors.primary.blue}}/> : <ArrowRightIcon sx={{color: colors.primary.blue}}/>}
        </Flex>
    )
}