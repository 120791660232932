import {useDispatch, useSelector} from "react-redux";
import {hrDialogsSelector, setCancelMyTimeOffAction} from "../../../store/slice";
import {portalHrCancelMyTimeOffRequestAction} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHistory, useLocation} from "react-router-dom";
import {HR_TIME_OFF_PATH} from "../../../../../../newShared/constants";


export const useCancelTimeOffDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.dialogs'});
    const dispatch = useDispatch();
    const {cancelMyTimeOff: {isOpen, isLoading, selectedId}} = useSelector(hrDialogsSelector);
    const {setMessage} = useMessageDialog();
    const history = useHistory();
    const location = useLocation();


    const handleClose = () => {
        dispatch(setCancelMyTimeOffAction({clear: true}));
    }

    const handleOpenCancelTimeOff = (selectedId: string) => {
        dispatch(setCancelMyTimeOffAction({isOpen: true, selectedId}));
    }

    const handleCancelTimeOff = () => {
        selectedId && dispatch(portalHrCancelMyTimeOffRequestAction({
            data: {timeOffId: selectedId},
            onSuccess: () => {
                history.push(HR_TIME_OFF_PATH + location.search);
                setMessage({
                    title: t('Canceled successfully'),
                    message: t('Time off request {{timeOffId}} has been cancelled.', {timeOffId: selectedId})
                })
            }
        }));
    }

    return {
        isOpen,
        isLoading,
        selectedId,
        handleClose,
        handleCancelTimeOff,
        handleOpenCancelTimeOff
    }

}
