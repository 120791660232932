import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {TRAININGS_ASSIGNMENTS_EXACT_PATH, TRAININGS_ASSIGNMENTS_PATH,} from "../../../newShared/constants";

import {AssignmentsList} from "./pages/assignmentsList";
import {AssignmentsExact} from "./pages/assignmentsExact";


export const Routes: FC = () => {

    return (
        <Switch>
            <Route exact path={[TRAININGS_ASSIGNMENTS_PATH]} component={AssignmentsList}/>
            <Route exact path={TRAININGS_ASSIGNMENTS_EXACT_PATH} component={AssignmentsExact}/>

            <Redirect to={TRAININGS_ASSIGNMENTS_PATH}/>
        </Switch>
    );
};
