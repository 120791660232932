import {useDispatch, useSelector} from "react-redux";
import {
    eraseMyTimeOffList,
    hrIsLoadingSelector,
    hrMyTimeOffSelector,
    hrTimeOffSettingsSelector,
    replaceMyTimeOffMinMaxLoadedPage,
    setCreateTimeOffDialogAction,
} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {HR_PROFILE_PATH, HR_TIME_OFF_PATH, HR_TIME_OFF_PATH_EXACT,} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    portalHrGetMyTimeOffRequestsWithFilterPaginationAction,
    portalHrGetTimeOffRequestsSettingsAction,
} from "../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getTimeOffStatusChip} from "../../helpers";
import {HrTimeOffStatus, PortalHrTimeOffRequestModel} from "../../../../../newShared/GQLTypes";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";


export const useHrMyTimeOff = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathTimeOff'});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {requests, pageInfo, minMaxLoadedPage} = useSelector(hrMyTimeOffSelector);
    const {myTimeOffList: isLoadingList} = useSelector(hrIsLoadingSelector);
    const settings = useSelector(hrTimeOffSettingsSelector);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;


    useSetBreadcrumbs([
        {
            title: tMenu('HR'),
            path: HR_PROFILE_PATH
        },
        {
            title: tMenu('My time off requests'),
            path: HR_TIME_OFF_PATH
        },
    ]);

    useEffect(() => {
        if(!settings) dispatch(portalHrGetTimeOffRequestsSettingsAction({ data: {}}));

        //eslint-disable-next-line
    }, []);

    const handleOpenTimeOff = (selected: PortalHrTimeOffRequestModel) => {
        selected && history.push(`${HR_TIME_OFF_PATH_EXACT.replace(':timeoffId', selected?.id)}${location.search}`);
        // dispatch(openIssueView());
    }

    const handleOpenUpdate = (selected: PortalHrTimeOffRequestModel) => {
        dispatch(setCreateTimeOffDialogAction({isOpen: true, selected}));
    }


    //filters
    const timeOffTypeConfig: keyConfig<string> = {
        type: 'filter',
        key: 'type',
        name: t('Time off type'),
        options: settings?.timeOffType ?? [],
        getOptionLabel: (a) => t(a),
        default: true
    }

    const statusConfig: keyConfig<HrTimeOffStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(HrTimeOffStatus),
        OptionRenderer: (a) => getTimeOffStatusChip(a, true),
        default: true,
    }

    const startDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'startDate',
        name: t('Start date'),
        default: true
    }

    const endDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'endDate',
        name: t('End date'),
        default: true
    }

    const requestDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'requestDate',
        name: t('Request date'),
        default: true
    }


    return {
        requests,
        pageInfo,

        handleOpenTimeOff,
        handleOpenUpdate,

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceMyTimeOffMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseMyTimeOffList()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, timeOffIdLike: string) => {
                    dispatch(portalHrGetMyTimeOffRequestsWithFilterPaginationAction({data: {pageRequest: {page, count}, filter: {...filters, timeOffIdLike}}}));
                },
            },
            filters: {
                configs: [
                    startDateConfig,
                    endDateConfig,
                    timeOffTypeConfig,
                    statusConfig,
                    requestDateConfig,
                ]
            }
        }
    }
}
