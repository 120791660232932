import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useCancelTimeOffDialog} from "../../../hooks/dialogs/useCancelTimeOff";


export const CancelTimeOffDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.dialogs'});
    const {
        isOpen,
        isLoading,
        selectedId,
        handleClose,
        handleCancelTimeOff,
    } = useCancelTimeOffDialog();


    return (
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Cancel time off request {{timeOffId}}', {timeOffId: selectedId})}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'}>{t('Are you sure you want to cancel time off request {{timeOffId}}?', {timeOffId: selectedId})}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Cancel')}</Button>
                <LoadingButton size={'small'} loading={isLoading} onClick={handleCancelTimeOff} >
                    {t('Cancel time off request')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
