import styled from "styled-components";
import {device} from "../../../../../../../newShared/constants";
import colors from "../../../../../../../newShared/theme/colors";

//welcome on board
export const BackgroundWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    position: relative;
    padding: 40px 0 0 0;
`;

export const TextWrapper = styled.div`
  padding: 40px 0 10px;
  overflow: auto;
    width: 50%;
    z-index: 100;

      ${device.desktopM}{
        width: 70%;
      }
    ${device.tablet}{
        width: 90%;
    }
`;

export const WelcomeOnBoardCard = styled.div`
    width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid ${colors.stroke.table};
  background: ${colors.backgrounds.white};
  padding: 32px;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  margin: 32px 0 0 0;
`;

export const NoAccessChip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 15px;
  border: 1px solid ${colors.stroke.dark_grey};
  color: ${colors.text.grey};
  padding: 6px 14px;
`;


export const NoAccessChipsDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.text.grey};
  border-radius: 50%;
`;

export const SelectWorkspaceFakeDialogCard = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${colors.stroke.table};
  background: ${colors.backgrounds.white};
  padding: 32px;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  margin: 32px 0;

  width: 45%;
  z-index: 100;
  
  ${device.desktop}{
    width: 60%;
  }
  ${device.tablet}{
    width: 90%;
  }
`;