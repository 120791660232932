import {useDispatch, useSelector} from "react-redux";
import {
    hideIssueView,
    hrDialogsSelector,
    hrMyIssuesSelector,
} from "../../../store/slice";
import {
    getPortalHrIssueByIdAction,
} from "../../../store/actions";
import {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {HR_ISSUES_PATH} from "../../../../../../newShared/constants";


export const useIssueViewDialog = () => {

    const dispatch = useDispatch();
    const {issueId} = useParams<{issueId: string}>();
    const {issueView: {isOpen, isLoading}} = useSelector(hrDialogsSelector);
    const {selected} = useSelector(hrMyIssuesSelector);
    const location = useLocation();
    const history = useHistory();


    const handleClose = () => {
        dispatch(hideIssueView());
        history.push(`${HR_ISSUES_PATH}${location.search}`);
    }

    useEffect(() => {
        isOpen && issueId && dispatch(getPortalHrIssueByIdAction({data: {issueId}}))

        //eslint-disable-next-line
    }, [isOpen]);



    return {
        isOpen,
        isLoading,
        selected,
        handleClose,
    }

}
