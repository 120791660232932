import React, {FC, useState} from "react";
import {
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Typography
} from "@mui/material";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {ArrowBack, ArrowForward, CompareArrows, TaskAlt} from "@mui/icons-material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PassingExamMenuItem} from "./components/passingExamMenuItem";
import {TrainingAssignmentExamAnswerStatus} from "../../../../../../newShared/GQLTypes";
import {PassingExamAnswerBlock} from "./components/passingExamAnswerBlock";
import {MenuIcon} from "../startTrainingDialog/components/menuIcon";
import colors from "../../../../../../newShared/theme/colors";
import {ExamInfoTimer} from "./components/examInfoTimer";
import {usePassingExamDialog} from "../../../hooks/dialogs/usePassingExamDialog";
import {FinishExamDialog} from "../finishExamDialog";
import {ValidationExamDialog} from "../validationExamDialog";


export const PassingExamDialog: FC<{refreshAssignment: () => void}> = ({refreshAssignment}) => {
    const {t, tCommon, revDir} = useMainTranslation();

    const [isQuestionListOpened, setIsQuestionListOpened] = useState(true);

    const {
        isOpen,
        loadings: {completingExam, savingAnswer, gettingExam},

        formik,
        isAbleToFinish,
        isChangingAnswer,
        activeQuestion,
        examName,
        timeIsUp,

        actions: {
            handleClose,
            handleCompleteExam,
            handleSaveAnswer,
            handleChangeAnswer,
            handleSelectActiveQuestion,
            handleNextQuestion,
        },

        examInfoTimer,
    } = usePassingExamDialog({refreshAssignment});

    /////////
    const isAnswering = savingAnswer.find(e => e.id === activeQuestion?.question.questionId);
    const ANSWER_SKIPPING = isAnswering?.type === 'SKIP';
    const ANSWER_SAVING = isAnswering?.type === 'SAVE';

    const answerSubmitted =
        formik.values.questions[activeQuestion?.index ?? -1]?.status === 'ANSWERED'
        || formik.values.questions[activeQuestion?.index ?? -1]?.status === 'SKIPPED'
    ;

    // const currentError = formik.errors.questions?.[activeQuestion?.index ?? -1];
    const enabledSaveButton = !!formik.values.questions[activeQuestion?.index ?? -1]?.answer?.[0]?.trim();

    return (
        <Dialog open={isOpen} fullScreen sx={{'.MuiPaper-root': {width: '100%'}}}>
            <DialogTitle component={FlexRow} sx={{gap: '12px', alignItems: 'center'}}>
                <Typography variant={'*h3'} sx={{flexGrow: 1, fontWeight: 600}}>{examName}</Typography>

                {!timeIsUp && answerSubmitted && !isChangingAnswer && (
                    <>
                        <Button onClick={() => handleNextQuestion()} variant={'outlined'} size={'small'} startIcon={!revDir ? <ArrowForward/> : <ArrowBack/>}>{t('Next')}</Button>
                        <Button onClick={() => handleChangeAnswer(true)} variant={'outlined'} size={'small'}>{t('Change answer')}</Button>
                    </>
                )}

                {!timeIsUp && isChangingAnswer && (
                    <>
                        <Button onClick={() => handleChangeAnswer(false)} variant={'outlined'} size={'small'}>{t('Cancel')}</Button>

                        {activeQuestion?.question.status === TrainingAssignmentExamAnswerStatus.Answered && (
                            <LoadingButton onClick={() => handleSaveAnswer('SKIP')} variant={'outlined'} size={'small'} startIcon={<CompareArrows/>}
                                           disabled={ANSWER_SAVING} loading={ANSWER_SKIPPING}>{t('Skip')}</LoadingButton>
                        )}

                        <LoadingButton onClick={() => handleSaveAnswer('SAVE')} variant={'contained'} size={'small'}
                                       disabled={ANSWER_SKIPPING || !enabledSaveButton} loading={ANSWER_SAVING}>{t('Save')}</LoadingButton>
                    </>
                )}

                {!timeIsUp && activeQuestion?.question.status === TrainingAssignmentExamAnswerStatus.Todo && (
                    <>
                        <LoadingButton onClick={() => handleSaveAnswer('SKIP', true)} variant={'outlined'} size={'small'} startIcon={<CompareArrows/>}
                                       disabled={ANSWER_SAVING} loading={ANSWER_SKIPPING}>
                            {formik.values.questions.find(q => q.questionId !== activeQuestion?.question.questionId && q.status === 'TODO')
                                ? t('Skip & Next')
                                : t('Skip')
                            }
                        </LoadingButton>

                        <LoadingButton onClick={() => handleSaveAnswer('SAVE', true)} variant={'contained'} size={'small'}
                                       disabled={ANSWER_SKIPPING || !enabledSaveButton} loading={ANSWER_SAVING}>
                            {formik.values.questions.find(q => q.questionId !== activeQuestion?.question.questionId && q.status === 'TODO')
                                ? t('Save & Next')
                                : t('Save')
                            }
                        </LoadingButton>
                    </>
                )}

                {isAbleToFinish && (
                    <LoadingButton onClick={() => handleCompleteExam(false)} startIcon={<TaskAlt/>} variant={'contained'} size={'small'}
                                   loading={completingExam}>
                        {t('Finish exam')}
                    </LoadingButton>
                )}

                {timeIsUp && (
                    <Button onClick={handleClose} variant={'contained'} size={'small'}>
                        {tCommon('Close')}
                    </Button>
                )}
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%'}}>
                <FlexRow width={'100%'} height={'100%'} gap={'30px'} overflow={'hidden'} maxWidth={'min(1550px, 90%)'} sx={{marginInline: 'auto'}}>

                    <FlexRow sx={{maxWidth: 'max(30%, 290px)', height: '100%', flexGrow: 1}}>
                        <Collapse in={isQuestionListOpened} orientation={'horizontal'}
                                  sx={{
                                      '.MuiCollapse-wrapperInner': {width: '100%', display: 'flex', flexDirection: 'column', paddingBottom: '16px'},

                                  }}>
                            <FlexColumn sx={{width: '100%', overflow: 'hidden', flexGrow: 1, gap: '16px'}}>
                                <Typography variant={'*bodyText1_semibold'} color={colors.grayText}>{('List of questions')}</Typography>

                                <FlexColumn sx={{width: '100%', gap: '10px', overflow: 'auto'}}>
                                    {gettingExam && Array.from({length: 10}).map((_, i) => (
                                        <PassingExamMenuItem key={`skeleton ${i}`} question={null}/>
                                    ))}

                                    {formik.values.questions.map((question, questionIndex, array) => (
                                        <PassingExamMenuItem key={question.questionId}
                                                             question={question} onClick={() => handleSelectActiveQuestion(question, questionIndex)}
                                                             answered={formik.values.questions[questionIndex].status === 'ANSWERED'}
                                                             skipped={formik.values.questions[questionIndex].status === 'SKIPPED'}
                                                             selected={activeQuestion?.question.questionId === question.questionId}
                                                             // error={!!formik.errors.questions?.[questionIndex]}
                                                             touched={!!formik.touched.questions?.[questionIndex]}
                                                             loading={!!savingAnswer.find(e => e.id === question.questionId)}
                                        />
                                    ))}
                                </FlexColumn>
                            </FlexColumn>

                            {formik.values.questions.length > 0 &&
                                <>
                                    <Divider flexItem sx={{margin: '8px 0'}}/>
                                    <ExamInfoTimer {...examInfoTimer}/>
                                </>
                            }
                        </Collapse>
                    </FlexRow>

                    <FlexRow position={'relative'} sx={{flexGrow: 1}}>
                        <Divider orientation={'vertical'} flexItem/>
                        <MenuIcon isOpen={isQuestionListOpened} onClick={() => setIsQuestionListOpened(prev => !prev)} />
                    </FlexRow>

                    <FlexRow sx={{width: '100%', height: '100%', flexGrow: 1, overflow: 'hidden'}}>
                        {formik.values.questions.length === 0 &&
                            <FlexColumn sx={{gap: '16px', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <CircularProgress size={48}/>
                            </FlexColumn>
                        }

                        {formik.values.questions.map((q, questionIndex) => q.questionId === activeQuestion?.question.questionId && (
                            <PassingExamAnswerBlock key={q.questionId} questionIndex={questionIndex} masterFormik={formik}
                                                    disabled={(answerSubmitted && !isChangingAnswer) || timeIsUp}/>
                        ))}
                    </FlexRow>
                </FlexRow>
            </DialogContent>

            <FinishExamDialog refreshAssignment={refreshAssignment}/>
            <ValidationExamDialog/>
        </Dialog>
    );
};