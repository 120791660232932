import axiosSync from "../../../../newShared/utils/axios/axios";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {AxiosResponse} from "axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {
    DocApproverInput,
    GetFullDocumentResponseModel,
    GetSharedShortDocumentsPagingDocsQuery,
    GetSharedShortDocumentsPagingDocsQueryVariables,
    GetShortDocumentsPagingDocsQuery,
    GetShortDocumentsPagingDocsQueryVariables,
    NewDocDataInput,
    NewDocumentActorInput,
    RecipientInput
} from "../../../../newShared/GQLTypes";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {NewDocumentExecutorFragment, NewDocumentFragment} from "./fragments";
import {TPageInfo} from "../../../../newShared/types";

export const getShortDocumentsPagingDocs = async (variables: GetShortDocumentsPagingDocsQueryVariables): Promise<GetShortDocumentsPagingDocsQuery["portalGetShortDocumentsPagingDocs"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getShortDocumentsPagingDocs($pageInfo:PaginationInputAnyFilter!, $workspaceId: String!){
                        portalGetShortDocumentsPagingDocs(pageInfo:$pageInfo, workspaceId: $workspaceId){
                            files {
                                id
                                documentId
                                workspaceId
                                type
                                name
                                folderId
                                status
                                origin
                                version
                                latest
                                createdDate
                                tags
                                lastModified
                                generatedFileId
                                sender
                                workspaceName
                                currentStepEndDate
                                recipients{
                                    role
                                    message
                                    order
                                    actor{
                                        email
                                        firstName
                                        lastName
                                    }
                                    fields{
                                        id
                                        pageId
                                        type
                                        position{
                                            x
                                            y
                                            metaPageHeight
                                        }
                                        size{
                                            width
                                            height
                                        }
                                    }
                                }
                            }
                            pageInfo{
                                total
                                page
                                count
                                sort
                            }
                        }
                    }
                `),
                variables,
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalGetShortDocumentsPagingDocs;
    })
)

export const getSharedShortDocumentsPagingDocs = async (variables: GetSharedShortDocumentsPagingDocsQueryVariables): Promise<GetSharedShortDocumentsPagingDocsQuery["portalGetSharedShortDocumentsPagingDocs"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getSharedShortDocumentsPagingDocs($pageInfo:PaginationInputAnyFilter!, $workspaceId: String!){
                        portalGetSharedShortDocumentsPagingDocs(pageInfo:$pageInfo, workspaceId: $workspaceId){
                            files {
                                id
                                documentId
                                workspaceId
                                type
                                name
                                folderId
                                status
                                origin
                                version
                                latest
                                createdDate
                                tags
                                lastModified
                                generatedFileId
                                sender
                                workspaceName
                                currentStepEndDate
                                recipients{
                                    role
                                    message
                                    order
                                    actor{
                                        email
                                        firstName
                                        lastName
                                    }
                                    fields{
                                        id
                                        pageId
                                        type
                                        position{
                                            x
                                            y
                                            metaPageHeight
                                        }
                                        size{
                                            width
                                            height
                                        }
                                    }
                                }
                            }
                            pageInfo{
                                total
                                page
                                count
                                sort
                            }
                        }
                    }
                `),
                variables
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalGetSharedShortDocumentsPagingDocs;
    })
)

export const getFullDocumentWithExecutorForDocs = async (documentId: string, workspaceId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getFullDocumentWithExecutorForDocs($documentId:String!, $workspaceId: String!){
                        portalGetFullDocumentWithExecutorForDocs(documentId:$documentId, workspaceId: $workspaceId){
                            file{...NewDocumentFragment}
                            executor{...NewDocumentExecutorFragment}
                        }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    documentId, workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalGetFullDocumentWithExecutorForDocs;
    })
)

export const rejectSignStageByStageId = async(stageId: string, documentId: string, workspaceId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation rejectSignStageByStageId($stageId:String!, $documentId: String!, $workspaceId: String!){
                      portalRejectSignStageByStageId(stageId:$stageId, documentId: $documentId, workspaceId: $workspaceId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    documentId,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalRejectSignStageByStageId;
    })
)

export const rejectApproveStageByStageId = async(stageId: string, documentId: string, workspaceId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation rejectApproveStageByStageId($stageId:String!, $documentId: String!, $workspaceId: String!){
                      portalRejectApproveStageByStageId(stageId:$stageId, documentId: $documentId, workspaceId: $workspaceId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    documentId,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalRejectApproveStageByStageId;
    })
)


export const fillFillableFieldOnSignStage = async(stageId: string, values: {fieldId: string, value: string}[], documentId: string, workspaceId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation fillFillableFieldOnSignStage($values:[FieldIdValueDto!]!, $stageId:String!, $documentId: String!, $workspaceId: String!){
                      portalFillFillableFieldOnSignStage(values:$values, stageId:$stageId, documentId: $documentId, workspaceId: $workspaceId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    values,
                    documentId,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalFillFillableFieldOnSignStage;
    })
)

export const approveSignStageByStageId = async(stageId: string, documentId: string, workspaceId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation approveSignStageByStageId($stageId:String!, $documentId: String!, $workspaceId: String!){
                      portalApproveSignStageByStageId(stageId:$stageId, documentId: $documentId, workspaceId: $workspaceId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    documentId,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalApproveSignStageByStageId;
    })
)

export const approveApproveStageByStageId = async(stageId: string, documentId: string, workspaceId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation approveApproveStageByStageId($stageId:String!, $documentId: String!, $workspaceId: String!){
                      portalApproveApproveStageByStageId(stageId:$stageId, documentId: $documentId, workspaceId: $workspaceId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    documentId,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalApproveApproveStageByStageId;
    })
)


export const updateFullDocument = async(documentId: string, data: NewDocDataInput, name: string, recipients: RecipientInput[], editors: NewDocumentActorInput[], approvers: DocApproverInput[], workspaceId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateFullDocumentByDocs($documentId: String!, $data: NewDocDataInput!, $name: String!, $recipients: [RecipientInput!]!, $editors: [NewDocumentActorInput!]!, $approvers: [DocApproverInput!]!, $workspaceId: String!){
                      portalUpdateFullDocumentByDocs(data:$data, name:$name, recipients:$recipients, editors:$editors, approvers:$approvers, documentId:$documentId, workspaceId: $workspaceId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    documentId,
                    data,
                    name,
                    recipients,
                    editors,
                    approvers,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalUpdateFullDocumentByDocs;
    })
)


//getAutocompleteSender
export const getAutocompleteSender = async(workspaceId: string, {page, count, search}: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<{values: string[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getDocsMyDocumentsAutocompleteSender($page: Float!, $count: Float!, $nameLike: String!, $workspaceId: String!){
                      portalGetDocsMyDocumentsAutocompleteSender(page:$page, count:$count, nameLike: $nameLike, workspaceId: $workspaceId){
                        values
                        pageInfo{
                            page
                            count
                            total
                        }
                      }
                    }
                `),
                variables: {
                    page, count, nameLike: search, workspaceId
                },
                signal
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalGetDocsMyDocumentsAutocompleteSender;
    })
)
