import React, {FC, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {Document, Page} from "react-pdf";
import {PDFDocumentProxy} from "pdfjs-dist/types/src/display/api";
import {getFile} from "../../../../../../../../newShared/components/docViewer/api";
import {DEFAULT_ZOOM} from "../../../../../../../../newShared/components/docViewer/constants";
import {TinyMceEditor} from "../../../../../../../../newShared/components/tinyMceEditor";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import {LocalSpinner} from "../../../../../../../../newShared/components/Spinner";
import {Typo} from "../../../../../../../../newShared/components/Typography";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {MainTrainingContentType, PortalTrainingContentModel} from "../../../../../../../../newShared/GQLTypes";
import {VideoFilePreview} from "../preview/videoFilePreview";
import {VideoLinkPreview} from "../preview/videoLinkPreview";


type TTrainingSectionProps = {
    section: PortalTrainingContentModel,
}

export const TrainingSection: FC<TTrainingSectionProps> = ({section}) => {
    const workspaceId = UseManageWorkspacesAndOrganizations().workspaceId || '';

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [file, setFile] = useState<string>('');
    const [zoom, setZoom] = React.useState<number>(DEFAULT_ZOOM);
    const [totalPages, setTotalPages] = useState<number | null>(null);
    // const [currentPage, setCurrentPage] = useState<number>(1);

    const getFileById = async(fileId: string, fileName: string, workspaceId: string) => {
        setIsLoading(true);
        try{
            const {file} = await getFile(fileId, fileName, workspaceId);
            setFile(file);
            setIsLoading(false);
        }catch (ex){
            setIsLoading(false);
        }
    }

    const getDocumentFile = () => `data:application/pdf;base64,${file}`;

    async function onDocumentLoadSuccess({ numPages, getViewerPreferences }: PDFDocumentProxy) {
        setTotalPages(numPages);
    }


    //SCROLL PAGES
    // const pagesRef = useRef<HTMLDivElement>(null);

    const [refs, setRefs] = useState<{[key: number]: HTMLDivElement}>({});

    const handleAppendRef = (index: number, ref?: HTMLDivElement | null) => {
        ref && refs[index] === undefined && setRefs((prev) => {return {...prev, [index]: ref}});
    }


    useEffect(() => {
        console.log(`TrainingSection section`, section)
        if (section.type === MainTrainingContentType.File && section.data.fileId && section.data.name) {
            getFileById(section.data.fileId, section.data.name, workspaceId);
        }

        return () => {
            setZoom(DEFAULT_ZOOM);
            // setCurrentPage(1);
            setRefs({});
        }
        // eslint-disable-next-line
    }, [section]);


    return (
        <Flex overflow={'auto'} direction={'column'} w={'100%'}>
            { section.type === MainTrainingContentType.File &&
                <>
                    {isLoading && <LocalSpinner />}
                    {!isLoading && (!section.data.fileId || !section.data.name) && <Typo fontSize={'16px'} fontWeight={400}>File not found!</Typo>}
                    {!isLoading && section.data.fileId && file.length > 0 &&
                        <Flex minw={'fit-content'} jc={'center'} >
                            <Document
                                file={getDocumentFile()}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                {(totalPages ?? 0) > 0 && new Array(totalPages).fill(1).map((_, i) => (
                                    <Flex m={'16px'} ai={'center'} jc={'center'} key={i+1} ref={(ref) => {handleAppendRef(i+1, ref)}}>
                                        <Page pageNumber={i+1} scale={zoom}/>
                                    </Flex>
                                ))}
                            </Document>
                        </Flex>
                    }
                </>
            }

            {section.type === MainTrainingContentType.Editor && section.data.innerHtml && (
                <>
                    <Box sx={{width: '100%', flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                        <TinyMceEditor readonly hideMenu initialContent={section.data.innerHtml}  onSave={() => null} />
                    </Box>
                </>
            )}

            {(section.type === MainTrainingContentType.VideoFile) &&
                <VideoFilePreview section={section}/>
            }

            {(section.type === MainTrainingContentType.VideoLink) &&
                <VideoLinkPreview section={section}/>
            }
        </Flex>
    )

}
