import React, {FC} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {MicroTableWithoutBorder} from "../../../../../../newShared/components/microTableWithoutBorder";
import {useRemovePhysicalAssetDialog} from "../../../hooks/useRequestRemoveAsset";
import colors from "../../../../../../newShared/theme/colors";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useAssetsRequestError} from "../../../hooks/dialogs/useAssetsRequestError";

export const RemovePhysicalAssetDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathAssets.pathAssetsList'})

    const {
        isOpen,
        asset,
        //comment,
        //setComment,
        isLoading,
        handleConfirm,
        handleClose,
        formik
    } = useRemovePhysicalAssetDialog();


    const {isOpen: isOpenErrorDialog} = useAssetsRequestError();

    return (
        <Dialog open={isOpen && !isOpenErrorDialog} onClose={handleClose}>
            <DialogTitle>{`${t('Request to remove asset')} ${asset?.assetId || ''}`}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto', gap: '16px'}}>
                <Typography variant={'body2'}>{t('This request requires admin approval. By clicking “Send” you confirm that you want to remove this asset from your list.')}</Typography>

                <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
                    {/*<Typography variant={'*bodyText2_semibold'}>{t('Comment')}</Typography>*/}
                    <TextField rows={3} multiline disabled={isLoading} placeholder={`${tCommon('Enter text')}...`}
                               error={formik.touched.comment && Boolean(formik.errors.comment)}
                               helperText={formik.touched.comment && formik.errors.comment}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               value={formik.values.comment}
                               name={'comment'}
                               label={'Comment'}
                    />
                </Box>
                <Divider flexItem sx={{bgcolor: colors.stroke.grey, flexShrink: 0}}/>

                <Typography variant={'*bodyText1_semibold'}>{t('Asset information')}</Typography>

                <MicroTableWithoutBorder isLoading={false}
                                         rows={[
                                             {
                                                 label: `${t('ID')}: `,
                                                 text: asset?.assetId,
                                             },
                                             {
                                                 label: `${t('Serial')}: `,
                                                 text: asset?.serial,
                                             },
                                             {
                                                 label: `${t('Model')}: `,
                                                 text: asset?.model,
                                             },
                                             {
                                                 label: `${t('Category')}: `,
                                                 text: asset?.category,
                                             },
                                             {
                                                 label: `${t('Manufacturer')}: `,
                                                 text: asset?.manufacture,
                                             },
                                         ]}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{(tCommon('Cancel'))}</Button>
                <LoadingButton variant={"contained"} onClick={handleConfirm} loading={isLoading}
                               disabled={!formik.isValid} size={'small'}>
                    {tCommon('Send')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
