import {useDispatch, useSelector} from "react-redux";
import {
    loadings,
    openAddOrEditJustificationDialog,
    openShowPermissions,
    openViewJustification,
    selectedStage
} from "../../store/slice";
import {useState} from "react";
import {calculateIfDeadlinePassed, mapAppsByAccountIds} from "../../helpers";
import {
    ACCOUNT_DELETE_STATUS,
    ACCOUNT_KEEP_STATUS,
    ACCOUNT_REVOKE_STATUS,
    COMPLETE_STATUS,
    TERMINATED_STATUS
} from "../../constants";
import {applicationAccessDataWithoutAccounts, campaignReviewExecutorAccountFillableType} from "../../types";
import {UpdateCampaignReviewRows, VerifyActorChanges} from "../../store/actions";

export const useCampaignReviewManagerStep = () => {
    const dispatch = useDispatch();
    const stage = useSelector(selectedStage);
    const {campaignReviewUpdatingRowsWithIds, verifyChanges} = useSelector(loadings);

    const managersStep = stage?.stageCampaignReview ?? null;
    //
    const [selectedManagerAccounts, setSelectedManagersAccounts] = useState<string[]>([]);
    const [selectingAccountsOfActorEmail, setSelectingAccountsOfActorEmail] = useState<string | null>(null);

    const handleDeselectAllAccounts = () => {
        setSelectedManagersAccounts([]);
        setSelectingAccountsOfActorEmail(null);
    }

    return{
        selectedManagerAccounts,
        selectingAccountsOfActorEmail,
        commonSelectors: {
            isLoadingVerify: verifyChanges,
            loadingIds: campaignReviewUpdatingRowsWithIds,
        },
        handleDeselectAllAccounts,
        onRoleClick:(roleName: string, rolePermissions: string[]) => {
            rolePermissions.length > 0 && dispatch(openShowPermissions({rolePermissions, roleName}));
        },
        //SELECT
        handleSelectManagerAccount: (accountId: string, actorEmail: string) => {
            setSelectingAccountsOfActorEmail(actorEmail); //to define which actor is working on now (to prevent on selecting accounts for another actor (""))
            setSelectedManagersAccounts(prev => {return [...prev, accountId]});
        },
        handleDeselectManagerAccount: (accountId: string, actorEmail: string) => {
            setSelectedManagersAccounts(prev => {return prev.filter(e => e !== accountId)});
            if(!selectedManagerAccounts.length) setSelectingAccountsOfActorEmail(null); //MAYBE BAG HERE
        },
        handleSelectAllAccounts: (email: string) => {
            setSelectingAccountsOfActorEmail(email); //to define which actor is working on now (to prevent on selecting accounts for another actor (""))
            if(managersStep){
                const act = managersStep.actors.find(e => e.email === email);
                if(act){
                    setSelectedManagersAccounts((act.appsData ?? []).flatMap(e => e.data).map(e => e.id));
                }
            }
        },
        isSelectedManagerAccount: (accountId: string): boolean => {
            return selectedManagerAccounts.some(e => e === accountId);
        },
        isSelectedAllManagersAccounts: (email: string): boolean => {
            if(managersStep){
                const act = managersStep.actors.find(e => e.email === email);
                if(act){
                    return (act.appsData ?? []).flatMap(e => e.data).length === selectedManagerAccounts.length
                }
            }
            return false;
        },
        //
        stage: managersStep,
        isDeadlinePassed: calculateIfDeadlinePassed(managersStep),
        isComplete: managersStep?.status === COMPLETE_STATUS,
        isTerminated: managersStep?.status === TERMINATED_STATUS,
        handleVerifyActorChanges: (fromActorEmail: string) => {
            if(managersStep && stage){
                dispatch(VerifyActorChanges({
                    data: {
                        executorId: stage.stage.executorId,
                        signatureId: null,
                        verifyComment: null, //stage managers - set null
                        stageId: managersStep.id,
                    }
                }));
            }
        },
        viewJustification: (email: string, justification: string | null) => {
            justification && dispatch(openViewJustification({email, justification}));
        },
        editJustification: (app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, stageId: string, actorEmail: string) => {
            dispatch(openAddOrEditJustificationDialog({
                app,
                account,
                stageId,
                actorEmail,
                isManager: true
            }));
        },
        handleChangeSingleStatus: (app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, stageId: string, actorEmail: string, status: string) => {
            stage && dispatch(UpdateCampaignReviewRows({
                data: {
                    apps: [
                        {...app, data: [{...account, statusManager: status}]}
                    ],
                    stageId,
                    executorId: stage.stage.executorId
                }
            }))
        },
        handleKeepAllSelectedManagerAccounts: () => {
            if(managersStep && selectingAccountsOfActorEmail !== null){
                const actor = managersStep.actors.find(e => e.email === selectingAccountsOfActorEmail);
                if(actor){
                    const apps = mapAppsByAccountIds(selectedManagerAccounts, actor, ACCOUNT_KEEP_STATUS, true);
                    stage && dispatch(UpdateCampaignReviewRows({
                        data: {
                            apps,
                            stageId: managersStep.id,
                            executorId: stage.stage.executorId,
                            onSuccess: () => {
                                handleDeselectAllAccounts(); //removing selections
                                setSelectingAccountsOfActorEmail(null); //giving ability to select other actor accounts
                            }
                        }
                    }))
                }
            }
        },
        handleRevokeAllSelectedManagerAccounts: () => {
            if(managersStep && selectingAccountsOfActorEmail !== null){
                const actor = managersStep.actors.find(e => e.email === selectingAccountsOfActorEmail);
                if(actor){
                    const apps = mapAppsByAccountIds(selectedManagerAccounts, actor, ACCOUNT_REVOKE_STATUS, true);
                    stage && dispatch(UpdateCampaignReviewRows({
                        data: {
                            apps,
                            stageId: managersStep.id,
                            executorId: stage.stage.executorId,
                            onSuccess: () => {
                                handleDeselectAllAccounts(); //removing selections
                                setSelectingAccountsOfActorEmail(null); //giving ability to select other actor accounts
                            }
                        }
                    }))
                }
            }
        },
        handleDeleteAllSelectedManagerAccounts: () => {
            if(managersStep && selectingAccountsOfActorEmail !== null){
                const actor = managersStep.actors.find(e => e.email === selectingAccountsOfActorEmail);
                if(actor){
                    const apps = mapAppsByAccountIds(selectedManagerAccounts, actor, ACCOUNT_DELETE_STATUS, true);
                    stage && dispatch(UpdateCampaignReviewRows({
                        data: {
                            apps,
                            stageId: managersStep.id,
                            executorId: stage.stage.executorId,
                            onSuccess: () => {
                                handleDeselectAllAccounts(); //removing selections
                                setSelectingAccountsOfActorEmail(null); //giving ability to select other actor accounts
                            }
                        }
                    }))
                }
            }
        },
    }
}