import {Flex} from "../../../../../../../../../newShared/components/Layouts";
import {PortalTrainingContentModel} from "../../../../../../../../../newShared/GQLTypes";
import {APP_FILES_LINK} from "../../../../../../../../../newShared/constants";
import React, {useEffect, useState} from "react";
import colors from "../../../../../../../../../newShared/theme/colors";
import {Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {downloadFileAction} from "../../../../../../../../../newShared/actions/downloadFile";
import {ReactComponent as VideoIcon} from "../../../../../../../../../newShared/images/empty/video.svg";
import {loadingsSelector} from "../../../../../../store/slice";
import {LoadingButton} from "../../../../../../../../../newShared/components/Basic/CommonButtons";

export const VideoFilePreview = ({section}:{section: PortalTrainingContentModel}) => {
    const dispatch = useDispatch();
    const [error, setError] = useState<boolean>(false);
    const isLoadings = useSelector(loadingsSelector).isDownloadingFile.some(e => e === section.data.fileId);
    const link = `${APP_FILES_LINK}/video/${section.data.fileId}/${section.data.name?.toLowerCase().endsWith('mp4') ? 'mp4' : 'webm'}`

    const handleDownload = () => {
        dispatch(downloadFileAction({
            data: {fileName: section.data.name ?? '', fileId: section.data.fileId ?? ''}
        }))
    }

    useEffect(() => {
        setError(false);
    }, [section]);

    return(
        <Flex w={'480px'} ai={'center'} jc={'center'}>
            {error ?
                <Flex
                    w={'100%'}
                    jc={'center'}
                    ai={'center'}
                    h={'270px'}
                    br={'6px'}
                    direction={'column'}
                    background={colors.primary.disable2}
                >
                    <Flex w={'50%'} ai={'center'} jc={'space-between'} gap={'24px'}>
                        <VideoIcon style={{height: '72px'}}/>
                        <Flex direction={'column'} gap={'6px'}>
                            <Typography variant={'*bodyText1'} color={colors.grayText}>{('Preview not available')}</Typography>
                            <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{('But your recipients can download the video to watch')}</Typography>
                        </Flex>
                    </Flex>

                    <LoadingButton loading={isLoadings} sx={{marginTop: '23px'}} size={'small'} onClick={handleDownload}>
                        {('Download')}
                    </LoadingButton>
                </Flex>
                :
                <video width={480}
                       controls
                       src={link}
                       onError={(e) => {
                           setError(true)
                       }}
                />
            }
        </Flex>
    )
}