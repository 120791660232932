import styled from "styled-components";
import {device} from "../../../../../newShared/constants";
import {TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import colors from "../../../../../newShared/theme/colors";

export const ListPageTopButtonContainer = styled.div`
    display: flex;
    margin: 8px 0 0 0;
    align-items: center;
    ${device.generateMax(1049)} {
        width: 100%;
        justify-content: space-between;
    }
    ${device.generateMax(501)} {
        justify-content: space-between;
        margin: 11px 0 0 0;
    }
`;

export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    width: 25%;
    margin: 30px 0 0 0;
    & div{
        margin: 0 10px 0 0;
    }
    ${device.generateMax(858)}{
        width: 50%;
    }
    ${device.tablet} {
        width: 50%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const TextFieldCustom = styled(TextField)`
  & .MuiInputBase-input {
    height: 16px;
  }
  & label{
    top: 0 !important;
  }
`;

export const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 24px;
  }
  & label{
    top: 0 !important;
  }
`;

export const TableHeaderWrapper = styled.div<{ width?: string, jc?: string, padding?: string}>`
    width: ${props => props.width ?? '90%'};
    ${props => props.padding && `padding: ${props.padding}`};
    display: flex;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: center;
    margin: 25px 0 24px 0;
    ${device.tablet} {
        width: 100%;
        margin: 25px 0 24px 0;
    }
`;


export const TableHeaderItemWrapper = styled.div<{ width?: string, ai?: string, cursor?: string}>`
    width: ${props => props.width ?? '24%'};
    // border: 1px solid red;
    display: flex; 
    align-items: ${props => props.ai ?? 'center'};
    cursor: ${props => props.cursor ? props.cursor : 'default'};
    & p {
        white-space: nowrap;
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
`;

export const MainTableRowWrapper = styled.div<{disableHover?: boolean, width?: string, minWidth?: string, selected?: boolean}>`
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
    width: ${props => props.width ?? '100%'};
    ${props => props.selected && `background-color: ${colors.backgrounds.blue_light_1}`};
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.backgrounds.grey_light};
            cursor: pointer;
        }
    `}
`;

export const TableRowWrapper = styled.div<{disableHover?: boolean, jc?: string, width?: string, selected?: boolean, ai?: string}>`
    width: ${props => props.width ?? '90%'};
    ${props => props.selected && `background-color: ${colors.backgrounds.blue_light_1}`};
    display: flex;
    justify-content: ${props => props.jc ?? 'space-between'};
    align-items: ${props => props.ai ?? 'center'};
    padding: 15px 5px;
    ${props => props.disableHover ?'' : `
        &:hover{
            background-color: ${colors.backgrounds.grey_light};
            cursor: pointer;
        }
    `}
   
    ${device.tablet} {
        width: 100%;
    }
`;

export const TableRowItemWrapper = styled.div<{width?: string}>`
    width: ${props => props.width ?? '24%'};
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 12px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    & p {
        text-overflow: ellipsis; 
        display: block; 
        overflow: hidden; 
        line-height: normal;
    }
    // border: 1px solid red;
`;

export const MainTableScrollWrapper = styled.div`
    overflow: auto;
    padding: 0 5px;
    height: 100%;
    
    ${device.generateMax(858)}{
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0 0;
    }
`;

export const WorkspacesTableScrollWrapper = styled.div`
    overflow: auto;
    padding: 0 5px;
    
    ${device.tablet}{
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 0 0;
    }
`;

export const InviteCardContainer = styled.div<{revDir: boolean}>`
    width: calc(50% - 16px);
    min-width: 330px;
    // border: 1px solid black;
    ${device.desktopNarrow}{
        width: 100%;
    }
    padding: 10px 20px;
    margin: ${props => !props.revDir ? '0 16px 16px 0' : '0 16px 16px 0'};
    
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
`;

export const OrganizationCardContainer = styled.div<{revDir: boolean}>`
    width: calc(50% - 16px); 
    min-width: 330px;
    // border: 1px solid black;
    ${device.desktopNarrow}{
        width: 100%;
    }
    padding: 10px 20px;
    margin: ${props => !props.revDir ? '0 16px 16px 0' : '0 16px 16px 0'};
    
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
`;


export const ProfileSettingWrapper = styled.div<{margin?: string}>`
    // border: 1px solid red;
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: ${props => props.margin ?? '16px 0 0 0'};
    ${device.generateMax(1200)}{
        width: 40%;
    }
    ${device.tablet} {
        width: 50%;
    }
    ${device.generateMax(635)}{
        width: 60%;
    }
    ${device.generateMax(545)}{
        width: 70%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const QrCodeWrapper = styled.div`
    width: 30%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 0 0;
    ${device.tablet}{
        width: 35%;
    }
                   
`;

export const SecretRevealWrapper = styled.div<{padding?: string}>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: ${props => props.padding ?? '41px 0 0 0'};
    & p{
        display: inline-block;
        white-space: pre-wrap;
    }
    flex-wrap: nowrap;
    ${device.tablet}{
        width: 100%;
    }
`;

export const ExactOrganizationOverviewWrapper = styled.div`
    width: 90%;
    display: flex;
    margin: 16px 0 0 0;
    ${device.desktopNarrow} {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
`;

export const ExactOrgOverviewSubWrapper = styled.div<{width?: string}>`
    width: ${props => props.width ?? '50%'};
    flex-direction: column;
    display: flex;
    ${device.desktopNarrow} {
        width: 100%;
    }
`;

export const ExactOrganizationWorkspacesTabSearchWrapper = styled.div`
    width: 25%;
    margin: 16px 0 0 0;
    ${device.tablet}{
        width: 50%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const ExactOrganizationUsersTabSearchWrapper = styled.div`
    width: 40%;
    display: flex;
    margin: 16px 0 0 0;
    ${device.generateMax(1200)}{
        width: 50%;
    }
    ${device.generateMax(960)}{
        width: 60%;
    }
    ${device.tablet}{
        width: 75%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const ExactworkspaceUsersTabSearchWrapper = styled.div`
    width: 50%;
    display: flex;
    margin: 16px 0 0 0;
    ${device.generateMax(960)}{
        width: 60%;
    }
    ${device.tablet}{
        width: 75%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const PlanCardContainer = styled.div`
    width: 100%;
    margin: 16px 5px 0 5px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
`;

export const PlanCard = styled.div<{revDir: boolean, disableMargin?: boolean, selected?: boolean}>`
    // height: 100%;
    width: calc(25% - 16px);
    padding: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
    box-shadow: 0px 0px 1px 0px rgba(163, 164, 165, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(163, 164, 165, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(163, 164, 165, 0.08);
    ${props => props.selected && `border: 1px solid ${colors.secondary.green};`}
    margin: ${props => !props.revDir ? (props.disableMargin ? '0 0 16px 0' : '0 16px 16px 0') : (props.disableMargin ? '0 16px 0 0' : '0 0 16px 16px')};
    ${device.generateMax(858)}{
        width: calc(50% - 16px);
    }
    ${device.desktopNarrow}{
        width: 95%;
        height: auto;
        margin: 16px auto;
    }
`;

export const PlansUseLicenseCodeWrapper = styled.div`
    width: 50%;
    ${device.tablet} {
        width: 70%;
    }
    ${device.mobile} {
        width: 100%;
    }
`;

export const WorkspaceCardContainer = styled.div<{revDir: boolean}>`
    margin: 0 auto;
    width: calc(50% - 16px); 
    min-width: 330px;
    // border: 1px solid black;
    ${device.desktopNarrow}{
        width: 100%;
    }
    padding: 10px 20px;
    margin: ${props => !props.revDir ? '0 16px 16px 0' : '0 16px 16px 0'};
    
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
`;

export const UserCardWrapper = styled.div<{revDir: boolean}>`
    margin: 0 auto;
    width: calc(50% - 16px); 
    min-width: 330px;
    // border: 1px solid black;
    border-radius: 8px;
    ${device.desktopNarrow}{
        width: 100%;
    }
    padding: 10px 20px;
    margin: ${props => !props.revDir ? '0 16px 16px 0' : '0 16px 16px 0'};
    
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 1px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 2px 6px 0px rgba(125, 151, 174, 0.02);
    box-shadow: 0px 8px 16px 0px rgba(125, 151, 174, 0.08);
`;

export const CreateWorkspaceLogoAndNameWrapper = styled.div`
    width: 30%;
  
    ${device.generateMax(1150)}{
        width: 40%;
    }
    ${device.generateMax(858)}{
        width: 50%;
    }
    ${device.desktopNarrow}{
        margin: 0 auto;
        width: 90%;
    }
`;

export const CreateOrgUseLicenseCodeWrapper = styled.div`
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 0 0;
  
    ${device.generateMax(858)}{
        width: 50%;
    }
    ${device.desktopNarrow}{
        margin: 0 auto;
        width: 90%;
    }
`;

export const DialogContentWrapper = styled.div<{margin?: string}>`
    width: 50%;
    ${props => props.margin && `margin: ${props.margin};`}

    ${device.tablet}{
        width: 60%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const InviteUserDialogContentWrapper = styled.div<{margin?: string}>`
    width: 60%;
    ${props => props.margin && `margin: ${props.margin};`}
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

//OUTSIDE INVITE
export const LoginMainWrapper = styled.div`
    width: 100%;
    min-width: 322px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 570px){
        justify-content: center;
    }
    ${device.mobileL}{
        justify-content: center;
    }
`;

export const LoginImage = styled.div<{revDir: boolean}>`
    width: 50%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    transform: ${props => props.revDir ? 'scaleX(-1)' : 'none'};
    margin: ${props => !props.revDir ? '0 100px 0 0' : '0 0 0 100px'};
    
    ${device.desktopL} {
        width: 55%;
    }
    ${device.desktop} {
        width: 50%;
        margin: ${props => !props.revDir ? '0 60px 0 0' : '0 0 0 60px'};
    }
    ${device.tablet}{
        width: 45%;
        margin: ${props => !props.revDir ? '0 60px 0 0' : '0 0 0 60px'};
    }
    @media screen and (max-width: 570px){
        display: none;
    }
    ${device.mobileL}{
        display: none;
    }
    
    
    
`;

export const FormContainer = styled.div`
    width: 35%;
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex; 
    flex-direction: column;
    & h1{
        margin: 30px 0 0 0;
    }
    
    ${device.desktopM} {
        width: 25%;
        padding: 30px 0 0 0;
        & h1{
            margin: 15px 0 0 0;
        }
        & h4{
            margin: 16px 0 0 0;
        }
    }
    ${device.desktop} {
        padding: 50px 0 0 0;
        width: 30%;
        & h1{
            margin: 10px 0 0 0;
        }
    }
    @media screen and (max-width: 1070px){
        width: 40%;
    }
    ${device.tablet}{
        width: 50%;
        
    }
    @media screen and (max-width: 660px){
        width: 50%;
        padding: 20px 0 0 0;
    }
    
    @media screen and (max-width: 570px){
        width: 80%;
    }
    ${device.mobileL}{
        width: 90%;
        & h1{
            margin: 30px 0 0 0;
        }
        & h4{
            margin: 16px 0 0 0;
        }
    }
`;

export const LogoAndNameContainer = styled.div`
    & img {
        width: 320px;
    }
    ${device.desktopM}{
        & img{
            width: 200px;
        }
    }
    ${device.tablet}{
        & img{
            width: 200px;
        }
    }
    @media screen and (max-width: 570px){
        & img{
            width: 200px;
        }
    }
    ${device.mobile}{
        & img{
            width: 200px;
        }
    }
`;

export const ButtonContainer = styled.div<{disableDirectionColumn?: boolean, margin?: string}>`
    margin: ${props => props.margin ?? '0'};
    width: 35%;
    display: flex;
    flex-direction: ${props => props.disableDirectionColumn ? '' : 'column'};
    // align-items: center;
    ${device.desktop} {
        width: 35%;
    }
    ${device.tablet} {
        width: 35%;
    }
    @media screen and (max-width: 570px){
        & img{
            width: 65%;
        }
    }
`;

export const ForbiddenWrapperSelectWorkspaceContainer = styled.div`
  width: 100%;
  height: calc(100% - 32px);
  display: flex;
  flex-direction: column;
`;

export const ForbiddenWrapperContentContainer = styled.div`
    width: 40%;
    padding: 14px 16px;
    display: flex;
    flex-direction: column;
    ${device.tablet}{
        width: 70%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;


//NEW CREATE ORG
export const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    
    & img { 
        position: absolute;
        width: 30vw;
        bottom: 0;
        right: 0;
        overflow: hidden;
        // width: 100%;
        transform: rotate(180deg);
    }
`;

export const CreateOrgLeftSideBar = styled.div`
    width: 30%;
    height: 100%;
    background-color: rgba(234, 237, 253, 0.4);
    padding: 40px;
    display: flex;
    flex-direction: column;
`;

export const CreateOrgRightSideContainer = styled.div`
    width: 70%;
    // min-height: 700px;
    height: 100vh;
    position: relative;
    
    overflow: auto;
    
    display: flex;
    flex-direction: column;
    

    ${device.tablet}{
        width: 100%;
    }
`;

export const CreateOrgRightSideFieldsContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 40px 40px;
    
    ${device.desktopL}{
        width: 50%;
    }
    
    ${device.generateMax(1512)}{
        width: 50%;
    }
    
    ${device.desktop}{
        width: 60%;
    }
    
    ${device.generateMax(1100)}{
        width: 70%;
    }
    
    ${device.generateMax(1012)}{
        width: 90%;
    }
    
    ${device.tablet}{
        width: 80%;
        padding: 0 16px 80px 16px;
    }
    
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const CreateOrgPlanSelectionContainer = styled.div<{overflow: string}>`
    width: 100%;
    max-width: 1060px;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    
    padding: 40px 40px;
    
    ${device.tablet}{
        width: 100%;
        padding: 16px 16px 80px 16px;
    }
`;

export const CreateOrgRightSideSubDetails = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 40px 40px;
    
    ${device.desktopL}{
        width: 70%;
    }
    
    ${device.generateMax(1512)}{
        width: 80%;
    }
    
    ${device.desktop}{
        width: 80%;
    }
    
    ${device.generateMax(1100)}{
        width: 70%;
    }
    
    ${device.generateMax(1012)}{
        width: 90%;
    }
    
    ${device.tablet}{
        width: 80%;
        padding: 0 16px 80px 16px;
    }
    
    ${device.desktopNarrow}{
        width: 100%;
    }
`;

export const CreateOrgRightSidePaymentIframeContainer = styled.div`
    width: 80%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 40px 40px;
    max-height: 800px;
    max-width: 500px;

  ${device.tablet}{
        width: 100%;
        padding: 20px 0 50px 0;
        min-height: 750px;
        max-width: none;
    }
`;

export const CreateOrgRightSideSuccessContainer = styled.div`
    width: 80%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 200px auto;
  ${device.tablet}{
        width: 100%;
        padding: 20px;
  }
`;

export const UploadedlogoContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    
    margin: 18px 0 0 0;
    
    & img{
        transform: rotate(0deg);
        position: relative; //fix from parent setting in CreateOrgRightSideContainer
        width: 44px;
    }
`;

export const TabletStepScroller = styled.div`
    display: flex;
    overflow-y: auto;
    max-width: 100vw;
    margin: 84px 20px 0 20px;
    min-height: 100px;
`;

//
export const SelectPlanContainer = styled.div`
    justify-content: center;
    
    width: 100%;
    max-width: 1060px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    
    padding: 16px 40px 16px 40px;
    
    ${device.tablet}{
        width: 100%;
        padding: 8px 0 0 0;
    }
`;

export const PriceCardsContainer = styled.div`
  width: 240px;
  box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  ${device.desktopNarrow} {
    width: 100%;
  }
`;

//EXACT ORG BILLING

export const OrganizationBillingTabContainer = styled.div`
    width: 80%;
    overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 35px 20px 0 20px;
  ${device.tablet}{
    width: 100%;
  }
`;

export const OrganizationBillingInfoAndPaymentMethodContainer = styled.div`
    width: 100%;
  display: flex;
  justify-content: space-between;
  ${device.tablet}{
    flex-direction: column;
  }
`;

export const BillingOrPaymentContainer = styled.div`
    width: 40%;
  display: flex;
  flex-direction: column;
  ${device.tablet}{
    width: 100%;
    margin: 16px 0 0 0;
  }
`;


export const OrganizationBillingHistoryTabContainer = styled.div`
    width: 100%;
  height: 100%;
    overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 35px 20px 0 20px;
  ${device.tablet}{
    width: 100%;
  }
`;

export const ExactOrganizationBillingHistoryStatusSelect = styled.div`
    width: 30%;
    ${device.tablet}{
        width: 75%;
    }
    ${device.desktopNarrow}{
        width: 100%;
    }
`;