import React, {FC, useEffect, useState} from "react";
import {Alert, AppBar, Box, Button, Collapse, IconButton, Toolbar, Typography} from "@mui/material";
import {Menu as MenuIcon} from "@mui/icons-material";

import {useMainTranslation} from "../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

import colors from "../../../newShared/theme/colors";
import {TopBarHelp} from "./components/topBarHelp";
import {TopBarSettings} from "./components/topBarSettings";
import {TopBarAvatar} from "./components/topBarAvatar";
import {TopBarWorkspace} from "./components/topBarWorkspace";
import {useLeftMenu} from "../leftMenu/hooks/useLeftMenu";
import {PATH_LOCAL_LOGIN} from "../../../newShared/constants";
import {useHistory} from "react-router";
import {ThreeDotsTopBar} from "./components/threeDotsTopBar";
import {useMedia} from "../../../newShared/hooks/useMedia";
import {showUnProtectedTopBar} from "../shell/helpers";
import {useAlerts} from "../snack/hooks/useAlerts";
import {
    UseManageWorkspacesAndOrganizations
} from "../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
// import {useAlertOnChangeWorkspace} from "../snack/hooks/useAlertOnChangeWorkspace";
import {TobBarLogo} from "../../../newShared/components/TobBarLogo";
import {diffBetweenDateAndNow} from "../../../newShared/utils/dateTools";

type TProps = {
    isHidden: boolean,
}
export const TopBar: FC<TProps> = ({isHidden}) => {
    const {t, currentLanguage} = useMainTranslation('', {keyPrefix: 'TopBar'});
    const history = useHistory();

    const {currData: {
        currentUser, selectedWorkspace, selectedOrganization, is2FaAuthComplete
    }} = UseManageWorkspacesAndOrganizations();

    const {isOpen, setOpen, secondMenuWidth} = useLeftMenu();
    const {isDesktop, matches_1440Down} = useMedia();

    const {alerts, removeAlert} = useAlerts();
    const orgExpiresIn = diffBetweenDateAndNow(selectedOrganization?.subscriptionExpiry ?? '', currentLanguage.momentLocale);

    const [isSecondMenuExists, setIsSecondMenuExists] = useState<boolean>(false);

    //eslint-disable-next-line
    useEffect(() => {
        const leftSecondMenu = document.getElementById('leftSecondMenu');
        setIsSecondMenuExists(leftSecondMenu !== null);
        //eslint-disable-next-line
    });

    return (
        <>
            {!isHidden ? (
                <AppBar position={"static"} style={{minHeight: matches_1440Down ? '48px' : undefined}}
                        sx={{
                            zIndex: (theme) => theme.zIndex.drawer + 30,
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)'
                        }} >
                    <Toolbar>
                        {!showUnProtectedTopBar(history.location.pathname) && (
                            <IconButton
                                size={"large"}
                                sx={{color: isOpen ? colors.primary.blue : colors.text.grey_dark}}
                                onClick={() => {
                                    setOpen()
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                        )}

                        <TobBarLogo />

                        {!showUnProtectedTopBar(history.location.pathname) && (
                            isDesktop ? (
                                <>
                                    <Box height={'100%'} minWidth={'10px'} flexGrow={1}/>

                                    {is2FaAuthComplete && currentUser?.publicId && selectedWorkspace && selectedOrganization && <TopBarWorkspace/>}
                                    {/*{is2FaAuthComplete && currentUser?.publicId && <TopBarNotifications/>}*/}
                                    <TopBarHelp/>
                                    {is2FaAuthComplete && currentUser?.publicId && <TopBarSettings/>}
                                    {is2FaAuthComplete && currentUser?.publicId && <TopBarAvatar/>}
                                </>
                            ) : (
                                <>
                                    <Box height={'100%'} minWidth={'10px'} flexGrow={1}/>
                                    {is2FaAuthComplete && currentUser?.publicId && <ThreeDotsTopBar/>}
                                </>
                            )
                        )}

                        {!showUnProtectedTopBar(history.location.pathname) && !currentUser?.publicId && (
                            <Button onClick={() => {history.push(PATH_LOCAL_LOGIN)}}>{t('Login')}</Button>
                        )}
                    </Toolbar>
                </AppBar>
            ) : null}

            <Box sx={{
                zIndex: theme => theme.zIndex.drawer - 1,
                width: isSecondMenuExists ? `calc(100% - ${secondMenuWidth}px)` : '100%',
                // left: secondMenuWidth,
                position: 'relative',
                alignSelf: 'end',
                // top: '64px',
            }}>
                {alerts.map(alert => (
                    <Collapse key={alert.key}
                              in={alert.isOpen}>
                        <Alert severity={alert.variant} variant={"filled"}
                               onClose={() => {removeAlert(alert.key)}}
                        >
                            {alert.message}
                        </Alert>
                    </Collapse>
                ))}


                {selectedOrganization && orgExpiresIn <=10 && <Alert severity={'error'} variant={"filled"} sx={{padding: '4px 8px'}} icon={false}
                >
                    <Typography variant={'*bodyText2_semibold'} color={colors.text.white}>
                        Your organization will expire in {Math.floor(orgExpiresIn)} days.
                    </Typography>
                </Alert>}
            </Box>
        </>
    )
}