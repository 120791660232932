import {MouseEvent, useState} from "react";
import {useDispatch} from "react-redux";
import {openSelectWorkspaceDialog} from "../../../../authWorkspacesCookies/settings/store/slice";

export const useTopBar = () => {
    const dispatch = useDispatch();

    const [anchorWorkspaceEl, setAnchorWorkspaceEl] = useState<null | HTMLElement>(null);
    const handleWorkspacesOpen = (event: MouseEvent<HTMLElement>) => {setAnchorWorkspaceEl(event.currentTarget);};
    const handleWorkspacesClose = () => {setAnchorWorkspaceEl(null);};

    const [anchorPageEl, setAnchorPageEl] = useState<null | HTMLElement>(null);
    const handlePageOpen = (event: MouseEvent<HTMLElement>) => {setAnchorPageEl(event.currentTarget);};
    const handlePageClose = () => {setAnchorPageEl(null);};

    const [anchorNotificationEl, setAnchorNotificationEl] = useState<null | HTMLElement>(null);
    const handleNotificationOpen = (event: MouseEvent<HTMLElement>) => {setAnchorNotificationEl(event.currentTarget);};
    const handleNotificationClose = () => {setAnchorNotificationEl(null);};

    const [anchorAvatarEl, setAnchorAvatarEl] = useState<null | HTMLElement>(null);
    const handleAvatarOpen = (event: MouseEvent<HTMLElement>) => {setAnchorAvatarEl(event.currentTarget);};
    const handleAvatarClose = () => {setAnchorAvatarEl(null);};

    const [anchorThreeDotsEl, setAnchorThreeDotsEl] = useState<null | HTMLElement>(null);
    const handleThreeDotsOpen = (event: MouseEvent<HTMLElement>) => {setAnchorThreeDotsEl(event.currentTarget);};
    const handleThreeDotsClose = () => {setAnchorThreeDotsEl(null);};

    return {
        anchorWorkspaceEl, setAnchorWorkspaceEl,
        handleWorkspacesOpen, handleWorkspacesClose,

        anchorPageEl, setAnchorPageEl,
        handlePageOpen, handlePageClose,

        anchorNotificationEl, setAnchorNotificationEl,
        handleNotificationOpen, handleNotificationClose,

        anchorAvatarEl, setAnchorAvatarEl,
        handleAvatarOpen, handleAvatarClose,

        anchorThreeDotsEl, setAnchorThreeDotsEl,
        handleThreeDotsOpen, handleThreeDotsClose,

        //
        // handleGoToReports, handleGoToDocs, handleGoToMain,
        handleOpenSelectWorkspaceDialog: () => {
            dispatch(openSelectWorkspaceDialog())
        }

    }
}
