import {Flex} from "../../../../../newShared/components/Layouts"
import {Divider} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {loadings, selectedStage} from "../../store/slice";
import {useSelector} from "react-redux";
import {Typo} from "../../../../../newShared/components/Typography";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {getStatus} from "../../helpers";
import React from "react";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import {
    REVIEW_CAMPAIGN_STEP_MANAGER,
    REVIEW_CAMPAIGN_STEP_SIGN,
    REVIEW_CAMPAIGN_STEP_VENDOR,
    REVIEW_CAMPAIGN_STEP_VERIFY
} from "../../constants";
import {CampaignReviewManager} from "../campaignReview/managers";
import {ViewJustificationDialog} from "../campaignReview/dialogs/viewJustificationDialog";
import {ViewOrEditJustificationDialog} from "../campaignReview/dialogs/viewOrEditJustificationDialog";
import {PreviewRoleDialog} from "../campaignReview/dialogs/previewRoleDialog";
import {CampaignReviewVendors} from "../campaignReview/vendors";
import {CampaignReviewVerify} from "../campaignReview/verify";
import {DeclineVerifyStepDialog} from "../campaignReview/dialogs/declineVerifyStepDialog";
import {CampaignReviewSignStep} from "../campaignReview/sign";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";

export const DocumentCampaignReviewComponent = () => {
    const stage = useSelector(selectedStage);
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'workerSpace'});
    const {campaignReviewStage} = useSelector(loadings);
    const selected = useSelector(selectedStage);

    if(!stage || !stage.stageCampaignReview) return <></>;
    //
    //
    return(
        <Flex direction={'column'} w={'74%'} overflow={'auto'}>
            {/*TOPBAR*/}
            {campaignReviewStage && selected &&  !selected.stageCampaignReview && <LocalSpinner />}
            {selected && selected.stageCampaignReview &&
                <>
                    {/*TOPBAR*/}
                    <Flex w={'100%'} direction={'column'} ai={'center'} m={'35px 0 0 0'}>
                        <Flex w={'100%'} jc={'space-between'} ai={'center'} wrap={'wrap'}>
                            <Flex w={'80%'} ai={'center'} wrap={'wrap'}>
                                <Typo fontSize={'16px'} fontWeight={300} color={'#878BA1'} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'}>{('Campaign review name')}: </Typo>
                                <Flex w={'60%'} overflow={'hidden'}>
                                    <Typo fontSize={'16px'} fontWeight={300} color={'#1D1D1D'} margin={!revDir ? '0 15px 0 0' : '0 0 0 15px'} textOverflow={'ellipsis'} noWrap>{stage.stage.name}</Typo>
                                </Flex>
                            </Flex>
                            <Typo fontSize={'16px'} fontWeight={300} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'} color={'#FFA000'}>{(stage.stage.type.toUpperCase())}</Typo>
                        </Flex>

                        <Flex jc={'space-between'} w={'100%'} ai={'center'} wrap={'wrap'}>
                            <Flex ai={'center'}>
                                <Typo fontSize={'16px'} fontWeight={300} color={'#878BA1'} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'}>{t('Expiration Date')}: </Typo>
                                <Typo fontSize={'16px'} fontWeight={200} color={'#1D1D1D'} margin={!revDir ? '0 10px 0 0' : '0 0 0 10px'} textOverflow={'fade'}>{parseDateAuto(stage.stage.expirationDate)}</Typo>
                                {getStatus(stage.stage.actor.status)}
                            </Flex>

                            <Flex ai={'center'} wrap={'wrap'}>
                                <Typo fontSize={'16px'} fontWeight={300} color={'#878BA1'} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'}>{t('Date Received')}: </Typo>
                                <Typo fontSize={'16px'} fontWeight={200} color={'#1D1D1D'} textOverflow={'fade'}>{parseDateAuto(stage.stage.runDate)}</Typo>
                            </Flex>
                        </Flex>

                        <Divider flexItem style={{backgroundColor: colors.primary.blue, margin: '8px 0 0 0'}} />
                    </Flex>
                    {/*STAGES*/}
                    {selected.stageCampaignReview.type === REVIEW_CAMPAIGN_STEP_MANAGER && <CampaignReviewManager />}
                    {selected.stageCampaignReview.type === REVIEW_CAMPAIGN_STEP_VENDOR && <CampaignReviewVendors />}
                    {selected.stageCampaignReview.type === REVIEW_CAMPAIGN_STEP_VERIFY && <CampaignReviewVerify />}
                    {selected.stageCampaignReview.type === REVIEW_CAMPAIGN_STEP_SIGN && <CampaignReviewSignStep />}
                </>
            }

            <ViewJustificationDialog />
            <ViewOrEditJustificationDialog />
            <PreviewRoleDialog />
            <DeclineVerifyStepDialog />
        </Flex>
    )
}