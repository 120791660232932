import {PortalHrEmployeeModel, PortalHrGetOrgTreeQuery, PortalHrJobHistoryModel} from "../../../newShared/GQLTypes";

export type THrProfile = PortalHrEmployeeModel;
export type THrProfileJobHistory = PortalHrJobHistoryModel;
export type TSmallEmployee = PortalHrGetOrgTreeQuery["portalHrGetOrgTree"][number];


type TEventEdgeConnectEnd = MouseEvent & {
    target?: {dataset?: {nodeid: string}},
    // srcElement?: {dataset?: {nodeid: string}},
}
export function isEventEdgeConnectEnd(event: unknown): event is TEventEdgeConnectEnd {
    return typeof event === "object" && !!event
        && "target" in event && typeof event.target === "object" && !!event.target
        && "dataset" in event.target && typeof event.target.dataset === "object" && !!event.target.dataset
        && "nodeid" in event.target.dataset && typeof event.target.dataset.nodeid === 'string'
    // && "srcElement" in event && typeof event.srcElement === "object" && !!event.srcElement
    // && "dataset" in event.srcElement && typeof event.srcElement.dataset === "object" && !!event.srcElement.dataset
    // && "nodeid" in event.srcElement.dataset && typeof event.srcElement.dataset.nodeid === 'string'
}