import {TPageInfo} from "../../../newShared/types";
import {RequiredActionsType} from "../../../newShared/GQLTypes";


export const defaultPageInfo: TPageInfo = {
    page: 0,
    count: 25,
    total: 0,
    sort: ''
}

export const requiredActionsTaskTypeTitleMapper: Record<RequiredActionsType, string> = {
    [RequiredActionsType.ProvideFiles]: 'Provide files'
}
