import {PortalTrainingContentModel, TrainingContentStatus} from "../../../../../../../../newShared/GQLTypes";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import {TypographyNew} from "../../../../../../../../newShared/components/TypographyNew";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import {LightTooltip} from "../../../../../../../../newShared/components/pageTitleAndActions";

export type SectionsMenuPropsType = {
    sections: PortalTrainingContentModel[];
    onSectionClick: (section: PortalTrainingContentModel) => void,
    selectedSection: PortalTrainingContentModel | null;
}
export const SectionsMenu = ({sections, onSectionClick, selectedSection}: SectionsMenuPropsType) => {
    return(
        <Flex w={'100%'} direction={'column'} grow={'1'} overflow={'auto'}>
            <Typography variant={'*bodyText1_semibold'} color={colors.grayText}>{('List of sections')}</Typography>

            <Flex w={'100%'} maxw={'430px'} overflow={'auto'} direction={'column'} m={'16px 0 0 0'} gap={'10px'} >
                {sections
                    .slice()
                    .sort((a, b) => a.order - b.order)
                    .map(e => {
                    const isSelected = selectedSection?.sectionId === e.sectionId;
                    const isDone = e.status === TrainingContentStatus.Done;
                    // const isInProgress = e.status === TrainingContentStatus.InProgress;
                    const isTodo = e.status === TrainingContentStatus.Todo;
                    return(
                        <LightTooltip title={isTodo ? 'You have to finish previous sections first' : ''}>
                            <Flex
                                w={'100%'}
                                key={e.sectionId}
                                cursor={!isTodo ? 'pointer' : undefined}
                                onClick={() => onSectionClick(e)}
                                p={'16px'}
                                br={'6px'}
                                border={`1px solid ${colors.stroke.table}`}
                                background={isSelected ? colors.backgrounds.optional : colors.backgrounds.white}
                                jc={'space-between'}
                            >
                                <TypographyNew
                                    noWrap
                                    variant={'*bodyText2_semibold'}
                                    color={isTodo ? colors.text.grey : colors.grayText}
                                    sx={{cursor: 'default'}}
                                >
                                    {e.sectionName}
                                </TypographyNew>

                                {isDone &&
                                    <LightTooltip title={'Section is done'}>
                                        {isDone && <CheckCircleOutlinedIcon sx={{color: colors.text.success}}/>}
                                    </LightTooltip>
                                }
                            </Flex>
                        </LightTooltip>
                    )
                })}
            </Flex>
        </Flex>
    )
}