//Core
import React, {FC} from 'react';
import {Routes} from "./routes";

//Layout
//Styled
import {BodyContainer} from "./components/styled"
import {Snack} from "../snack";

import {SnackbarProvider} from "notistack";
import {Box, ThemeProvider} from "@mui/material";
import {useMainTranslationHookInit} from "../../../newShared/hooks/useMainTranslationHooks/useInit";
import {FlexStart} from '../../../newShared/components/Layouts';
import {Spinner} from "../../../newShared/components/Spinner";
import {SubscriptionHandler} from "../SubscriptionHandler";
import {useSideBar} from "./hooks/useSideBar";
import {TopBar} from "../topBar";
import {LeftMenu} from "../leftMenu";
import colors from "../../../newShared/theme/colors";
import {MessageDialog} from "../MessageDialog";
import {DocViewerDialog} from "../../../newShared/components/docViewer";
import {SelectWorkspaceDialog} from "../../authWorkspacesCookies/settings/components/selectWorkspaceDialog";
import {useWorkspacesAndOrganizations} from "../../authWorkspacesCookies/cookies/hooks/useWorkspacesAndOrganizations";
import {useNextPathOnLoad} from "./hooks/useNextPath";


export const Shell: FC = () => {
    const isHiddenBar = useSideBar();
    const {revDir, themeWithLocale} = useMainTranslationHookInit();

    //when workspace or organization is not selected - disabling  buttons and paths user can not go without selection
    const {isLoadingCookieData, isWorkspaceSelected, isSignedIn, } = useWorkspacesAndOrganizations();

    useNextPathOnLoad();

    return (
        <ThemeProvider theme={themeWithLocale}>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: !revDir ? 'right' : 'left' }} preventDuplicate>
                <FlexStart w={"100vw"} h={"100vh"} of={"hidden"} d={'column'}>
                    <TopBar isHidden={isHiddenBar}/>

                    <BodyContainer w={'100%'}>
                        <Snack/>
                        {isSignedIn && <LeftMenu/>}
                        <Box overflow={"hidden"} height={'100%'} width={'100%'} bgcolor={colors.backgrounds.white}>
                            {/*<Alert severity={"warning"} sx={{zIndex: 3000}}>This is a warning alert — check it out!</Alert>*/}

                            {!isLoadingCookieData ? <Routes/> : <Spinner />}
                            {(isWorkspaceSelected) && <SubscriptionHandler />}
                            <MessageDialog/>
                            <DocViewerDialog/>
                            <SelectWorkspaceDialog />
                        </Box>
                    </BodyContainer>
                </FlexStart>
            </SnackbarProvider>

        </ThemeProvider>
    )
}
