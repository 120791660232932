import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {portalHrGetIssueOffenderAutocomplete, portalHrGetTimeOffEmployeesAutocomplete} from "./query";
import {buildCommonHeader} from "../../../../newShared/utils";
import {AxiosResponse} from "axios";
import {
    PortalHrIssueOffenderAutocompleteModel,
    PortalHrTimeOffEmployeesAutocompleteModel
} from "../../../../newShared/GQLTypes";
import {print} from "graphql";

export const portalHrGetIssueOffenderAutocompleteApi = async (workspaceId: string, {page, count, search}: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<PortalHrIssueOffenderAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(portalHrGetIssueOffenderAutocomplete),
                variables: {
                    workspaceId,
                    pageRequest: {page, count},
                    search
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalHrGetIssueOffenderAutocomplete;
    })
)

export const portalHrGetTimeOffEmployeesAutocompleteApi = async (workspaceId: string, {page, count, search}: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<PortalHrTimeOffEmployeesAutocompleteModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(portalHrGetTimeOffEmployeesAutocomplete),
                variables: {
                    workspaceId,
                    pageRequest: {page, count},
                    search
                }
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalHrGetTimeOffEmployeesAutocomplete;
    })
)
