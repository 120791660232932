import {FC} from "react";
import {useHrRequests} from "../../hooks/useHrRequests";
import * as S from "../../components/styled";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {HrRequestStatus, PortalHrRequestModel} from "../../../../../newShared/GQLTypes";
import {getRequestStatusChip} from "../../helpers";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import colors from "../../../../../newShared/theme/colors";
import {statusTypeTitleMapper} from "../../constants";
import {HrRequestViewDialog} from "../../components/dialogs/hrRequestView";
import {CancelRequestDialog} from "../../components/dialogs/cancelRequest";

export const HrRequests: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathMyHrRequests'});
    const {requests, genericTable, handleOpenRequestView, handleOpenCancelRequest } = useHrRequests();
    const {isMobile} = useMedia();

    return (
        <S.PageWrapper>
            <PageTitleAndActions title={t('My HR Requests')} actions={[]} />

            <GenericTable<PortalHrRequestModel>
                id={'MyHrRequests'}
                columnsConfig={{totalName: 'Total requests', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: requests,
                    // customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenRequestView,
                    rowActionsConfig: [
                        {
                            title: t('Cancel request'),
                            onClick: handleOpenCancelRequest,
                            icon: <CancelScheduleSendIcon fontSize={'small'} style={{color: colors.primary.blue}}/>,
                            dontShow: (row) => row.status !== HrRequestStatus.Created
                        },
                    ],
                    columnsConfigs: [
                        {key: "id", name: t('Request ID'), default: true, },
                        {key: 'type', name: t('Request type'), default: true, valueGetter: (row) => statusTypeTitleMapper[row.type] ?? row.type},
                        {key: 'requestDate', name: t('Request date'), default: true, valueGetter: (row) => parseDateAuto(row.requestDate)},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row: PortalHrRequestModel) => getRequestStatusChip(row.status)},
                        {key: 'resolver', name: t('Resolver'), default: true, valueGetter: (row) => row.resolver ? row.resolver.employeeFullName ?? row.resolver.collaboratorFullName : '-'},
                        {key: 'resolveDate', name: t('Resolve date'), default: true, valueGetter: (row) => row.resolveDate ? parseDateAuto(row.resolveDate ?? '') : ''},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'requestIdLike',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('No requests to display')}}}
            />

            {/*Dialogs*/}
            <HrRequestViewDialog/>
            <CancelRequestDialog />

        </S.PageWrapper>
    );
};
