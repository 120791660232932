import {gql} from "@apollo/react-hooks";

export const portalAssetFragment =  gql`
    fragment portalAssetFragment on PortalPhysicalAssetModel {
        id
        workspaceId
        assetId
        serial
        model
        manufacture
        category
        assigned
        customFields
    }
`

export const assetRequestFragment =  gql`
    fragment assetRequestFragment on PortalAssetsRequestModel {
        assetType 
        comment
        data {
            fieldName
            fieldValue
        }
        id
        requestDate
        resolution
        resolveDate
        resolver {
            fullName
            publicId
        }
        status
        type
        workspaceId
    }
`
