import {useDispatch, useSelector} from "react-redux";
import {dialogsSelector, loadingsSelector, setPreviewFinishedTrainingDialogAction} from "../../../store/slice";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {portalGetAssignmentTrainingByIdAction} from "../../../store/actions";
import {PortalTrainingContentModel, TrainingContentStatus} from "../../../../../../newShared/GQLTypes";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";

export const usePreviewFinishedTrainingDialog = () => {
    //root
    const {setMessage} = useMessageDialog();
    const dispatch = useDispatch();
    const {assignmentId} = useParams<{ assignmentId: string }>();

    //selectors
    const {getAssignmentTrainingById} = useSelector(loadingsSelector);
    const {isOpen, training} = useSelector(dialogsSelector).previewFinishedTrainingDialog;

    const handleClose = () => dispatch((setPreviewFinishedTrainingDialogAction({clear: true})));

    //useEffects
    useEffect(() => {
        if(isOpen){
            dispatch(portalGetAssignmentTrainingByIdAction({
                data: {id: assignmentId ?? ''},
                onSuccess: (req, response) => {
                    setSelectedSection(response.content[0])
                },
                onError: (_, error) => {
                    const error409 = error.e409?.[0];
                    if (error409?.type === 'STATUS_CHANGED') {
                        setMessage({
                            title: ('Action conflict error'),
                            message: ('Action not applicable! Assignment status was changed.'),
                        });
                    }
                    if (error409?.type === 'TRAINING_NOT_EXISTS') {
                        setMessage({
                            title: ('Action conflict error'),
                            message: ('Action not applicable! Training not found.'),
                        });
                    }
                }
            }))
        }else{
            setIsMenuOpened(true);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    //store
    const [selectedSection, setSelectedSection] = useState<PortalTrainingContentModel | null>(null);

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(true);

    // console.log(`training?.content ?? []`, training?.content ?? [])
    return{
        isOpen,
        loadings: {
            isLoadingGetTraining: getAssignmentTrainingById,
        },
        training,
        actions: {
            handleClose,
        },
        sectionsMenu: {
            onSectionClick: (section: PortalTrainingContentModel) => {
                if(section.status === TrainingContentStatus.Done){
                    setSelectedSection(section);
                }
            },
            sections: training?.content ?? [],
            selectedSection,
            isMenuOpened,
            toggleMenu: () => setIsMenuOpened(!isMenuOpened),
        },
        trainingInfo: {
            trainingLimitInH: training?.limitMinDuration ?? 0,
            sections: training?.content ?? [],
        },
        sectionDetails: {
            section: selectedSection
        }
    }
}