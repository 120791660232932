// import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {
    portalTrainingAssignmentExamFragment,
    portalTrainingAssignmentModelFragment,
    portalTrainingFragment,
    portalTrainingsAssignmentShortFragment
} from "./fragments";

export const portalTrainingsGetAssignmentsWithFilterPagination = gql`
    query portalTrainingsGetAssignmentsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: PortalAssignmentsFilters!) {
        portalTrainingsGetAssignmentsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...portalTrainingsAssignmentShortFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${portalTrainingsAssignmentShortFragment}
`

export const portalTrainingGetAssignmentRequestorsAutocomplete = gql`
    query portalTrainingGetAssignmentRequestorsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        portalTrainingGetAssignmentRequestorsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                collaboratorId
                fullName
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;


export const portalTrainingGetAssignmentTrainingsAutocomplete = gql`
    query portalTrainingGetAssignmentTrainingsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        portalTrainingGetAssignmentTrainingsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                id
                name
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const portalTrainingGetAssignmentExamsAutocomplete = gql`
    query portalTrainingGetAssignmentExamsAutocomplete($workspaceId: String!, $pageRequest: SharedPaginationInput!, $search: String!) {
        portalTrainingGetAssignmentExamsAutocomplete(workspaceId: $workspaceId, pageRequest: $pageRequest, search: $search) {
            result {
                id
                name
            }
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
`;

export const portalGetAssignmentTrainingById = gql`
    query portalGetAssignmentTrainingById($workspaceId: String!, $id: String!) {
        portalGetAssignmentTrainingById(workspaceId: $workspaceId, id: $id) {
            ...portalTrainingFragment
        }
    }
    ${portalTrainingFragment}
`;

export const portalStartTraining = gql`
    mutation portalStartTraining($workspaceId: String!, $id: String!) {
        portalStartTraining(workspaceId: $workspaceId, id: $id) {
            message
        }
    }
`;

export const portalTrainingsStartExam = gql`
    mutation portalTrainingsStartExam($workspaceId: String!, $assignmentId: String!, $type: TrainingAssignmentExamType!) {
        portalTrainingsStartExam(workspaceId: $workspaceId, assignmentId: $assignmentId, type: $type) {
            message
        }
    }
`;

export const portalTrainingsGetExamByAssignmentId = gql`
    query portalTrainingsGetExamByAssignmentId($workspaceId: String!, $assignmentId: String!, $type: TrainingAssignmentExamType!) {
        portalTrainingsGetExamByAssignmentId(workspaceId: $workspaceId, assignmentId: $assignmentId, type: $type) {
            ...portalTrainingAssignmentExamFragment
        }
    }
    ${portalTrainingAssignmentExamFragment}
`;

export const portalTrainingsExamSaveAnswer = gql`
    mutation portalTrainingsExamSaveAnswer($workspaceId: String!, $assignmentId: String!, $type: TrainingAssignmentExamType!, $questionId: String!, $answer: [String!]!) {
        portalTrainingsExamSaveAnswer(workspaceId: $workspaceId, assignmentId: $assignmentId, type: $type, questionId: $questionId, answer: $answer) {
            readyToFinish
            skippedQuestions
        }
    }
`;

export const portalTrainingsExamSkipAnswer = gql`
    mutation portalTrainingsExamSkipAnswer($workspaceId: String!, $assignmentId: String!, $type: TrainingAssignmentExamType!, $questionId: String!) {
        portalTrainingsExamSkipAnswer(workspaceId: $workspaceId, assignmentId: $assignmentId, type: $type, questionId: $questionId) {
            readyToFinish
            skippedQuestions
        }
    }
`;

export const portalTrainingsFinishExam = gql`
    mutation portalTrainingsFinishExam($workspaceId: String!, $assignmentId: String!, $type: TrainingAssignmentExamType!) {
        portalTrainingsFinishExam(workspaceId: $workspaceId, assignmentId: $assignmentId, type: $type) {
            examStatus
            exam {
                ...portalTrainingAssignmentExamFragment
            }
        }
    }
    ${portalTrainingAssignmentExamFragment}
`;

export const portalUpdateTrainingDuration = gql`
    mutation portalUpdateTrainingDuration($workspaceId: String!, $assignmentId: String!, $duration: Float!) {
        portalUpdateTrainingDuration(workspaceId: $workspaceId, assignmentId: $assignmentId, duration: $duration) {
            message
        }
    }
`;

export const portalFinishTrainingSection = gql`
    mutation portalFinishTrainingSection($workspaceId: String!, $assignmentId: String!, $sectionId: String!, $duration: Float!) {
        portalFinishTrainingSection(workspaceId: $workspaceId, assignmentId: $assignmentId, sectionId: $sectionId, duration: $duration) {
            readyToFinish
            nextSection
            duration
        }
    }
`;

export const portalFinishTraining = gql`
    mutation portalFinishTraining($workspaceId: String!, $assignmentId: String!) {
        portalFinishTraining(workspaceId: $workspaceId, assignmentId: $assignmentId) {
            message
        }
    }
`;

export const portalTrainingsGetAssignmentDetails = gql`
    query portalTrainingsGetAssignmentDetails($workspaceId: String!, $assignmentId: String!) {
        portalTrainingsGetAssignmentDetails(workspaceId: $workspaceId, assignmentId: $assignmentId) {
            ...portalTrainingAssignmentModelFragment
        }
    }
    ${portalTrainingAssignmentModelFragment}
`;