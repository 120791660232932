import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {SETTINGS_LANGUAGE_PATH, SETTINGS_PROFILE_PATH, SETTINGS_SECURITY_PATH,} from "./constants";
import {Profile} from "./pages/profile";
import {Language} from "./pages/language";
import {Security} from "./pages/security";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={SETTINGS_PROFILE_PATH} component={Profile}/>
            <Route exact path={SETTINGS_LANGUAGE_PATH} component={Language}/>
            <Route exact path={SETTINGS_SECURITY_PATH} component={Security}/>

            <Redirect to={SETTINGS_PROFILE_PATH}/>
        </Switch>
    )
}