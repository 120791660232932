import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {TPageInfo} from "../../../../newShared/types";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {defaultPageInfo} from "../../hr/constants";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {
    portalFinishTrainingAction,
    portalFinishTrainingSectionAction,
    portalGetAssignmentTrainingByIdAction,
    portalStartTrainingAction,
    portalTrainingsExamSaveAnswerAction,
    portalTrainingsExamSkipAnswerAction,
    portalTrainingsFinishExamAction,
    portalTrainingsGetAssignmentDetailsAction,
    portalTrainingsGetAssignmentsWithFilterPaginationAction,
    portalTrainingsGetExamByAssignmentIdAction,
    portalTrainingsStartExamAction,
    portalUpdateTrainingDurationAction
} from "./actions";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {
    MainTrainingAssignmentModel,
    PortalTrainingContentModel,
    PortalTrainingModel,
    PortalTrainingsAssignmentModel,
    PortalTrainingsAssignmentsExamModel,
    PortalTrainingsShortAssignmentModel,
    TrainingAssignmentExamType,
    TrainingAssignmentObjectStatus,
    TrainingContentStatus
} from "../../../../newShared/GQLTypes";
import {addDownloadFileActionCases} from "../../../../newShared/actions/downloadFile";
import {addSetAllDialogActions} from "../../../../newShared/actions/setDialogAction";

export type TTrainingsSlice = {
    assignments: {
        assignments: PortalTrainingsShortAssignmentModel[],
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType,
        selected: PortalTrainingsAssignmentModel | null,
        // filterOptions: PortalAssetsGetPhysicalAssetsSettingsForFiltersQuery["portalAssetsGetPhysicalAssetsSettingsForFilters"] | null,
    },

    loadings: {
        assignmentsList: boolean,
        getAssignmentDetails: boolean,
        getAssignmentTrainingById: boolean;
        startTrainingById: boolean;
        isDownloadingFile: string[];

        startingExam: boolean,
        gettingExam: boolean,
        savingAnswer: {id: string, type: 'SAVE' | 'SKIP'}[],
        completingExam: boolean,
        interruptTraining: boolean;
        finishTraining: boolean;
        finishTrainingSection: boolean;
    },
    dialogs: {
        startTrainingDialog: {
            isOpen: boolean;
            training: PortalTrainingModel | null,
        },
        previewFinishedTrainingDialog: {
            isOpen: boolean;
            training: PortalTrainingModel | null,
        },
        startExam: {
            isOpen: {type: TrainingAssignmentExamType, limit: number} | null,
        },
        finishExam: {
            isOpen: {total: number, skipped: number, answered: number, timeEnd: boolean, inputsCount: number} | null,
        },
        validationExam: {
            isOpen: {countInputs: number} | {passingPoints: number, yourPoints: number, status: TrainingAssignmentObjectStatus} | null,
        },
        passingExam: {
            isOpen: {type: TrainingAssignmentExamType, assignmentId: string} | null,
            exam: PortalTrainingsAssignmentsExamModel | null,
        },
        examResultDialog: {
            assignment: MainTrainingAssignmentModel | null,
        },
    },
}

const initialState: TTrainingsSlice = {
    assignments: {
        assignments: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selected: null,
    },

    loadings: {
        assignmentsList: false,
        getAssignmentDetails: false,
        getAssignmentTrainingById: false,
        startTrainingById: false,
        isDownloadingFile: [],

        startingExam: false,
        gettingExam: false,
        savingAnswer: [],
        completingExam: false,

        interruptTraining: false,
        finishTraining: false,
        finishTrainingSection: false,
    },

    dialogs: {
        startTrainingDialog: {
            isOpen: false,
            training: null
        },
        previewFinishedTrainingDialog: {
            isOpen: false,
            training: null
        },
        startExam: {
            isOpen: null,
        },
        finishExam: {
            isOpen: null,
        },
        validationExam: {
            isOpen: null,
        },
        passingExam: {
            isOpen: null,
            exam: null,
        },
        examResultDialog: {
            assignment: null
        }
    },
}

export const trainingsSlice = createSlice({
    name: 'trainings',
    initialState,
    reducers: {
        ...addSetAllDialogActions(initialState),

        replaceAssignmentsMinMaxLoadedPage: (slice, { payload }: { payload: minMaxLoadedPageType }) => {
            slice.assignments.minMaxLoadedPage = payload;
        },
        eraseAssignments: (slice) => {
            slice.assignments.assignments = [];
            slice.assignments.pageInfo = defaultPageInfo;
        },
        eraseSelectedAssignment: (slice) => {
            slice.assignments.selected = null;
        },

        openStartTrainingDialog: (slice) => {
            slice.dialogs.startTrainingDialog = {isOpen: true, training: null};
        },
        hideStartTrainingDialog: (slice) => {
            slice.dialogs.startTrainingDialog = {isOpen: false, training: null};
        }
    },
    extraReducers: builder => {
        addDownloadFileActionCases(builder);
        builder
            .addCase(portalTrainingsGetAssignmentsWithFilterPaginationAction.pending, (slice: TTrainingsSlice , {meta: {arg: {clean}}}) => {
                slice.loadings.assignmentsList = true;

                if (clean) {
                    slice.assignments.assignments = initialState.assignments.assignments;
                    slice.assignments.pageInfo = initialState.assignments.pageInfo;
                    slice.assignments.minMaxLoadedPage = initialState.assignments.minMaxLoadedPage;
                }
            })
            .addCase(portalTrainingsGetAssignmentsWithFilterPaginationAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.assignmentsList = false;
            })
            .addCase(portalTrainingsGetAssignmentsWithFilterPaginationAction.fulfilled, (slice: TTrainingsSlice, {payload}) => {
                slice.loadings.assignmentsList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<PortalTrainingsShortAssignmentModel>(
                    slice.assignments.assignments,
                    slice.assignments.pageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.assignments.minMaxLoadedPage.minLoadedPage,
                    slice.assignments.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.assignments.assignments = results;
                slice.assignments.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.assignments.pageInfo = payload.pageInfo;
            })
            // portalGetAssignmentTrainingByIdAction
            .addCase(portalGetAssignmentTrainingByIdAction.pending, (slice: TTrainingsSlice , {meta: {arg: {clean}}}) => {
                slice.loadings.getAssignmentTrainingById = true;
            })
            .addCase(portalGetAssignmentTrainingByIdAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.getAssignmentTrainingById = false;
            })
            .addCase(portalGetAssignmentTrainingByIdAction.fulfilled, (slice: TTrainingsSlice, {payload}) => {
                slice.loadings.getAssignmentTrainingById = false;
                slice.dialogs.startTrainingDialog.training = payload;
                slice.dialogs.previewFinishedTrainingDialog.training = payload;
            })
        //portalStartTrainingAction
            .addCase(portalStartTrainingAction.pending, (slice: TTrainingsSlice , {meta: {arg: {clean}}}) => {
                slice.loadings.startTrainingById = true;
            })
            .addCase(portalStartTrainingAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.startTrainingById = false;
            })
            .addCase(portalStartTrainingAction.fulfilled, (slice: TTrainingsSlice, {payload}) => {
                slice.loadings.startTrainingById = false;
            })

            .addCase(portalTrainingsStartExamAction.pending, (slice: TTrainingsSlice , {meta: {arg: {clean}}}) => {
                slice.loadings.startingExam = true;
            })
            .addCase(portalTrainingsStartExamAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.startingExam = false;
            })
            .addCase(portalTrainingsStartExamAction.fulfilled, (slice: TTrainingsSlice, {payload}) => {
                slice.loadings.startingExam = false;
                slice.dialogs.startExam = initialState.dialogs.startExam;
            })

            .addCase(portalTrainingsGetExamByAssignmentIdAction.pending, (slice: TTrainingsSlice , {meta: {arg: {clean}}}) => {
                slice.loadings.gettingExam = true;
            })
            .addCase(portalTrainingsGetExamByAssignmentIdAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.gettingExam = false;
            })
            .addCase(portalTrainingsGetExamByAssignmentIdAction.fulfilled, (slice: TTrainingsSlice, {payload}) => {
                slice.loadings.gettingExam = false;
                slice.dialogs.passingExam.exam = payload;
            })

            .addCase(portalTrainingsExamSaveAnswerAction.pending, (slice: TTrainingsSlice , {meta: {arg: {data}}}) => {
                slice.loadings.savingAnswer.push({id: data.questionId, type: 'SAVE'});
            })
            .addCase(portalTrainingsExamSaveAnswerAction.rejected, (slice: TTrainingsSlice, {meta: {arg: {data}}}) => {
                slice.loadings.savingAnswer = slice.loadings.savingAnswer.filter(e => e.id !== data.questionId);
            })
            .addCase(portalTrainingsExamSaveAnswerAction.fulfilled, (slice: TTrainingsSlice, {payload, meta: {arg: {data}}}) => {
                slice.loadings.savingAnswer = slice.loadings.savingAnswer.filter(e => e.id !== data.questionId);
            })

            .addCase(portalTrainingsExamSkipAnswerAction.pending, (slice: TTrainingsSlice , {meta: {arg: {data}}}) => {
                slice.loadings.savingAnswer.push({id: data.questionId, type: 'SKIP'});
            })
            .addCase(portalTrainingsExamSkipAnswerAction.rejected, (slice: TTrainingsSlice, {meta: {arg: {data}}}) => {
                slice.loadings.savingAnswer = slice.loadings.savingAnswer.filter(e => e.id !== data.questionId);
            })
            .addCase(portalTrainingsExamSkipAnswerAction.fulfilled, (slice: TTrainingsSlice, {payload, meta: {arg: {data}}}) => {
                slice.loadings.savingAnswer = slice.loadings.savingAnswer.filter(e => e.id !== data.questionId);
            })

            .addCase(portalTrainingsFinishExamAction.pending, (slice: TTrainingsSlice ) => {
                slice.loadings.completingExam = true;
            })
            .addCase(portalTrainingsFinishExamAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.completingExam = false;
            })
            .addCase(portalTrainingsFinishExamAction.fulfilled, (slice: TTrainingsSlice, {payload}) => {
                slice.loadings.completingExam = false;
            })
        //portalUpdateTrainingDurationAction
            .addCase(portalUpdateTrainingDurationAction.pending, (slice: TTrainingsSlice , {meta: {arg: {clean}}}) => {
                slice.loadings.interruptTraining = true;
            })
            .addCase(portalUpdateTrainingDurationAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.interruptTraining = false;
            })
            .addCase(portalUpdateTrainingDurationAction.fulfilled, (slice: TTrainingsSlice, {payload}) => {
                slice.loadings.interruptTraining = false;
            })
        //portalFinishTrainingSectionAction
            .addCase(portalFinishTrainingSectionAction.pending, (slice: TTrainingsSlice , {meta: {arg: {clean}}}) => {
                slice.loadings.finishTrainingSection = true;
            })
            .addCase(portalFinishTrainingSectionAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.finishTrainingSection = false;
            })
            .addCase(portalFinishTrainingSectionAction.fulfilled, (slice: TTrainingsSlice, {payload, meta}) => {
                slice.loadings.finishTrainingSection = false;
                if(slice.dialogs.startTrainingDialog.training){
                    //replace last section status to Done + next  section to In progress
                    slice.dialogs.startTrainingDialog.training = {
                        ...slice.dialogs.startTrainingDialog.training,
                        duration: payload.duration,
                        content: slice.dialogs.startTrainingDialog.training?.content.map((e: PortalTrainingContentModel) =>
                            e.sectionId === meta.arg.data.sectionId ? ({...e, status: TrainingContentStatus.Done}) :
                                e.sectionId === payload.nextSection ? ({...e, status: TrainingContentStatus.InProgress}) : ({...e})
                        )
                    }
                }
            })
        //portalFinishTrainingAction
            .addCase(portalFinishTrainingAction.pending, (slice: TTrainingsSlice , {meta: {arg: {clean}}}) => {
                slice.loadings.finishTraining = true;
            })
            .addCase(portalFinishTrainingAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.finishTraining = false;
            })
            .addCase(portalFinishTrainingAction.fulfilled, (slice: TTrainingsSlice, {payload}) => {
                slice.loadings.finishTraining = false;
            })

        //portalTrainingsGetAssignmentDetailsAction
            .addCase(portalTrainingsGetAssignmentDetailsAction.pending, (slice: TTrainingsSlice , {meta: {arg: {clean}}}) => {
                slice.loadings.getAssignmentDetails = true;
            })
            .addCase(portalTrainingsGetAssignmentDetailsAction.rejected, (slice: TTrainingsSlice) => {
                slice.loadings.getAssignmentDetails = false;
            })
            .addCase(portalTrainingsGetAssignmentDetailsAction.fulfilled, (slice: TTrainingsSlice, {payload}) => {
                slice.loadings.getAssignmentDetails = false;
                slice.assignments.selected = payload;
            })
    }
});

export const TrainingsReducer = trainingsSlice.reducer;

export const {
    replaceAssignmentsMinMaxLoadedPage,
    eraseAssignments,

    openStartTrainingDialog,
    hideStartTrainingDialog,
    eraseSelectedAssignment,
    setExamResultDialogAction,
    setPreviewFinishedTrainingDialogAction
} = trainingsSlice.actions;

const selectSelf = (state: AppState): TTrainingsSlice => state.trainings;

export const loadingsSelector = createSelector(selectSelf, state => state.loadings);
export const dialogsSelector = createSelector(selectSelf, state => state.dialogs);

export const assignmentsSelector = createSelector(selectSelf, state => state.assignments);

