import {Button, Checkbox, FormGroup, Radio, Typography} from "@mui/material";
import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {
    ACCOUNT_DELETE_STATUS,
    ACCOUNT_KEEP_STATUS,
    ACCOUNT_REVOKE_STATUS,
    IN_PROGRESS_STATUS
} from "../../../constants";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import FormControlLabel from "@mui/material/FormControlLabel";
import {CustomTableHeader} from "../customTableHeader";
import {useCampaignReviewVendorsStep} from "../../../hooks/useCampaignReviewVendorsStep";
import {getColorAndTextByAccountStatus} from "../../../helpers";
import {CustomTableRow} from "../customTableRow";
import {TableRowItemWrapper} from "../../styled";
import RadioGroup from "@mui/material/RadioGroup";

export const CampaignReviewVendors = () => {
    const stageVendors = useCampaignReviewVendorsStep();
    const {revDir} = useMainTranslation();
    return(
        <Flex w={'100%'} direction={'column'}>
            {stageVendors.isTerminated ?
                <>
                    <Flex m={'16px 0 0 0'}>
                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>Stage vendors was terminated on </Typography>
                        <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{parseDateAuto(stageVendors.stage?.statusChangedDate)}</Typography>
                    </Flex>
                </>
                :
                <>
                    {stageVendors.stage && stageVendors.stage.status === IN_PROGRESS_STATUS &&
                        <Flex w={'100%'} ai={'center'} m={'8px 0'}>
                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>Deadline for Vendor owners</Typography>
                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{parseDateAuto(stageVendors.stage?.expirationDate)}</Typography>
                            {stageVendors.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange}>{'The deadline has arrived'}</Typography>}
                        </Flex>
                    }

                    {stageVendors.stage && stageVendors.stage.actors.map((actor, id) => {
                        const isEditable: boolean = actor.status === IN_PROGRESS_STATUS && !actor.mainControl;
                        const isStageCanBeVerified = !((actor.appsData ?? []).flatMap(e => e.data).some(e => e.statusVendor === null)) && isEditable;
                        const columnWidths = (isEditable ?
                            [{name: 'Employee', width: '18%'}, {name: 'Account', width: '18%'}, {name: 'Permissions', width: '19%'}, {name: 'Status', width: '45%'}]
                            :
                            [{name: 'Employee', width: '25%'}, {name: 'Account', width: '25%'}, {name: 'Permissions', width: '35%'}, {name: 'Status', width: '15%'}]);

                        return(
                            <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                {actor.appsData && actor.appsData.map(app => {
                                    return(
                                        <Flex direction={'column'} m={'16px 0 0 0'}>
                                            <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={'16px 2px'}>
                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0'}} fontWeight={600}>{'Vendor'}</Typography>
                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 32px 0'}}>{app.vendorName}</Typography>

                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0'}} fontWeight={600}>{'Owner'}</Typography>
                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 32px 0'}}>{actor.firstName} {actor.lastName}</Typography>

                                                {isStageCanBeVerified &&
                                                    <LoadingButton
                                                        variant={'contained'}
                                                        size={'small'}
                                                        onClick={() => stageVendors.handleVerifyActorChanges(actor.email)}
                                                        loading={stageVendors.commonSelectors.isLoadingVerify} //is loading verify changes for current user
                                                    >{('Verify changes')}
                                                    </LoadingButton>
                                                }
                                            </Flex>

                                            {isEditable &&
                                                <Flex ai={'center'} m={'16px 0'}>
                                                    <FormGroup
                                                        sx={{margin: !revDir ? '0 16px' : '0 8px 0 16px'}}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    size={'small'}
                                                                    checked={stageVendors.isSelectedAllVendorsAccounts(actor.email)}
                                                                    onChange={(e) => e.target.checked ? stageVendors.handleSelectAllVendorAccount(actor.email) : stageVendors.handleDeselectAllVendorAccounts()}
                                                                />}
                                                            label="Select all"
                                                        />
                                                    </FormGroup>

                                                    <Button
                                                        variant={'outlined'}
                                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 16px 0 0'}}
                                                        size={'small'}
                                                        onClick={stageVendors.handleKeepAllSelectedManagerAccounts}
                                                        disabled={!stageVendors.selectedVendorsAccounts.length || stageVendors.commonSelectors.loadingIds.length > 0}
                                                    >
                                                        {`Keep all ${stageVendors.selectedVendorsAccounts.length ?? ''}`}
                                                    </Button>

                                                    <Button
                                                        variant={'outlined'}
                                                        sx={{margin: !revDir ? '0 16px 0 0' : '0 16px 0 0'}}
                                                        size={'small'}
                                                        onClick={stageVendors.handleRevokeAllSelectedManagerAccounts}
                                                        disabled={!stageVendors.selectedVendorsAccounts.length || stageVendors.commonSelectors.loadingIds.length > 0}
                                                    >
                                                        {`Revoke all ${stageVendors.selectedVendorsAccounts.length ?? ''}`}
                                                    </Button>

                                                    <Button
                                                        variant={'outlined'}
                                                        size={'small'}
                                                        onClick={stageVendors.handleDeleteAllSelectedManagerAccounts}
                                                        disabled={!stageVendors.selectedVendorsAccounts.length || stageVendors.commonSelectors.loadingIds.length > 0}
                                                    >
                                                        {`Delete all ${stageVendors.selectedVendorsAccounts.length ?? ''}`}
                                                    </Button>
                                                </Flex>
                                            }

                                            <CustomTableHeader
                                                columns={columnWidths}
                                                hideIf={false}
                                                padding={'8px 0 12px 0 '}
                                                width={'100%'}
                                            />

                                            {app.data.map(data => {
                                                const status = getColorAndTextByAccountStatus(data.statusVendor);
                                                const isLoading = stageVendors.commonSelectors.loadingIds.some(e => e === data.id);
                                                return(
                                                    <CustomTableRow divider disableHover innerWrapperWidth={'100%'}>
                                                        <TableRowItemWrapper width={columnWidths[0]?.width ?? '25%'}>
                                                            {isEditable &&
                                                                <Checkbox
                                                                    disabled={(stageVendors.selectingVendorsAccountsOfActorEmail !== null && stageVendors.selectingVendorsAccountsOfActorEmail !== actor.email)}
                                                                    size={'small'}
                                                                    checked={stageVendors.isSelectedVendorAccount(data.id)}
                                                                    onChange={(e) => e.target.checked ? stageVendors.handleSelectVendorAccount(data.id, actor.email) : stageVendors.handleDeselectVendorAccount(data.id, actor.email)}
                                                                    sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}
                                                                />
                                                            }
                                                            <Typography sx={{color: colors.darkText}} variant={'body2'}>{data.employee ?? '- - -'}</Typography>
                                                        </TableRowItemWrapper>

                                                        <TableRowItemWrapper width={columnWidths[1]?.width ?? '25%'}>
                                                            <Typography sx={{color: colors.darkText}} variant={'body2'}>{data.account}</Typography>
                                                        </TableRowItemWrapper>

                                                        <TableRowItemWrapper width={columnWidths[2]?.width ?? '25%'}>
                                                            <Flex w={'90%'} direction={'column'}>
                                                                {data.roles.map(e => {
                                                                    const moreThen0Roles = e.rolePermissions.length > 0;
                                                                    return(
                                                                        <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                            <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={'body2'}>{e.roleName}</Typography>
                                                                            <Typography
                                                                                sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                variant={'body2'}
                                                                                onClick={() => stageVendors.onRoleClick(e.roleName, e.rolePermissions)}
                                                                            >{e.rolePermissions.length} permissions</Typography>
                                                                        </Flex>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        </TableRowItemWrapper>

                                                        <TableRowItemWrapper width={columnWidths[3]?.width ?? '25%'}>
                                                            {isEditable ?
                                                                <Flex w={'100%'}>
                                                                    <RadioGroup
                                                                        row
                                                                        value={data.statusVendor ?? ''}
                                                                        sx={{flexWrap: 'nowrap'}}
                                                                        onChange={(e, value) => {
                                                                            stageVendors.stage && stageVendors.handleChangeSingleStatus(
                                                                                {
                                                                                    applicationAccessId: app.applicationAccessId,
                                                                                    applicationAccessName: app.applicationAccessName,
                                                                                    applicationId: app.applicationId,
                                                                                    vendorId: app.vendorId,
                                                                                    vendorName: app.vendorName,
                                                                                },
                                                                                data, stageVendors.stage.id, actor.email, value);
                                                                        }}
                                                                    >
                                                                        <FormControlLabel disabled={isLoading} value={ACCOUNT_KEEP_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{'Keep'}</Typography>} />
                                                                        <FormControlLabel disabled={isLoading} value={ACCOUNT_REVOKE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{'Revoke'}</Typography>} />
                                                                        <FormControlLabel disabled={isLoading} value={ACCOUNT_DELETE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{'Delete account'}</Typography>} />
                                                                    </RadioGroup>

                                                                    <Button
                                                                        variant={'text'}
                                                                        size={'small'}
                                                                        disabled={data.statusVendor === null}
                                                                        onClick={() => {
                                                                            stageVendors.editJustification(
                                                                                {
                                                                                    applicationAccessId: app.applicationAccessId,
                                                                                    applicationAccessName: app.applicationAccessName,
                                                                                    applicationId: app.applicationId,
                                                                                    vendorId: app.vendorId,
                                                                                    vendorName: app.vendorName,
                                                                                }, data, actor.email)
                                                                        }}
                                                                    >
                                                                        {(data.commentVendor ?? '').length > 0 ? 'Edit justification' : 'Add justification'}
                                                                    </Button>
                                                                </Flex>
                                                                :
                                                                <Flex w={'100%'} direction={'column'}>
                                                                    <Typography color={status.color} variant={'body2'}>{status.text}</Typography>
                                                                    {(data.commentVendor ?? '').length > 0 && <Typography
                                                                        color={colors.primary.blue}
                                                                        variant={'body2'}
                                                                        sx={{margin: '8px 0 0 0', cursor: 'pointer'}}
                                                                        onClick={() => stageVendors.viewJustification(actor.email, data.commentVendor)}
                                                                    >{'View justification'}</Typography>
                                                                    }
                                                                </Flex>
                                                            }
                                                        </TableRowItemWrapper>
                                                    </CustomTableRow>
                                                )
                                            })}
                                        </Flex>
                                    )
                                })}
                            </Flex>
                        )

                    })
                    }
                </>
            }
        </Flex>
    )
}