import React, {FC, useEffect} from "react";
import {ReactFlowProvider,} from 'reactflow';
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import {Box} from "@mui/material";
import 'reactflow/dist/style.css';
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useEmployeesTree} from "../../hooks/useEmployeesTree";
import {EmployeesBarUnderOrgTree} from "../../components/orgChart/employeesBarUnderOrgTree";
import {EmployeesTreeComponent} from "../../components/orgChart/employeesTreeComponent";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {HR_ORG_CHART_PATH, HR_PATH} from "../../../../../newShared/constants";


export const EmployeesTree: FC = () => {
    const {tMenu} = useMainTranslation('', {keyPrefix: ''});
    const {getTree, setTree, isLoading: {isLoadingTree}, fullView} = useEmployeesTree();

    //Controller and first loadData
    const controller = new AbortController();
    useEffect(() => {
        !isLoadingTree && getTree(controller.signal);
        return () => {
            setTree({clear: true})
            controller.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useSetBreadcrumbs([
        {
            title: tMenu('My HR'),
            path: HR_PATH
        },
        {
            title: tMenu('Organization tree'),
            path: HR_ORG_CHART_PATH
        }
    ]);

    return (
        <ReactFlowProvider>
            <Box display={"flex"} flexDirection={"column"} width={'100%'} height={'100%'} overflow={"hidden"}>
                <PageTitleAndActions
                    title={tMenu('Organization tree')}
                    actions={[]}
                >
                    <EmployeesBarUnderOrgTree/>
                </PageTitleAndActions>

                <Box width={fullView ? '100vw' : '100%'} height={fullView ? '100vh' : '100%'}
                     padding={fullView ? '0 12px' : '0'}
                     bgcolor={'white'}
                     sx={{
                         position: fullView ? 'absolute' : 'relative',
                         left: '0',
                         top: '0',
                         zIndex: fullView ? 3000 : undefined
                     }}>

                    {isLoadingTree && (
                        <Box width={'100%'} height={'100%'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <LocalSpinner/>
                        </Box>
                    )}

                    <EmployeesTreeComponent readonly/>
                </Box>

            </Box>
        </ReactFlowProvider>
    )
}