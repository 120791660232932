import {gql} from "@apollo/react-hooks";
import {portalRequiredActionModelFragment} from "./fragments";
import {pageInfoFragment} from "../../../../newShared/graphQl/fragments";


export const portalGetRequiredActionsWithFilterPagination = gql`
    query portalGetRequiredActionsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: PortalRequiredActionsFilter!) {
        portalGetRequiredActionsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...portalRequiredActionModelFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${portalRequiredActionModelFragment}
`;

export const portalRequiredActionsGetTaskById = gql`
    query portalRequiredActionsGetTaskById($workspaceId: String!, $taskId: String!) {
        portalRequiredActionsGetTaskById(workspaceId: $workspaceId, taskId: $taskId) {
            ...portalRequiredActionModelFragment
        }
    }
    ${portalRequiredActionModelFragment}
`;

export const portalRequiredActionsResolverAutocomplete = gql`
    query portalRequiredActionsResolverAutocomplete($workspaceId:String!, $pageRequest:SharedPaginationInput!, $search: String!){
        portalRequiredActionsResolverAutocomplete(workspaceId:$workspaceId, pageRequest:$pageRequest, search: $search){
            result {
                collaboratorId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`

export const portalRequiredActionsRequestorAutocomplete = gql`
    query portalRequiredActionsRequestorAutocomplete($workspaceId:String!, $pageRequest:SharedPaginationInput!, $search: String!){
        portalRequiredActionsRequestorAutocomplete(workspaceId:$workspaceId, pageRequest:$pageRequest, search: $search){
            result {
                collaboratorId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`

export const portalRequiredActionsPerformActionProvideFiles = gql`
    mutation portalRequiredActionsPerformActionProvideFiles($workspaceId:String!, $data: PerformActionProvideFilesInputDto!){
        portalRequiredActionsPerformActionProvideFiles(workspaceId:$workspaceId, data: $data){
            message
        }
    }
`
