import {useDispatch, useSelector} from "react-redux";
import {clearList, deselectDoc, documents, loadings, pageInfo, selectedDocument} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DEFAULT_PAGING, DOCUMENTS_ROOT, MY_DOCUMENTS_PATH} from "../../constants";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {DocActorStatusVariants, DRAFT_STATUS, SHARED_DRAFT_STATUS, TDocumentStatus, TShortDocument} from "../../types";
import {
    ApproveApproveStageByStageId,
    ApproveSignStageByStageId,
    FillFillableFieldOnSignStage,
    GetFullDocumentWithExecutorForDocs,
    GetShortDocumentsPagingDocs,
    RejectApproveStageByStageId,
    RejectSignStageByStageId,
    UpdateFullDocument
} from "../../store/actions";
import {
    TEditorActorsConfigFromDB,
    TEditorElementData,
    TUpdateDataInFillableFieldEventData
} from "../../../../../newShared/components/editorUnion/types";
import {NewDocDataVariableModel} from "../../../../../newShared/GQLTypes";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";
import {createEventUpdateDataInFillableField} from "../../../../../newShared/components/editorUnion/helpers";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getAutocompleteSender} from "../../api";
import {
    useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

export const useMyDocs = () => {
    //root
    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const snack = useSnack();
    const {workspaceId: _workspaceId} = UseManageWorkspacesAndOrganizations();
    const [workspaceId, setWorkspaceId] = useState(_workspaceId);

    //selectors
    const documentsList = useSelector(documents);
    const _pageInfo = useSelector(pageInfo);
    const _loadings = useSelector(loadings);
    const _selectedDoc = useSelector(selectedDocument);
    const {id} = useParams<{id: string}>();
    const {currentSearchForFetch, currentFiltersForFetch, clearFilters} = useGenericFiltersStorage();

    //effects
    useSetBreadcrumbs([
        {
            title: tMenu('Documents'),
            path: DOCUMENTS_ROOT
        },
        {
            title: tMenu('My documents'),
            path: MY_DOCUMENTS_PATH
        },
    ]);

    useEffect(() => {
        if (id) {
            dispatch(GetFullDocumentWithExecutorForDocs(id));
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    //clear list
    const handleCleanData = () => {
        dispatch(clearList());
        clearFilters();
    }

    //table
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            dispatch(GetShortDocumentsPagingDocs({page, count: DEFAULT_PAGING, filters: {...currentFiltersForFetch, search: [currentSearchForFetch]}}));
        },
        arrayLength: documentsList.length,
        isLoading: _loadings.isLoadingList,
        pageInfo: _pageInfo,
        cleanData: handleCleanData,
        // controller: controller,
        // isOnTop: true,
        // isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: false,
        // disableClearOnUnmount: true
    })

    useEffect(() => {
        if (workspaceId !== _workspaceId) {
            handleCleanData();
            setWorkspaceId(_workspaceId)
            dispatch(GetShortDocumentsPagingDocs({
                page: 0,
                count: DEFAULT_PAGING,
                filters: {search: [currentSearchForFetch]}
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_workspaceId]);

    //actions
    const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, file: TShortDocument) => {
        if(e.detail === 2 && file){
            //doubleClick
            dispatch(GetFullDocumentWithExecutorForDocs(file.documentId ?? ''))
        }
    }

    const openRecipientsDialog = (recipients: TShortDocument["recipients"]) => {

    }


    //FILTERS
    //search,
    // sender,
    // workspaceName,
    // expired, [ok]
    // status,  [ok]
    // lastModified [ok]
    const statusConfig: keyConfig<{status: TDocumentStatus, title: string}> = {
        type: 'filter',
        key: 'status',
        name: ('Status'),
        options: DocActorStatusVariants.filter(e => e.status !== DRAFT_STATUS && e.status !== SHARED_DRAFT_STATUS),
        isOptionEqualToSearch: (option, search) => option.status.trim().toLowerCase().includes(search.trim().toLowerCase()),
        getFilterValue: (a) => a.status,
        getOptionLabel: (e) => e.title,
        default: true
    }


    const lastModified: keyConfig<string> = {
        type: 'date',
        key: 'lastModified',
        name: ('Last modified'),
        default: true,
    }

    const expired: keyConfig<{id: string, title: string}> = {
        type: 'filter',
        key: 'expired',
        name: ('Expired'),
        options: [
            {id: 'true', title: ('Expired only')},
        ],
        isOptionEqualToSearch: (option, search) => option.title.trim().toLowerCase().includes(search.trim().toLowerCase()),
        isOptionEqual: (a, b) => a.id === b.id,
        getFilterValue: (a) => a.id,
        getOptionLabel: (e) => e.title,
        default: true,
    }

    const senderConfig: keyConfig<string> = {
        type: 'filter',
        key: 'senderEmail',
        name: ('Sender email'),
        fetchOptions: (workspaceId, data) => getAutocompleteSender(workspaceId ?? '', {page: data.page, count: data.count, search: data.search}),
        // fetchOptions: (data, signal) => getAutocompleteSender({...data, workspaceId: workspaceId || ''}, signal),
        default: true
    }


    const docId = _selectedDoc?.document?.documentId
    return{
        docs: documentsList,
        isLoading: _loadings.isLoadingList,
        bottomLoader,
        onScroll,
        scrollRef,
        pageInfo: _pageInfo,
        hasMore: _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count),
        total: !documentsList.length ? 'loading' : _pageInfo.total,
        handleRowClick,
        openRecipientsDialog,
        isNoDocs: _loadings.isLoadingList ? false : !_pageInfo.total,
        selectedDocument: _selectedDoc,
        loadings: _loadings,
        currentEmail: UseManageWorkspacesAndOrganizations().user?.email || '',
        actions: {
            handleCloseEditor: () => {
                dispatch(deselectDoc())
            },
            onRejectSignStage: (stageId: string) => {
                docId && dispatch(RejectSignStageByStageId({documentId: docId, stageId}))
            },
            onRejectApproveStage: (stageId: string) => {
                docId && dispatch(RejectApproveStageByStageId({documentId: docId, stageId}))
            },
            onApproveApproveStage: (stageId: string) => {
                docId && dispatch(ApproveApproveStageByStageId({documentId: docId, stageId}))
            },
            onApproveSignStage: (stageId: string) => {
                docId && dispatch(ApproveSignStageByStageId({documentId: docId, stageId}))
            },
            onFillFields: (stepId: string, data: TUpdateDataInFillableFieldEventData) => {
                const onReject = () => {
                    createEventUpdateDataInFillableField(data.map(e => ({...e, value: ''})));
                }
                docId && dispatch(FillFillableFieldOnSignStage({stageId: stepId, values: data, onReject, documentId: docId}))
            },
            handleSave: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                // console.log("onSave: \n", {data, variables, docTitle, actors})
                if(_selectedDoc.document) {
                    dispatch(UpdateFullDocument({
                        documentId: _selectedDoc.document.documentId ?? '',
                        name: docTitle,
                        data: {
                            editor: data,
                            variables,
                            pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                        },
                        recipients: actors.recipients,
                        approvers: actors.approvers,
                        editors: actors.editors
                    }))
                }
            }
        },
        host: window.location.host,
        snack,
        filters: {
            config: [senderConfig, lastModified, statusConfig, expired],
            handleFetch: (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
                dispatch(clearList());
                dispatch(GetShortDocumentsPagingDocs({page: 0, count: DEFAULT_PAGING, filters: {...filters, search: [search]}}));
            }
        }
    }
}
