import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {TMenuTitle} from "../constants";
import {portalHrGetProfileAction} from "../../../components/hr/store/actions";
import {myHrTitle} from "../titles";
import {addCheckMenuItemsForAction} from "../helpers";

export type TLeftMenuSlice = {
    isOpen: boolean,
    isOpenCustomMenu: boolean,

    pinnedItems: string[] | null,

    isChecking: TMenuTitle[],
    checkedItems: TMenuTitle[],

    secondMenuWidth: number,
}

const initialState: TLeftMenuSlice = {
    isOpen: false,
    isOpenCustomMenu: false,

    pinnedItems: null,

    isChecking: [],
    checkedItems: [],

    secondMenuWidth: 0,
}

const Slice = createSlice({
    name: 'leftMenu',
    initialState,
    reducers: {
        setOpenMenuAction(slice: TLeftMenuSlice, {payload}: {payload: TLeftMenuSlice["isOpen"]}) {
            slice.isOpen = payload
        },
        setOpenCustomMenuAction(slice: TLeftMenuSlice, {payload}: {payload: TLeftMenuSlice["isOpenCustomMenu"]}) {
            slice.isOpenCustomMenu = payload
        },
        setPinnedMenuItemsAction(slice: TLeftMenuSlice, {payload}: {payload: TLeftMenuSlice["pinnedItems"]}) {
            slice.pinnedItems = payload
        },

        setSecondMenuWidthAction(slice: TLeftMenuSlice, {payload}: {payload: TLeftMenuSlice["secondMenuWidth"]}) {
            slice.secondMenuWidth = payload
        },
    },
    extraReducers: builder => {
        addCheckMenuItemsForAction(builder, myHrTitle, portalHrGetProfileAction);
    }
});

export const leftMenuReducerReducer = Slice.reducer;

export const {
    setOpenMenuAction,
    setOpenCustomMenuAction,
    setPinnedMenuItemsAction,
    setSecondMenuWidthAction,
} = Slice.actions;

const selectSelf = (state: AppState): TLeftMenuSlice => state.leftMenu;

export const leftMenuIsOpenSelector = createSelector(selectSelf, state => state.isOpen);
export const leftMenuIsOpenCustomSelector = createSelector(selectSelf, state => state.isOpenCustomMenu);
export const leftMenuPinnedItemsSelector = createSelector(selectSelf, state => state.pinnedItems);
export const leftSecondMenuWidthSelector = createSelector(selectSelf, state => state.secondMenuWidth);

export const leftSecondCheckedItemsSelector = createSelector(selectSelf, state => ({checkedItems: state.checkedItems, isChecking: state.isChecking}));
