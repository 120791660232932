import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    styled,
    Table,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useIncomingTimeOffRequestExactView} from "../../../hooks/dialogs/useIncomingTimeOffRequestExactView";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {HrTimeOffStatus} from "../../../../../../newShared/GQLTypes";
import React from "react";
import {MicroTableWithoutBorder} from "../../../../../../newShared/components/microTableWithoutBorder";
import {getTimeOffStatusChip} from "../../../helpers";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import colors from "../../../../../../newShared/theme/colors";
import {ReactComponent as FolderIcon} from "../../../../../../../src/newShared/images/other/Folder.svg";
import {useHrTimeOffHistory} from "../../../hooks/dialogs/useTimeOffHistory";
import {FileDownloadOutlined} from "@mui/icons-material";
import {useRequestError} from "../../../hooks/dialogs/useRequestError";

const NoPaddingTableCell = styled(TableCell)({
    paddingBlock: '4px !important',
})

export const IncomingTimeOffRequestExactView = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathTimeOff'})
    const {isOpen,
        handleClose,
        isLoadingRequest,
        isLoadingApproveRequest,
        isLoadingRejectRequest,
        request,
        handleRejectRequest,
        handleDownloadFile,
        handleApproveRequest,
        formik,
        timeoffId,
        downloadingFile
    } = useIncomingTimeOffRequestExactView();
    const {handleOpenTimeOffHistory, isOpen: isOpenHistory} = useHrTimeOffHistory()
    const {isOpen: isOpenError} = useRequestError()

    return (
        <Dialog open={isOpen && !isOpenHistory && !isOpenError} onClose={handleClose}>
            <DialogTitle>{t('Time off request {{id}}', {id: request?.id})}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '26px',}}>
                <MicroTableWithoutBorder
                    isLoading={isLoadingRequest}
                    rows={[
                        {
                            label: `${t('Status')}: `,
                            text: request ? getTimeOffStatusChip(request?.status) : '',
                        },
                        {
                            label: `${t('Requestor')}: `,
                            text: request ? request?.requestor?.collaboratorFullName : '-',
                        },
                        {
                            label: `${t('Time off type')}: `,
                            text: request ? request.type : '-',
                        },
                        {
                            label: `${t('Start date')}: `,
                            text: request?.startDate ? parseDateAuto(request?.startDate) : '-',
                        },
                        {
                            label: `${t('End date')}: `,
                            text: request?.endDate ? parseDateAuto(request?.endDate) : '-',
                        },
                        {
                            label: `${t('Comment')}: `,
                            text: request?.comment ? (request?.comment) : '-',
                        },
                        {
                            label: `${t('Attachments')}: `,
                            noVerticalAlign: true,
                            text: !!request?.attachments?.length ? (
                                    <Table sx={{mt: '-10px'}}>
                                        {request?.attachments.map((item, index) => {
                                            const isLoading = downloadingFile.includes(item.fileId);
                                            return (
                                                <TableRow sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} key={item.fileId}>
                                                    <NoPaddingTableCell sx={{display: 'flex', justifyContent: 'flex-start', gap: '10px'}}>
                                                        <FolderIcon/>
                                                        <Typography key={index}>{item.fileName}</Typography>
                                                    </NoPaddingTableCell>
                                                    <NoPaddingTableCell>
                                                        <IconButton size={"small"} sx={{color: colors.primary.blue}}
                                                                    disabled={isLoading}
                                                                    onClick={() => {handleDownloadFile(item.fileId, item.fileName)}}>
                                                            {isLoading ? <CircularProgress size={24}/> : <FileDownloadOutlined/>}
                                                        </IconButton>
                                                    </NoPaddingTableCell>
                                                </TableRow>)
                                        })}
                                    </Table>
                            ) : '-',
                        }

                    ]}

                />
                {request?.status === HrTimeOffStatus.Approval &&
                    <>
                        <Divider sx={{borderColor: colors.primary.disable}} />
                        <Typography variant={'*bodyText2'}>{t('Please leave comment for requestor (to reject request comment is required)')}</Typography>
                        <TextField
                            multiline
                            rows={4}
                            label={t('Comment')}
                            onChange={formik.handleChange}
                            value={formik.values.comment}
                            onBlur={formik.handleBlur}
                            name={'comment'}
                            error={Boolean(formik.errors.comment)}
                            helperText={formik.errors.comment}
                            sx={{margin: '-10px'}}
                        />
                    </>
                }
                {request?.status !== HrTimeOffStatus.Cancelled && (
                    <Button startIcon={ <GradingOutlinedIcon sx={{ color: colors.primary.blue }} />} sx={{width: 'fit-content'}} onClick={() => request && handleOpenTimeOffHistory(request)} variant={"text"} >
                        {t("View approval history")}
                    </Button>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} size={'small'} variant={'text'}>{(tCommon('Close'))}</Button>
                {request?.status === HrTimeOffStatus.Approval &&
                    <>
                        <LoadingButton disabled={!formik.isValid || !formik.values.comment.trim().length} variant={"outlined"} onClick={() => {timeoffId && handleRejectRequest(timeoffId)}} loading={isLoadingRejectRequest} size={'small'}>
                            {t('Reject')}
                        </LoadingButton>
                        <LoadingButton disabled={!formik.isValid} variant={"contained"} onClick={() => {timeoffId && handleApproveRequest(timeoffId)}} loading={isLoadingApproveRequest} size={'small'}>
                            {t('Approve')}
                        </LoadingButton>
                    </>
                }

            </DialogActions>
        </Dialog>
    )
}
