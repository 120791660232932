import React, {FC} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";


export const Trainings: FC = () => {
    //Routes
    return (
        <LeftSecondMenu>
            <Routes />

            {/*<LeftSecondMenu>*/}
            {/*    <Flex w={'100%'}>*/}
            {/*        <EmptyArrayImage*/}
            {/*            type={'builderDev'}*/}
            {/*            isShowing={true}*/}
            {/*            text={'Training in development'}*/}
            {/*            withoutAdd*/}
            {/*        />*/}
            {/*    </Flex>*/}
            {/*</LeftSecondMenu>*/}
        </LeftSecondMenu>
    )
}
