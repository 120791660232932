import {useDispatch, useSelector} from "react-redux";
import {clearSelectedMyTimeOffRequestAction, hrIsLoadingSelector, hrMyTimeOffSelector} from "../../../store/slice";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useEffect} from "react";
import {portalHrGetTimeOffRequestByIdAction} from "../../../store/actions";
import {HR_TIME_OFF_PATH} from "../../../../../../newShared/constants";
import {useCreateUpdateTimeOffDialog} from "../useCreateUpdateTimeOffDialog";
import {DownloadFile} from "../../../../workerSpace/store/actions";
//import {HrTimeOffStatus} from "../../../../../../newShared/GQLTypes";

export const useTimeOffRequestExactView = () => {
    const {selected: request} = useSelector(hrMyTimeOffSelector);
    const dispatch = useDispatch();
    const {timeoffId} = useParams<{timeoffId?: string}>();

    const {
        myTimeOffRequest: isLoadingRequest,
        cancelMyTimeOffRequest: isLoadingCancelRequest,
        editMyTimeOffRequest: isLoadingEditRequest,
        downloadingFile
    } = useSelector(hrIsLoadingSelector);

    const isOpen = !!timeoffId;

    const {handleOpen: handleOpenUpdate} = useCreateUpdateTimeOffDialog();

    const history = useHistory();
    const location = useLocation();
    const handleClose = () => {
        history.push(HR_TIME_OFF_PATH + location.search)
    }

    const handleDownloadFile = (fileId: string, fileName: string) => {
        dispatch(DownloadFile({fileId, fileName}))
    }


    const getTimeOffRequestById = (timeoffId: string) => {
            dispatch(portalHrGetTimeOffRequestByIdAction({
                data: {requestId: timeoffId},
                onError: (request, error, addictiveData) => {
                    // const errors409 = getErrors409<string>(error);
                    // if (errors409.length) {
                    //     handleClose()
                    // }
                    handleClose()
                }
            }))
    }

    const cleanSelected = () => {
        dispatch(clearSelectedMyTimeOffRequestAction())
    };

    useEffect(() => {
        if (timeoffId) {
            getTimeOffRequestById(timeoffId);
        } else {
            cleanSelected();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeoffId])

    return{
        isOpen,
        timeoffId,
        handleClose,
        isLoadingCancelRequest,
        isLoadingEditRequest,
        isLoadingRequest,
        request,
        handleOpenUpdate,
        handleDownloadFile,
        downloadingFile
    }
}
