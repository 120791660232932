// export {}

import {TrainingAssignmentObjectStatus, TrainingAssignmentStatus} from "../../../newShared/GQLTypes";
import {StatusBox, StatusTitle} from "../hr/components/styled";
import colors from "../../../newShared/theme/colors";

export const getTrainingAssignmentStatusChip = (status: TrainingAssignmentStatus, isFilter?: boolean) => {
    switch (status) {
        case TrainingAssignmentStatus.Scheduled: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.blue_1}><StatusTitle color={colors.textStatus.neutral}>{'Scheduled'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Completed: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.green_1}><StatusTitle color={colors.textStatus.positive}>{'Completed'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Passed: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.green_2}><StatusTitle color={colors.textStatus.positive}>{'Passed'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Failed: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.decorative.background_2}><StatusTitle color={colors.textStatus.negative}>{'Failed'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Assigned: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.violet_1}><StatusTitle color={colors.textStatus.neutral}>{'Assigned'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.InProgress: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.blue_2}><StatusTitle color={colors.textStatus.neutral}>{'In progress'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Cancelled: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.violet_1}><StatusTitle color={colors.textStatus.negative}>{'Cancelled'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Expired: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.orange_1}><StatusTitle color={colors.textStatus.negative}>{'Expired'}</StatusTitle></StatusBox>;
        case TrainingAssignmentStatus.Validation: return <StatusBox marginTop={isFilter ? '8px' : undefined} bgColor={colors.status.grey_2}><StatusTitle color={colors.textStatus.neutral}>{'Validation'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{status}</StatusTitle></StatusBox>;
    }
}

export const getCommonStatusChip = (status?: TrainingAssignmentObjectStatus) => {
    switch (status) {
        case (TrainingAssignmentObjectStatus.InProgress): return <StatusBox bgColor={colors.status.blue_2}><StatusTitle color={colors.textStatus.neutral}>{'In progress'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Abandoned): return <StatusBox bgColor={colors.backgrounds.warning}><StatusTitle color={colors.textStatus.negative}>{'Abandoned'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Done): return <StatusBox bgColor={colors.status.green_1}><StatusTitle color={colors.textStatus.positive}>{'Completed'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Expired): return <StatusBox bgColor={colors.status.orange_1}><StatusTitle color={colors.textStatus.negative}>{'Expired'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Failed): return <StatusBox bgColor={colors.decorative.background_2}><StatusTitle color={colors.textStatus.negative}>{'Failed'}</StatusTitle></StatusBox>;
        case (TrainingAssignmentObjectStatus.Passed): return <StatusBox bgColor={colors.status.green_2}><StatusTitle color={colors.textStatus.positive}>{'Passed'}</StatusTitle></StatusBox>;
        // case (TrainingAssignmentObjectStatus.SkippedBySystem): return <StatusBox bgColor={colors.status.blue_2}><StatusTitle color={colors.textStatus.neutral}>{'SkippedBySystem'}</StatusTitle></StatusBox>
        case (TrainingAssignmentObjectStatus.Todo): return <StatusBox bgColor={colors.status.blue_1}><StatusTitle color={colors.textStatus.neutral}>{'Todo'}</StatusTitle></StatusBox>
        case (TrainingAssignmentObjectStatus.Validation): return <StatusBox bgColor={colors.status.grey_2}><StatusTitle color={colors.textStatus.neutral}>{'Validation'}</StatusTitle></StatusBox>;

        default: return <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{status ?? '---'}</StatusTitle></StatusBox>;
    }
}

export const getAssignmentTrainingAndExamStatusChip = ({assignmentStatus, trainingStatus, examStatus}:{assignmentStatus: TrainingAssignmentStatus, trainingStatus?: TrainingAssignmentObjectStatus, examStatus?: TrainingAssignmentObjectStatus}) => {

    if (assignmentStatus === TrainingAssignmentStatus.Assigned) {
        return ({
            trainingChip: getCommonStatusChip(trainingStatus),
            examChip:!trainingStatus ?
                <StatusBox bgColor={colors.status.blue_1}><StatusTitle color={colors.textStatus.neutral}>{'Todo'}</StatusTitle></StatusBox>
                :
                <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Waiting'}</StatusTitle></StatusBox>,
        })
    }

    if (assignmentStatus === TrainingAssignmentStatus.Scheduled) {
        return ({
            trainingChip:  <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Waiting'}</StatusTitle></StatusBox>,
            examChip: <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Waiting'}</StatusTitle></StatusBox>,
        })
    }

    if (assignmentStatus === TrainingAssignmentStatus.Cancelled) {
        return ({
            trainingChip: <StatusBox bgColor={colors.status.grey_1}><StatusTitle color={colors.textStatus.neutral}>{'Not started'}</StatusTitle></StatusBox>,
            examChip: <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Not started'}</StatusTitle></StatusBox>,
        })
    }


    if (assignmentStatus === TrainingAssignmentStatus.InProgress) {
        return ({
            trainingChip:  getCommonStatusChip(trainingStatus),
            examChip: trainingStatus === TrainingAssignmentObjectStatus.InProgress ?
                <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Waiting'}</StatusTitle></StatusBox>
                :
                getCommonStatusChip(examStatus),
        })
    }

    if (assignmentStatus === TrainingAssignmentStatus.Validation || assignmentStatus === TrainingAssignmentStatus.Completed || assignmentStatus === TrainingAssignmentStatus.Failed || assignmentStatus === TrainingAssignmentStatus.Passed) {
        return ({
            trainingChip:  getCommonStatusChip(trainingStatus),
            examChip: getCommonStatusChip(examStatus),
        })
    }

    if (assignmentStatus === TrainingAssignmentStatus.Expired) {
        return ({
            trainingChip:  getCommonStatusChip(trainingStatus),
            examChip: trainingStatus === TrainingAssignmentObjectStatus.Expired ? <StatusBox bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.textStatus.neutral}>{'Not started'}</StatusTitle></StatusBox>  : getCommonStatusChip(examStatus),
        })
    }
    else return ({
        trainingChip:  getCommonStatusChip(trainingStatus),
        examChip: getCommonStatusChip(examStatus),
    })
}
