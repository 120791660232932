import {Flex} from "../../../../../newShared/components/Layouts";
import {Stage} from "../../types";
import {Checkbox} from "@mui/material";
import {Typo} from "../../../../../newShared/components/Typography";
import React, {useState} from "react";
import {SignStage} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {loadings} from "../../store/slice";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

export const ReviewConfirm = ({stage, isRed}: {stage: Stage, isRed: boolean}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'workerSpace'});

    const dispatch = useDispatch();
    const {approveDefaultDocStage} = useSelector(loadings);
    const [agree, setAgree] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAgree(event.target.checked);
    }

    const handleAgree = () => {
        agree && dispatch(SignStage(stage.stage.id));
    }

    return(
        <Flex jc={'space-between'} ai={'flex-start'} w={'calc(100% + 15px)'} m={!revDir ? '50px 0 0 -15px' : '50px -15px 0 0'}>
            <Flex w={'50%'} ai={'center'}>
                <Checkbox checked={agree} onChange={handleChange}/>
                <Typo
                    fontSize={'18px'}
                    fontWeight={300}
                    margin={'0 0 0 15px'}
                >
                    {t('By selecting this checkbox, I confirm that I read and reviewed this document.')}
                </Typo>
            </Flex>
            {/*<ButtonWithBackround width={'108px'} height={'35px'} color={agree ? colors.primary.blue : 'gray'} fontColor={'white'} text={'SAVE'} onClick={handleAgree}/>*/}
            <LoadingButton
                variant={'contained'}
                loading={approveDefaultDocStage}
                onClick={handleAgree}
                disabled={!agree}
                sx={{
                    marginTop: '22px'
                }}
            >
                {t('SAVE')}
            </LoadingButton>
        </Flex>
    )
}