//Core
import React, {Suspense} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {ApolloProvider} from '@apollo/client';

//Other
import {store} from '../../newShared/redux';
import {client} from "../../newShared/graphQl";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';


//SnackBar
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import theme from '../../newShared/theme/theme';

//I18NEXT
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'
import {Shell} from "../../newDomain/barsEnvironment/shell";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {Spinner} from '../../newShared/components/Spinner';
import {TouchBackend} from "react-dnd-touch-backend";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {useDndBackend} from "../hooks/useDndBackend";
import {initLabels} from "../../newShared/env";

//I18
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        // debug: true,
        supportedLngs: ['en', 'he', 'ar'],
        fallbackLng: 'en',
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json?' + new Date().getTime(), // Add timestamp to avoid caching
        },
        detection: {
            order: ['cookie'],
            caches: ['cookie']
        },
        missingKeyNoValueFallbackToKey: true,
        interpolation: {
            escapeValue: false,
        },
        // react: {useSuspense: false},
    })

export default function App() {
    initLabels();

    const _is_touch_device = useDndBackend();
    return (
        <Suspense fallback={<Spinner/>}>
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <StyledThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DndProvider backend={_is_touch_device ? TouchBackend : HTML5Backend}>
                                <Router>
                                    <Shell/>
                                </Router>
                            </DndProvider>
                        </LocalizationProvider>
                    </StyledThemeProvider>
                </Provider>
            </ApolloProvider>
        </Suspense>
    );
}
