import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {finalApprover, loadings, selectedStage} from "../../../store/slice";
import {calculateIfDeadlinePassed} from "../../../helpers";
import {IN_PROGRESS_STATUS, TERMINATED_STATUS} from "../../../constants";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {IconButton, Typography} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {usePDFView} from "../../../../../../newShared/components/docViewer/hooks/usePDFView";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import {DownloadFile, FinalApproverSignatureVerify, GetFinalApproverByStageIdAndExcId,} from "../../../store/actions";
import {DocViewerDialog} from "../../../../../../newShared/components/docViewer";
import {Typo} from "../../../../../../newShared/components/Typography";
import {CustomButton} from "../../../../../../newShared/components/Buttons";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {LocalSpinner} from "../../../../../../newShared/components/Spinner";

const SignatureCanvas = require('react-signature-canvas-react17-compatible').default;


export const CampaignReviewSignStep = () => {
    const dispatch = useDispatch();
    const stage = useSelector(selectedStage);
    const _finalApprover = useSelector(finalApprover);
    const {isLoadingFinalApprover, verifyChanges} = useSelector(loadings);
    const {revDir} = useMainTranslation();
    const {handleOpen} = usePDFView();
    const [canvas, setCanvas] = useState<any>(null);


    useEffect(() => {
        stage && dispatch(GetFinalApproverByStageIdAndExcId({data: {stageId: stage.stage.id, executorId: stage.stage.executorId}}))
        //eslint-disable-next-line
    }, []);

    //
    const signStage = stage?.stageCampaignReview ?? null;
    const isDeadlinePassed = calculateIfDeadlinePassed(stage?.stageCampaignReview);
    const isTerminated= signStage?.status === TERMINATED_STATUS;

    const openViewer = () => {
        _finalApprover && _finalApprover.documentId && stage && handleOpen({fileId: _finalApprover.documentId, fileName: `${stage.stage.name}_reviewCampaign.pdf`});
    }

    const download = () => {
        if(_finalApprover && _finalApprover.documentId && stage){
            dispatch(DownloadFile({fileId: _finalApprover.documentId, fileName: `${stage.stage.name}_reviewCampaign.pdf`}));
            setIsRed(true);
        }
    }

    const [isRed, setIsRed] = useState<boolean>(false);

    const handleApprove = () => {
        if(stage && canvas && !canvas.isEmpty()){
            const base64 = canvas.getCanvas().toDataURL();
            dispatch(FinalApproverSignatureVerify({
                stageId: stage.stage.id,
                executorId: stage.stage.executorId,
                file: base64
            }));
        }
    }

    return(
        <>
            {isLoadingFinalApprover && <LocalSpinner />}
            {stage && stage.stageCampaignReview && _finalApprover &&
                <Flex w={'100%'} direction={'column'}>
                    {isTerminated ?
                        <>
                            <Flex m={'16px 0 0 0'}>
                                <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>Stage sign was terminated on </Typography>
                                <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{parseDateAuto(signStage?.statusChangedDate)}</Typography>
                            </Flex>
                        </>
                        :
                        <>
                            {signStage && signStage.status === IN_PROGRESS_STATUS &&
                                <Flex w={'100%'} ai={'center'} m={'8px 0'}>
                                    <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>Deadline for Vendor owners</Typography>
                                    <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{parseDateAuto(signStage.expirationDate)}</Typography>
                                    {isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange}>{'The deadline has arrived'}</Typography>}
                                </Flex>
                            }
                            {/*<Divider flexItem style={{backgroundColor: colors.primary.blue, margin: '8px 0 0 0'}} />*/}

                            <Flex w={'100%'} ai={'center'}>
                                <Typography variant={'body1'} color={colors.lightGrayText} sx={{lineHeight: 'normal'}}>Document to sign</Typography>

                                {<Flex ai={'center'}>
                                    <IconButton onClick={download}>
                                        <DownloadIcon fontSize={'small'} color={'primary'} />
                                    </IconButton>
                                    <IconButton onClick={openViewer}>
                                        <VisibilityIcon fontSize={'small'} color={'primary'}/>
                                    </IconButton>
                                    <Typography variant={'body1'} color={colors.grayText}
                                                sx={{lineHeight: 'normal'}}>{`${stage.stage.name}_reviewCampaign.pdf`}</Typography>
                                </Flex>}
                            </Flex>

                            {stage && stage.stage.actor.status === IN_PROGRESS_STATUS &&
                                <>
                                    {/*<Divider flexItem style={{backgroundColor: colors.primary.blue, margin: '8px 0 0 0'}} />*/}

                                    <Flex w={'1000px'} m={'28px 8px 0 8px'} box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'} br={'12px'} overflow={'hidden'}>
                                        <Flex w={'1000px'} jc={'center'}>
                                            <SignatureCanvas
                                                ref={(ref: any) => setCanvas(ref)}
                                                penColor='black'
                                                backgroundColor={'white'}
                                                canvasProps={{width: 1000, height: 120, className: 'sigCanvas'}}
                                            />
                                        </Flex>
                                    </Flex>

                                    <Flex jc={'space-between'} ai={'flex-start'} w={'calc(100% + 15px)'} m={!revDir ? '50px 0 0 -15px' : '50px -15px 0 0'}>
                                        <Flex w={'50%'} ai={'center'}>
                                            <Typo
                                                fontSize={'18px'}
                                                fontWeight={300}
                                                margin={!revDir ? '0 0 0 15px' : '0 15px 0 0'}>
                                                {isRed ?
                                                    ('By signing above, I confirm that I read and approve this document.')
                                                    :
                                                    'You must read the document'
                                                }
                                            </Typo>
                                        </Flex>

                                        <Flex ai={'center'}>
                                            <CustomButton
                                                type={'submit'}
                                                onClick={() => canvas && canvas.clear()}
                                                // active={agree}
                                                // disabled={!agree}
                                                variant={'contained'}
                                                sx={{
                                                    margin: !revDir ? '22px 16px 0 0' : '22px 0 0 16px'
                                                }}
                                            >
                                                {('CLEAR')}
                                            </CustomButton>

                                            <LoadingButton
                                                variant={'contained'}
                                                loading={verifyChanges}
                                                onClick={handleApprove}
                                                sx={{
                                                    marginTop: '22px'
                                                }}
                                            >
                                                {('SAVE')}
                                            </LoadingButton>
                                        </Flex>
                                    </Flex>
                                </>
                            }
                        </>
                    }
                </Flex>

            }

            <DocViewerDialog/>
        </>
    )
}