import React, {ChangeEvent, FC} from "react";
import {createStyles, CSSProperties, makeStyles} from '@mui/styles';
// import {NewButton} from "../../NewButton";
import {Theme} from "@mui/material";
import { CustomButton } from "../../Buttons";


const useStyles = makeStyles((theme: Theme) => (
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
        button: {
            boxShadow: "none",
            width: '137px',
            height: '35px',
            fontWeight: 600,
            fontSize: '16px',
            borderRadius: '25px',
            color: 'white',
            // '&:hover': {
            //     // color: 'white'
            // }
        }
    })
));


type PropsType = {
    id: string;
    func?: () => void;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    accept?: string,
    small?: boolean,
    sx?: CSSProperties,
    text?: string,
    revDir? : boolean
}

export const UploadFileBTN: FC<PropsType> = ({func, id, onChange, accept, small, sx, text, revDir = false}: PropsType) => {
    const classes = useStyles();

    return (
        <div onClick={e => {
            e.stopPropagation();
        }}>
            <input
                id={"contained-button-file " + id}
                type="file"
                accept={accept || ''}
                className={classes.input}
                onChange={async e => {
                    await onChange(e);
                }}
            />
            <label htmlFor={"contained-button-file " + id}>
                {small
                    ?
                    <CustomButton
                        variant={'contained'}
                        component={'span'}
                        size={'small'}
                        sx={{margin: !revDir ? '0 0 0 20px' : '0 20px 0 0', color: 'white !important', ...sx}}
                    >
                        {text ? text : 'Upload'}
                    </CustomButton>
                    :
                    <CustomButton
                        variant={'contained'}
                        component={'span'}
                        // className={classes.button}
                        sx={{marginLeft: '20px', color: 'white !important', ...sx}}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    >
                        {text ? text : 'Upload'}
                    </CustomButton>
                }
            </label>
        </div>
    )
}