import {useDispatch, useSelector} from "react-redux";
import {assignmentsSelector, dialogsSelector, hideStartTrainingDialog, loadingsSelector} from "../../../store/slice";
import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {
    portalFinishTrainingAction,
    portalFinishTrainingSectionAction,
    portalGetAssignmentTrainingByIdAction
} from "../../../store/actions";
import {PortalTrainingContentModel, TrainingContentStatus} from "../../../../../../newShared/GQLTypes";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";

export const useStartTrainingDialog = ({refetchAssignment}: {refetchAssignment: () => void}) => {
    //root
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {assignmentId} = useParams<{ assignmentId: string }>();
    const {selected} = useSelector(assignmentsSelector);

    //selectors
    const isExamInAssignment = selected ? selected.postExam !== null : false;
    const {getAssignmentTrainingById, finishTraining, finishTrainingSection} = useSelector(loadingsSelector);
    const {isOpen, training} = useSelector(dialogsSelector).startTrainingDialog;

    const handleClose = () => dispatch(hideStartTrainingDialog());

    //useEffects
    useEffect(() => {
        if(isOpen){
            dispatch(portalGetAssignmentTrainingByIdAction({
                data: {id: assignmentId ?? ''},
                onSuccess: (req, response) => {
                    setTrainingDuration(response.duration);
                    if(response.limitMinDuration > 0){
                        startCountdown();
                    }
                    setSelectedSection(response.content.find(e => e.status === TrainingContentStatus.InProgress) ?? response.content[response.content.length - 1])
                },
                onError: (_, error) => {
                    const error409 = error.e409?.[0];
                    if (error409?.type === 'STATUS_CHANGED') {
                        setMessage({
                            title: ('Action conflict error'),
                            message: ('Action not applicable! Assignment status was changed.'),
                        });
                    }
                    if (error409?.type === 'TRAINING_NOT_EXISTS') {
                        setMessage({
                            title: ('Action conflict error'),
                            message: ('Action not applicable! Training not found.'),
                        });
                    }
                }
            }))
        }else{
            setIsMenuOpened(true);
            setLeaveDialog(false);
            setFinishDialog(false);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const message = 'You have uncompleted exam. Are you sure you want to leave?';
            event.returnValue = message;
            return event;
        };
        if(isOpen){
            window.addEventListener('beforeunload', handleBeforeUnload);
        }
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            _interval && clearInterval(_interval);
            _setInterval(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    //other
    //time management
    const [trainingDuration, setTrainingDuration] = useState<number>(0); //seconds
    const [_interval, _setInterval] = useState<NodeJS.Timer | null>(null);

    const incrementDuration = useCallback(() => {
        setTrainingDuration(prev => prev + 1);
        //eslint-disable-next-line
    }, [trainingDuration]);

    const startCountdown = () => {
        const interval = setInterval(incrementDuration, 1000);
        _setInterval(interval);
    }

    //store
    const [selectedSection, setSelectedSection] = useState<PortalTrainingContentModel | null>(null);

    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(true);

    const [leaveDialog, setLeaveDialog] = useState<boolean>(false);
    const [finishDialog, setFinishDialog] = useState<boolean>(false);

    //actions
    const finishSection = (onSuccess?: () => void) => {
        selectedSection && training && dispatch(portalFinishTrainingSectionAction({
            data: {assignmentId, sectionId: selectedSection.sectionId, duration: trainingDuration},
            onSuccess: (request, response, addictiveData) => {
                if(response.nextSection !== null){
                    const nextSection = training.content.find(e => e.sectionId === response.nextSection);
                    nextSection && setSelectedSection(nextSection);
                }
                onSuccess && onSuccess();
            },
            onError: (_, error) => {
                const error409 = error.e409?.[0];
                if (error409?.type === 'STATUS_CHANGED') {
                    setMessage({
                        title: ('Action conflict error'),
                        message: ('Action not applicable! Assignment status was changed.'),
                    });
                }
                if (error409?.type === 'TRAINING_NOT_EXISTS') {
                    setMessage({
                        title: ('Action conflict error'),
                        message: ('Action not applicable! Training not found.'),
                    });
                }
                if (error409?.type === 'SECTION_NOT_EXISTS') {
                    setMessage({
                        title: ('Action conflict error'),
                        message: ('Action not applicable! Section not found.'),
                    });
                }
            }
        }))
    }

    const finishTrainingAction = () => {
        selectedSection && training && dispatch(portalFinishTrainingAction({
            data: {assignmentId},
            onSuccess: () => {
                handleClose();
                refetchAssignment();
                setMessage({
                    title: 'Training compilation',
                    message: isExamInAssignment ? 'The training is completed. Now you can start the exam.' : 'The training is completed.'
                })
            },
            onError: (_, error) => {
                const error409 = error.e409?.[0];
                if (error409?.type === 'STATUS_CHANGED') {
                    setMessage({
                        title: ('Action conflict error'),
                        message: ('Action not applicable! Assignment status was changed.'),
                    });
                }
                if (error409?.type === 'TRAINING_NOT_EXISTS') {
                    setMessage({
                        title: ('Action conflict error'),
                        message: ('Action not applicable! Training not found.'),
                    });
                }
                if (error409?.type === 'SECTION_NOT_EXISTS') {
                    setMessage({
                        title: ('Action conflict error'),
                        message: ('Action not applicable! Section not found.'),
                    });
                }
            }
        }))
    }


    return{
        isOpen,
        loadings: {
            isLoadingGetTraining: getAssignmentTrainingById,
            finishTraining,
            finishTrainingSection
        },
        training,
        actions: {
            handleClose: () => setLeaveDialog(true),
            handleGoToNextSection: finishSection,
            handleFinishTraining: () => {
                if(training && training.content.filter(e => e.status !== TrainingContentStatus.Done).length > 0){
                    //there is still some unfinished section - open dialog after section finished
                    finishSection(() => setFinishDialog(true));
                }else{
                    //all section complete
                    setFinishDialog(true);
                }
            },
            isLastSection: training && selectedSection && training.content[training.content.length - 1].sectionId === selectedSection.sectionId
        },
        sectionsMenu: {
            onSectionClick: (section: PortalTrainingContentModel) => {
                if(section.status === TrainingContentStatus.Done || section.status === TrainingContentStatus.InProgress){
                    setSelectedSection(section);
                }
            },
            sections: training?.content ?? [],
            selectedSection,
            isMenuOpened,
            toggleMenu: () => setIsMenuOpened(!isMenuOpened),
        },
        trainingInfo: {
            currentDuration: trainingDuration,
            trainingLimitInH: training?.limitMinDuration ?? 0,
            sections: training?.content ?? [],
        },
        sectionDetails: {
            section: selectedSection
        },
        leaveDialog: {
            isOpen: leaveDialog,
            handleClose: () => setLeaveDialog(false),
            handleLeave: () => {
                //interrupt training fetched
                //close dialog + refetch assignment
                handleClose();
                refetchAssignment();
            }, //close this dialog
            duration: trainingDuration,
            assignmentId,
            limitMinDuration: training?.limitMinDuration ?? 0
        },
        finishDialog: {
            isOpen: finishDialog,
            handleClose: () => setFinishDialog(false),
            limitMinDuration: training?.limitMinDuration ?? 0,
            duration: trainingDuration,
            finishTrainingAction,
            isActive: Boolean(training && (training.limitMinDuration > 0 ? training.duration >= training.limitMinDuration * 60 * 60 : true)),
            isAllComplete: training && training.content.every(e => e.status === TrainingContentStatus.Done)
        }
    }
}