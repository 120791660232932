import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {
    PortalFinishTrainingMutation,
    PortalFinishTrainingMutationVariables,
    PortalFinishTrainingSectionMutation,
    PortalFinishTrainingSectionMutationVariables,
    PortalGetAssignmentTrainingByIdQuery,
    PortalGetAssignmentTrainingByIdQueryVariables,
    PortalStartTrainingMutation,
    PortalStartTrainingMutationVariables,
    PortalTrainingsExamSaveAnswerMutation,
    PortalTrainingsExamSaveAnswerMutationVariables,
    PortalTrainingsExamSkipAnswerMutation,
    PortalTrainingsExamSkipAnswerMutationVariables,
    PortalTrainingsFinishExamMutation,
    PortalTrainingsFinishExamMutationVariables,
    PortalTrainingsGetAssignmentDetailsQuery,
    PortalTrainingsGetAssignmentDetailsQueryVariables,
    PortalTrainingsGetAssignmentsWithFilterPaginationQuery,
    PortalTrainingsGetAssignmentsWithFilterPaginationQueryVariables,
    PortalTrainingsGetExamByAssignmentIdQuery,
    PortalTrainingsGetExamByAssignmentIdQueryVariables,
    PortalTrainingsStartExamMutation,
    PortalTrainingsStartExamMutationVariables,
    PortalUpdateTrainingDurationMutation,
    PortalUpdateTrainingDurationMutationVariables,
} from "../../../../newShared/GQLTypes";
import {
    portalFinishTraining,
    portalFinishTrainingSection,
    portalGetAssignmentTrainingById,
    portalStartTraining,
    portalTrainingsExamSaveAnswer,
    portalTrainingsExamSkipAnswer,
    portalTrainingsFinishExam,
    portalTrainingsGetAssignmentDetails,
    portalTrainingsGetAssignmentsWithFilterPagination,
    portalTrainingsGetExamByAssignmentId,
    portalTrainingsStartExam,
    portalUpdateTrainingDuration,
} from "../api/query";

export const portalTrainingsGetAssignmentsWithFilterPaginationAction =
    createCommonAsyncThunk<PortalTrainingsGetAssignmentsWithFilterPaginationQueryVariables, PortalTrainingsGetAssignmentsWithFilterPaginationQuery["portalTrainingsGetAssignmentsWithFilterPagination"]>(
        'trainings', 'portalTrainingsGetAssignmentsWithFilterPagination', true, false, portalTrainingsGetAssignmentsWithFilterPagination
    )

//portalGetAssignmentTrainingById
export const portalGetAssignmentTrainingByIdAction =
    createCommonAsyncThunk<PortalGetAssignmentTrainingByIdQueryVariables, PortalGetAssignmentTrainingByIdQuery["portalGetAssignmentTrainingById"]>(
        'trainings', 'portalGetAssignmentTrainingById', true, false, portalGetAssignmentTrainingById
    )

//portalStartTraining
export const portalStartTrainingAction =
    createCommonAsyncThunk<PortalStartTrainingMutationVariables, PortalStartTrainingMutation["portalStartTraining"]>(
        'trainings', 'portalStartTraining', true, false, portalStartTraining
    )
    
export const portalTrainingsStartExamAction =
    createCommonAsyncThunk<PortalTrainingsStartExamMutationVariables, PortalTrainingsStartExamMutation["portalTrainingsStartExam"]>(
        'trainings', 'portalTrainingsStartExam', true, false, portalTrainingsStartExam
    )

export const portalTrainingsGetExamByAssignmentIdAction =
    createCommonAsyncThunk<PortalTrainingsGetExamByAssignmentIdQueryVariables, PortalTrainingsGetExamByAssignmentIdQuery["portalTrainingsGetExamByAssignmentId"]>(
        'trainings', 'portalTrainingsGetExamByAssignmentId', true, false, portalTrainingsGetExamByAssignmentId
    )


export const portalUpdateTrainingDurationAction =
    createCommonAsyncThunk<PortalUpdateTrainingDurationMutationVariables, PortalUpdateTrainingDurationMutation["portalUpdateTrainingDuration"]>(
        'trainings', 'portalUpdateTrainingDuration', true, false, portalUpdateTrainingDuration
    )

export const portalFinishTrainingSectionAction =
    createCommonAsyncThunk<PortalFinishTrainingSectionMutationVariables, PortalFinishTrainingSectionMutation["portalFinishTrainingSection"]>(
        'trainings', 'portalFinishTrainingSection', true, false, portalFinishTrainingSection
    )

export const portalFinishTrainingAction =
    createCommonAsyncThunk<PortalFinishTrainingMutationVariables, PortalFinishTrainingMutation["portalFinishTraining"]>(
        'trainings', 'portalFinishTraining', true, false, portalFinishTraining
    )

export const portalTrainingsExamSaveAnswerAction =
    createCommonAsyncThunk<PortalTrainingsExamSaveAnswerMutationVariables, PortalTrainingsExamSaveAnswerMutation["portalTrainingsExamSaveAnswer"]>(
        'trainings', 'portalTrainingsExamSaveAnswer', true, false, portalTrainingsExamSaveAnswer
    )

export const portalTrainingsExamSkipAnswerAction =
    createCommonAsyncThunk<PortalTrainingsExamSkipAnswerMutationVariables, PortalTrainingsExamSkipAnswerMutation["portalTrainingsExamSkipAnswer"]>(
        'trainings', 'portalTrainingsExamSkipAnswer', true, false, portalTrainingsExamSkipAnswer
    )

export const portalTrainingsFinishExamAction =
    createCommonAsyncThunk<PortalTrainingsFinishExamMutationVariables, PortalTrainingsFinishExamMutation["portalTrainingsFinishExam"]>(
        'trainings', 'portalTrainingsFinishExam', true, false, portalTrainingsFinishExam
    )

export const portalTrainingsGetAssignmentDetailsAction =
    createCommonAsyncThunk<PortalTrainingsGetAssignmentDetailsQueryVariables, PortalTrainingsGetAssignmentDetailsQuery["portalTrainingsGetAssignmentDetails"]>(
        'trainings', 'portalTrainingsGetAssignmentDetails', true, false, portalTrainingsGetAssignmentDetails
    )
