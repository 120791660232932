import {useSelectWorkspaceDialog} from "../../hooks/useSelectWorkspaceDialog";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import React, {Fragment} from "react";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Autocomplete} from "@mui/lab";
import {ForbiddenWrapperOrganization} from "../../types";
import {CustomSearchField} from "../customSearch";
import colors from "../../../../../newShared/theme/colors";
import {TextFieldCustom} from "../styled";
import {isExpired} from "../../../../../newShared/utils/dateTools";
import {TypographyNew} from "../../../../../newShared/components/TypographyNew";

export const SelectWorkspaceDialog = () => {
    const {currentLanguage, t} = useMainTranslation('', {keyPrefix: 'pathSettings.dialogs'});
    const {
        isOpen,
        isLoading,
        handleClose,
        organizations,
        isLoadingSelectWorkspace,
        handleSubmit,
        selectedOrganization,
        selectedWorkspace,
        handleSelectWorkspace,
        handleSelectOrganization,
        workspaces,

        handleCleanSearch,
        search,
        handleChange,
        isOk,
    } = useSelectWorkspaceDialog();

    const getOptionLabelOrg = (option: ForbiddenWrapperOrganization) => `${option.name} ${option.blocked ? `(${t('blocked')})` : ''} ${isExpired(option.subscriptionExpiry, currentLanguage.momentLocale) ? `(${t('expired')})` : '' }`;

    return(
        <Dialog open={isOpen} onClose={handleClose} sx={{
            '& .MuiDialog-paper': {
                width: 'min(678px, 95vw)',
                maxWidth: 'min(678px, 95vw)',
            }
        }}>
            <DialogTitle>{t('Select workspace')}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '8px', overflow: 'hidden', height: 'min(95vh, 600px)'}}>
                <Flex w={'100%'} ai={'center'} gap={'8px'} shrink={'0'}>
                    <Typography variant={'*bodyText1_semibold'} sx={{
                        overflowWrap: 'break-word',
                    }}>{t('Select workspace from')}</Typography>

                    <Autocomplete<ForbiddenWrapperOrganization>
                        // fullWidth
                        sx={{minWidth: '150px', flexGrow: 1}}
                        size={"small"}
                        options={organizations}
                        getOptionLabel={getOptionLabelOrg}
                        renderOption={(props, option) => (<li {...props} key={option.id}>{getOptionLabelOrg(option)}</li>)}
                        loading={isLoading}
                        value={selectedOrganization}
                        onChange={(e, newValue) => newValue && handleSelectOrganization(newValue)}
                        renderInput={(params) => <TextFieldCustom {...params} label={t("Organization")} />}
                    />
                </Flex>

                <CustomSearchField
                    variant={'outlined'}
                    onChange={handleChange}
                    name={'search'}
                    value={search}
                    onClear={handleCleanSearch}
                    disabled={!selectedOrganization}
                    label={t('Search by workspace name')}
                    size={"small"}
                    fullWidth
                    sx={{flexShrink: 0}}
                />

                <Divider flexItem sx={{bgcolor: colors.stroke.grey, flexShrink: 0}}/>

                <Flex w={'100%'} grow={'1'} overflowy={'auto'} direction={'column'}>
                    {workspaces.map(e => (
                        <Fragment key={e.id}>
                            <Flex w={'100%'} ai={'center'} jc={'space-between'} p={'16px'} gap={'8px'}>
                                <Flex direction={'column'} jc={'space-between'} grow={'1'} overflow={'hidden'}>
                                    <TypographyNew variant={'*bodyText1'} color={colors.primary.blue_dark} sx={{cursor: 'pointer'}} noWrap>
                                        {e.name}
                                    </TypographyNew>
                                    <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{e.collaboratorsCount} {t('collaborators')}</Typography>
                                </Flex>

                                {e.id === selectedWorkspace?.id
                                    ? (
                                        <Button color={'secondary'} variant={'contained'} sx={{flexShrink: 0}} disabled>{t('Selected')}</Button>
                                    ) : (
                                        <Button color={'secondary'} variant={'outlined'} sx={{flexShrink: 0}}
                                                onClick={() => handleSelectWorkspace(e)}
                                                disabled={isLoading || selectedOrganization?.blocked || isExpired(selectedOrganization?.subscriptionExpiry ?? '', currentLanguage.momentLocale)}
                                        >
                                            {t('Select')}
                                        </Button>
                                    )
                                }
                            </Flex>

                            <Divider flexItem sx={{bgcolor: colors.stroke.grey}}/>
                        </Fragment>
                    ))}
                </Flex>

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} disabled={isLoading} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    loading={isLoadingSelectWorkspace}
                    onClick={handleSubmit}
                    disabled={!isOk}
                >
                    {t('Change')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}