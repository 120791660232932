import {gql} from "@apollo/react-hooks";

export const portalTrainingsAssignmentShortFragment =  gql`
    fragment portalTrainingsAssignmentShortFragment on PortalTrainingsShortAssignmentModel {
        id
#        workspaceId 
        
        requestor
        
        startDate
        endDate
        
        status
        trainingName
        examName
    }
`

export const portalTrainingAssignmentExamFragment =  gql`
    fragment portalTrainingAssignmentExamFragment on PortalTrainingsAssignmentsExamModel {
        examVersionId
        examName
        examDescription
        examVersion
        examCategory
        examLevel
        examCover {
            fileType
            image
            imageName
            thumbnail
            type
        }
        status
        timeToPass
        examPassingPoints
        totalPoints
        startDate
        completeDate
        questions {
            answer
            answerDate
            description
            options {
                correct
                option
                order
                points
            }
            question
            questionId
            status
            type
            order
            points
            validation {
                collaboratorId
                correctAnswer
                email
                fullName
                points
                type
                validationDate
            }
        }
    }
`;

export const portalTrainingFragment =  gql`
    fragment portalTrainingFragment on PortalTrainingModel {
        trainingVersionId
        trainingName
        trainingVersion
        trainingCategory
        trainingDescription
        trainingLevel
        status
        limitMaxDuration
        limitMinDuration
        startDate
        completeDate
        duration
        trainingCover{
            thumbnail
            image
            imageName
            type
        }
        content{
            sectionId
            sectionName
            type
            data{
                name
                fileId
                thumbnail
                url
                innerHtml
            }
            status
            order
        }
    }
`

export const portalTrainingAssignmentModelFragment = gql`
    fragment portalTrainingAssignmentModelFragment on PortalTrainingsAssignmentModel {
        id
        workspaceId
        recipient {
            collaboratorId
            fullName
            email
        }
        requestor {
            collaboratorId
            fullName
            email
        }
        requestDate
        startDate
        endDate
        actualStartDate
        completeDate
        status
        training {
            trainingVersionId
            trainingName
            trainingVersion
            trainingCategory
            trainingLevel
            trainingDescription
            trainingCover {
                imageName
                type
                image
                thumbnail
                fileType
            }
            status
            limitMaxDuration
            limitMinDuration
            content {
                sectionId
                sectionName
                type
                status
                order
                data {
                    name
                    fileId
                    thumbnail
                    innerHtml
                    url
                }
            }
            startDate
            completeDate
        }
        postExam {
            examVersionId
            examName
            examDescription
            examVersion
            examCategory
            examLevel
            examCover {
                imageName
                type
                image
                thumbnail
                fileType
            }
            status
            timeToPass
            examPassingPoints
            totalPoints
            startDate
            completeDate
            totalQuestions
            questions {
                questionId
                question
                description
                order
                points
                type
                options {
                    option
                    correct
                    points
                    order
                }
                status
                answerDate
                answer
                validation {
                    type
                    collaboratorId
                    fullName
                    email
                    correctAnswer
                    points
                    validationDate
                }
            }
        }
    }
`
