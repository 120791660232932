import React, {FC, useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {useTrainingsDialogs} from "../../../hooks/dialogs/useTrainingsDialogs";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../newShared/theme/colors";
import {getCommonStatusChip} from "../../../helpers";

export const ValidationExamDialog: FC = () => {
    const {t, tCommon} = useMainTranslation();

    const finishExamDialog = useTrainingsDialogs().finishExam;
    const passingExamDialog = useTrainingsDialogs().passingExam;
    const {isOpen, handleClose: _handleClose} = useTrainingsDialogs().validationExam;

    const handleClose = () => {
        _handleClose();
        passingExamDialog.handleClose();
        finishExamDialog.handleClose();
    };

    useEffect(() => {
        return () => {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isOpen && !finishExamDialog.isOpen ? (
        <Dialog open onClose={handleClose}>
            <DialogTitle component={FlexRow} sx={{gap: '12px', alignItems: 'center'}}>
                {t('Validation')}
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', gap: '16px'}}>
                {'countInputs' in isOpen ? (
                    <>
                        <Typography variant={'*bodyText2_semibold'}>{t('The exam contains {{count}} questions with user free-fill answers to respond.', {count: isOpen.countInputs})}</Typography>

                        <Typography variant={'*bodyText2'}>{t('These question need manual validation. All your answers were sent to the requestor for validation and the exam was transferred to the Validation status.')}</Typography>
                        <Typography variant={'*bodyText2'}>{t('You will get notification when validation is done.')}</Typography>
                        <Typography variant={'*bodyText2'}>{t('The due date is not affected by validation time. Formally you have completed the assignment just now.')}</Typography>
                    </>
                ) : ('yourPoints' in isOpen) && (
                    <>
                        <Typography variant={'*bodyText2_semibold'}>{t('Your exam result')}</Typography>

                        <FlexRow sx={{justifyContent: 'space-between', gap: '36px'}}>
                            <FlexColumn sx={{flexBasis: '30%', gap: '8px'}}>
                                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey}>{t('Passing points')}</Typography>
                                <Typography variant={'*h3'}>{isOpen.passingPoints}</Typography>
                            </FlexColumn>

                            <FlexColumn sx={{flexBasis: '30%', gap: '8px'}}>
                                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey}>{t('Your points')}</Typography>
                                <Typography variant={'*h3'}>{isOpen.yourPoints}</Typography>
                            </FlexColumn>

                            <FlexColumn sx={{flexBasis: '30%', gap: '8px'}}>
                                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey}>{t('Exam')}</Typography>
                                <Typography variant={'*h3'}>{getCommonStatusChip(isOpen.status)}</Typography>
                            </FlexColumn>
                        </FlexRow>
                    </>

                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'small'}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    ) : null
}