import {Flex} from "../../../../../../newShared/components/Layouts"
import {useCampaignReviewVerifyStep} from "../../../hooks/useCampaignReviewVerifyStep";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {IN_PROGRESS_STATUS} from "../../../constants";
import {Typography} from "@mui/material";
import colors from "../../../../../../newShared/theme/colors";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {CustomTableHeader} from "../customTableHeader";
import {CustomTableRow} from "../customTableRow";
import {TableRowItemWrapper} from "../../styled";

export const CampaignReviewVerify = () => {
    const stageVerify = useCampaignReviewVerifyStep();
    const {revDir} = useMainTranslation();

    return(
        <Flex w={'100%'} direction={'column'}>
            {stageVerify.isTerminated ?
                <>
                    <Flex m={'16px 0 0 0'}>
                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>Stage verify was terminated on </Typography>
                        <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{parseDateAuto(stageVerify.stage?.statusChangedDate)}</Typography>
                    </Flex>
                </>
                :
                <>
                    {stageVerify.stage && stageVerify.stage.status === IN_PROGRESS_STATUS &&
                        <Flex w={'100%'} ai={'center'} m={'16px 0'}>
                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>Deadline for stage verify</Typography>
                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{parseDateAuto(stageVerify.stage.expirationDate)}</Typography>
                            {stageVerify.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange}>{'The deadline has arrived'}</Typography>}
                        </Flex>
                    }

                    {stageVerify.stage && stageVerify.stage.actors.map((actor, id) => {
                        const isEditable: boolean = actor.status === IN_PROGRESS_STATUS && !actor.mainControl;

                        return(
                            <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                {actor.appsData && actor.appsData.map(app => {
                                    return(
                                        <Flex direction={'column'} m={'16px 0 0 0'}>
                                            <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={'16px 2px'}>
                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0'}} fontWeight={600}>{'Vendor'}</Typography>
                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 32px 0'}}>{app.vendorName}</Typography>

                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0'}} fontWeight={600}>{'Owner'}</Typography>
                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 32px 0'}}>{actor.firstName} {actor.lastName}</Typography>

                                                {isEditable && <LoadingButton
                                                    variant={'contained'}
                                                    size={'small'}
                                                    onClick={() => stageVerify.handleVerifyChanges(actor.email)}
                                                    loading={stageVerify.commonSelectors.isLoadingVerify} //is loading verify changes for current user
                                                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                                                >{('Verify changes')}
                                                </LoadingButton>}

                                                {isEditable && <LoadingButton
                                                    variant={'contained'}
                                                    size={'small'}
                                                    onClick={() => stageVerify.handleDeclineChanges(actor.email)}
                                                    loading={stageVerify.commonSelectors.isLoadingVerify} //is loading verify changes for current user
                                                >{('Decline changes')}
                                                </LoadingButton>}
                                            </Flex>

                                            <CustomTableHeader
                                                columns={[{name: 'Employee', width: '30%'}, {name: 'Account', width: '30%'}, {name: 'Permission', width: '40%'}]}
                                                hideIf={false}
                                                padding={'8px 0 12px 0 '}
                                                width={'100%'}
                                            />

                                            {app.data.map(data => {
                                                return(
                                                    <CustomTableRow divider disableHover innerWrapperWidth={'100%'}>
                                                        <TableRowItemWrapper width={'30%'}>
                                                            <Typography sx={{color: colors.darkText}} variant={'body2'}>{data.employee ?? '- - -'}</Typography>
                                                        </TableRowItemWrapper>

                                                        <TableRowItemWrapper width={'30%'}>
                                                            <Typography sx={{color: colors.darkText}} variant={'body2'}>{data.account}</Typography>
                                                        </TableRowItemWrapper>

                                                        <TableRowItemWrapper width={'40%'}>
                                                            <Flex w={'90%'} direction={'column'}>
                                                                {data.roles.map(e => {
                                                                    const moreThen0Roles = e.rolePermissions.length > 0;
                                                                    return(
                                                                        <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                            <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={'body2'}>{e.roleName}</Typography>
                                                                            <Typography
                                                                                sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                variant={'body2'}
                                                                                onClick={() => stageVerify.onRoleClick(e.roleName, e.rolePermissions)}
                                                                            >{e.rolePermissions.length} permissions</Typography>
                                                                        </Flex>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        </TableRowItemWrapper>
                                                    </CustomTableRow>
                                                )
                                            })}
                                        </Flex>
                                    )
                                })}
                            </Flex>
                        )

                    })
                    }
                </>
            }
        </Flex>
    )
}