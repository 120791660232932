import {useDispatch, useSelector} from "react-redux";
import {
    loadings,
    openAddOrEditJustificationDialog,
    openShowPermissions,
    openViewJustification,
    selectedStage
} from "../../store/slice";
import {calculateIfDeadlinePassed, mapAppsByAccountIds} from "../../helpers";
import {
    ACCOUNT_DELETE_STATUS,
    ACCOUNT_KEEP_STATUS,
    ACCOUNT_REVOKE_STATUS,
    COMPLETE_STATUS,
    TERMINATED_STATUS
} from "../../constants";
import {useState} from "react";
import {UpdateCampaignReviewRows, VerifyActorChanges} from "../../store/actions";
import {applicationAccessDataWithoutAccounts, campaignReviewExecutorAccountFillableType} from "../../types";

export const useCampaignReviewVendorsStep = () => {
    const dispatch = useDispatch();
    const stage = useSelector(selectedStage);
    const {campaignReviewUpdatingRowsWithIds, verifyChanges} = useSelector(loadings);

    const vendorsStep = stage?.stageCampaignReview ?? null;

    //
    const [selectedVendorsAccounts, setSelectedVendorsAccounts] = useState<string[]>([]);
    const [selectingVendorsAccountsOfActorEmail, setSelectingVendorsAccountsOfActorEmail] = useState<string | null>(null);

    const handleDeselectAllVendorAccounts = () => {
        setSelectedVendorsAccounts([]);
        setSelectingVendorsAccountsOfActorEmail(null);
    }

    return{
        onRoleClick:(roleName: string, rolePermissions: string[]) => {
            rolePermissions.length > 0 && dispatch(openShowPermissions({rolePermissions, roleName}));
        },
        handleDeselectAllVendorAccounts,
        commonSelectors: {
            isLoadingVerify: verifyChanges,
            loadingIds: campaignReviewUpdatingRowsWithIds,
        },
        stage: vendorsStep,
        isDeadlinePassed: calculateIfDeadlinePassed(vendorsStep),
        isComplete: vendorsStep?.status === COMPLETE_STATUS,
        isTerminated: vendorsStep?.status === TERMINATED_STATUS,
        //SELECT
        handleSelectVendorAccount: (accountId: string, actorEmail: string) => {
            setSelectingVendorsAccountsOfActorEmail(actorEmail); //to define which actor is working on now (to prevent on selecting accounts for another actor (""))
            setSelectedVendorsAccounts(prev => {return [...prev, accountId]});
        },
        handleDeselectVendorAccount: (accountId: string, actorEmail: string) => {
            setSelectedVendorsAccounts(prev => {return prev.filter(e => e !== accountId)});
            if(!selectedVendorsAccounts.length) setSelectingVendorsAccountsOfActorEmail(null); //MAYBE BAG HERE
        },
        handleSelectAllVendorAccount: (email: string) => {
            setSelectingVendorsAccountsOfActorEmail(email); //to define which actor is working on now (to prevent on selecting accounts for another actor (""))
            if(vendorsStep){
                const act = vendorsStep.actors.find(e => e.email === email);
                if(act){
                    setSelectedVendorsAccounts((act.appsData ?? []).flatMap(e => e.data).map(e => e.id));
                }
            }
        },
        isSelectedVendorAccount: (accountId: string): boolean => {
            return selectedVendorsAccounts.some(e => e === accountId);
        },
        handleVerifyActorChanges: (fromActorEmail: string) => {
            if(vendorsStep && stage){
                dispatch(VerifyActorChanges({
                    data: {
                        executorId: stage.stage.executorId,
                        verifyComment: null, //stage managers - set null
                        stageId: vendorsStep.id,
                        signatureId: null
                    }
                }));
            }
        },
        viewJustification: (email: string, justification: string | null) => {
            justification && dispatch(openViewJustification({email, justification}));
        },
        editJustification: (app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, actorEmail: string) => {
            vendorsStep && dispatch(openAddOrEditJustificationDialog({
                app,
                account,
                stageId: vendorsStep.id,
                actorEmail,
                isManager: false
            }));
        },
        handleChangeSingleStatus: (app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, stageId: string, actorEmail: string, status: string) => {
            stage && dispatch(UpdateCampaignReviewRows({
                data: {
                    apps: [
                        {...app, data: [{...account, statusVendor: status}]}
                    ],
                    stageId,
                    executorId: stage.stage.executorId
                }
            }))
        },
        handleKeepAllSelectedManagerAccounts: () => {
            if(vendorsStep && selectingVendorsAccountsOfActorEmail !== null){
                const actor = vendorsStep.actors.find(e => e.email === selectingVendorsAccountsOfActorEmail);
                if(actor){
                    const apps = mapAppsByAccountIds(selectedVendorsAccounts, actor, ACCOUNT_KEEP_STATUS, false);
                    stage && dispatch(UpdateCampaignReviewRows({
                        data: {
                            apps,
                            stageId: vendorsStep.id,
                            executorId: stage.stage.executorId,
                            onSuccess: () => {
                                handleDeselectAllVendorAccounts(); //removing selections
                                setSelectingVendorsAccountsOfActorEmail(null); //giving ability to select other actor accounts
                            }
                        }
                    }))
                }
            }
        },
        handleRevokeAllSelectedManagerAccounts: () => {
            if(vendorsStep && selectingVendorsAccountsOfActorEmail !== null){
                const actor = vendorsStep.actors.find(e => e.email === selectingVendorsAccountsOfActorEmail);
                if(actor){
                    const apps = mapAppsByAccountIds(selectedVendorsAccounts, actor, ACCOUNT_REVOKE_STATUS, false);
                    stage && dispatch(UpdateCampaignReviewRows({
                        data: {
                            apps,
                            stageId: vendorsStep.id,
                            executorId: stage.stage.executorId,
                            onSuccess: () => {
                                handleDeselectAllVendorAccounts(); //removing selections
                                setSelectingVendorsAccountsOfActorEmail(null); //giving ability to select other actor accounts
                            }
                        }
                    }))
                }
            }
        },
        handleDeleteAllSelectedManagerAccounts: () => {
            if(vendorsStep && selectingVendorsAccountsOfActorEmail !== null){
                const actor = vendorsStep.actors.find(e => e.email === selectingVendorsAccountsOfActorEmail);
                if(actor){
                    const apps = mapAppsByAccountIds(selectedVendorsAccounts, actor, ACCOUNT_DELETE_STATUS, false);
                    stage && dispatch(UpdateCampaignReviewRows({
                        data: {
                            apps,
                            stageId: vendorsStep.id,
                            executorId: stage.stage.executorId,
                            onSuccess: () => {
                                handleDeselectAllVendorAccounts(); //removing selections
                                setSelectingVendorsAccountsOfActorEmail(null); //giving ability to select other actor accounts
                            }
                        }
                    }))
                }
            }
        },
        selectingVendorsAccountsOfActorEmail,
        selectedVendorsAccounts,
        isSelectedAllVendorsAccounts: (email: string): boolean => {
            if(vendorsStep){
                const act = vendorsStep.actors.find(e => e.email === email);
                if(act){
                    return (act.appsData ?? []).flatMap(e => e.data).length === selectedVendorsAccounts.length
                }
            }
            return false;
        }
    }
}