import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Skeleton} from "@mui/material";
import {downloadFileAction} from "../../../../../newShared/actions/downloadFile";
import {MainTrainingCoverImageType, TrainingCoverImageModel} from "../../../../../newShared/GQLTypes";

export const CoverImageView = ({cover, size}: {cover: TrainingCoverImageModel, size: 'list' | 'create' | 'exact' | 'preview'}) => {
    const dispatch = useDispatch();
    const [image, setImage] = useState<string | null>(null);

    useEffect(() => {
        if(cover.type === MainTrainingCoverImageType.FileId){
            dispatch(downloadFileAction({
                data: {
                    fileId: size === 'list' ? cover.thumbnail : cover.image,
                    fileName: cover.imageName,
                    dontSave: true
                },
                onSuccess: (request, response) => {
                    setImage('data:image/png;base64,' + response.file);
                }
            }))
        }else{
            setImage(size === 'list' ? cover.thumbnail : cover.image);
        }
        //eslint-disable-next-line
    }, [cover, size]);
    // const link = cover.type === MainTrainingCoverImageType.FileId ?
    //     `${APP_FILES_LINK}/files/base64/${size === 'thumbnail' ? cover.thumbnail : cover.image}`
    //     :
    //     size === 'thumbnail' ? cover.thumbnail : cover.image;

    const getImageStyleBySize = () => {
        switch (size){
            case "list": return {width: 60, height: 45};
            case "create": return {width: 124, height: 93};
            case "preview": return {width: 288, height: 216};
            case "exact": return {width: 400, height: 300};
        }
    }


    const style = getImageStyleBySize();
    return(
        <>
            {!image ?
                <Skeleton width={style.width} height={style.height}/>
                :
                <img src={image} style={{width: `${style.width}px`, height: `${style.height}px`, flexShrink: 0, borderRadius: '6px'}} alt={cover.imageName}/>
            }
        </>
    )
}