import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {forbiddenWrapper, handleSetLastPathForRedirectAfterWSSelect, reset403} from "../../store/slice";
import {useEffect, useState} from "react";
import {GetSmallOrgsAndWorkspacesForSelect} from "../../store/actions";
import {ForbiddenWrapperOrganization, TSmallWorkspace} from "../../types";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {SAVE_WS_PREFIX,} from "../../constants";
import {UseManageWorkspacesAndOrganizations} from "../../../cookies/hooks/useManageWorkspacesAndOrganizations";
import {Logging} from "../../../../../newShared/utils/logs/log";
import {useLocation} from "react-router";
import {isFutureDate} from "../../../../../newShared/utils/dateTools";

export const useForbiddenWrapper = ({path}: {path: string}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const {isLoading, organizations, is403, message} = useSelector(forbiddenWrapper);
    const {workspaceId, organizationId, setCurrentWorkspaceAndOrganization, subscription, user, eraseSelectedWorkspaceAndOrgInCookiesAndStore} = UseManageWorkspacesAndOrganizations();

    //when page inits it can be shown throw forbidden wrapper because loading still not started and cookies already there - and it thinks that loading is finished and orgs fetched but its not
    //making this true when starting fetching or if already found organizations after rerender
    //used to prevent res.toJson() is not a func error from controller.signal provided to api action (it fires on first init and page rerenders after it), so here we are)
    const [alreadyFetched, setAlreadyFetched] = useState<boolean>(false);
    const [isLoadingSelect, setIsLoadingSelect] = useState<boolean>(false);
    const [selectingWsId, setSelectedWsId] = useState<string | null>(null);

    const handleChooseWsFromLocalStorage = async(orgs: ForbiddenWrapperOrganization[]): Promise<boolean> => {
        Logging.log(`handleChooseWsFromLocalStorage: ${orgs.length} orgs`);
        if(user){
            // console.log(`handleChooseWsFromLocalStorage: user found`);
            const data = localStorage.getItem(`${SAVE_WS_PREFIX}${user.publicId}`);
            if(data){
                Logging.log(`handleChooseWsFromLocalStorage: data found`);
                const parsed: {workspaceId: string, organizationId: string} = JSON.parse(data);
                const currentOrg = orgs.find(e => e.id === parsed?.organizationId);
                if(parsed && parsed.workspaceId && parsed.organizationId &&
                    parsed.workspaceId.length > 0 && parsed.organizationId.length > 0 &&
                    currentOrg && currentOrg.workspaces.some(e => e.id === parsed.workspaceId) &&
                    !currentOrg.blocked && isFutureDate(currentOrg.subscriptionExpiry)
                ){
                    Logging.log('handleChooseWsFromLocalStorage: found working data - orgId and wsId is valid (length > 0)');
                    Logging.log(`handleChooseWsFromLocalStorage: parsed && parsed.workspaceId && parsed.organizationId found - organizationId: ${organizationId}`);
                    const org = orgs.find(e => e.id === parsed.organizationId);
                    Logging.log(`handleChooseWsFromLocalStorage: org found: ${JSON.stringify(org)}`);
                    const ws = (org?.workspaces ?? []).find(e => e.id === parsed.workspaceId);
                    Logging.log(`handleChooseWsFromLocalStorage: ws found: ${JSON.stringify(ws)}`);
                    if(org && ws){
                        Logging.log(`CHOOSE WS FROM LOCALSTORAGE`);
                        setIsLoadingSelect(true);
                        setSelectedWsId(ws.id);
                        // const path = handleGetLastPath(ws.id); //must be here!!! before generating token
                        // const path = '/' + (history.location.pathname.split('/')[1] || '')

                        await setCurrentWorkspaceAndOrganization(ws, org)
                        setIsLoadingSelect(false);
                        setSelectedWsId(null);
                        // history.push(path);
                        return true;
                    }
                }else{
                    Logging.log(`handleChooseWsFromLocalStorage: localStorage data is invalid`);
                    //invalid data in localStorage
                }
            }else{
                Logging.log(`handleChooseWsFromLocalStorage: no data was found in localStorage`);
                //no localStorage data was found
            }
        }
        // console.log(`handleChooseWsFromLocalStorage`);
        return false;
    }

    // const handleGetLastPath = (wsId: string): string => {
    //     // return PATH_LOCAL_COMPLIANCE_FRAMEWORKS;
    //
    //     if(user){
    //         // console.log(`handleGetLastPath: user found; searching path by key ${`${SAVE_PATH_PREFIX}${user.publicId}#${wsId}`}`)
    //         const nextPath = localStorage.getItem(`${SAVE_NEXT_PATH_PREFIX}${user.publicId}#${wsId}`);
    //         if (nextPath) localStorage.removeItem(`${SAVE_NEXT_PATH_PREFIX}${user.publicId}#${wsId}`)
    //
    //         const res = nextPath || localStorage.getItem(`${SAVE_PATH_PREFIX}${user.publicId}#${wsId}`);
    //         if(res){
    //             // console.log(`handleGetLastPath: found path`)
    //             return res;
    //         }else{
    //             // console.log(`handleGetLastPath: patht not found`)
    //             return '';
    //         }
    //     }else{
    //         // console.log(`handleGetLastPath: patht not found`)
    //         return '';
    //     }
    // }

    useEffect(() => {
        dispatch(reset403());
        //eslint-disable-next-line
    }, [pathname]);

    useEffect(() => {
        Logging.log('-----------------useForbiddenWrapper[]');
        //if on page 1 got 403 - any another page will show 403 error since is403 is true
        //erasing on every init to prevent
        dispatch(reset403());
        //setting path to redirect when ws will be selected in dialog
        dispatch(handleSetLastPathForRedirectAfterWSSelect(path));
        //saving current path in localStorage
        if(!organizations.length){
            Logging.log('-----------------useForbiddenWrapper[] no orgs - fetching');
            dispatch(GetSmallOrgsAndWorkspacesForSelect({}));
            setAlreadyFetched(true);
        }else{
            Logging.log('-----------------useForbiddenWrapper[] orgs found');
            setAlreadyFetched(true);
            if(!organizationId || !workspaceId){
                Logging.log('-----------------useForbiddenWrapper[] no workspace and organizationId in cookies - going to select from localStorage');
                //after logout - cookies are clear and there have to be no organization and no workspace IDS
                //after login we have to choose workspace and organization from localStorage
                handleChooseWsFromLocalStorage(organizations);
            }else{
                const currentOrg = organizations.find(e => e.id === organizationId);
                if(!(workspaceId && organizationId &&
                    workspaceId.length > 0 && organizationId.length > 0 &&
                    currentOrg && currentOrg.workspaces.some(e => e.id === workspaceId) &&
                    !currentOrg.blocked && isFutureDate(currentOrg.subscriptionExpiry)
                )){
                    eraseSelectedWorkspaceAndOrgInCookiesAndStore();
                    Logging.log('-----------------useForbiddenWrapper[] organizationId or workspaceId was presented in cookies - NO MATCH FOUND - null the cookies and store');
                }else{
                    Logging.log('-----------------useForbiddenWrapper[] organizationId or workspaceId was presented in cookies -MATCH FOUND- doing nothing');
                }
                //cookies with organizationId or workspaceId were found in cookies

            }
            // (!organizationId || !workspaceId) && handleChooseWsFromLocalStorage(organizations);
            const org = organizations[0];
            setFilteredWorkspaces(org.workspaces);
            if(org){
                setSelect({organization: org, workspace: null});
            }
        }

        return function cleanUp(){
            setSelect({organization: null, workspace: null});
            usersForm.setForm({search: ''});
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        Logging.log('-----------------useForbiddenWrapper[isLoading]');
        if(!isLoading && alreadyFetched){
            // (!organizationId || !workspaceId) && handleChooseWsFromLocalStorage(organizations);
            if(!organizationId || !workspaceId){
                Logging.log('-----------------useForbiddenWrapper[isLoading] no workspace and organizationId in cookies - going to select from localStorage');
                //after logout - cookies are clear and there have to be no organization and no workspace IDS
                //after login we have to choose workspace and organization from localStorage
                handleChooseWsFromLocalStorage(organizations);
            }else{
                //cookies with organizationId or workspaceId were found in cookies
                const currentOrg = organizations.find(e => e.id === organizationId);
                if(!(
                    workspaceId && organizationId &&
                    workspaceId.length > 0 && organizationId.length > 0 &&
                    currentOrg && currentOrg.workspaces.some(e => e.id === workspaceId) &&
                    !currentOrg.blocked && isFutureDate(currentOrg.subscriptionExpiry)
                )){
                    eraseSelectedWorkspaceAndOrgInCookiesAndStore();
                    Logging.log('-----------------useForbiddenWrapper[isLoading] organizationId or workspaceId was presented in cookies - NO MATCH FOUND - null the cookies');
                }else{

                    Logging.log('-----------------useForbiddenWrapper[isLoading] organizationId or workspaceId was presented in cookies  -MATCH FOUND- doing nothing');
                }
            }
            const org = organizations[0];
            if(org){
                const org = organizations[0];
                setFilteredWorkspaces(org.workspaces);
                if(org){
                    setSelect({organization: org, workspace: null});
                }
            }
        }else{
            Logging.log('-----------------useForbiddenWrapper[isLoading] if(!isLoading && organizations.length > 0) === false');
        }
        //eslint-disable-next-line
    }, [isLoading]);

    const handleSubmit = async(ws: TSmallWorkspace) => {
        if(select.organization){
            try{
                setIsLoadingSelect(true);
                setSelectedWsId(ws.id);
                await setCurrentWorkspaceAndOrganization(ws, select.organization);
                setIsLoadingSelect(false);
                setSelectedWsId(null);
                history.push(path);
            }catch (ex){
                setIsLoadingSelect(false);
                setSelectedWsId(null);
            }
        }
    }

    const [select, setSelect] = useState<{organization: ForbiddenWrapperOrganization | null, workspace: TSmallWorkspace | null}>({organization: null, workspace: null});

    const handleSelectWorkspace = (workspace: TSmallWorkspace) => {
        setSelect({...select, workspace});
    }

    const handleSelectOrganization = (organization: ForbiddenWrapperOrganization) => {
        setSelect({...select, organization});
        setFilteredWorkspaces(organization.workspaces);
        usersForm.setForm({search: ''});
    }

    //
    const usersForm = useForm<{search: string}>({search: ''});
    const [filteredWorkspaces, setFilteredWorkspaces] = useState<TSmallWorkspace[]>([]);

    const handleCleanSearch = () => {
        if(select.organization){
            usersForm.setForm({search: ''});
            setFilteredWorkspaces(select.organization?.workspaces);
        }
    }

    const isOk = select.organization !== null && select.workspace !== null && workspaceId !== select.workspace.id

    const handleGoBack = () => {
        // console.log(`-------handleGoBack------`)
        history.goBack()
        dispatch(reset403());
    }


    const handleChangeLocal = (e: any) => {
        const search = e.target.value.trim().toLowerCase();
        if(select.organization){
            setFilteredWorkspaces(select.organization?.workspaces.filter(e => e.name.trim().toLowerCase().includes(search)));
        }
        usersForm.handleChange(e);
    }

    return{
        isWorkspaceSelected: workspaceId !== null && organizationId !== null,
        isLoading,
        organizations,
        isLoadingSelectWorkspace: isLoadingSelect,
        handleSubmit,
        selectedWorkspace: select.workspace,
        selectedOrganization: select.organization,
        handleSelectOrganization,
        handleSelectWorkspace,
        workspaces: filteredWorkspaces,
        handleCleanSearch,
        search: usersForm.form.search,
        handleChange: handleChangeLocal,
        isOk,
        is403,
        // handleGoToCreateOrg,
        subscription,
        message,
        handleGoBack,
        selectingWsId,
        alreadyFetched,
        // isAvailableEnterprise
    }
}