import {Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import React, {FC, useEffect} from "react";
import {useHrCreateEditRequestDialog} from "../../../hooks/dialogs/useHrCreateEditRequestDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useHrProfile} from "../../../hooks/useHrProfile";
import {PortalHrEditRequestInputDto, RequestType} from "../../../../../../newShared/GQLTypes";
import {THrProfile} from "../../../types";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {CategoryTitleCard} from "../../categoryTitleCard";
import {Home, Person, Phone} from "@mui/icons-material";
import colors from "../../../../../../newShared/theme/colors";
import {DEFAULT_DATE_FORMAT, parseDateAuto,} from "../../../../../../newShared/utils/dateTools";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

type THrProfileEditData = Omit<Partial<THrProfile>, 'employeeStatus'>;

export const HrCreateEditRequestDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathHrProfile'});
    const {isOpen, isLoading, handleCloseEditRequest, handleLoadOptions, countryOptions, isCountryOptionsLoading} = useHrCreateEditRequestDialog();

    const {employee, createEditRequest} = useHrProfile();

    const {form, setForm, handleChange} = useForm<THrProfileEditData>({});
    const editProfile: PortalHrEditRequestInputDto["data"]["editProfile"] = Object.entries(form).reduce((prev, [key, value]) => {
        if (employee) {
            if (key === 'birthDay') value !== employee[key as keyof THrProfileEditData] && prev.push({ fieldName: key,  fieldValue: value ? parseDateAuto(value, false) + 'T00:00:00' : ''});
            else value?.trim() !== employee[key as keyof THrProfileEditData]?.trim() && prev.push({ fieldName: key,  fieldValue: value || ''});
        }

        return prev;
    }, [] as PortalHrEditRequestInputDto["data"]["editProfile"]);

    const isOk =
        form.firstName?.trim().length &&
        form.lastName?.trim().length &&
        form.country?.trim().length &&
        form.city?.trim().length &&
        form.workMobilePhone?.trim().length &&
        editProfile.length
    ;
    const handleConfirm = () => {
        if (!isOk) return;

        createEditRequest({requestType: RequestType.EditProfile, data: {editProfile}})
    };

    useEffect(() => {
        if (isOpen && employee) {
            const tmpEmployee = JSON.parse(JSON.stringify(employee));
            delete tmpEmployee.employeeStatus;

            setForm(tmpEmployee);

            handleLoadOptions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, employee]);

    return employee && (
        <Dialog open={isOpen} onClose={handleCloseEditRequest}>
            <DialogTitle>
                {t('Edit request')}
            </DialogTitle>

            <DialogContent sx={{
                display: 'flex', flexDirection: 'column', gap: '16px',
            }}>
                <CategoryTitleCard title={t('Personal information')} icon={<Person sx={{color: colors.text.blue}}/>}
                                   fullChildren={(
                                       <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '14px 16px', mt:'10px'}}>
                                           <TextField
                                               value={form.firstName}
                                               onChange={handleChange}
                                               label={t('First name')}
                                               name={'firstName'}
                                               required
                                               sx={{flexBasis: 'calc(50% - 8px)'}}
                                               size={'small'}
                                           />

                                           <TextField
                                               value={form.middleName}
                                               onChange={handleChange}
                                               label={t('Middle name')}
                                               name={'middleName'}
                                               sx={{flexBasis: 'calc(50% - 8px)'}}
                                               size={'small'}
                                           />

                                           <TextField
                                               value={form.lastName}
                                               onChange={handleChange}
                                               label={t('Last name')}
                                               name={'lastName'}
                                               required
                                               sx={{flexBasis: 'calc(50% - 8px)'}}
                                               size={'small'}
                                           />

                                           <TextField
                                               value={form.nickname}
                                               onChange={handleChange}
                                               label={t('Nickname')}
                                               name={'nickname'}
                                               sx={{flexBasis: 'calc(50% - 8px)'}}
                                               size={'small'}
                                           />

                                           <DatePicker
                                               label={t('Birth date')}
                                               inputFormat={DEFAULT_DATE_FORMAT}
                                               value={form.birthDay}
                                               onChange={(birthDay) => birthDay && setForm({...form, birthDay})}
                                               renderInput={(params) => (
                                                   <TextField {...params}
                                                              sx={{flexBasis: 'calc(50% - 8px)'}}
                                                              size={'small'} />
                                               )}
                                           />
                                       </Box>
                                   )}
                />

                <CategoryTitleCard title={t('Address')} icon={<Home sx={{color: colors.text.blue}}/>}
                                   fullChildren={(
                                       <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '14px 16px', mt:'10px'}}>
                                           <Autocomplete
                                               loading={isCountryOptionsLoading}
                                               options={countryOptions}
                                               value={form.country}
                                               onChange={(e, country ) => country && setForm({...form, country})}
                                               sx={{flexBasis: 'calc(50% - 8px)'}}
                                               renderInput={(params) => <TextField {...params} label={t('Country')} size={'small'} required/>}
                                           />

                                           <TextField
                                               value={form.city}
                                               onChange={handleChange}
                                               label={t('City')}
                                               name={'city'}
                                               required
                                               sx={{flexBasis: 'calc(50% - 8px)'}}
                                               size={'small'}
                                           />

                                           <TextField
                                               value={form.street}
                                               onChange={handleChange}
                                               label={t('Street')}
                                               name={'street'}
                                               sx={{flexBasis: 'calc(50% - 8px)'}}
                                               size={'small'}
                                           />

                                           <TextField
                                               value={form.zip}
                                               onChange={handleChange}
                                               label={t('ZIP code')}
                                               name={'zip'}
                                               sx={{flexBasis: 'calc(50% - 8px)'}}
                                               size={'small'}
                                           />
                                       </Box>
                                   )}
                />

                <CategoryTitleCard title={t('Contacts')} icon={<Phone sx={{color: colors.text.blue}}/>}
                                   fullChildren={(
                                       <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '14px 16px', mt:'10px'}}>
                                           <TextField
                                               value={form.workMobilePhone}
                                               onChange={handleChange}
                                               label={t('Work mobile phone')}
                                               name={'workMobilePhone'}
                                               required
                                               sx={{flexBasis: 'calc(50% - 8px)'}}
                                               size={'small'}
                                           />
                                       </Box>
                                   )}
                />

            </DialogContent>

            <DialogActions>
                <Button variant={"text"} onClick={handleCloseEditRequest}>{tCommon('Cancel')}</Button>
                <LoadingButton loading={isLoading} onClick={handleConfirm} disabled={!isOk}>
                    {tCommon('Submit')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
