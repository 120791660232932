import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideAddOrEditJustificationDialog, loadings, selectedStage} from "../../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useEffect} from "react";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import { DialogContentWrapper } from "../../../styled";
import {UpdateCampaignReviewRows} from "../../../../store/actions";

export const ViewOrEditJustificationDialog = () => {
    const dispatch = useDispatch();
    const {revDir} = useMainTranslation();

    const {
        isOpen,
        isManager,
        app,
        account,
        stageId,
        actorEmail
    } = useSelector(dialogs).addOrEditJustificationDialog;
    const stage = useSelector(selectedStage);
    const isLoading = useSelector(loadings).campaignReviewUpdatingRowsWithIds.some(e => e === account?.id);

    useEffect(() => {
        if(isOpen && account){
            if(isManager){
                setForm({justification: account.commentManager ?? ''});
            }else{
                setForm({justification: account.commentVendor ?? ''});
            }
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideAddOrEditJustificationDialog());
    }

    const handleSubmit = () => {
        (stage && account && stageId && actorEmail !== null && app) && dispatch(UpdateCampaignReviewRows({
            data: {
                apps: [
                    {...app, data: [{
                        ...account,
                            commentManager: isManager ? form.justification : account.commentManager,
                            commentVendor: !isManager ? form.justification : account.commentVendor
                        }]}
                ],
                stageId,
                executorId: stage.stage.executorId
            }
        }));
    }

    const {form, handleChange, setForm} = useForm<{justification: string}>({justification: ''});

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{(`Justification for ${account?.account} account`)}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper>
                    <TextField
                        size={'small'}
                        sx={{margin: '16px 0 0 0'}}
                        value={form.justification}
                        name={'justification'}
                        onChange={handleChange}
                        label={'Justification'}
                        required
                    />
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {('Save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}