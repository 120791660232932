import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import {dialogs, hideShowPermissions} from "../../../../store/slice";
import {useDispatch, useSelector} from "react-redux";

export const PreviewRoleDialog = () => {
    //root
    const dispatch = useDispatch();
    const {isOpen, rolePermissions, roleName} = useSelector(dialogs).showPermissions;

    //actions
    const handleClose = () => {
        dispatch(hideShowPermissions());
    }

    return(
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{`Role ${roleName} permissions`}</DialogTitle>

            <DialogContent>
                {rolePermissions.slice().sort().map(e => <Typography key={e} fontSize={'12px'} fontWeight={400}>{e}</Typography>)}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}