import {Flex} from "../../../../../newShared/components/Layouts";
import {PageInfo, Stage} from "../../types";
import {Typo} from "../../../../../newShared/components/Typography";
import {getStatus, shortString} from "../../helpers";
import {TablePagination} from "@mui/material";
import {GetCampaignReviewStageById, GetStages} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {loadings, selectedStage, selectStage} from "../../store/slice";
import CachedIcon from '@mui/icons-material/Cached';
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DOCUMENT_AUTOMATION_TYPE} from "../../constants";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";


export const SelectionBar = ({stages, pageInfo}: { stages: Stage[], pageInfo: PageInfo }) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'workerSpace'});

    const dispatch = useDispatch();
    const selected = useSelector(selectedStage);
    const {list} = useSelector(loadings);


    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        dispatch(GetStages({page: newPage, count: 10}));
    };

    const onClick = (stage: Stage) => {
        if(stage.stage.automationType !== DOCUMENT_AUTOMATION_TYPE){
            dispatch(GetCampaignReviewStageById({stageId: stage.stage.id, executorId: stage.stage.executorId}));
        }
        dispatch(selectStage(stage));
    }

    const reload = () => dispatch(GetStages({page: pageInfo.page, count: 10}));

    return(
        <Flex w={'25%'} direction={'column'} jc={'flex-start'} ai={'flex-end'} >
            <Flex w={'100%'} ai={'center'} jc={'space-between'}>
                <CachedIcon onClick={reload} style={{cursor: 'pointer', margin: !revDir ? '0 0 0 10px' : '0 10px 0 0'}}/>
                <TablePagination
                    sx={{minHeight: '50px', overflow: 'hidden'}}
                    component="div"
                    count={pageInfo.total}
                    page={pageInfo.page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageInfo.count}
                    rowsPerPageOptions={[]}
                />
            </Flex>

            <Flex disableScrollBar={true} p={'5px 5px 0 5px'} w={'100%'} direction={'column'} jc={'flex-start'} ai={'flex-end'} overflow={'auto'} >
                {list && <Flex
                    w={'100%'}
                    minw={'278px'}
                    m={'0 0 12px 0'}
                    h={'90px'}
                    ai={'center'}
                    jc={'center'}
                    br={'12px'}
                    border={'1px dotted #878BA1'}
                    box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}>
                    <Typo fontSize={'14px'} fontWeight={300}>{('LOADING')}</Typo>
                </Flex>}

                {(!list && !stages.length) ?
                    <Flex
                        w={'100%'}
                        minw={'278px'}
                        h={'90px'}
                        ai={'center'}
                        jc={'center'}
                        br={'12px'}
                        border={'1px dotted #878BA1'}
                        box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}>
                        <Typo fontSize={'14px'} fontWeight={300}>{t('NO SHARED DOCUMENTS YET')}</Typo>
                    </Flex>
                    :
                    stages.map((e: Stage) => {
                        return <Flex
                            key={e.stage.id}
                            w={'100%'}
                            minh={'90px'}
                            minw={'278px'}
                            ai={'center'}
                            background={'white'}
                            jc={'space-between'}
                            direction={'column'}
                            br={'12px'}
                            border={(selected && selected.stage.id === e.stage.id) ? '1px solid #14CBFD' : ''}
                            p={!revDir ? '12px 25px 16px 27px' : '12px 27px 16px 25px'}
                            m={'0 0 12px 0'}
                            onClick={() => onClick(e)}
                            box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'}>
                            <Flex w={'100%'} ai={'center'} jc={'space-between'} wrap={'wrap'}>
                                {(e.stage.automationType === DOCUMENT_AUTOMATION_TYPE) ?
                                    <Typo fontSize={'14px'} fontWeight={300}>{shortString(e.stage.documentName, 30)}</Typo>
                                    :
                                    <Typo fontSize={'14px'} fontWeight={300}>{shortString(e.stage.name, 30)}</Typo>
                                }
                                {getStatus(e.stage.actor.status)}
                                {/*<Typo fontSize={'14px'} fontWeight={300}>{e.stage.executorId}</Typo>*/}
                            </Flex>

                            <Flex w={'100%'} ai={'center'} jc={'space-between'} wrap={'wrap'}>
                                <Flex>
                                    <Typo fontSize={'14px'} fontWeight={400} color={'#878BA1'} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'}>{t('Expires')}: </Typo>
                                    <Typo fontSize={'16px'} fontWeight={300} color={'#5D5D5D'} textOverflow={'clip'}>{parseDateAuto(e.stage.expirationDate)}</Typo>
                                </Flex>

                                <Typo fontSize={'16px'} fontWeight={300} color={'#FFA000'}>{(e.stage.type.toLowerCase())}</Typo>
                            </Flex>
                        </Flex>
                    })
                }
            </Flex>
        </Flex>
    )
}
