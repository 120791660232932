import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {
    assetsSelector,
    eraseAssetsFilterOptions,
    eraseAssetsList,
    loadingsSelector,
    replaceAssetsMinMaxLoadedPage,
    settingsSelector
} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {ASSETS_EXACT_PATH, ASSETS_LIST_PATH} from "../../../../../newShared/constants";
import {PortalPhysicalAssetModel} from "../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {
    portalAssetsGetPhysicalAssetsSettingsForFiltersAction,
    portalAssetsGetPhysicalAssetsWithFilterPaginationAction,
    portalGetAssetConfigsAction,
} from "../../store/actions";
import {useEffect} from "react";


export const useAssetListPage = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathAssets.pathAssetsList'});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {assets, pageInfo, minMaxLoadedPage, filterOptions} = useSelector(assetsSelector);
    const {assetList: isLoadingList} = useSelector(loadingsSelector);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;
    const {manufacture, model, category} = useSelector(settingsSelector);

    useSetBreadcrumbs([
        {
            title: tMenu('Assets'),
            path: ASSETS_LIST_PATH
        },
        {
            title: tMenu('My assets'),
            path: ASSETS_LIST_PATH
        },
    ]);

    useEffect(() => {
        if (!manufacture || !category || !model){
            dispatch(portalGetAssetConfigsAction({data: {workspaceId: ''}}));
        }
        //eslint-disable-next-line
    }, [])


    useEffect(() => {
        !filterOptions && dispatch(portalAssetsGetPhysicalAssetsSettingsForFiltersAction({data: {workspaceId: ''}}));

        return () => {
            dispatch(eraseAssetsFilterOptions());
        }

        //eslint-disable-next-line
    }, []);

    const handleOpenAssetView = (selected: PortalPhysicalAssetModel) => {
        selected && history.push(`${ASSETS_EXACT_PATH.replace(':assetId', selected?.id)}${location.search}`);
        // dispatch(openAssetView(selected.id));
    }

    const modelConfig: keyConfig<string> = {
        type: 'filter',
        key: 'model',
        name: t('Model'),
        options: filterOptions?.model ?? [],
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    const categoryConfig: keyConfig<string> = {
        type: 'filter',
        key: 'category',
        name: t('Category'),
        options: filterOptions?.category ?? [],
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    const manufactureConfig: keyConfig<string> = {
        type: 'filter',
        key: 'manufacture',
        name: t('Manufacturer'),
        options: filterOptions?.manufacture ?? [],
        isOptionEqualToSearch: (a, search) => a.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    return {
        assets,
        isLoadingList,

        actions: {
            handleOpenAssetView,
        },

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceAssetsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseAssetsList()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, nameLike: string) => {
                    dispatch(portalAssetsGetPhysicalAssetsWithFilterPaginationAction({data: {pageRequest: {page, count}, filter: {...filters, nameLike}}}));
                },
            },
            filters: {
                configs: [
                    modelConfig,
                    categoryConfig,
                    manufactureConfig,
                ]
            }
        }

    }
}
