import {FC} from "react";
import * as S from "../../components/styled";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {PortalHrTimeOffRequestModel} from "../../../../../newShared/GQLTypes";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {getTimeOffStatusChip} from "../../helpers";
import {RequestErrorDialog} from "../../components/dialogs/RequestErrorDialog";
import {CancelTimeOffDialog} from "../../components/dialogs/cancelTimeOff";
import {useHrTimeOffManagement} from "../../hooks/useHrTimeOffManagement";
import {IncomingTimeOffRequestExactView} from "../../components/dialogs/incomingTimeOffRequestExactView";
import {TimeOffHistoryDialog} from "../../components/dialogs/timeOffHistory";

export const HrTimeOffManagement: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathTimeOff'});
    const {
        requests,
        genericTable,
        handleOpenTimeOff,
    } = useHrTimeOffManagement();

    const {isMobile} = useMedia();

    return (
        <S.PageWrapper>
            <PageTitleAndActions
                title={t('Time off management')}
                actions={[]}
            />

            <GenericTable<PortalHrTimeOffRequestModel>
                id={'MyHrIncomingTimoOff'}
                columnsConfig={{totalName: 'Total requests', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: requests,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenTimeOff,
                    // rowActionsConfig: [],

                    columnsConfigs: [
                        {key: "id", name: t('ID'), default: true, },
                        {key: "requestor", name: t('Requestor'), default: true, valueGetter: (row) => row.requestor.employeeFullName ?? row.requestor.collaboratorFullName },
                        {key: 'startDate', name: t('Start date'), default: true, valueGetter: (row) => parseDateAuto(row.startDate, undefined, undefined, undefined, '-')},
                        {key: 'endDate', name: t('End date'), default: true, valueGetter: (row) => parseDateAuto(row.endDate, undefined, undefined, undefined, '-')},
                        {key: "type", name: t('Time off type'), default: true, },
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getTimeOffStatusChip(row.status)},
                        {key: 'requestDate', name: t('Request date'), default: true, valueGetter: (row) => parseDateAuto(row.requestDate, undefined, undefined, undefined, '-')},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'timeOffIdLike',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('You do not have any time off requests to approve yet'), secondaryText: ''}}}
            />

            <RequestErrorDialog />
            <CancelTimeOffDialog />
            <IncomingTimeOffRequestExactView/>
            <TimeOffHistoryDialog />

        </S.PageWrapper>
    )
}
