import {Flex} from "../../../../../newShared/components/Layouts";
import {Stage} from "../../types";
import {Typo} from "../../../../../newShared/components/Typography";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UploadFile} from "../../store/actions";
import {CustomButton} from "../../../../../newShared/components/Buttons";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {loadings} from "../../store/slice";

const SignatureCanvas = require('react-signature-canvas-react17-compatible').default;

export const SignConfirm = ({stage, isRed}: {stage: Stage, isRed: boolean}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'workerSpace'});

    const {approveDocSignStage} = useSelector(loadings);

    const dispatch = useDispatch();
    const [canvas, setCanvas] = useState<any>(null);

    useEffect(() => {
        canvas && canvas.clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const approve = () => {
        if(canvas){
            if(!canvas.isEmpty()){
                const base64 = canvas.getCanvas().toDataURL();
                dispatch(UploadFile({file: base64, isSignature: true, stageId: stage.stage.id}));
            }
        }
    }

    return(
        <>
            <Flex w={'1000px'} m={'28px 0 0 0'} box_shadow={'0px 0px 5px 0px rgba(0, 0, 0, 0.15)'} br={'12px'} overflow={'hidden'}>
                <Flex w={'1000px'} jc={'center'}>
                    <SignatureCanvas
                        ref={(ref: any) => setCanvas(ref)}
                        penColor='black'
                        backgroundColor={'white'}
                        canvasProps={{width: 1000, height: 120, className: 'sigCanvas'}}
                    />
                </Flex>
            </Flex>

            <Flex jc={'space-between'} ai={'flex-start'} w={'calc(100% + 15px)'} m={!revDir ? '50px 0 0 -15px' : '50px -15px 0 0'}>
                <Flex w={'50%'} ai={'center'}>
                    <Typo
                        fontSize={'18px'}
                        fontWeight={300}
                        margin={!revDir ? '0 0 0 15px' : '0 15px 0 0'}>
                        {t('By signing above, I confirm that I read and approve this document.')}
                    </Typo>
                </Flex>

                <Flex ai={'center'}>
                    <CustomButton
                        type={'submit'}
                        onClick={() => canvas && canvas.clear()}
                        // active={agree}
                        // disabled={!agree}
                        variant={'contained'}
                        sx={{
                            margin: !revDir ? '22px 16px 0 0' : '22px 0 0 16px'
                        }}
                    >
                        {t('CLEAR')}
                    </CustomButton>

                    <LoadingButton
                        variant={'contained'}
                        loading={approveDocSignStage}
                        onClick={approve}
                        sx={{
                            marginTop: '22px'
                        }}
                    >
                        {t('SAVE')}
                    </LoadingButton>
                </Flex>
                {/*<ButtonWithBackround width={'108px'} height={'35px'} color={'gray'} fontColor={'white'} text={'CLEAR'} onClick={() => canvas && canvas.clear()}/>*/}
                {/*<ButtonWithBackround width={'108px'} height={'35px'} color={colors.primary.blue} fontColor={'white'} text={'SAVE'} onClick={approve}/>*/}
            </Flex>
        </>
    )
}