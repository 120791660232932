import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {
    PortalHrApproveTimeOffRequestMutation,
    PortalHrApproveTimeOffRequestMutationVariables,
    PortalHrCancelMyTimeOffRequestMutation,
    PortalHrCancelMyTimeOffRequestMutationVariables,
    PortalHrCancelRequestMutation,
    PortalHrCancelRequestMutationVariables,
    PortalHrCreateEditRequestMutation,
    PortalHrCreateEditRequestMutationVariables,
    PortalHrCreateIssueMutation,
    PortalHrCreateIssueMutationVariables,
    PortalHrCreateTimeOffRequestMutation,
    PortalHrCreateTimeOffRequestMutationVariables,
    PortalHrGetAndChangeStepStatusTimeOffRequestQuery,
    PortalHrGetAndChangeStepStatusTimeOffRequestQueryVariables,
    PortalHrGetIssueByIdQuery,
    PortalHrGetIssueByIdQueryVariables,
    PortalHrGetIssuesWithFilterPaginationQuery,
    PortalHrGetIssuesWithFilterPaginationQueryVariables,
    PortalHrGetMyTimeOffRequestsWithFilterPaginationQuery,
    PortalHrGetMyTimeOffRequestsWithFilterPaginationQueryVariables,
    PortalHrGetOrgTreeQuery,
    PortalHrGetOrgTreeQueryVariables,
    PortalHrGetProfileJobHistoryQuery,
    PortalHrGetProfileJobHistoryQueryVariables,
    PortalHrGetProfileQuery,
    PortalHrGetProfileQueryVariables,
    PortalHrGetRequestByIdQuery,
    PortalHrGetRequestByIdQueryVariables,
    PortalHrGetRequestsWithFilterPaginationQuery,
    PortalHrGetRequestsWithFilterPaginationQueryVariables,
    PortalHrGetSettingsCountriesQuery,
    PortalHrGetSettingsCountriesQueryVariables,
    PortalHrGetTimeOffRequestByIdQuery,
    PortalHrGetTimeOffRequestByIdQueryVariables,
    PortalHrGetTimeOffRequestsSettingsQuery,
    PortalHrGetTimeOffRequestsSettingsQueryVariables,
    PortalHrGetTimeOffRequestsWithFilterPaginationQuery,
    PortalHrGetTimeOffRequestsWithFilterPaginationQueryVariables,
    PortalHrRejectTimeOffRequestMutation,
    PortalHrRejectTimeOffRequestMutationVariables,
    PortalHrUpdateMyTimeOffRequestMutation,
    PortalHrUpdateMyTimeOffRequestMutationVariables
} from "../../../../newShared/GQLTypes";
import {
    portalHrApproveTimeOffRequest,
    portalHrCancelMyTimeOffRequest,
    portalHrCancelRequest,
    portalHrCreateEditRequest,
    portalHrCreateIssue,
    portalHrCreateTimeOffRequest,
    portalHrGetAndChangeStepStatusTimeOffRequest,
    portalHrGetIssueById,
    portalHrGetIssuesWithFilterPagination,
    portalHrGetMyTimeOffRequestsWithFilterPagination,
    portalHrGetOrgTree,
    portalHrGetProfile,
    portalHrGetProfileJobHistory,
    portalHrGetRequestById,
    portalHrGetRequestsWithFilterPagination,
    portalHrGetSettingsCountries,
    portalHrGetTimeOffRequestById,
    portalHrGetTimeOffRequestsSettings,
    portalHrGetTimeOffRequestsWithFilterPagination,
    portalHrRejectTimeOffRequest,
    portalHrUpdateMyTimeOffRequest
} from "../api/query";

export const portalHrGetProfileAction =
    createCommonAsyncThunk<PortalHrGetProfileQueryVariables, PortalHrGetProfileQuery["portalHrGetProfile"]>(
        'HR', 'portalHrGetProfile', true, false, portalHrGetProfile
    )

export const portalHrGetProfileJobHistoryAction =
    createCommonAsyncThunk<PortalHrGetProfileJobHistoryQueryVariables, PortalHrGetProfileJobHistoryQuery["portalHrGetProfileJobHistory"]>(
        'HR', 'portalHrGetProfileJobHistory', true, false, portalHrGetProfileJobHistory
    )

export const portalHrGetSettingsCountriesAction =
    createCommonAsyncThunk<PortalHrGetSettingsCountriesQueryVariables, PortalHrGetSettingsCountriesQuery["portalHrGetSettingsCountries"]>(
        'HR', 'portalHrGetSettingsCountries', true, false, portalHrGetSettingsCountries
    )

export const portalHrCreateEditRequestAction =
    createCommonAsyncThunk<PortalHrCreateEditRequestMutationVariables, PortalHrCreateEditRequestMutation["portalHrCreateEditRequest"]>(
        'HR', 'portalHrCreateEditRequest', true, false, portalHrCreateEditRequest
    )

export const getPortalHrRequestsWithFilterPaginationAction =
    createCommonAsyncThunk<PortalHrGetRequestsWithFilterPaginationQueryVariables, PortalHrGetRequestsWithFilterPaginationQuery["portalHrGetRequestsWithFilterPagination"]>(
        'HR', 'portalHrGetRequestsWithFilterPagination', true, false, portalHrGetRequestsWithFilterPagination
    )

export const getPortalHrRequestByIdAction =
    createCommonAsyncThunk<PortalHrGetRequestByIdQueryVariables, PortalHrGetRequestByIdQuery["portalHrGetRequestById"]>(
        'HR', 'portalHrGetRequestById', true, false, portalHrGetRequestById
    )

export const portalHrCancelRequestAction =
    createCommonAsyncThunk<PortalHrCancelRequestMutationVariables, PortalHrCancelRequestMutation["portalHrCancelRequest"]>(
        'HR', 'portalHrCancelRequest', true, false, portalHrCancelRequest
    )

export const portalHrGetOrgTreeAction =
    createCommonAsyncThunk<PortalHrGetOrgTreeQueryVariables, PortalHrGetOrgTreeQuery["portalHrGetOrgTree"]>(
        'HR', 'portalHrGetOrgTree', true, false, portalHrGetOrgTree
    )

//Issues
export const portalHrGetIssuesWithFilterPaginationAction =
    createCommonAsyncThunk<PortalHrGetIssuesWithFilterPaginationQueryVariables, PortalHrGetIssuesWithFilterPaginationQuery["portalHrGetIssuesWithFilterPagination"]>(
        'HR', 'portalHrGetIssuesWithFilterPagination', true, false, portalHrGetIssuesWithFilterPagination
    )

export const portalHrCreateIssueAction =
    createCommonAsyncThunk<PortalHrCreateIssueMutationVariables, PortalHrCreateIssueMutation["portalHrCreateIssue"]>(
        'HR', 'portalHrCreateIssue', true, false, portalHrCreateIssue
    )

export const getPortalHrIssueByIdAction =
    createCommonAsyncThunk<PortalHrGetIssueByIdQueryVariables, PortalHrGetIssueByIdQuery["portalHrGetIssueById"]>(
        'HR', 'portalHrGetIssueById', true, false, portalHrGetIssueById
    )

//MyTimeOff
export const portalHrGetMyTimeOffRequestsWithFilterPaginationAction =
    createCommonAsyncThunk<PortalHrGetMyTimeOffRequestsWithFilterPaginationQueryVariables, PortalHrGetMyTimeOffRequestsWithFilterPaginationQuery["portalHrGetMyTimeOffRequestsWithFilterPagination"]>(
        'HR', 'portalHrGetMyTimeOffRequestsWithFilterPagination', true, false, portalHrGetMyTimeOffRequestsWithFilterPagination
    )

//TimeOff

export const portalHrGetTimeOffRequestsWithFilterPaginationAction =
    createCommonAsyncThunk<PortalHrGetTimeOffRequestsWithFilterPaginationQueryVariables, PortalHrGetTimeOffRequestsWithFilterPaginationQuery["portalHrGetTimeOffRequestsWithFilterPagination"]>(
        'HR', 'portalHrGetTimeOffRequestsWithFilterPagination', true, false, portalHrGetTimeOffRequestsWithFilterPagination
    )

export const portalHrGetTimeOffRequestByIdAction =
    createCommonAsyncThunk<PortalHrGetTimeOffRequestByIdQueryVariables, PortalHrGetTimeOffRequestByIdQuery['portalHrGetTimeOffRequestById']>(
        'HR', 'portalHrGetTimeOffRequestById', true, false, portalHrGetTimeOffRequestById
    )

export const portalHrGetTimeOffRequestsSettingsAction =
    createCommonAsyncThunk<PortalHrGetTimeOffRequestsSettingsQueryVariables, PortalHrGetTimeOffRequestsSettingsQuery["portalHrGetTimeOffRequestsSettings"]>(
        'HR', 'portalHrGetTimeOffRequestsSettings', true, false, portalHrGetTimeOffRequestsSettings
    )

export const portalHrCreateTimeOffRequestAction =
    createCommonAsyncThunk<PortalHrCreateTimeOffRequestMutationVariables, PortalHrCreateTimeOffRequestMutation["portalHrCreateTimeOffRequest"]>(
        'HR', 'portalHrCreateTimeOffRequest', true, false, portalHrCreateTimeOffRequest
    )

export const portalHrUpdateMyTimeOffRequestAction =
    createCommonAsyncThunk<PortalHrUpdateMyTimeOffRequestMutationVariables, PortalHrUpdateMyTimeOffRequestMutation["portalHrUpdateMyTimeOffRequest"]>(
        'HR', 'portalHrUpdateMyTimeOffRequest', true, false, portalHrUpdateMyTimeOffRequest
    )

export const portalHrCancelMyTimeOffRequestAction =
    createCommonAsyncThunk<PortalHrCancelMyTimeOffRequestMutationVariables, PortalHrCancelMyTimeOffRequestMutation["portalHrCancelMyTimeOffRequest"]>(
        'HR', 'portalHrCancelMyTimeOffRequest', true, false, portalHrCancelMyTimeOffRequest
    )

export const portalHrGetAndChangeStepStatusTimeOffRequestAction =
    createCommonAsyncThunk<PortalHrGetAndChangeStepStatusTimeOffRequestQueryVariables, PortalHrGetAndChangeStepStatusTimeOffRequestQuery["portalHrGetAndChangeStepStatusTimeOffRequest"]>(
        'HR', 'portalHrGetAndChangeStepStatusTimeOffRequest', true, false, portalHrGetAndChangeStepStatusTimeOffRequest
    )

export const portalHrApproveTimeOffRequestAction =
    createCommonAsyncThunk<PortalHrApproveTimeOffRequestMutationVariables, PortalHrApproveTimeOffRequestMutation["portalHrApproveTimeOffRequest"]>(
        'HR', 'portalHrApproveTimeOffRequest', true, false, portalHrApproveTimeOffRequest
    )

export const portalHrRejectTimeOffRequestAction =
    createCommonAsyncThunk<PortalHrRejectTimeOffRequestMutationVariables, PortalHrRejectTimeOffRequestMutation["portalHrRejectTimeOffRequest"]>(
        'HR', 'portalHrRejectTimeOffRequest', true, false, portalHrRejectTimeOffRequest
    )
