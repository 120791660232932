import * as yup from "yup";
import {useFormik} from "formik";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {PortalHrCreateTimeOffRequestInputDto, PortalHrTimeOffRequestModel} from "../../../../../../newShared/GQLTypes";
import {useDispatch, useSelector} from "react-redux";
import {
    hrDialogsSelector,
    hrIsLoadingSelector,
    hrMyTimeOffSelector,
    hrTimeOffSettingsSelector,
    setCreateTimeOffDialogAction
} from "../../../store/slice";
import {
    portalHrCreateTimeOffRequestAction,
    portalHrGetMyTimeOffRequestsWithFilterPaginationAction,
    portalHrUpdateMyTimeOffRequestAction
} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useEffect, useState} from "react";
import {uploadFile} from "../../../../workerSpace/api";
import {Moment} from "moment";
import {parseDateAuto, parseDateInDateAuto} from "../../../../../../newShared/utils/dateTools";
import moment from "moment/moment";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {GENERIC_FILTER_OPTIONS_DEFAULT_PAGING} from "../../../../../../newShared/components/genericFilter/constants";
import {uuid} from "../../../../../../newShared/utils";

type TFile = {base64?: string, fileId: string, fileName: string};

export const useCreateUpdateTimeOffDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathTimeOff'});
    const {setMessage} = useMessageDialog();

    const dispatch = useDispatch();

    const {createTimeOff: {isOpen, selected}} = useSelector(hrDialogsSelector);
    const {creatingTimeOff, timeOffSettings} = useSelector(hrIsLoadingSelector);
    const hrTimeOffSettings = useSelector(hrTimeOffSettingsSelector);

    const types = hrTimeOffSettings?.timeOffType || [];

    const handleOpen = (selected?: PortalHrTimeOffRequestModel) => {
        dispatch(setCreateTimeOffDialogAction({isOpen: true, selected}));
    }

    const handleClose = () => {
        dispatch(setCreateTimeOffDialogAction({clear: true}));
    }

    const {pageInfo: {page, count}} = useSelector(hrMyTimeOffSelector);
    const {currentFiltersForFetch, currentSearchForFetch, } = useGenericFiltersStorage();
    const refreshTable = () => {
        dispatch(portalHrGetMyTimeOffRequestsWithFilterPaginationAction({data: {pageRequest: {page, count: count || GENERIC_FILTER_OPTIONS_DEFAULT_PAGING}, filter: {...currentFiltersForFetch, timeOffIdLike: currentSearchForFetch}}, clean: true}));
    }

    const handleSendCreate = (data: PortalHrCreateTimeOffRequestInputDto) => {
        dispatch(portalHrCreateTimeOffRequestAction({
            data: {data},
            onSuccess: (request, response, addictiveData) => {
                setMessage({
                    title: tCommon('Completed successfully'),
                    message: t("Your time off request has been submitted."),
                });

                refreshTable();
            },
        }))
    }

    const handleSendUpdate = (data: PortalHrCreateTimeOffRequestInputDto) => {
        selected && dispatch(portalHrUpdateMyTimeOffRequestAction({
            data: {data, timeOffId: selected.id},
            onSuccess: (request, response, addictiveData) => {
                setMessage({
                    title: tCommon('Completed successfully'),
                    message: t("Your time off request has been updated."),
                });

                refreshTable();
            },
        }))
    }

    const validationSchema = yup.object({
        type: yup.string()
            .required(t('Type is required'))
            .oneOf(types)
            .typeError(t('Type is required')),
        startDate: yup.date()
            .required(t('Start date is required'))
            .test(
                'is-not-past-date',
                t('Start date cannot be in the past'),
                value => moment(value).isSameOrAfter(moment(new Date()).subtract({day: 1}))
            )
            .typeError(t('Start date is required')),
        endDate: yup.date()
            .required(t('End date is required'))
            .nullable()
            .min(
                yup.ref('startDate'),
                t('End date must be later or equal to Start date')
            )
            .typeError(t('End date is required')),
        comment: yup.string()
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols')),
        attachments: yup.array()
            .of(yup.object({
                base64: yup.string(),
                fileId: yup.string().required().notOneOf(['uploading']),
                fileName: yup.string().required(),
            }))
            .max(10, t('You can upload up to 10 files')),
    });

    const [files, setFiles] = useState<TFile[]>([]);
    const formik = useFormik<Omit<PortalHrCreateTimeOffRequestInputDto, 'attachments' | 'startDate' | 'endDate'> & {attachments: TFile[], startDate: Moment | null, endDate?: Moment | null}>({
        initialValues: {type: '', startDate: null, endDate: null, comment: '', attachments: []},
        validationSchema,
        onSubmit: (values) => {
            if (selected) {
                handleSendUpdate({
                    ...values,
                    attachments: values.attachments.map(({fileId, fileName}) => ({fileId, fileName})),
                    startDate: values.startDate?.toISOString() ? parseDateAuto(values.startDate?.toISOString(), false) + 'T00:00:00' : '',
                    endDate: values.endDate?.toISOString() ? parseDateAuto(values.endDate?.toISOString(), false) + 'T00:00:00' : undefined,
                })
            } else {
                handleSendCreate({
                    ...values,
                    attachments: values.attachments.map(({fileId, fileName}) => ({fileId, fileName})),
                    startDate: values.startDate?.toISOString() ? parseDateAuto(values.startDate?.toISOString(), false) + 'T00:00:00' : '',
                    endDate: values.endDate?.toISOString() ? parseDateAuto(values.endDate?.toISOString(), false) + 'T00:00:00' : undefined,
                })
            }
        },
        isInitialValid: !!selected,
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    const handleFileUpload = async (files: {base64: string, fileName: string}[]) => {
        const notUploadedFiles = files.map(e => ({...e, fileId: 'uploading' + uuid()}));
        setFiles(prev => [...prev, ...notUploadedFiles]);

        try {
            const uploaded = await Promise.all(notUploadedFiles.map(({base64, fileName, fileId}) => uploadFile(base64).then(e => ({oldFileId: fileId, fileId: e, fileName}))));
            setFiles(values => values.map(e => uploaded.find(({oldFileId}) => oldFileId === e.fileId) || e));
        } catch (e) {

        } finally {
            setFiles(prev => prev.filter(e => !notUploadedFiles.some(({fileId}) => e.fileId === fileId)));
        }
    }

    const handleDeleteFile = (fileId: string) => () => {
        setFiles(attachments => attachments.filter(({fileId: id}) => id !== fileId));
    };

    useEffect(() => {
        if (isOpen && selected) {
            setFiles(selected?.attachments?.map(({fileId, fileName}) => ({fileId, fileName})) || []);
            formik.setValues({
                type: selected?.type || '',
                startDate: selected?.startDate ? parseDateInDateAuto(selected.startDate, true) : null,
                endDate: selected?.endDate ? parseDateInDateAuto(selected.endDate, true) : null,
                comment: selected?.comment || '',
                attachments: selected?.attachments?.map(({fileId, fileName}) => ({fileId, fileName})) || [],
            });
        } else {
            setFiles([])
            formik.resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, selected]);

    useEffect(() => {
        if (JSON.stringify(files) !== JSON.stringify(formik.values.attachments)) {
            formik.setFieldValue('attachments', files);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(files)])

    return {
        isOpen,
        isLoading: {
            creatingTimeOff,
            // uploadingFile,
            timeOffSettings,
        },

        handleOpen,
        handleClose,

        types,
        selected,

        formik: {...formik, handleChange: formikOnChange},
        handleDeleteFile,
        handleFileUpload,
    }
};
