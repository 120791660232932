import {useDispatch, useSelector} from "react-redux";
import {
    hideRequestView,
    hrDialogsSelector,
    hrIsLoadingSelector,
    hrMyProfileSelector,
    hrMyRequestsSelector
} from "../../../store/slice";
import {getPortalHrRequestByIdAction, portalHrCancelRequestAction} from "../../../store/actions";
import {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {HR_REQUESTS_PATH} from "../../../../../../newShared/constants";


export const useRequestViewDialog = () => {

    const dispatch = useDispatch();
    const {requestId} = useParams<{requestId: string}>();
    const {requestView: {isOpen}} = useSelector(hrDialogsSelector);
    const {selected} = useSelector(hrMyRequestsSelector);
    const {requestExact, cancelRequest } = useSelector(hrIsLoadingSelector);
    const current = useSelector(hrMyProfileSelector);
    const location = useLocation();
    const history = useHistory();


    const handleClose = () => {
        dispatch(hideRequestView());
        history.push(`${HR_REQUESTS_PATH}${location.search}`);
    }

    useEffect(() => {
        isOpen && requestId && dispatch(getPortalHrRequestByIdAction({data: {requestId}}))

        //eslint-disable-next-line
    }, [isOpen]);

    const handleCancelRequest = () => {
        selected && dispatch(portalHrCancelRequestAction({data: {requestId: selected.id}}));
    }

    return {
        isOpen,
        loadings: {
            requestExact,
            cancelRequest
        },
        selected,
        current,
        handleClose,
        handleCancelRequest,
    }

}
