import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";

import {useHistory} from "react-router";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import {useParams} from "react-router-dom";
import {portalRequiredActionsGetTaskByIdAction} from "../../store/actions";
import {REQUIRED_ACTIONS_TASKS_PATH} from "../../../../../newShared/constants";
import {
    FrameworksDelegatedTaskDataAttachedFilesFileModel,
    RequiredActionsStatus
} from "../../../../../newShared/GQLTypes";
import {loadingsSelector, requiredActionsSelector} from "../../store/slice";
import {DownloadFile} from "../../../workerSpace/store/actions";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const useRequiredActionExact = ({refreshTable}: {refreshTable: () => void}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {handleOpen} = usePDFView();
    const {taskId} = useParams<{taskId?: string}>();
    const isOpen = Boolean(taskId);
    const {selected: task} = useSelector(requiredActionsSelector);
    const {requiredActionsExact: isLoadingExact} = useSelector(loadingsSelector);
    const {setMessage} = useMessageDialog();
    const {t} = useMainTranslation('', {keyPrefix: 'pathRequiredActions.dialogs'});

    //state
    const [allDocsPreviewDialog, setAllDocsPreviewDialog] = useState<boolean>(false);
    const [evidenceDocsPreviewDialog, setEvidenceDocsPreviewDialog] = useState<boolean>(false);

    //useEffects
    useEffect(() => {
        if(taskId){
            //remove old selected task - refetch new one
            getTaskById(taskId);
        }
        //eslint-disable-next-line
    }, [taskId]);


    //actions
    const getTaskById = (_id: string) => {

        dispatch(portalRequiredActionsGetTaskByIdAction({
            data: {workspaceId: '', taskId: _id}, clean: true,
            onError: (request) => {
                setMessage({title: t('Action conflict error'), message: t(`Required action with id {{taskId}} not found.`, {taskId: request.taskId})});
                handleClose();
            },
            onSuccess: (request, response) => {
                if (response.status === RequiredActionsStatus.Cancelled) {
                    setMessage({title: t('Required action {{taskId}}', {taskId: response.id}), message: t(`Action has been cancelled.`)});
                    handleClose();
                    refreshTable();
                }
            }
        }));
    }
    const handleClose = () => {
        history.push({pathname: `${REQUIRED_ACTIONS_TASKS_PATH}`, search: history.location.search});
        setEvidenceDocsPreviewDialog(false);
        setAllDocsPreviewDialog(false);
    }

    const handlePreviewFile = (file: FrameworksDelegatedTaskDataAttachedFilesFileModel) => {
        handleOpen({...file});
    }

    const handleDownloadFile = ({fileId, fileName}: FrameworksDelegatedTaskDataAttachedFilesFileModel) => {
        dispatch(DownloadFile({fileId, fileName}))
    }

    const openPreviewAllFiles = () => setAllDocsPreviewDialog(true);

    const openPreviewAllAddedToEvidenceFiles = () => setEvidenceDocsPreviewDialog(true);

    return{
        isOpen: isOpen && !allDocsPreviewDialog && !evidenceDocsPreviewDialog,
        task,
        actions: {
            handleClose,
            handleDownloadFile,
            handlePreviewFile,
            openPreviewAllFiles,
            openPreviewAllAddedToEvidenceFiles
        },
        previewAllDocsDialog: {
            isOpen: allDocsPreviewDialog || evidenceDocsPreviewDialog,
            handleClose: () => {
                setAllDocsPreviewDialog(false);
                setEvidenceDocsPreviewDialog(false);
            },
            attachments: task?.data?.attachedFiles ?? [],
            isEvidenceAttachments: evidenceDocsPreviewDialog
        },
        isLoadingExact,
        taskId,
    }
}
