import {TMenuTitle} from "./constants";
import {ActionReducerMapBuilder, AsyncThunk} from "@reduxjs/toolkit";
import {TLeftMenuSlice} from "./store/slice";
import {TApolloErrors} from "../../../newShared/utils/asyncThunk/types";


export const addCheckMenuItemsForAction = <TSlice extends TLeftMenuSlice = TLeftMenuSlice>(
    builder: ActionReducerMapBuilder<TSlice>,
    title: TMenuTitle,
    action: AsyncThunk<any, any, {rejectValue: TApolloErrors}>,
) => {
    builder
        .addCase(action.pending,(slice: TLeftMenuSlice) => {
            slice.isChecking.push(title);
            // slice.checkedItems = slice.checkedItems.filter(item => item !== title);
        })
        .addCase(action.rejected,(slice: TLeftMenuSlice) => {
            slice.isChecking = slice.isChecking.filter(item => item !== title);
            slice.checkedItems = slice.checkedItems.filter(item => item !== title);
        })
        .addCase(action.fulfilled, (slice: TLeftMenuSlice) => {
            slice.isChecking = slice.isChecking.filter(item => item !== title);
            slice.checkedItems = [...slice.checkedItems.filter(item => item !== title), title];
        })
}