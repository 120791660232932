import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideViewJustification} from "../../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import React from "react";
import colors from "../../../../../../../newShared/theme/colors";

export const ViewJustificationDialog = () => {
    const dispatch = useDispatch();

    const {
        isOpen,
        email,
        justification
    } = useSelector(dialogs).viewJustification;

    const handleClose = () => {
        dispatch(hideViewJustification());
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{(`Justification for ${email} account`)}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'} color={colors.darkBlueText}>
                    {justification}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{('Close')}</Button>
            </DialogActions>
        </Dialog>
    )
}