import * as S from '../components/styled';
import {NoAccessChip, NoAccessChipsDot} from '../components/styled';
import {Box, Typography} from '@mui/material';
import colors from '../../../../../../newShared/theme/colors';
import Lines from "../../../../../../newShared/images/login/lines.svg";
import {ReactComponent as PortalIcon} from "../components/img/portalIcon.svg";
import React from "react";
import {LABEL,} from "../../../../../../newShared/constants";
import {Flex} from '../../../../../../newShared/components/Layouts';

export const NoAccessPage = () => {
    return(
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"} overflow={'hidden'}
             position={"relative"} width={'100%'} height={'100%'}
             sx={{
                 background: 'linear-gradient(107.56deg, #FFFFFF 0%, #F0F6FB 54.69%, #FFFFFF 100%)',
             }}>

            <img src={Lines} alt={'background'} style={{
                position: 'absolute',  top: '100px', zIndex: 0,
                ...(document.body.offsetWidth > document.body.offsetHeight ? {width: '100vw'} : {height: '100%', left: '0'}),
            }}/>

            <S.TextWrapper>
                <S.WelcomeOnBoardCard>
                    <Flex jc={'space-between'} ai={'center'}>
                        <PortalIcon />

                        <NoAccessChip>
                            <NoAccessChipsDot />
                            <Typography variant={'*bodyText2'}>{'No access'}</Typography>
                        </NoAccessChip>
                    </Flex>

                    <Typography color={colors.primary.blue_dark} variant={'*h3'} margin={'22px 0 0 0'}>
                        {LABEL} Portal
                    </Typography>

                    <Typography color={colors.grayText} variant={'*bodyText2'} margin={'10px 0 0 0'}>
                        {`No organization has granted you access to ${LABEL} Portal. Ask your organization owner or admin to provide you access.`}
                    </Typography>
                </S.WelcomeOnBoardCard>
            </S.TextWrapper>
        </Box>
    )
}