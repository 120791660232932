import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {GetStages} from "../../store/actions";
import {
    loadings,
    pageInfo,
    selectedStage,
    stages
} from "../../store/slice";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";

export const useWorkspaceSpace = () => {
    const dispatch = useDispatch();
    const {campaignReviewStage} = useSelector(loadings);
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const {list: isLoadingList} = useSelector(loadings);
    const _stages = useSelector(stages);

    useEffect(() => {
        dispatch(GetStages({page: 0, count: 10}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch(GetStages({page: 0, count: 10}));
        //eslint-disable-next-line
    }, [workspaceId])

    return{
        stages: isLoadingList ? [] : _stages,
        selected: useSelector(selectedStage),
        pageInfo: useSelector(pageInfo),
        selectedStage: useSelector(selectedStage),
        isLoadingCampaignReviewStage: campaignReviewStage,
    }
}
