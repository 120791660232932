import React, {FC} from "react";
import {TabPanel} from "@mui/lab";
import {Skeleton, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {AssignmentInd, DateRange, PrivacyTip, Work} from "@mui/icons-material";
import {CategoryTitleCard} from "../../../../components/categoryTitleCard";
import colors from "../../../../../../../newShared/theme/colors";
import {useHrProfile} from "../../../../hooks/useHrProfile";
import {StatusChip} from "../../../../components/statusChip";
import {JobHistoryTable} from "../jobHistoryTable";

export const JobInfoTab: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathHrProfile'});

    const {employee} = useHrProfile();

    const roleSummary: {title: string, value?: string}[] = [
        {title: t('Job title'), value: !employee ? undefined : employee.jobTitle || '-'},
        {title: t('Department'), value: !employee ? undefined : employee.department || '-'},
        {title: t('Employment type'), value: !employee ? undefined : employee.employmentType || '-'},
        {title: t('Manager'), value: !employee ? undefined : employee.manager || '-'},
    ];

    return (
        <TabPanel value={'jobInfo'} sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto', width: '100%', p: 0, gap: '16px'}}>
            <CategoryTitleCard title={t('Role summary')} icon={<AssignmentInd sx={{color: colors.text.blue}}/>}
                               valuePairs={roleSummary}/>

            <CategoryTitleCard title={t('Hire date')} icon={<DateRange sx={{color: colors.text.blue}}/>}>
                <Typography variant={"*bodyText1"} color={colors.text.dark}>
                    {employee ? employee.hireDate || '-' : (
                        <Skeleton variant={"text"} width={100}/>
                    )}
                </Typography>
            </CategoryTitleCard>

            <CategoryTitleCard title={t('Employee status')} icon={<PrivacyTip sx={{color: colors.text.blue}}/>}>
                {employee ? <StatusChip status={employee.employeeStatus || null}/> : <Skeleton variant={"text"} width={100}/>}
            </CategoryTitleCard>

            <CategoryTitleCard title={t('Job history')} icon={<Work sx={{color: colors.text.blue}}/>}
                               fullChildren={<JobHistoryTable/>}/>
        </TabPanel>
    )
}