import {FC} from "react";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {AssetsRequestStatus, PortalAssetsRequestModel} from "../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {useRequestsListPage} from "../../hooks/useRequestsListPage";
import {getAssetRequestStatusChip} from "../../helpers";
import {assetRequestTypeTitleMapper} from "../../constants";
import {AssetRequestExactDialog} from "../AssetRequestExactDialog";
import {CancelAssetRequestDialog} from "../../components/dialogs/cancelRequestDialog";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import colors from "../../../../../newShared/theme/colors";
import {useCancelAssetRequestDialog} from "../../hooks/useCancelAssetRequestDialog";

export const AssetsRequestsList: FC = () => {

    const {t, tMenu} = useMainTranslation('', {keyPrefix: 'pathAssets.pathRequestsList'});
    const {requests, genericTable, actions} = useRequestsListPage();
    const {isMobile} = useMedia();
    const {openCancelRequest} = useCancelAssetRequestDialog();

    return (
        <PageWrapper>
            <PageTitleAndActions title={tMenu('My asset requests')} actions={[]}/>

            <GenericTable<PortalAssetsRequestModel>
                id={'myAssetRequests'}
                columnsConfig={{totalName: 'Total requests', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    customCellPaddings: '8px',
                    rows: requests,
                    getRowUniqueId: (row) => row.id,
                    onRowClick: (row) => actions.handleOpenRequestView(row.id),
                    rowActionsConfig: [
                        {
                            title: t('Cancel request'),
                            onClick: (row) => openCancelRequest(row.id),
                            icon: <CancelScheduleSendIcon fontSize={'small'} style={{color: colors.primary.blue}}/>,
                            dontShow: (row) => row.status !== AssetsRequestStatus.Created
                        },
                    ],
                    columnsConfigs: [
                        {key: 'id', name: t('Request id'), default: true, },
                        {key: 'type', name: t('Request type'), default: true, valueGetter: (row) => assetRequestTypeTitleMapper[row.type]},
                        {key: 'requestDate', name: t('Request date'), default: true, valueGetter: (row) => parseDateAuto(row.requestDate)},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getAssetRequestStatusChip(row.status)},
                        {key: 'resolver', name: t('Resolver'), default: true, valueGetter: (row) => row.resolver?.fullName || '-'},
                        {key: 'resolveDate', name: t('Resolve date'), default: true, valueGetter: (row) => row.resolveDate ? parseDateAuto(row.resolveDate) : '-'},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'requestIdLike',
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('You do not have any request yet'), secondaryText: t('You can create request in “My Assets” section.')}}}
            />

            <AssetRequestExactDialog/>
            <CancelAssetRequestDialog />
        </PageWrapper>
    );
};
