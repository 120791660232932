import {createAsyncThunk} from "@reduxjs/toolkit";
import {addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";
import {
    confirmSignStage,
    getCampaignReviewStageById,
    getFile,
    getFinalApproverByStageIdAndExcId,
    getStages,
    signStage,
    updateCampaignReviewRows,
    updateDocument,
    uploadFile,
    verifyActorChanges
} from "../api";
import {saveAs} from "file-saver";
import {campaignReviewExecutorStageActorAppData, StageDoc} from "../types";
import {getActionsData, RootState} from "../../../../newShared/redux";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
import {TWithOptions} from "../../../../newShared/types";
import {AppState} from "../../../../newShared/redux/rootReducer";

export {};

//getStages
export const GetStages = createAsyncThunk(
    'Space/GetStages',
    async (data: {page: number, count: number}, {getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getStages(workspaceId, data.page, data.count);
    }
);

//getFile

export const DownloadFile = createAsyncThunk(
    'Space/downloadFile',
    async (data: {fileId: string, fileName: string}, {dispatch, getState, rejectWithValue}) => {

        const resp = await getFile(data.fileId);
        saveAs(b64toBlob(resp.file), data.fileName)
        return {}
    }
);

//signStage

export const SignStage = createAsyncThunk(
    'Space/approveStage',
    async (stageId: string, {dispatch, getState, rejectWithValue}) => {

        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const mes = await signStage(workspaceId, stageId);
        dispatch(addSuccessfulSnack(mes));
        return stageId;
    }
);

//uploadFile
export const UploadFile = createAsyncThunk(
    'Automations/UploadFile',
    async (data: {file: string, isSignature: boolean, stageId: string, insteadFileLink?: string, fileName?: string, handleReplaceReadFile?: (delFileId: string, addFileId: string) => void}, {dispatch,getState}): Promise<{fileId: string, isSignature: boolean}> => {
        const fileId =  await uploadFile(data.file);
        (data.handleReplaceReadFile && data.insteadFileLink) && data.handleReplaceReadFile(data.insteadFileLink, fileId);
        if(data.isSignature && fileId.length > 0){
            //dispatch approve sign
            dispatch(ConfirmSignStage({stageId: data.stageId, signatureId: fileId}));
        }else{
            //file review was uploaded
            const {Space: {selectedStage}} = getState() as RootState;
            if(selectedStage && selectedStage.stageDocument && data.insteadFileLink && data.fileName){
                const links = selectedStage.stageDocument.links.map((e) => e.link === data.insteadFileLink
                    ?
                    {
                        link: fileId,
                        fileName: data.fileName!,
                        lastUpdate: new Date(Date.now()).toISOString()
                    }
                    :
                    e
                );
                console.log(`file review was uploaded`, selectedStage, data, links);
                dispatch(UpdateDocument({stageId: data.stageId, doc: {type: selectedStage.stageDocument.type, links}}));
            }
        }

        return {fileId: '', isSignature: data.isSignature};
    }
);

export const FinalApproverSignatureVerify = createAsyncThunk(
    'Automations/FinalApproverSignatureVerify',
    async (data: {file: string, executorId: string, stageId: string}, {dispatch,getState}): Promise<void> => {
        const res = await uploadFile(data.file);
        dispatch(VerifyActorChanges({
            data: {
                executorId: data.executorId,
                signatureId: res,
                verifyComment: null, //stage managers - set null
                stageId: data.stageId,
            }
        }));
    }
);

//confirmSignStage
export const ConfirmSignStage = createAsyncThunk(
    'Space/confirmSignStage',
    async (data: {stageId: string, signatureId: string}, {dispatch, getState, rejectWithValue}) => {

        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        const mes = await confirmSignStage(workspaceId, data.stageId, data.signatureId);
        dispatch(addSuccessfulSnack(mes));
        return data.stageId;
    }
);

//updateDocument
export const UpdateDocument = createAsyncThunk(
    'Space/updateDocument',
    async (data: {stageId: string, doc: StageDoc}, {dispatch, getState, rejectWithValue}) => {

        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateDocument(workspaceId, data.stageId, data.doc);
        dispatch(addSuccessfulSnack('File uploaded'));
        return data;
    }
);

//getCampaignReviewStageById
export const GetCampaignReviewStageById = createAsyncThunk(
    'Space/getCampaignReviewStageById',
    async (data: {stageId: string, executorId: string}, {dispatch, getState, rejectWithValue}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getCampaignReviewStageById(workspaceId, data.executorId, data.stageId);
    }
);

//
export const UpdateCampaignReviewRows = createAsyncThunk(
    'Space/updateCampaignReviewRows',
    async ({data, signal}: TWithOptions<{stageId: string, apps: campaignReviewExecutorStageActorAppData[], executorId: string, onSuccess?: () => void}>, {getState}): Promise<{stageId: string, apps: campaignReviewExecutorStageActorAppData[]}> => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await updateCampaignReviewRows(workspaceId, data.stageId, data.apps, data.executorId, signal);
        data.onSuccess && data.onSuccess();
        return data;
    }
);

export const VerifyActorChanges = createAsyncThunk(
    'accessManagement/verifyActorChanges',
    async ({data, signal}: TWithOptions<{executorId: string, signatureId: string | null, stageId: string, verifyComment: string | null}>, {getState, dispatch}): Promise<{stageId: string}> => {

        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        await verifyActorChanges(workspaceId, data.signatureId, data.stageId, data.verifyComment, signal);
        return {stageId: data.stageId};
    }
);

// getFinalApproverByStageIdAndExcId

export const GetFinalApproverByStageIdAndExcId = createAsyncThunk(
    'accessManagement/getFinalApproverByStageIdAndExcId',
    async ({data, signal}: TWithOptions<{executorId: string, stageId: string}>, {getState, dispatch}) => {
        console.log(`stageId: ${data.stageId} exec: ${data.executorId}`);
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);
        return await getFinalApproverByStageIdAndExcId(workspaceId, data.stageId, data.executorId, signal);
    }
);
