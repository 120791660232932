import axiosAsync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils";
import {AxiosResponse} from "axios";
import {
    campaignReviewExecutorStageActorAppData,
    campaignReviewExecutorStageType,
    finalApprover,
    PageInfo,
    Stage,
    StageDoc
} from "../types";
import {gql} from "graphql.macro";
import {print} from "graphql";

export const getStages = async (workspaceId: string, page: number, count: number): Promise<{pageInfo: PageInfo, stages: Stage[]}> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                query workerGetStages($workspaceId:String!, $data:SharedPaginationInput!){
                    workerGetStages(workspaceId:$workspaceId, data:$data){
                        pageInfo{
                            page
                            count
                            total
                            sort
                        }
                        stages{
                            stage{
                                executorId
                                name
                                documentName
                                id
                                eta
                                expirationDate
                                runDate
                                status
                                statusChangedDate
                                type
                                automationType
                                actor{
                                    email
                                    publicId
                                    status
                                    statusChangedDate
                                }
                            }
                            stageDocument{
                                type
                                links{
                                    fileName
                                    lastUpdate
                                    link
                                }
                            }
                            stageCampaignReview{
                                email
                                status
                                statusChangedDate
                                firstName
                                lastName
                                publicId
                                mainControl
                                verifyRevokeComment
                                appsData{
                                    applicationAccessName
                                    applicationAccessId
                                    applicationId
                                    vendorId
                                    vendorName
                                    data{
                                        id
                                        commentManager
                                        statusManager
                                        commentVendor
                                        statusVendor
                                        publicIdVendor
                                        publicIdManager
                                        employee
                                        employeeId
                                        account
                                        accountId
                                        accountType
                                        status
                                        roles{
                                            roleName
                                            rolePermissions
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `),
                variables: {
                    workspaceId,
                    data: {page, count, sort: 'default'},
                }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.workerGetStages;
    })
}

export const getCampaignReviewStageById = async (workspaceId: string, executorId: string, stageId: string): Promise<campaignReviewExecutorStageType> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                query getCampaignReviewStageById($workspaceId:String!, $executorId:String!, $stageId:String!){
                    getCampaignReviewStageById(workspaceId:$workspaceId, executorId:$executorId, stageId:$stageId){
                        id
                        type
                        runDate
                        expirationDate
                        eta
                        status
                        statusChangedDate
                        actors{
                            email
                            status
                            statusChangedDate
                            firstName
                            lastName
                            publicId
                            mainControl
                            verifyRevokeComment
                            appsData{
                                applicationAccessName
                                applicationAccessId
                                applicationId
                                vendorId
                                vendorName
                                data{
                                    id
                                    commentManager
                                    statusManager
                                    commentVendor
                                    statusVendor
                                    publicIdVendor
                                    publicIdManager
                                    employee
                                    employeeId
                                    account
                                    accountId
                                    accountType
                                    status
                                    roles{
                                        roleName
                                        rolePermissions
                                    }
                                }
                            }
                        }
                    }
                }

            `),
            variables: {
                workspaceId,
                executorId,
                stageId
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.getCampaignReviewStageById;
    })
}

export const getFile = async (id: string): Promise<{file: string}> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                query downLoadFile($id:String!, $workspaceId:String!){
                    downloadFile(workspaceId: $workspaceId, id:$id){
                        file
                    }
                }
            `),
            variables: {
                id,
                workspaceId: ''
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.downloadFile;
    })
}

export const signStage = async (workspaceId: string, id: string): Promise<string> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation workerConfirmStage($workspaceId:String!, $id:String!){
                    workerConfirmStage(workspaceId:$workspaceId, id:$id){
                        message
                    }
                }
            `),
            variables: {
                workspaceId,
                id,
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.workerConfirmStage.message;
    })
}

export const confirmSignStage = async (workspaceId: string, id: string, signatureId: string): Promise<string> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation workerConfirmSignStage($workspaceId:String!, $data:WorkerConfirmSignatureDto!){
                    workerConfirmSignStage(workspaceId:$workspaceId, data:$data){
                        message
                    }
                }
            `),
            variables: {
                workspaceId,
                data: {
                    stageId: id,
                    signatureId,
                }
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.workerConfirmSignStage.message;
    })
}

export const uploadFile = async (file: string): Promise<string> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation portalUploadFile($file:String!){
                    portalUploadFile(file:$file){
                        message
                    }
                }
            `),
            variables: {
                file,
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.portalUploadFile.message;
    })
}

export const updateDocument = async (workspaceId: string, stageId: string, document: StageDoc): Promise<string> => {
    return axiosAsync.then((axios) => axios.post(PATH_SERVER, {
            query: print(gql`
                mutation updateDocument($workspaceId:String!, $data:WorkerUploadNewFileDto!){
                    workerUploadNewFileForReview(workspaceId:$workspaceId, data:$data){
                        message
                    }
                }
            `),
            variables: {
                workspaceId,
                data: {stageId, stageDocument: document},
            }
        }, {
            headers: buildCommonHeader()
        })
    ).then((result: AxiosResponse<any>) => {
        return result?.data?.data?.workerUploadNewFileForReview.message;
    })
}

export const updateCampaignReviewRows = async (workspaceId: string, stageId: string, appsData: campaignReviewExecutorStageActorAppData[], executorId: string, signal?: AbortSignal): Promise<string> => {
    return axiosAsync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateCampaignReviewRows($workspaceId:String!, $stageId:String!, $data:UpdateRowStatusOrCommentDtoRequestDto!, $executorId: String!){
                        updateCampaignReviewRowsDocs(workspaceId:$workspaceId, stageId:$stageId, data:$data, executorId: $executorId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    data: {appsData},
                    stageId,
                    executorId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateCampaignReviewRowsDocs.message;
    })
}

export const verifyActorChanges = async (workspaceId: string, signatureId: string | null, stageId: string, verifyComment: string | null, signal?: AbortSignal): Promise<string> => {
    return await axiosAsync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation verifyActorChangesDocs($workspaceId:String!, $signatureId:String, $stageId:String!, $verifyComment:String){
                        verifyActorChangesDocs(workspaceId:$workspaceId, signatureId:$signatureId, stageId:$stageId, verifyComment:$verifyComment){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    stageId,
                    verifyComment,
                    signatureId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.verifyActorChangesDocs.message;
    })
}

export const getFinalApproverByStageIdAndExcId = async (workspaceId: string, stageId: string, executorId: string, signal?: AbortSignal): Promise<finalApprover> => {
    console.log(`getFinalApproverByStageIdAndExcId: (stageId: ${stageId} | executorId${executorId})`);
    return await axiosAsync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getFinalApproverByStageIdAndExcId($workspaceId:String!, $stageId:String!, $executorId:String!){
                        getFinalApproverByStageIdAndExcId(workspaceId:$workspaceId, stageId:$stageId, executorId:$executorId){
                            email
                            firstName
                            lastName
                            publicId
                            documentId
                            signId
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    stageId,
                    executorId
                }
            },
            {
                headers: buildCommonHeader(),
                signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getFinalApproverByStageIdAndExcId;
    })
}
