import React, {useEffect, useState} from "react";
import {useSnack} from "../../../newDomain/barsEnvironment/snack/hooks/useSnack";
import {useMainTranslation} from "../useMainTranslationHooks/useMainTranslation";
import {toBase64} from "../../utils/base64/base64";

export const useMultiFileDropZone = (fileLimitInMB: number = 0) => {
    const snack = useSnack();
    const {tCommon} = useMainTranslation()

    const ref = React.createRef<HTMLInputElement>();
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [filesInBase64, setFilesInBase64] = useState<{file: string, name: string}[]>([]);

    const fileHandler = (files: FileList | null) => {
        if (files) {
            if(fileLimitInMB && Array.from(files).some(e => e.size > fileLimitInMB * 1024 * 1024)) {
                snack(tCommon(`File size should be up to {{fileLimitInMB}}MB`, {fileLimitInMB}), "error");
            }

            setUploadedFiles(prev => [
                ...prev,
                ...(
                    fileLimitInMB
                        ? Array.from(files).filter(e => e.size <= fileLimitInMB * 1024 * 1024)
                        : files
                )
            ]);
        }
    };

    const handleDeleteFile = (fileName: string) => {
        setUploadedFiles([...uploadedFiles.filter(e => e.name !== fileName)]);
    }

    const filePicker = () => ref.current && ref.current.click();

    const makeBase64 = async (files: File[]) => {
        const base64Files = await Promise.all(files.map(async file => ({name: file.name, file: await toBase64(file)})));
        setFilesInBase64(base64Files);
    }

    useEffect(() => {
        if (uploadedFiles.length) makeBase64(uploadedFiles)
        else setFilesInBase64([]);
    }, [uploadedFiles]);

    return{
        filePicker,
        ref,
        fileHandler,
        handleDeleteFile,
        uploadedFiles,

        filesInBase64,
        reset: () => setUploadedFiles([]),
    }
}