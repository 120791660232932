import React, {FC} from "react";
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";
import {useLeftMenu} from "../../barsEnvironment/leftMenu/hooks/useLeftMenu";
import {myHrTitle} from "../../barsEnvironment/leftMenu/titles";
import {Hr403} from "./pages/hr403";

export const HR: FC = () => {
    const {checkItems: {checkedItems}} = useLeftMenu();

    const isChecked = checkedItems.includes(myHrTitle);

    return (
        <LeftSecondMenu>
            {isChecked ? <Routes /> : <Hr403 />}
        </LeftSecondMenu>
    )
};