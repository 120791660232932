import React, {useState} from 'react';
import {Logging} from "../../utils/logs/log";

export function useForm<T>(initialValues:T){
    const [form, setForm] = useState(initialValues);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event?.persist?.();
        if(event.target.id?.startsWith('.')){
            const fieldName = event.target.id.slice(1);
            if(fieldName && Object.keys(form ?? {}).some(e => e === fieldName)){
                setForm((prevValues) => ({
                    ...prevValues,
                    [fieldName]: {
                        ...prevValues[fieldName as keyof T],
                        [event.target.name]: event.target.value,
                    }
                }));
            }
        }else{
            setForm((prevValues) => ({
                ...prevValues,
                [event.target.name]: event.target.value,
            }));
        }
    };

    const setValue = (key: keyof T, value: T[typeof key], disableTypeCheck?: boolean) => {
        if(disableTypeCheck){
            setForm(prev => {return {
                ...prev,
                [key]: value
            }})
        }else{
            if(typeof form[key] === typeof value || form[key] === null){
                //if form contains value as string | null, and initial value is null it will be considered as object
                setForm(prev => {return {
                    ...prev,
                    [key]: value
                }})
            }else{
                Logging.error(`useForm: setValue error of value ${value} for key ${String(key)} \n
                type in form: ${typeof form[key]} (${form[key]}) \n
                type of value: ${typeof value}
            `)
            }
        }
    }

    return {
        handleChange,
        form,
        setForm,
        setValue
    }
}
