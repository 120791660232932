import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import styled from "styled-components";
import colors from "../../../../../newShared/theme/colors";
import {TypographyBody2, TypographySubtitle2} from "../../../../../newShared/components/Inputs/styled";

export const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const DocsTableContainer = styled(TableContainer)`
  margin: 16px 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px !important;
  border: 1px solid #EAECF0;
  height: 100%;
  flex-grow: 1;
  overflow: auto;
`;

export const InboxTable = styled(Table)``;
InboxTable.defaultProps = {
    stickyHeader: true,
}

export const InboxTableBody = styled(TableBody)`
  & .MuiTableRow-root:last-child td {
    border-bottom: 1px solid ${colors.primary.disable};
`;

export const InboxTableHead = styled(TableHead)`
  background-color: ${colors.backgrounds.grey_light};
`;

export const InboxTotalTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    background-color: ${colors.backgrounds.grey_light};
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
}
`;

export const InboxTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    background-color: ${colors.backgrounds.grey_light}
}
`;

export const InboxTableCell = styled(TableCell)`
  & p {
    white-space: nowrap
  }
`;

export const InboxTotalTypography = styled(TypographySubtitle2)`
    color: ${colors.text.grey};
`;

export const InboxTableTypography = styled(TypographyBody2)`
    color: ${colors.text.grey_dark};
`;