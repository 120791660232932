

export const PATHS_UNPROTECTED_BUT_AVAILABLE_FOR_LOGGED_IN = [
    //for example - invitation - this path have to be opened for signed in user and for not signed ether
    // ACCEPT_INVITATION,
    // PATH_LOCAL_SHARE_REPORTS,
]

export const isPathAvailableForEveryone = (pathname: string): boolean => {
    return  PATHS_UNPROTECTED_BUT_AVAILABLE_FOR_LOGGED_IN.some(e => pathname.startsWith(e));
}