import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    ASSETS_EXACT_PATH,
    ASSETS_LIST_PATH,
    ASSETS_REQUESTS_EXACT_PATH,
    ASSETS_REQUESTS_PATH,
} from "../../../newShared/constants";

import {AssetList} from "./pages/assetList";
import {AssetsRequestsList} from "./pages/assetsRequestsList";


export const Routes: FC = () => {

    return (
        <Switch>
            <Route exact path={[ASSETS_LIST_PATH, ASSETS_EXACT_PATH]} component={AssetList}/>
            <Route exact path={[ASSETS_REQUESTS_PATH, ASSETS_REQUESTS_EXACT_PATH]} component={AssetsRequestsList}/>
            <Redirect to={ASSETS_LIST_PATH}/>
        </Switch>
    );
};
