import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useCancelRequestDialog} from "../../../hooks/dialogs/useCancelRequest";


export const CancelRequestDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.dialogs'});
    const {
        isOpen,
        isLoading,
        handleClose,
        handleCancelRequest,
    } = useCancelRequestDialog();


    return (
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Cancel request')}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'}>{t('Are you sure you want to cancel this request?')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
                <LoadingButton size={'small'} loading={isLoading} onClick={handleCancelRequest}>
                    {t('Cancel request')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
