import {Typography} from "@mui/material";
import React, {FC} from "react";
import colors from "../../../../../newShared/theme/colors";
import {ReactComponent as HrIcon} from "../../../../../newShared/images/other/hrIcon.svg";
import {LABEL} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Hr403Wrapper, Hr403MessageWrapper} from "../../components/styled";

export const Hr403: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathMyHrRequests'})

    return (
        <Hr403Wrapper>
            <Hr403MessageWrapper>
                <HrIcon/>
                <Typography color={colors.primary.blue_dark} variant={'*h3'}>{t('My HR Module')}</Typography>
                <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('We would like to inform that HR Module is not connected for this workspace or your employee record is not available in the HR Module.')}</Typography>
                <Typography variant={'*bodyText2'} color={colors.text.dark}>{t('As a result, you do not have access to  HR-related features in {{LABEL}} Portal.', {LABEL})}</Typography>
            </Hr403MessageWrapper>
        </Hr403Wrapper>
    )
}