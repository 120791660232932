import React, {FC, useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {FlexRow} from "../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useTrainingsDialogs} from "../../../hooks/dialogs/useTrainingsDialogs";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {loadingsSelector} from "../../../store/slice";
import {portalTrainingsFinishExamAction} from "../../../store/actions";
import colors from "../../../../../../newShared/theme/colors";
import {ArrowCircleLeftOutlined, ArrowCircleRightOutlined, TaskAltOutlined} from "@mui/icons-material";
import {TRAININGS_ASSIGNMENTS_PATH} from "../../../../../../newShared/constants";
import {TrainingAssignmentStatus} from "../../../../../../newShared/GQLTypes";
import {useHistory} from "react-router-dom";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";

export const FinishExamDialog: FC<{refreshAssignment: () => void}> = ({refreshAssignment}) => {
    const {t, tCommon, revDir} = useMainTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {setMessage} = useMessageDialog();

    const {completingExam} = useSelector(loadingsSelector);
    const {isOpen, handleClose} = useTrainingsDialogs().finishExam;
    const passingExamDialog = useTrainingsDialogs().passingExam;
    const validationExamDialog = useTrainingsDialogs().validationExam;

    const finishExam = (timeIsUp: boolean) => {
        const {isOpen: passingExamData} = passingExamDialog;
        isOpen && passingExamData && dispatch(portalTrainingsFinishExamAction({
            data: {assignmentId: passingExamData.assignmentId, type: passingExamData.type},
            onSuccess: (request, response, addictiveData) => {
                !timeIsUp && handleClose();

                validationExamDialog.setIsOpen(response.exam ? {
                    yourPoints: response.exam.questions.reduce((prev, curr) => prev + (curr.validation?.points || 0), 0),
                    passingPoints: response.exam.examPassingPoints || 0,
                    status: response.exam.status
                } : {countInputs: isOpen.inputsCount})

                refreshAssignment();
            },
            onError: (request, errors, addictiveData) => {
                // const e400 = errors.e400?.[0];
                const e404 = errors.e404?.[0];
                const e409 = errors.e409?.[0];

                if (e404) {
                    setMessage({title: 'Assignment exam', message: 'Assignment not found.'});
                    handleClose();
                    history.push(TRAININGS_ASSIGNMENTS_PATH);
                }

                if (e409?.type === 'STATUS_CHANGED') {
                    const value = e409?.values.find(e => e.parameter === 'status')?.value;
                    if (value === TrainingAssignmentStatus.Cancelled) {
                        setMessage({title: 'Assignment exam', message: 'Assignment was cancelled.'});
                        handleClose();
                        refreshAssignment();
                        history.push(TRAININGS_ASSIGNMENTS_PATH);
                    } else {
                        setMessage({title: 'Assignment exam', message: 'Assignment status was changed.'});
                        refreshAssignment();
                        handleClose();
                    }
                }

                if (e409?.type === 'EXAM_NOT_EXISTS') {
                    setMessage({title: 'Assignment exam', message: 'Exam not found.'});
                    refreshAssignment();
                    handleClose();
                }

                if (e409?.type === 'QUESTION_NOT_EXISTS') {
                    setMessage({title: 'Start assignment exam', message: 'Question not found. Try again.'});
                }
            },
        }));
    };

    useEffect(() => {
        if (isOpen?.timeEnd) {
            finishExam(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen?.timeEnd]);

    useEffect(() => {
        return () => {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isOpen && (
        <Dialog open onClose={!isOpen?.timeEnd ? handleClose : undefined}>
            <DialogTitle component={FlexRow} sx={{gap: '12px', alignItems: 'center'}}>
                {t('Finish exam')}
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', gap: '16px'}}>
                <FlexRow sx={{gap: '8px', alignItems: 'center'}}>
                    <TaskAltOutlined sx={{color: colors.stroke.blue, fontSize: '24px'}}/>
                    <Typography variant={'*bodyText2'}>
                        {t(`You have answered {{answered}} of {{total}} questions.`, {answered: isOpen.answered, total: isOpen.total})}
                    </Typography>
                </FlexRow>

                {isOpen.skipped > 0 && (
                    <FlexRow sx={{gap: '8px', alignItems: 'center'}}>
                        {!revDir ? <ArrowCircleRightOutlined sx={{color: colors.stroke.dark_grey, fontSize: '24px'}}/> : <ArrowCircleLeftOutlined sx={{color: colors.stroke.dark_grey, fontSize: '24px'}}/>}
                        <Typography variant={'*bodyText2'}>{t(`Skipped {{skipped}} questions.`, {skipped: isOpen.skipped})}</Typography>
                    </FlexRow>
                )}

                {isOpen.timeEnd ? (
                    <>
                        <Typography variant={'*bodyText2'}>{t('The time for the exam is over!')}</Typography>
                        {(isOpen.total > isOpen.answered) && <Typography variant={'*bodyText2'}>{t('All unanswered questions will be marked as skipped.')}</Typography>}
                    </>
                ) : (
                    <Typography variant={'*bodyText2'}>{t('Do you want to finish the exam?')}</Typography>
                )}
            </DialogContent>

            <DialogActions>
                {!isOpen.timeEnd && <Button onClick={handleClose} variant={'text'} size={'small'}>{tCommon('Close')}</Button>}
                <LoadingButton size={'small'} loading={completingExam} onClick={!isOpen.timeEnd ? () => finishExam(false) : handleClose}>
                    {t('Finish exam')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}