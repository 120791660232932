import {FC} from "react";
import {Flex, PageWrapper} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    RequiredActionsPerformProvideFilesDialog
} from "../../components/dialogs/requiredActionsPerformProvideFilesDialog";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useRequiredActionsPage} from "../../hooks/useRequiredActionsPage";
import {PortalRequiredActionModel} from "../../../../../newShared/GQLTypes";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {requiredActionsTaskTypeTitleMapper} from "../../constants";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {RequiredActionsStatusChips} from "../../components/requiredActionStatus";
import {RequiredActionExactDialog} from "../requiredActionExact";

export const RequiredActionList: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathRequiredActions.pathTasks'});
    const {tasks, genericTable, refreshTable, handleOpenExact} = useRequiredActionsPage();
    const {isMobile} = useMedia();

    return (
        <PageWrapper>
            <PageTitleAndActions margin={'0 0 8px 0'} title={t('Required actions')} actions={[]} />

            <GenericTable<PortalRequiredActionModel>
                id={"requiredActions"}
                columnsConfig={{
                    totalName: "Total tasks",
                    disableColumnsSettings: true,
                    forceShowCardsWhen: isMobile,
                }}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: tasks,
                    getRowUniqueId: (row) => row.id,
                    onRowClick: (row) => {handleOpenExact(row.id)},
                    customCellPaddings: '8px',
                    // rowActionsConfig: [],

                    columnsConfigs: [
                        {key: "id", name: t('Task id'), default: true, },
                        {key: "type", name: t('Task type'), default: true, valueGetter: (row) => requiredActionsTaskTypeTitleMapper[row.type] },
                        {key: "requestor", name: t('Requestor'), default: true, valueGetter: (row) => row.requestor.fullName },
                        {key: 'createdDate', name: t('Created date'), default: true, valueGetter: (row) => parseDateAuto(row.createdDate ?? '', undefined, undefined, undefined, '-')},
                        {key: 'performDate', name: t('Performed date'), default: true, valueGetter: (row) => parseDateAuto(row.performDate ?? '', undefined, undefined, undefined, '-')},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => <Flex>{RequiredActionsStatusChips({status: row.status})}</Flex>},
                        {key: "resolver", name: t('Resolver'), default: true, valueGetter: (row) => row.resolver?.fullName ?? '-' },
                        {key: 'resolveDate', name: t('Resolved date'), default: true, valueGetter: (row) => parseDateAuto(row.resolveDate ?? '', undefined, undefined, undefined, '-')},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: "taskIdLike",
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        collapsable: true,
                    },
                }}
                emptyArrayImageProps={{type: "any", filterNotApplied: {text: t("You do not have any delegated tasks yet"), secondaryText: ""}}}
            />


            <RequiredActionsPerformProvideFilesDialog refreshTable={refreshTable}/>
            <RequiredActionExactDialog refreshTable={refreshTable} />
        </PageWrapper>
    );
};
