import React, {FC, useEffect} from "react";
import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {useCreateUpdateTimeOffDialog} from "../../../hooks/dialogs/useCreateUpdateTimeOffDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import colors from "../../../../../../newShared/theme/colors";
import {FileDropZone} from "../../../../../../newShared/components/fileDropZone";
import {useMultiFileDropZone} from "../../../../../../newShared/hooks/useMultiFileDropZone";
import {FileDrop} from "react-file-drop";
import {DeleteOutline} from "@mui/icons-material";
import {DEFAULT_DATE_FORMAT} from "../../../../../../newShared/utils/dateTools";
import {useRequestError} from "../../../hooks/dialogs/useRequestError";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";

export const CreateUpdateTimeOffDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathTimeOff'})
    const {
        isOpen, isLoading: {creatingTimeOff, timeOffSettings},
        handleClose,
        formik, types, handleDeleteFile, handleFileUpload,
        selected,
    } = useCreateUpdateTimeOffDialog();

    const limitMB = 5;
    const limitCount = 10;

    const {fileHandler, filesInBase64, ref, filePicker, reset} = useMultiFileDropZone(limitMB);

    const {isOpen: isErrorOpen} = useRequestError();
    const {message} = useMessageDialog();

    useEffect(() => {
        filesInBase64.length && handleFileUpload(filesInBase64.map(e => ({base64: e.file, fileName: e.name})));
        reset();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filesInBase64)]);

    return (
        <Dialog open={isOpen && !message && !isErrorOpen} onClose={handleClose} scroll={"paper"}>
            <DialogTitle>{selected ? t('Edit time off request {{requestId}}', {requestId: selected.id}) : t('Create time off request')}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', width: '100%', gap: '16px'}}>
                <Autocomplete<string>
                    loading={timeOffSettings}
                    value={formik.values.type || null}
                    size={"small"}
                    fullWidth
                    options={types}
                    onChange={(e, selected) => {
                        formik.setFieldTouched('type');
                        formik.setFieldValue('type', selected || '')
                    }}
                    renderInput={(params) => (
                        <TextField {...params} onBlur={formik.handleBlur}
                                   label={t('Select category')}
                                   helperText={formik.touched.type && formik.errors.type}
                                   error={formik.touched.type && Boolean(formik.errors.type)}
                                   name={'type'} required/>
                    )}
                />

                <FlexRow sx={{alignItems: 'flex-start', gap: '16px'}}>
                    <DatePicker
                        label={t("Start date")}
                        inputFormat={DEFAULT_DATE_FORMAT}
                        value={formik.values.startDate}
                        disablePast
                        onChange={(value) => formik.setFieldValue('startDate', value || null)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size={"small"}
                                required
                                fullWidth
                                name={'startDate'}
                                onBlur={formik.handleBlur}
                                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                helperText={formik.touched.startDate && formik.errors.startDate}
                            />)}
                        />

                    <DatePicker
                        label={t("End date")}
                        inputFormat={DEFAULT_DATE_FORMAT}
                        value={formik.values.endDate || null}
                        minDate={formik.values.startDate || undefined}
                        onChange={(value) => formik.setFieldValue('endDate', value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size={"small"}
                                required
                                fullWidth
                                name={'endDate'}
                                onBlur={formik.handleBlur}
                                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                helperText={formik.touched.endDate && formik.errors.endDate}
                            />)}
                        />
                </FlexRow>

                <TextField rows={4} multiline
                           error={formik.touched.comment && Boolean(formik.errors.comment)}
                           helperText={formik.touched.comment && formik.errors.comment}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={formik.values.comment}
                           name={'comment'}
                           label={t('Comment')}
                />

                <FlexColumn width={'100%'} gap={'4px'}>
                    <Typography variant={'*bodyText2_semibold'}>{t('Attachment')}</Typography>
                    <Typography variant={'*bodyText2'} color={colors.text.grey} sx={{mt: '4px'}}>
                        {tCommon('Make sure that the attached file does not exceed {{mb}} MB.', {mb: limitMB})}
                    </Typography>

                    {formik.values.attachments.length > limitCount && (
                        <Typography variant={'*bodyText2'} color={colors.text.critical}>
                            {tCommon('You can upload up to {{count}} files', {count: limitCount})}
                        </Typography>
                    )}
                    {formik.values.attachments.map(e => (
                        <FlexRow sx={{alignItems: 'center', width: '100%', overflow: 'hidden', gap: '8px'}} key={e.fileId}>
                            <Typography variant={'*bodyText2'} sx={{flexGrow: 1}} noWrap>
                                {e.fileName}
                            </Typography>

                            {e.fileId.startsWith('uploading') ? (
                                <IconButton size={"small"} sx={{flexShrink: 0}} disabled>
                                    <CircularProgress size={24}/>
                                </IconButton>
                            ) : (
                                <IconButton size={"small"} sx={{flexShrink: 0}} onClick={handleDeleteFile(e.fileId)} color={"primary"}>
                                    <DeleteOutline/>
                                </IconButton>
                            )}
                        </FlexRow>
                    ))}
                </FlexColumn>

                {formik.values.attachments.length <= 10 && (
                    <FileDrop onTargetClick={filePicker} onDrop={fileHandler}>
                        <FileDropZone inputRef={ref}
                                      fileHandler={fileHandler}
                                      accept={'.pdf, .docx, .xlsx, .doc, .xls'}
                                      multiple
                        />
                    </FileDrop>
                )}
            </DialogContent>

            <DialogActions>
                <Button size={"small"} variant={"text"} onClick={handleClose}>{tCommon('Cancel')}</Button>
                <LoadingButton size={"small"} variant={"contained"}
                               onClick={formik.submitForm}
                               disabled={!formik.isValid || formik.values.attachments.some(e => e.fileId.startsWith('uploading'))}
                               loading={creatingTimeOff}
                >{tCommon('Send')}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
