import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import {RequiredActionAttachedFilesNode} from "./components/requiredActionAttachedFilesNode";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useRequiredActionExact} from "../../hooks/useRequiredActionExact";
import {LocalSpinner} from "../../../../../newShared/components/Spinner";
import colors from "../../../../../newShared/theme/colors";
import {Flex} from "../../../../../newShared/components/Layouts";
import {MicroTableWithoutBorder} from "../../../../../newShared/components/microTableWithoutBorder";
import {RequiredActionsStatusChips} from "../../components/requiredActionStatus";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {
    useRequiredActionsPerformProvideFilesDialog
} from "../../hooks/dialogs/useRequiredActionsPerformProvideFilesDialog";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import React, {FC} from "react";
import {RequiredActionsStatus} from "../../../../../newShared/GQLTypes";
import {requiredActionsTaskTypeTitleMapper} from "../../constants";
import {PreviewAllFilesDialog} from "./components/previewAllFilesDialog";

export const RequiredActionExactDialog:FC<{refreshTable: () => void}> = ({refreshTable}) => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathRequiredActions.dialogs'});
    const {
        isOpen,
        task,
        actions,
        isLoadingExact,
        taskId,
        previewAllDocsDialog
    } = useRequiredActionExact({refreshTable});

    const {handleOpen, isOpen: isOpenPerforming} = useRequiredActionsPerformProvideFilesDialog({});
    const {isOpen: isOpenMessage} = useMessageDialog();

    return (
        <>
            <Dialog open={isOpen && !isOpenMessage && !isOpenPerforming} onClose={actions.handleClose}>
                <DialogTitle>{t(`Required action {{taskId}}`, {taskId})}</DialogTitle>

                <DialogContent>
                    {isLoadingExact || !task || task.status === RequiredActionsStatus.Cancelled ? <LocalSpinner /> :
                        <>
                            <Flex direction={'column'}>
                                <Typography variant={'*bodyText2_semibold'} color={colors.grayText}>{task.title}</Typography>
                                <Typography variant={'*bodyText2'} color={colors.grayText} margin={'6px 0 0 0'}>{task.description}</Typography>
                                <Divider sx={{margin: '16px 0'}} />

                                <MicroTableWithoutBorder
                                    isLoading={false}
                                    rows={[
                                        {
                                            label: `${t('Status')}:`,
                                            text: <Flex><RequiredActionsStatusChips status={task.status}/></Flex>,
                                        },
                                        {
                                            label: `${t('Created date')}:`,
                                            text: parseDateAuto(task.createdDate),
                                        },
                                        {
                                            label: `${t('Task type')}:`,
                                            text: requiredActionsTaskTypeTitleMapper[task.type],
                                        },
                                        {
                                            label: `${t('Requestor')}:`,
                                            text: task.requestor.fullName,
                                        },
                                        {
                                            label: `${t('Requestor comment')}:`,
                                            text: task.requestorComment,
                                        },
                                    ]}
                                />
                            </Flex>

                            {task?.status !== RequiredActionsStatus.Created &&
                                <MicroTableWithoutBorder
                                    isLoading={false}
                                    rows={[
                                        {
                                            label: `${t('Performed date')}:`,
                                            text: parseDateAuto(task?.performDate),
                                        },
                                        {
                                            label: `${t('Attached files')}:`,
                                            noVerticalAlign: true,
                                            text: <RequiredActionAttachedFilesNode
                                                attachments={task.data?.attachedFiles ?? []}
                                                handleDownloadFile={actions.handleDownloadFile}
                                                handlePreviewFile={actions.handlePreviewFile}
                                                handlePreviewAll={actions.openPreviewAllFiles}
                                            />,
                                        },
                                        {
                                            label: `${t('Performer comment')}:`,
                                            text: task.performerComment,
                                        }
                                    ]}
                                />
                            }

                            {task?.status === RequiredActionsStatus.Declined &&
                                <>
                                    <Divider sx={{margin: '16px 0'}} />
                                    <MicroTableWithoutBorder
                                        isLoading={false}
                                        rows={[
                                            {
                                                label: `${t('Resolver')}:`,
                                                text: task.resolver?.fullName ?? 'N/A',
                                            },
                                            {
                                                label: `${t('Resolved date')}`,
                                                text: parseDateAuto(task.resolveDate),
                                            },
                                            {
                                                label: `${t('Decline reason')}:`,
                                                text: task.resolverComment,
                                            }
                                        ]}
                                    />
                                </>
                            }

                            {task?.status === RequiredActionsStatus.Approved &&
                                <>
                                    <Divider sx={{margin: '16px 0'}} />
                                    <MicroTableWithoutBorder
                                        isLoading={false}
                                        rows={[
                                            {
                                                label: `${t('Resolver')}:`,
                                                text: task.resolver?.fullName ?? 'N/A',
                                            },
                                            {
                                                label: `${t('Resolved date')}`,
                                                text: parseDateAuto(task.resolveDate),
                                            },
                                            {
                                                label: `${t('Files added to evidence')}`,
                                                noVerticalAlign: true,
                                                text: <RequiredActionAttachedFilesNode
                                                    attachments={task.data?.attachedFiles?.filter(e => e.addedToEvidence) ?? []}
                                                    handleDownloadFile={actions.handleDownloadFile}
                                                    handlePreviewFile={actions.handlePreviewFile}
                                                    handlePreviewAll={actions.openPreviewAllAddedToEvidenceFiles}
                                                />,
                                            }
                                        ]}
                                    />
                                </>
                            }
                        </>
                    }
                </DialogContent>

                <DialogActions>
                    <Button variant={'text'} size={'small'} onClick={actions.handleClose}>{tCommon('Close')}</Button>
                    {task?.status === RequiredActionsStatus.Created && (
                        <Button size={'small'} onClick={() => handleOpen(task)}>
                            {t('Perform')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <PreviewAllFilesDialog
                task={task}
                isOpen={previewAllDocsDialog.isOpen}
                attachments={previewAllDocsDialog.attachments}
                handleClose={previewAllDocsDialog.handleClose}
                handlePreviewFile={actions.handlePreviewFile}
                handleDownloadFile={actions.handleDownloadFile}
                isEvidenceAttachments={previewAllDocsDialog.isEvidenceAttachments}
            />
        </>
    )
}
