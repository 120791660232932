import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as Yup from "yup";
import {useMemo} from "react";
import {TrainingAssignmentExamAnswerStatus} from "../../../../../../newShared/GQLTypes";

export const useValidationSchema = () => {
    const {t} = useMainTranslation();

    const answerSchema = Yup.string()
        .when("$status", {
            is: TrainingAssignmentExamAnswerStatus.Skipped,
            then: Yup.string(),
            otherwise: Yup.string().required(t('Answer is required')),
        })
    ;

    const questionSchema = Yup.object({
        status: Yup.string()
            .oneOf([TrainingAssignmentExamAnswerStatus.Skipped, TrainingAssignmentExamAnswerStatus.Answered]),
        answers: Yup.array()
            .when("status", {
                is: TrainingAssignmentExamAnswerStatus.Skipped,
                then: Yup.array(),
                otherwise: Yup.array().min(1, t('Answer is required')),
            })
            .of(answerSchema),
    });

    const questionsSchema = Yup.object({
        questions: Yup.array()
            .of(questionSchema)
        // .min(1, t('At least one question is required')),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => questionsSchema, [])
};