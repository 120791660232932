import {useDispatch, useSelector} from "react-redux";
import {
    clearSelectedIncomingTimeOffRequestAction,
    hrIncomingTimeOffSelector,
    hrIsLoadingSelector,
    hrMyTimeOffSelector
} from "../../../store/slice";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useEffect} from "react";
import {HR_TIME_OFF_MANAGEMENT_PATH} from "../../../../../../newShared/constants";
import {useFormik} from "formik";
import * as yup from 'yup';
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    portalHrApproveTimeOffRequestAction,
    portalHrGetAndChangeStepStatusTimeOffRequestAction,
    portalHrGetTimeOffRequestsWithFilterPaginationAction,
    portalHrRejectTimeOffRequestAction
} from "../../../store/actions";
import {DownloadFile} from "../../../../workerSpace/store/actions";
import {
    useGenericFiltersStorage
} from "../../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {HrTimeOffStatus} from "../../../../../../newShared/GQLTypes";
import {GENERIC_FILTER_OPTIONS_DEFAULT_PAGING} from "../../../../../../newShared/components/genericFilter/constants";

export const useIncomingTimeOffRequestExactView = () => {
    const {selected: request} = useSelector(hrIncomingTimeOffSelector);
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const {timeoffId} = useParams<{timeoffId?: string}>();
    const {t} =useMainTranslation('', {keyPrefix: 'pathMyHr.pathTimeOff'});

    const handleApproveRequest = (timeoffId: string) => {
        dispatch(portalHrApproveTimeOffRequestAction({
            data: {requestId: timeoffId, comment: formik.values.comment},
            onError: (request, error, addictiveData) => {
                const errors409 = error.e409;
                if (errors409?.length) {
                    getTimeOffRequestById(timeoffId);
                }
            },
            onSuccess: (request, response, addictiveData) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: [
                        t('Time off request {{requestId}} has been approved', {requestId: timeoffId})
                    ]
                });
                handleClose();
            }
        }))
    }

    const handleRejectRequest = (timeoffId: string) => {
        dispatch(portalHrRejectTimeOffRequestAction({
            data: {requestId: timeoffId, comment: formik.values.comment},
            onError: (request, error, addictiveData) => {
                const errors409 = error.e409;
                if (errors409?.length) {
                    getTimeOffRequestById(timeoffId);
                }
            },
            onSuccess: (request, response, addictiveData) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: [
                        t('Time off request {{requestId}} has been rejected', {requestId: timeoffId})
                    ]
                });
                handleClose();
            }
        }))
    }

    const validationSchema = yup.object({
        comment: yup.string()
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols'))
    });

    const formik = useFormik({
        initialValues: {comment: ''},
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            timeoffId && handleApproveRequest(timeoffId)
        }
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    const {
        incomingTimeOffExact: isLoadingRequest,
        rejectIncomingTimeOffRequest: isLoadingRejectRequest,
        approveIncomingTimeOffRequest: isLoadingApproveRequest,
        downloadingFile
    } = useSelector(hrIsLoadingSelector);

    const isOpen = !!timeoffId;

    const history = useHistory();
    const location = useLocation();
    const handleClose = () => {
        history.push(HR_TIME_OFF_MANAGEMENT_PATH + location.search)
    }

    const handleDownloadFile = (fileId: string, fileName: string) => {
        dispatch(DownloadFile({fileId, fileName}))
    }

    const {pageInfo: {page, count}} = useSelector(hrMyTimeOffSelector);
    const {currentFiltersForFetch, currentSearchForFetch, } = useGenericFiltersStorage();
    const refreshTable = () => {
        dispatch(portalHrGetTimeOffRequestsWithFilterPaginationAction({data: {pageRequest: {page, count: count || GENERIC_FILTER_OPTIONS_DEFAULT_PAGING}, filter: {...currentFiltersForFetch, timeOffIdLike: currentSearchForFetch}}, clean: true}));
    }

    const getTimeOffRequestById = (timeoffId: string) => {
        dispatch(portalHrGetAndChangeStepStatusTimeOffRequestAction({
            data: {requestId: timeoffId},
            onError: (request, error, addictiveData) => {
                setTimeout(() => {handleClose()}, 300)
                refreshTable();
            },
            onSuccess: (request, response, addictiveData) => {
                if (response.status === HrTimeOffStatus.Cancelled) {
                    setTimeout(() => {handleClose()}, 300)
                    refreshTable();
                }
            },
        }))
    }

    const cleanSelected = () => {
        dispatch(clearSelectedIncomingTimeOffRequestAction());
    };

    useEffect(() => {
        if (timeoffId) {
            getTimeOffRequestById(timeoffId);
        } else {
            cleanSelected();
        }
        formik.resetForm();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeoffId])

    return{
        isOpen,
        handleClose,
        isLoadingRejectRequest,
        isLoadingApproveRequest,
        isLoadingRequest,
        request,
        handleRejectRequest,
        handleApproveRequest,
        handleDownloadFile,
        formik: {...formik, handleChange: formikOnChange},
        timeoffId,
        downloadingFile,
    }
}