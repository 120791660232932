import {Box, styled} from "@mui/material";


export const StatusBox = styled(Box,  {
    shouldForwardProp: (propName) => (
        !["bgColor"].includes(propName.toString())
    )})(({bgColor, margin}:{bgColor: string, margin?: string}) => ({
    minWidth: '104px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 14px',
    borderRadius: '15px',
    backgroundColor: bgColor,
    margin,
}));

export const StatusTitle = styled('span')(({color}:{color: string}) => ({
    fontSize: '14px',
    fontWeight: 500,
    color: color
}));
