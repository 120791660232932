import {gql} from "graphql.macro";
import {hrIssueFragment, hrRequestFragment, hrTimeOffFragment} from "./fragments";
import {pageInfoFragment} from "../../../../newShared/graphQl/fragments";

export const portalHrGetProfile = gql`
    query portalHrGetProfile($workspaceId: String!) {
        portalHrGetProfile(workspaceId: $workspaceId) {
            id
            employeeId
            firstName
            lastName
            middleName
            nickname
            workEmail
            birthDay
            country
            city
            street
            zip
            workMobilePhone
            jobTitle
            department
            employmentType
            manager
            hireDate
            employeeStatus {
                id
                name
                textColor
                backgroundColor
            }
        }
    }
`

export const portalHrGetProfileJobHistory = gql`
    query portalHrGetProfileJobHistory($workspaceId: String!, $pageRequest: SharedPaginationInput!) {
        portalHrGetProfileJobHistory(workspaceId: $workspaceId, pageRequest: $pageRequest) {
            result {
                department
                division
                effectiveDate
                employmentType
                jobTitle
                location
                manager
            }
            pageInfo {
                page
                count
                total
            }
        }
    }
`

export const portalHrGetSettingsCountries = gql`
    query portalHrGetSettingsCountries($workspaceId: String!) {
        portalHrGetSettingsCountries(workspaceId: $workspaceId)
    }
`

export const portalHrGetRequestsWithFilterPagination = gql`
    query portalHrGetRequestsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: PortalHrRequestsFilter!) {
        portalHrGetRequestsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...hrRequestFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${hrRequestFragment}
`

export const portalHrGetRequestById = gql`
    query portalHrGetRequestById($workspaceId: String!, $requestId: String!) {
        portalHrGetRequestById(workspaceId: $workspaceId, requestId: $requestId) {
            ...hrRequestFragment
        }
    }
    ${hrRequestFragment}
`

export const portalHrCreateEditRequest = gql`
    mutation portalHrCreateEditRequest($workspaceId: String!, $request: PortalHrEditRequestInputDto!) {
        portalHrCreateEditRequest(workspaceId: $workspaceId, request: $request) {
            message
        }
    }
`

export const portalHrCancelRequest = gql`
    mutation portalHrCancelRequest($workspaceId: String!, $requestId: String!) {
        portalHrCancelRequest(workspaceId: $workspaceId, requestId: $requestId) {
            message
        }
    }
`
export const portalHrGetOrgTree = gql`
    query portalHrGetOrgTree($workspaceId: String!){
        portalHrGetOrgTree(workspaceId: $workspaceId) {
            id
            name
            manager
            jobTitle
            workMobilePhone
            workEmail
        }
    }
`

export const portalHrGetIssuesWithFilterPagination = gql`
    query portalHrGetIssuesWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: PortalHrIssuesFilter!) {
        portalHrGetIssuesWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...hrIssueFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${hrIssueFragment}
`

export const portalHrGetIssueOffenderAutocomplete = gql`
    query portalHrGetIssueOffenderAutocomplete($workspaceId:String!, $pageRequest:SharedPaginationInput!, $search: String!){
        portalHrGetIssueOffenderAutocomplete(workspaceId:$workspaceId, pageRequest:$pageRequest, search: $search){
            result {
                employeeId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`

export const portalHrGetEmployeeAutocomplete = gql`
    query portalHrGetEmployeeAutocomplete($workspaceId:String!, $pageRequest:SharedPaginationInput!, $search: String!){
        portalHrGetEmployeeAutocomplete(workspaceId:$workspaceId, pageRequest:$pageRequest, search: $search){
            result {
                employeeId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`
export const portalHrCreateIssue = gql`
    mutation portalHrCreateIssue($workspaceId: String!, $issue: PortalHrCreateIssueInputDto!) {
        portalHrCreateIssue(workspaceId: $workspaceId, issue: $issue) {
            ...hrIssueFragment
        }
    }
    ${hrIssueFragment}
`


export const portalHrGetIssueById = gql`
    query portalHrGetIssueById($workspaceId: String!, $issueId: String!) {
        portalHrGetIssueById(workspaceId: $workspaceId, issueId: $issueId) {
            ...hrIssueFragment
        }
    }
    ${hrIssueFragment}
`

export const portalHrGetMyTimeOffRequestsWithFilterPagination = gql`
    query portalHrGetMyTimeOffRequestsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: PortalHrMyTimeOffFilter!) {
        portalHrGetMyTimeOffRequestsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...hrTimeOffFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${hrTimeOffFragment}
`


export const portalHrGetTimeOffRequestsWithFilterPagination = gql`
    query portalHrGetTimeOffRequestsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: PortalHrTimeOffFilter!) {
        portalHrGetTimeOffRequestsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...hrTimeOffFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${hrTimeOffFragment}
`
export const portalHrGetTimeOffRequestsSettings = gql`
    query portalHrGetTimeOffRequestsSettings($workspaceId: String!) {
        portalHrGetTimeOffRequestsSettings(workspaceId: $workspaceId) {
            timeOffType
        }
    }
`

export const portalHrUpdateMyTimeOffRequest = gql`
    mutation portalHrUpdateMyTimeOffRequest($workspaceId: String!, $timeOffId: String!, $data: PortalHrCreateTimeOffRequestInputDto!) {
        portalHrUpdateMyTimeOffRequest(workspaceId: $workspaceId, timeOffId: $timeOffId, data: $data) {
            message
        }
    }
`

export const portalHrCancelMyTimeOffRequest = gql`
    mutation portalHrCancelMyTimeOffRequest($workspaceId: String!, $timeOffId: String!) {
        portalHrCancelMyTimeOffRequest(workspaceId: $workspaceId, timeOffId: $timeOffId) {
            message
        }
    }
`
export const portalHrGetTimeOffRequestById = gql`
    query portalHrGetTimeOffRequestById($workspaceId: String!, $requestId: String!) {
        portalHrGetTimeOffRequestById(workspaceId: $workspaceId, requestId: $requestId) {
            ...hrTimeOffFragment
        }
    }
    ${hrTimeOffFragment}
`;

export const portalHrCreateTimeOffRequest = gql`
    mutation portalHrCreateTimeOffRequest($workspaceId: String!, $data: PortalHrCreateTimeOffRequestInputDto!) {
        portalHrCreateTimeOffRequest(workspaceId: $workspaceId, data: $data) {
            message
        }
    }
`

export const portalHrGetTimeOffEmployeesAutocomplete = gql`
    query portalHrGetTimeOffEmployeesAutocomplete($workspaceId:String!, $pageRequest:SharedPaginationInput!, $search: String!){
        portalHrGetTimeOffEmployeesAutocomplete(workspaceId:$workspaceId, pageRequest:$pageRequest, search: $search){
            result {
                employeeId
                fullName
            }
            pageInfo{...pageInfoFragment}
        }
    }
    ${pageInfoFragment}
`
//time off management
export const portalHrGetAndChangeStepStatusTimeOffRequest = gql`
    query portalHrGetAndChangeStepStatusTimeOffRequest($workspaceId:String!, $requestId: String!){
        portalHrGetAndChangeStepStatusTimeOffRequest(workspaceId:$workspaceId, requestId: $requestId){
            ...hrTimeOffFragment
        }
    }
    ${hrTimeOffFragment}
`

export const portalHrApproveTimeOffRequest = gql`
    mutation portalHrApproveTimeOffRequest($workspaceId: String!, $requestId: String!, $comment: String!) {
        portalHrApproveTimeOffRequest(workspaceId: $workspaceId, requestId: $requestId, comment: $comment) {
            message
        }
    }
`

export const portalHrRejectTimeOffRequest = gql`
    mutation portalHrRejectTimeOffRequest($workspaceId: String!, $requestId: String!, $comment: String!) {
        portalHrRejectTimeOffRequest(workspaceId: $workspaceId, requestId: $requestId, comment: $comment) {
            message
        }
    }
`



