import {FC} from "react";
import * as S from "../../components/styled";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {HrTimeOffStatus, PortalHrTimeOffRequestModel} from "../../../../../newShared/GQLTypes";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import AddIcon from '@mui/icons-material/Add';
import {useHrMyTimeOff} from "../../hooks/useHrTimeOff";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {getTimeOffStatusChip} from "../../helpers";
import colors from "../../../../../newShared/theme/colors";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import EditIcon from "@mui/icons-material/Edit";
import {RequestErrorDialog} from "../../components/dialogs/RequestErrorDialog";
import {CancelTimeOffDialog} from "../../components/dialogs/cancelTimeOff";
import {CreateUpdateTimeOffDialog} from "../../components/dialogs/createUpdateTimeOffDialog";
import {TimeOffRequestExactView} from "../../components/dialogs/timeOffRequestExactView";
import {TimeOffHistoryDialog} from "../../components/dialogs/timeOffHistory";
import {useCreateUpdateTimeOffDialog} from "../../hooks/dialogs/useCreateUpdateTimeOffDialog";
import {useCancelTimeOffDialog} from "../../hooks/dialogs/useCancelTimeOff";

export const HrMyTimeOff: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathTimeOff'});
    const {isMobile} = useMedia();
    const {
        requests,
        genericTable,
        handleOpenTimeOff,
        handleOpenUpdate,
    } = useHrMyTimeOff();

    const {handleOpen} = useCreateUpdateTimeOffDialog();
    const {handleOpenCancelTimeOff} = useCancelTimeOffDialog();


    return (
        <S.PageWrapper>
            <PageTitleAndActions
                title={t('My time off requests')}
                actions={[
                    {
                        title: t('Create time off requests'),
                        onClick: () => handleOpen(),
                        icon: <AddIcon />,
                        size: 'small'
                    },
                ]}
            />

            <GenericTable<PortalHrTimeOffRequestModel>
                id={'MyHrMyTimoOff'}
                columnsConfig={{totalName: 'Total requests', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: requests,
                    customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenTimeOff,
                    rowActionsConfig: [
                        {
                            title: t('Edit request'),
                            onClick: handleOpenUpdate,
                            icon: <EditIcon fontSize={'small'} style={{color: colors.primary.blue}}/>,
                            dontShow: (row) => row.status !== HrTimeOffStatus.Created
                        },
                        {
                            title: t('Cancel time off request'),
                            onClick: (row) => handleOpenCancelTimeOff(row.id),
                            icon: <CancelScheduleSendIcon fontSize={'small'} style={{color: colors.primary.blue}}/>,
                            dontShow: (row) => row.status !== HrTimeOffStatus.Created
                        },
                    ],

                    columnsConfigs: [
                        {key: "id", name: t('ID'), default: true, },
                        {key: 'startDate', name: t('Start date'), default: true, valueGetter: (row) => parseDateAuto(row.startDate, undefined, undefined, undefined, '-')},
                        {key: 'endDate', name: t('End date'), default: true, valueGetter: (row) => parseDateAuto(row.endDate, undefined, undefined, undefined, '-')},
                        {key: "type", name: t('Time off type'), default: true, },
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getTimeOffStatusChip(row.status)},
                        {key: 'requestDate', name: t('Request date'), default: true, valueGetter: (row) => parseDateAuto(row.requestDate, undefined, undefined, undefined, '-')},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'timeOffIdLike',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('You do not have any time off requests yet'), secondaryText: ''}}}
            />

            <RequestErrorDialog />
            <CancelTimeOffDialog />
            <TimeOffHistoryDialog />
            <TimeOffRequestExactView/>
            <CreateUpdateTimeOffDialog/>
        </S.PageWrapper>
    );
};
