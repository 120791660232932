import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import BlockIcon from '@mui/icons-material/Block';
import {Flex} from '../../../../newShared/components/Layouts';
import 'moment/locale/he';
import 'moment/locale/en-au';
import {
    applicationAccessDataWithoutAccounts,
    campaignReviewExecutorAccountFillableType,
    campaignReviewExecutorStageActorAppData,
    campaignReviewExecutorStageActorType,
    campaignReviewExecutorStageType
} from "../types";
import {
    ACCOUNT_DELETE_STATUS,
    ACCOUNT_KEEP_STATUS,
    ACCOUNT_REVOKE_STATUS,
    COMPLETE_STATUS,
    IN_PROGRESS_STATUS,
    TERMINATED_STATUS
} from "../constants";
import colors from '../../../../newShared/theme/colors';


export const getStatus = (status: string | null) => {
    switch(status){
        case TERMINATED_STATUS: return <BlockIcon style={{color: '#FF5959'}}/>;
        case COMPLETE_STATUS: return <CheckCircleOutlineOutlinedIcon style={{color: '#3BCC89'}}/>;
        case 'completed': return <CheckCircleOutlineOutlinedIcon style={{color: '#3BCC89'}}/>;
        case IN_PROGRESS_STATUS: return <TimerOutlinedIcon style={{color: '#FFA000'}}/>
        case 'failed': return <BlockIcon style={{color: '#FF5959'}}/>
        case null: return <Flex w={'20px'} h={'20px'} br={'50%'} border={'1px solid #A0A2B1'} />
    }
}

export const makeFirstLetterUpper = (text: string) => text.split('').map((e, id) => id === 0 ? e.toUpperCase() : e).join('');

export const shortString = (text: string, howMuch: number) => text.split('').length > howMuch ? text.split('').slice(0, howMuch).concat('.', '.', '.').join('') : text;

//

export const calculateIfDeadlinePassed = (stage?: campaignReviewExecutorStageType | null): boolean => {
    if(stage && stage.status === IN_PROGRESS_STATUS){
        return Date.parse(stage.expirationDate!) < Date.now()
    }
    return false;
}

export const mapAppsByAccountIds = (accountIds: string[], actor: campaignReviewExecutorStageActorType, setStatus: string, isManagers: boolean): campaignReviewExecutorStageActorAppData[] => {
    const res: campaignReviewExecutorStageActorAppData[] = [];

    (actor.appsData ?? []).forEach(e => {
        const app:campaignReviewExecutorStageActorAppData = {...e, data: []};
        e.data.forEach(data => {
            if(accountIds.some(a => a === data.id)){
                //if this account selected - changing status and pushing to new app obj
                app.data.push({...data, statusManager: isManagers ? setStatus : data.statusManager, statusVendor: !isManagers ? setStatus : data.statusVendor});
            }
        })
        if(app.data.length > 0) res.push(app);
    })

    return res;
}

export type groupByEmployeeReturnType = {
    [key: string]: employeeAndAccountsWithApp
}

export interface campaignReviewExecutorAccountFillableTypeWithApplication extends campaignReviewExecutorAccountFillableType{
    applicationAccessId: string,
    applicationAccessName: string,
    applicationId: string | null,
    vendorId: string,
    vendorName: string | null
}

export type employeeAndAccountsWithApp = {
    employee: string,
    employeeId: string,
    accounts: campaignReviewExecutorAccountFillableTypeWithApplication[]
}

export const groupByEmployee = (apps: campaignReviewExecutorStageActorAppData[]): employeeAndAccountsWithApp[] => {
    const res: groupByEmployeeReturnType = {};
    apps.forEach(app => {
        app.data.forEach(data => {
            if(data.employeeId){
                const obj = res[data.employeeId];
                if(obj){
                    //if already added - adding current account with appId
                    const newObj = {
                        ...obj,
                        accounts: [...obj.accounts, {
                            ...data,
                            applicationAccessId: app.applicationAccessId,
                            applicationAccessName: app.applicationAccessName,
                            applicationId: app.applicationId,
                            vendorId: app.vendorId,
                            vendorName: app.vendorName
                        }]
                    }
                    res[data.employeeId] = newObj;
                }else{
                    //no such employee in array
                    res[data.employeeId] = {
                        employee: data.employee ?? '',
                        employeeId: data.employeeId,
                        accounts: [{
                            ...data,
                            applicationAccessId: app.applicationAccessId,
                            applicationAccessName: app.applicationAccessName,
                            applicationId: app.applicationId,
                            vendorId: app.vendorId,
                            vendorName: app.vendorName
                        }]
                    }
                }
            }
        })
    })
    return Object.values(res);
}

export const getColorAndTextByAccountStatus = (status: string | null): {text: string, color: string} => {
    switch (status){
        case ACCOUNT_KEEP_STATUS: return {text: 'Kept', color: colors.text.success};
        case ACCOUNT_REVOKE_STATUS: return {text: 'Revoked', color: colors.warningActions.red};
        case ACCOUNT_DELETE_STATUS: return {text: 'Delete account', color: colors.text.grey_dark};
        default: return {text: 'Pending', color: colors.decorative.orange};
    }
}

export const mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest = (account: campaignReviewExecutorAccountFillableTypeWithApplication): {app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType} => {
    return {
        app: {
            applicationAccessId: account.applicationAccessId,
            applicationAccessName: account.applicationAccessName,
            applicationId: account.applicationId,
            vendorId: account.vendorId,
            vendorName: account.vendorName
        },
        account: {
            employee: account.employee,
            employeeId: account.employeeId,
            account: account.account,
            accountId: account.accountId,
            accountType: account.accountType,
            roles: account.roles,
            status: account.status,

            id: account.id,
            commentManager: account.commentManager,
            statusManager: account.statusManager,
            publicIdManager: account.publicIdManager,
            commentVendor: account.commentVendor,
            statusVendor: account.statusVendor,
            publicIdVendor: account.publicIdVendor,
        }
    }

}