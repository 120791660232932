import {assetsSelector, cleanSelectedAssetAction, loadingsSelector, TAssetsSlice} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {portalAssetsGetPhysicalAssetByIdAction} from "../../store/actions";

type TResponse = {
    asset: TAssetsSlice['assets']['selected'],
} & Record<string, any>;

export const usePhysicalAssetExact = () => {
    const dispatch = useDispatch();

    const {selected: asset} = useSelector(assetsSelector);
    const {assetExact: isLoading} = useSelector(loadingsSelector);

    const getPhysicalAsset = (assetId: string) => {
        dispatch(portalAssetsGetPhysicalAssetByIdAction({
            data: {assetId},
            disableSnackOnApiError: true
        }))
    }

    const cleanSelected = () => {
        dispatch(cleanSelectedAssetAction())
    }

    return {
        asset,
        isLoading,

        getPhysicalAsset,
        cleanSelected,
    } satisfies TResponse
}