export const SETTING_ROOT_PATH = '/settings';

//PROFILE
export const SETTINGS_PROFILE_PATH = `${SETTING_ROOT_PATH}/profile`;

//LANGUAGE
export const SETTINGS_LANGUAGE_PATH = `${SETTING_ROOT_PATH}/language`;

//SECURITY
export const SETTINGS_SECURITY_PATH = `${SETTING_ROOT_PATH}/security`;

//SELECTOR
export const DEFAULT_LOGO_URL = 'https://firebasestorage.googleapis.com/v0/b/regulait-dev.appspot.com/o/logos%2Fdefault_logos%2Forganization_default_logo.svg?alt=media&token=f716d380-c5a3-413d-a676-870deace147e';

export const SAVE_WS_PREFIX = 'wsSelection#';
// export const SAVE_PATH_PREFIX = 'lastPath#';
export const SAVE_NEXT_PATH_PREFIX = 'nextPath#';

export const ROLE_WS_OWNER = 'ROLE_WORKSPACE_OWNER';
export const ROLE_WS_ADMIN = 'ROLE_WORKSPACE_ADMIN';
