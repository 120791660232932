import {gql} from "@apollo/react-hooks";


export const portalRequiredActionModelFragment = gql`
    fragment portalRequiredActionModelFragment on PortalRequiredActionModel {
        id
        workspaceId
        title
        description
        requestor{
            collaboratorId
            email
            fullName
        }
        createdDate
        requestorComment
        resolver{
            collaboratorId
            email
            fullName
        }
        resolveDate
        resolverComment
        performDate
        performerComment
        status
        type
        source
        data {
            attachedFiles {
                fileName
                fileId
                addedToEvidence
            }
        }
       
    }
`
