import React, {FC, useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {FlexRow} from "../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useTrainingsDialogs} from "../../../hooks/dialogs/useTrainingsDialogs";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {loadingsSelector} from "../../../store/slice";
import {useHistory, useParams} from "react-router-dom";
import {portalTrainingsStartExamAction} from "../../../store/actions";
import {East, West} from "@mui/icons-material";
import colors from "../../../../../../newShared/theme/colors";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {TRAININGS_ASSIGNMENTS_PATH} from "../../../../../../newShared/constants";
import {TrainingAssignmentStatus} from "../../../../../../newShared/GQLTypes";

export const StartExamDialog: FC<{refreshAssignment: () => void }> = ({refreshAssignment}) => {
    const {t, tCommon, revDir} = useMainTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {setMessage} = useMessageDialog();

    const {startingExam} = useSelector(loadingsSelector);
    const {isOpen, handleClose} = useTrainingsDialogs().startExam;
    const {setIsOpen: setIsOpenPassingExamDialog} = useTrainingsDialogs().passingExam;

    const {assignmentId} = useParams<{assignmentId?: string}>();

    const startExam = () => {
        assignmentId && isOpen && dispatch(portalTrainingsStartExamAction({
            data: {
                assignmentId, type: isOpen.type
            },
            onSuccess: () => {
                setIsOpenPassingExamDialog({assignmentId, type: isOpen.type});
            },
            onError: (request, errors, addictiveData) => {
                // const e400 = errors.e400?.[0];
                const e404 = errors.e404?.[0];
                const e409 = errors.e409?.[0];

                if (e404) {
                    setMessage({title: 'Start assignment exam', message: 'Assignment not found.'});
                    handleClose();
                    history.push(TRAININGS_ASSIGNMENTS_PATH);
                }

                if (e409?.type === 'STATUS_CHANGED') {
                    const value = e409?.values.find(e => e.parameter === 'status')?.value;
                    if (value === TrainingAssignmentStatus.Cancelled) {
                        setMessage({title: 'Start assignment exam', message: 'Assignment was cancelled.'});
                        handleClose();
                        history.push(TRAININGS_ASSIGNMENTS_PATH);
                    } else {
                        setMessage({title: 'Start assignment exam', message: 'Assignment status was changed.'});
                        refreshAssignment();
                        handleClose();
                    }
                }

                if (e409?.type === 'EXAM_NOT_EXISTS') {
                    setMessage({title: 'Start assignment exam', message: 'Exam not found.'});
                    refreshAssignment();
                    handleClose();
                }
            }
        }))
    };

    const arrow = revDir ? <West sx={{fontSize: '18px', color: colors.primary.blue_light}}/> : <East sx={{fontSize: '18px', color: colors.primary.blue_light}}/>;

    useEffect(() => {
        return () => {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dialog open={!!isOpen} onClose={handleClose}>
            <DialogTitle component={FlexRow} sx={{gap: '12px', alignItems: 'center'}}>
                {t('Start exam')}
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', gap: '16px'}}>
                {isOpen?.limit ? (
                    <>
                        <Typography variant={'*bodyText2_semibold'}>{t(`The exam has limits time to pass {{limit}} minutes!`, {limit: isOpen.limit})}</Typography>

                        <FlexRow sx={{gap: '8px'}}>
                            {arrow}
                            <Typography variant={'*bodyText2'} sx={{flexGrow: 1}}>{t('The timer will start when you start the exam.')}</Typography>
                        </FlexRow>

                        <FlexRow sx={{gap: '8px'}}>
                            {arrow}
                            <Typography variant={'*bodyText2'} sx={{flexGrow: 1}}>{t('You can not interrupt the exam after starts.')}</Typography>
                        </FlexRow>

                        <FlexRow sx={{gap: '8px'}}>
                            {arrow}
                            <Typography variant={'*bodyText2'} sx={{flexGrow: 1}}>{t('If you close the exam’s page after the starts, the exam will be marked as abandoned and you can not repeat the exam.')}</Typography>
                        </FlexRow>
                    </>
                ) : (
                    <Typography variant={'*bodyText2'}>{t('If you close the exam’s page after the start exam, your progress will be reset and you will have to pass this exam again.')}</Typography>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'} size={'small'}>{tCommon('Cancel')}</Button>
                <LoadingButton size={'small'} loading={startingExam} onClick={startExam}>
                    {t('Start exam')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}