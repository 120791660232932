import {useDispatch, useSelector} from "react-redux";
import {
    eraseIssueList,
    hrIsLoadingSelector,
    hrMyIssuesSelector,
    openCreateIssue,
    openIssueView,
    replaceIssueMinMaxLoadedPage,
} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {HR_ISSUES_PATH, HR_ISSUES_PATH_EXACT, HR_PROFILE_PATH,} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {portalHrGetIssuesWithFilterPaginationAction,} from "../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getIssueStatusChip} from "../../helpers";
import {HrIssuesStatus, HrRequestRequestorModel, PortalHrIssueModel} from "../../../../../newShared/GQLTypes";
import {resultToValues} from "../../../../../newShared/components/genericFilter/helpers";
import {portalHrGetIssueOffenderAutocompleteApi} from "../../api";
import {TIdName} from "../../../../../newShared/types";
import {issueTypeVariants} from "../../constants";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";


export const useHrIssues = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathMyHrIssues'});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {issues, pageInfo, minMaxLoadedPage} = useSelector(hrMyIssuesSelector);
    const {issueList: isLoadingList} = useSelector(hrIsLoadingSelector);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;


    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: HR_PROFILE_PATH
        },
        {
            title: tMenu('My issues'),
            path: HR_ISSUES_PATH
        },
    ]);

    const handleOpenAddIssue = () => {
        dispatch(openCreateIssue())
    }

    const handleOpenIssue = (selected: PortalHrIssueModel) => {
        selected && history.push(`${HR_ISSUES_PATH_EXACT.replace(':issueId', selected?.id)}${location.search}`);
        dispatch(openIssueView());
    }



    //filters
    const issueTypeConfig: keyConfig<string> = {
        type: 'filter',
        key: 'issueType',
        name: t('Issue type'),
        options: issueTypeVariants,
        getOptionLabel: (a) => t(a),
        default: true
    }

    const statusConfig: keyConfig<HrIssuesStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(HrIssuesStatus),
        OptionRenderer: (a) => getIssueStatusChip(a, true),
        default: true,
    }

    const offenderConfig: keyConfig<HrRequestRequestorModel> = {
        type: 'filter',
        key: 'offender',
        name: t('Offender'),
        fetchOptions: resultToValues<HrRequestRequestorModel>(portalHrGetIssueOffenderAutocompleteApi),
        getOptionLabel: (a) => a.fullName,
        getFilterValue: (a) => a.employeeId,
        isOptionEqual: (a, b) => a.employeeId === b.employeeId,
        isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }

    const isAnonymousConfig: keyConfig<TIdName> = {
        type: 'filter',
        key: 'anonymous',
        name: t('Is anonymous'),
        options: [{id: 'true', name: 'Yes'}, {id: 'false', name: 'No'}],
        getOptionLabel: (e) => e.name,
        getFilterValue: (e) => e.id,
        isOptionEqual: (a, b) => a.id === b.id,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }

    const requestDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'requestDate',
        name: t('Reported date'),
        default: true
    }


    return {
        issues,
        pageInfo,

        handleOpenAddIssue,
        handleOpenIssue,

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceIssueMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseIssueList()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, issueIdLike: string) => {
                    dispatch(portalHrGetIssuesWithFilterPaginationAction({data: {pageRequest: {page, count}, filter: {...filters, issueIdLike}}}));
                },
            },
            filters: {
                configs: [
                    issueTypeConfig,
                    statusConfig,
                    offenderConfig,
                    isAnonymousConfig,
                    requestDateConfig
                ]
            }
        }
    }
}
