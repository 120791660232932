import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {FC} from "react";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import moment from "moment";

export type ExamInfoTimerProps = {
    currentDuration: number;
    examLimitInMin: number;
    totalQuestions: number;
    answeredQuestions: number;
}
export const ExamInfoTimer: FC<ExamInfoTimerProps> = ({currentDuration, examLimitInMin, totalQuestions, answeredQuestions}) => {
    const {t} = useMainTranslation();

    const timeToEndInSecs = examLimitInMin * 60 - currentDuration;
    const formattedTimeToEnd = timeToEndInSecs > 0 ? moment.utc(timeToEndInSecs * 1000).format('HH:mm:ss') : '00:00:00';

    return(
        <Flex
            minw={'279px'}
            w={'100%'}
            direction={'column'}
            p={'20px'}
            gap={'20px'}
            br={'8px'}
            border={`1px solid ${colors.stroke.table}`}
        >
            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Information')}</Typography>

            {examLimitInMin > 0 && (
                <Flex ai={'center'} gap={'12px'}>
                    <AccessTimeOutlinedIcon sx={{color: colors.primary.blue}}/>
                    <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t(`Maximum duration {{examLimitInMin}} minutes`, {examLimitInMin})}</Typography>
                </Flex>
            )}

            <Flex ai={'center'} gap={'12px'}>
                <CheckCircleOutlineOutlinedIcon sx={{color: colors.primary.blue}}/>
                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Progress {{answeredQuestions}}/{{totalQuestions}}', {answeredQuestions, totalQuestions})}</Typography>
            </Flex>

            {examLimitInMin > 0 && (
                <>
                    <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{t('Count down')}</Typography>

                    <Flex gap={'40px'} ai={'center'}>
                        <Typography variant={'*h3'}>{formattedTimeToEnd}</Typography>

                        <Flex w={'50%'}>
                            <Typography variant={'*bodySmall'} color={colors.text.grey_dark}>
                                {t('You will not be able to complete this exam when the timer ends.')}
                            </Typography>
                        </Flex>
                    </Flex>
                </>
            )}
        </Flex>
    )
}