import {FC} from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {HrEmployeeStatusModel} from "../../../../../newShared/GQLTypes";

export const StatusChip: FC<{status: HrEmployeeStatusModel | null}> = ({status}) => {
    const firstUPPER = (status?.name || 'Unknown status')
        .toLowerCase()
        .split('')
        .map((word, index) => index === 0 ? word.toUpperCase() : word).join('');

    return (
        <Flex background={status?.backgroundColor ?? colors.backgrounds.grey_light} p={'6px 14px'} br={'15px'}>
            <Typography color={status?.textColor ?? colors.grayText} variant={'*bodyText2'}>{firstUPPER}</Typography>
        </Flex>
    )
}