import {useDispatch, useSelector} from "react-redux";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {CommonFilterType, minMaxLoadedPageType,} from "../../../../../newShared/components/genericTable/types";
import {
  eraseRequiredActionsList,
  loadingsSelector,
  replaceRequiredActionsMinMaxLoadedPage,
  requiredActionsSelector
} from "../../store/slice";
import {portalGetRequiredActionsWithFilterPaginationAction} from "../../store/actions";
import {
  PortalRequiredActionsCollaboratorModel,
  RequiredActionsStatus,
  RequiredActionsType
} from "../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {portalRequiredActionsRequestorAutocompleteApi, portalRequiredActionsResolverAutocompleteApi} from "../../api";
import {RequiredActionsStatusChips} from "../../components/requiredActionStatus";
import {requiredActionsTaskTypeTitleMapper} from "../../constants";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {REQUIRED_ACTIONS_TASKS_EXACT_PATH, REQUIRED_ACTIONS_TASKS_PATH} from "../../../../../newShared/constants";
import {
  useGenericFiltersStorage
} from "../../../../../newShared/components/genericFilter/hooks/useGenericFiltersStorage";
import {useHistory} from "react-router-dom";

export const useRequiredActionsPage = () => {
  const {t, tMenu} = useMainTranslation("", {keyPrefix: "pathRequiredActions.pathTasks"});

  const dispatch = useDispatch();
  const { tasks, pageInfo, minMaxLoadedPage } = useSelector(requiredActionsSelector);
  const { minLoadedPage, maxLoadedPage } = minMaxLoadedPage;
  const { requiredActionsList } = useSelector(loadingsSelector);
  const history = useHistory();

  useSetBreadcrumbs([
    {
      title: tMenu('Required actions'),
      path: REQUIRED_ACTIONS_TASKS_PATH
    },
  ]);


  const handleOpenExact = (taskId: string) => {
    history.push({pathname: `${REQUIRED_ACTIONS_TASKS_EXACT_PATH.replace(':taskId', taskId)}`, search: history.location.search});
  }


  //filters
  const statusConfig: keyConfig<RequiredActionsStatus> = {
    type: "filter",
    key: "status",
    name: t("Status"),
    options: Object.values(RequiredActionsStatus),
    OptionRenderer: (a) => RequiredActionsStatusChips({status: a}),
    default: true,
  };

  const typeConfig: keyConfig<RequiredActionsType> = {
    type: "filter",
    key: "taskType",
    name: t("Task type"),
    options: Object.values(RequiredActionsType),
    getOptionLabel: (a) => requiredActionsTaskTypeTitleMapper[a],
    default: false,
  };


  const resolverConfig: keyConfig<PortalRequiredActionsCollaboratorModel> = {
    type: "filter",
    key: "resolver",
    name: t("Resolver"),
    fetchOptions: (workspaceId, {page, count, search}) => portalRequiredActionsResolverAutocompleteApi({workspaceId, pageRequest: {page, count}, search})
        .then(({result, pageInfo}) => ({values: result, pageInfo})),
    getOptionLabel: (a) => a.fullName,
    getFilterValue: (a) => a.collaboratorId,
    isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
    isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: false,
  };

  const requestorConfig: keyConfig<PortalRequiredActionsCollaboratorModel> = {
    type: "filter",
    key: "requestor",
    name: t("Requestor"),
    fetchOptions: (workspaceId, {page, count, search}) => portalRequiredActionsRequestorAutocompleteApi({workspaceId, pageRequest: {page, count}, search})
        .then(({result, pageInfo}) => ({values: result, pageInfo})),
    getOptionLabel: (a) => a.fullName,
    getFilterValue: (a) => a.collaboratorId,
    isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
    isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
    default: true,
  };

  const startDateConfig: keyConfig<string> = {
    type: "date",
    key: "createdDate",
    name: t("Created date"),
    default: true,
  };

  const resolveDateConfig: keyConfig<string> = {
    type: "date",
    key: "resolveDate",
    name: t("Resolved date"),
    default: false,
  };

  const performDateConfig: keyConfig<string> = {
    type: "date",
    key: "performDate",
    name: t("Performed date"),
    default: false,
  };

  const {currentFiltersForFetch, currentSearchForFetch, getCurrentPage} = useGenericFiltersStorage();

  const refreshTable = () => {
    dispatch(portalGetRequiredActionsWithFilterPaginationAction({data: {pageRequest: { page: getCurrentPage(), count: pageInfo.count }, filter: { ...currentFiltersForFetch, taskIdLike: currentSearchForFetch }}, clean: true}));
  }

  return {
    tasks,
    handleOpenExact,
    refreshTable,

    genericTable: {
      paging: {
        pageInfo,
        isLoading: requiredActionsList,
        minLoadedPage,
        maxLoadedPage,
        setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceRequiredActionsMinMaxLoadedPage(data)),
        clearRows: () => dispatch(eraseRequiredActionsList()),
        fetchPaging: (page: number, count: number, filters: CommonFilterType) => {
          dispatch(portalGetRequiredActionsWithFilterPaginationAction({data: {pageRequest: { page, count }, filter: filters}}));
        },
      },
      filters: {
        configs: [
          requestorConfig,
          startDateConfig,
          statusConfig,

          typeConfig,
          resolverConfig,
          resolveDateConfig,
          performDateConfig,
        ],
      },
    },
  };
};
