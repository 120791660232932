import {FC, useEffect} from "react";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {TRAININGS_ASSIGNMENTS_PATH} from "../../../../../newShared/constants";
import {useDispatch, useSelector} from "react-redux";
import {portalStartTrainingAction, portalTrainingsGetAssignmentDetailsAction} from "../../store/actions";
import {
    assignmentsSelector,
    eraseSelectedAssignment,
    hideStartTrainingDialog,
    loadingsSelector,
    openStartTrainingDialog,
    setPreviewFinishedTrainingDialogAction
} from "../../store/slice";
import {StartTrainingDialog} from "../../components/dialogs/startTrainingDialog";
import {PassingExamDialog} from "../../components/dialogs/passingExamDialog";
import {StartExamDialog} from "../../components/dialogs/startExamDialog";
import {useTrainingsDialogs} from "../../hooks/dialogs/useTrainingsDialogs";
import {
    MainTrainingProgressStatus,
    TrainingAssignmentExamType,
    TrainingAssignmentObjectStatus,
    TrainingAssignmentStatus
} from "../../../../../newShared/GQLTypes";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useHistory, useParams} from "react-router-dom";
import * as S from "../../components/styled";
import {AssignmentTrainingCard} from "../../components/styled";
import {Box, Divider, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {getAssignmentTrainingAndExamStatusChip, getTrainingAssignmentStatusChip} from "../../helpers";
import {CoverImageView} from "../../components/CoverImageView";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {AccessTime, AllInclusive, CheckCircleOutlined, HelpOutline} from "@mui/icons-material";
import {ExamResultDialog} from "../../components/dialogs/examResultDialog";
import {PreviewFinishedTrainingDialog} from "../../components/dialogs/previewFinishedTrainingDialog";

export const AssignmentsExact: FC = () => {
    // const {t} = useMainTranslation('', {keyPrefix: 'pathTrainings.pathAssignmentsList'});
    const {isLessThen1050, isTablet} = useMedia();

    const dispatch = useDispatch();
    const history = useHistory();
    const {setMessage} = useMessageDialog();
    const {startTrainingById} = useSelector(loadingsSelector);
    const {selected: assignment} = useSelector(assignmentsSelector);
    const { assignmentId } = useParams<{assignmentId: string}>();
    const isContinueLearning = assignment && assignment.training && assignment.training.status === TrainingAssignmentObjectStatus.InProgress;
    const isTrainingComplete = assignment && assignment.training ? assignment.training.status === TrainingAssignmentObjectStatus.Done : true;

    const handleStartTraining = () => {
        if(isContinueLearning){
            dispatch(openStartTrainingDialog());
        }else{
            dispatch(portalStartTrainingAction({
                data: {id: assignmentId},
                onSuccess: () => {
                    dispatch(openStartTrainingDialog());
                },
                onError: (_, error) => {
                    const error409 = error.e409?.[0];
                    if (error409?.type === 'STATUS_CHANGED') {
                        setMessage({
                            title: ('Action conflict error'),
                            message: ('Action not applicable! Assignment status was changed.'),
                        });
                    }
                    if (error409?.type === 'TRAINING_NOT_EXISTS') {
                        setMessage({
                            title: ('Action conflict error'),
                            message: ('Action not applicable! Training not found.'),
                        });
                    }
                }
            }))
        }
    }

    const handlePreviewTraining = () => {
        dispatch(setPreviewFinishedTrainingDialogAction({isOpen: true}));
    }

    const startExamDialog = useTrainingsDialogs().startExam;
    const handleStartExam = () => startExamDialog.setIsOpen({type: TrainingAssignmentExamType.PostExam, limit: assignment?.postExam?.timeToPass || 0});

    const getAssignmentById = (assignmentId: string) => {
        dispatch(portalTrainingsGetAssignmentDetailsAction({
            data: {assignmentId},
            onError: (request, errors, addictiveData) => {
                const e409 = errors.e409?.[0];
                const e404 = errors.e404?.[0];

                if (e404) {
                    setMessage({title: `Assignment ${assignmentId}`, message: 'Assignment not found.'});
                    history.push(TRAININGS_ASSIGNMENTS_PATH);
                }

                if (e409?.type === 'STATUS_CHANGED') {
                    setMessage({title: `Assignment ${assignmentId}`, message: 'Assignment was cancelled.'});
                    history.push(TRAININGS_ASSIGNMENTS_PATH);
                }
            }
        }))
    };

    const getValidationPoints = () => {
        let totalPoints: number = 0;
        assignment?.postExam?.questions && assignment.postExam.questions.forEach(e => {
            if (e?.validation?.points) totalPoints += e.validation.points || 0;
        });
        return totalPoints;
    }

    useEffect(() => {
        if (assignmentId) getAssignmentById(assignmentId);

        return () => {
            dispatch(eraseSelectedAssignment());
            dispatch(hideStartTrainingDialog());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignmentId]);

    const examResultDialog = useTrainingsDialogs().examResultDialog;
    const handleViewExamResult = () => assignment && examResultDialog.handleSet({assignment});

    // console.log(`
    // !isTrainingComplete ${isTrainingComplete}
    // ${assignment && (!assignment.postExam ||
    //     ![TrainingAssignmentObjectStatus.Todo, TrainingAssignmentObjectStatus.InProgress].includes(assignment.postExam.status))}
    //
    //     ${assignment?.status}
    //
    //     totalTrue - ${Boolean(
    //     !isTrainingComplete
    //     || (assignment && (!assignment.postExam || ![TrainingAssignmentObjectStatus.Todo, TrainingAssignmentObjectStatus.InProgress].includes(assignment.postExam.status)))
    // )}
    // `)


    return (
        <PageWrapper>
            <PageTitleAndActions margin={'0 0 8px 0'}
                                 title={assignment ? `${('Assignment ID')} ${assignment.id}` : undefined}
                                 handleGoBack={TRAININGS_ASSIGNMENTS_PATH}
                                 actions={[
                                     {
                                         title: isContinueLearning ? ('Continue learning') : ('Start training'),
                                         onClick: handleStartTraining,
                                         loading: startTrainingById,
                                         dontShowWhen: Boolean(assignment === null ||
                                             (assignment && assignment.training === null) ||
                                             ((assignment && assignment.training && assignment.training.status !== TrainingAssignmentObjectStatus.Todo && assignment.training.status !== TrainingAssignmentObjectStatus.InProgress))),
                                     },
                                     {
                                         title: ('View training'),
                                         variant: 'outlined',
                                         onClick: handlePreviewTraining,
                                         dontShowWhen: Boolean(assignment === null ||
                                             (assignment && assignment.training === null) ||
                                             ((assignment && assignment.training && assignment.training.status !== TrainingAssignmentObjectStatus.Done && assignment.training.status !== TrainingAssignmentObjectStatus.Expired)))
                                     },
                                     {
                                         title: 'Start exam',
                                         onClick: handleStartExam,
                                         dontShowWhen: Boolean(
                                             !isTrainingComplete
                                             || (assignment && (!assignment.postExam || ![TrainingAssignmentObjectStatus.Todo, TrainingAssignmentObjectStatus.InProgress].includes(assignment.postExam.status)))
                                         ),
                                         disabled: !assignment,
                                     },
                                     {
                                         title: ('Exam result'),
                                         onClick: handleViewExamResult,
                                         size: "small",
                                         variant: 'outlined',
                                         dontShowWhen: !(assignment?.status === TrainingAssignmentStatus.Passed || assignment?.status === TrainingAssignmentStatus.Failed),
                                         disabled: !assignment
                                     },
                                 ]}
            />

            <S.AssignmentExactHeader>
                {assignment &&
                    <>
                        <S.AssignmentExactHeaderElement>
                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{('Due date')}</Typography>
                            <Typography variant={'subtitle2'}>{assignment.endDate ?? '-'}</Typography>
                        </S.AssignmentExactHeaderElement>

                        <S.AssignmentExactHeaderElement>
                            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{('Status')}</Typography>
                            <Typography variant={'subtitle2'}>{getTrainingAssignmentStatusChip(assignment.status)}</Typography>
                        </S.AssignmentExactHeaderElement>

                        {(assignment.postExam?.status ===  TrainingAssignmentObjectStatus.Passed || assignment.postExam?.status ===  TrainingAssignmentObjectStatus.Failed)  &&
                            <S.AssignmentExactHeaderElement>
                                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{('Your points')}</Typography>
                                <Typography variant={'subtitle2'}>{getValidationPoints()}</Typography>
                            </S.AssignmentExactHeaderElement>
                        }
                    </>
                }

            </S.AssignmentExactHeader>

            <S.AssignmentExactContent>
                {assignment?.training &&
                    <S.AssignmentTrainingCard isLessThen1050={isLessThen1050}>
                        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant={'*h3'} >{('Training')}</Typography>
                            {getAssignmentTrainingAndExamStatusChip({assignmentStatus: assignment.status, trainingStatus: assignment.training.status}).trainingChip}
                        </Box>

                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"}/>

                        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'16px'}>
                            {assignment.training?.trainingCover && <CoverImageView cover={assignment.training?.trainingCover} size={isTablet ? 'list' : 'preview'}/>}

                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={'12px'} height={'100%'} >
                                <Typography variant={'*bodyText1_semibold'}>{assignment.training.trainingName}</Typography>
                                <Typography variant={'*bodyText2'}>{assignment.training.trainingDescription}</Typography>
                            </Box>

                        </Box>

                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"} sx={{margin: 'auto 0 0 0'}}/>

                        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'16px'} flexWrap={'wrap'}>
                            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.optional}} borderRadius={'50%'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.blue_light_1}} borderRadius={'50%'}>
                                        <CheckCircleOutlined style={{color: colors.primary.hovered}} />
                                    </Box>
                                </Box>

                                <Typography variant={'*bodyText2_semibold'} sx={{whiteSpace: 'nowrap'}}>
                                    {`${('Progress')} ${assignment.training.content.filter(e => e.status === MainTrainingProgressStatus.Done).length}/${assignment.training.content.length} ${('section')}`}
                                </Typography>

                            </Box>

                            {assignment.training.limitMinDuration > 0 &&
                                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'}
                                         sx={{backgroundColor: colors.backgrounds.optional}} borderRadius={'50%'}>
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
                                             padding={'6px'} sx={{backgroundColor: colors.backgrounds.blue_light_1}}
                                             borderRadius={'50%'}>
                                            <AccessTime style={{color: colors.primary.hovered}}/>
                                        </Box>
                                    </Box>

                                    <Typography variant={'*bodyText2_semibold'} sx={{whiteSpace: 'nowrap'}}>
                                        {`${assignment.training.limitMinDuration} ${('hour minimum duration')}`}
                                    </Typography>

                                </Box>
                            }
                        </Box>
                    </S.AssignmentTrainingCard>
                }


                {assignment?.postExam &&
                    <AssignmentTrainingCard isLessThen1050={isLessThen1050}>
                        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant={'*h3'} >{('Exam')}</Typography>
                            {getAssignmentTrainingAndExamStatusChip({assignmentStatus: assignment.status, trainingStatus: assignment.training?.status, examStatus: assignment.postExam.status }).examChip}
                        </Box>

                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"}/>

                        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'16px'}>
                            {assignment.postExam.examCover && <CoverImageView cover={assignment.postExam.examCover} size={isTablet ? 'list' : 'preview'}/> }
                            {/*{assignment.postExam.examCover &&*/}
                            {/*    <Box maxWidth={'200px'}>*/}
                            {/*        <img src={assignment.postExam.examCover.image} style={{borderRadius: '6px'}}*/}
                            {/*             width={'200px'} alt={assignment.postExam.examCover.imageName}/>*/}
                            {/*    </Box>*/}
                            {/*}*/}

                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} gap={'12px'} height={'100%'}>
                                <Typography variant={'*bodyText1_semibold'}>{assignment.postExam.examName}</Typography>
                                <Typography variant={'*bodyText2'}>{assignment.postExam.examDescription}</Typography>
                            </Box>

                        </Box>

                        <Divider flexItem variant={"fullWidth"} orientation={"horizontal"} sx={{margin: 'auto 0 0 0'}}/>

                        <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'16px'} flexWrap={'wrap'}>

                            {(assignment.postExam.status === TrainingAssignmentObjectStatus.Failed || assignment.postExam.status === TrainingAssignmentObjectStatus.Passed)  &&
                                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.optional}} borderRadius={'50%'}>
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.blue_light_1}} borderRadius={'50%'}>
                                            <CheckCircleOutlined style={{color: colors.primary.hovered}} />
                                        </Box>
                                    </Box>

                                    <Typography variant={'*bodyText2_semibold'} sx={{whiteSpace: 'nowrap'}}>
                                        {`${('Passing points')} ${assignment.postExam.examPassingPoints}/${assignment.postExam.totalPoints}`}
                                    </Typography>

                                </Box>
                            }

                            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'}
                                     sx={{backgroundColor: colors.backgrounds.optional}} borderRadius={'50%'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}
                                         padding={'6px'} sx={{backgroundColor: colors.backgrounds.blue_light_1}}
                                         borderRadius={'50%'}>
                                        <HelpOutline style={{color: colors.primary.hovered}}/>
                                    </Box>
                                </Box>

                                <Typography variant={'*bodyText2_semibold'} sx={{whiteSpace: 'nowrap'}}>{(`${assignment.postExam.totalQuestions} questions`)}</Typography>

                            </Box>

                            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.optional}} borderRadius={'50%'}>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} padding={'6px'} sx={{backgroundColor: colors.backgrounds.blue_light_1}} borderRadius={'50%'}>
                                        {assignment.postExam.timeToPass > 0 ? <AccessTime style={{color: colors.primary.hovered}}/> : <AllInclusive style={{color: colors.primary.hovered}}/>}
                                    </Box>
                                </Box>

                                <Typography variant={'*bodyText2_semibold'} sx={{whiteSpace: 'nowrap'}}>
                                    {assignment.postExam.timeToPass > 0 ? (`${assignment.postExam.timeToPass} minutes`) : ('Does not have limits for time to pass')}
                                </Typography>

                            </Box>

                        </Box>


                    </AssignmentTrainingCard>
                }

            </S.AssignmentExactContent>



            <StartTrainingDialog refetchAssignment={() => assignmentId && getAssignmentById(assignmentId)} />
            <StartExamDialog refreshAssignment={() => assignmentId && getAssignmentById(assignmentId)}/>
            <PassingExamDialog refreshAssignment={() => assignmentId && getAssignmentById(assignmentId)}/>
            <ExamResultDialog />
            <PreviewFinishedTrainingDialog />
        </PageWrapper>
    );
};
