import {gql} from "@apollo/react-hooks";

export const hrRequestFragment =  gql`
    fragment hrRequestFragment on PortalHrRequestModel {
        id
        workspaceId
        type
        requestor {
            employeeId
            fullName
        }
        requestDate
        status
        resolver {
            employeeId
            employeeFullName
            publicId
            collaboratorFullName
        }
        resolveDate
        data {
            editProfile {
                fieldName
                fieldValue
            }
        }
    }
    
`

export const hrIssueFragment =  gql`
    fragment hrIssueFragment on PortalHrIssueModel {
        id
        workspaceId
        anonymous
        requestor {
            employeeId
            fullName
        }
        requestDate
        status
        offender {
            employeeId
            fullName
        }
        issueType
        description
        resolver {
            employeeId
            employeeFullName
            publicId
            collaboratorFullName
        }
        resolveDate
        resolution
    }

`

export const hrTimeOffFragment =  gql`
    fragment hrTimeOffFragment on PortalHrTimeOffRequestModel {
        id
        startDate
        endDate
        type
        status
        comment
        attachments {
            fileId
            fileName
        }
        approvalRoutes {
            routeType
            steps {
                stepNumber
                type
                status
                resolver {
                    email
                    employeeId
                    employeeFullName
                    publicId
                    collaboratorFullName
                }
                resolveDate
                comment
            }
        }
        requestor {
            email
            employeeId
            employeeFullName
            publicId
            collaboratorFullName
        }
        requestDate
        currentRouteType
        currentStepNumber
    }
`
