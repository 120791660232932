export const DOCUMENT_AUTOMATION_TYPE = 'DOCUMENT';

//
export const IN_PROGRESS_STATUS = 'inProgress';
export const COMPLETE_STATUS = 'complete';
export const TERMINATED_STATUS = 'terminated';
//
export const ACCOUNT_KEEP_STATUS = 'KEEP';
export const ACCOUNT_REVOKE_STATUS = 'REVOKE';
export const ACCOUNT_DELETE_STATUS = 'DELETE';
//
export const EXACT_EXECUTOR_DATE_FORMAT = 'D/M/YYYY';
//
//stages
export const REVIEW_CAMPAIGN_STEP_MANAGER = 'MANAGER';
export const REVIEW_CAMPAIGN_STEP_VENDOR = 'VENDOR';
export const REVIEW_CAMPAIGN_STEP_EXECUTOR = 'EXECUTION';
export const REVIEW_CAMPAIGN_STEP_VERIFY = 'VERIFY';
export const REVIEW_CAMPAIGN_STEP_SIGN = 'SIGN';
export const REVIEW_CAMPAIGN_FINISH_STEP_CUSTOM = 'finish';
