import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";

import {TPageInfo} from "../../../../newShared/types";

import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {defaultPageInfo} from "../constants";
import {
    portalGetRequiredActionsWithFilterPaginationAction,
    portalRequiredActionsGetTaskByIdAction,
    portalRequiredActionsPerformActionProvideFilesAction
} from "./actions";
import {PortalRequiredActionModel, RequiredActionsStatus} from "../../../../newShared/GQLTypes";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {addSetAllDialogActions} from "../../../../newShared/actions/setDialogAction";

export type TRequiredActionsSlice = {
    requiredActions: {
        tasks: PortalRequiredActionModel[];
        selected: PortalRequiredActionModel | null;
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType,
    }

    loadings: {
        requiredActionsList: boolean,
        requiredActionsExact: boolean,

        performProvideFiles: boolean,
    },

    dialogs: {
        performProvideFiles: {
            task: PortalRequiredActionModel | null,
        },
    }
}

export const initialState: TRequiredActionsSlice = {
    requiredActions: {
        tasks: [],
        selected: null,
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
    },

    loadings: {
        requiredActionsList: false,
        requiredActionsExact: false,

        performProvideFiles: false,
    },

    dialogs: {
        performProvideFiles: {
            task: null,
        },

    }

}

export const RequiredActionsSlice = createSlice({
    name: 'requiredActions',
    initialState,
    reducers: {
        // clearList: (slice) => {
        //     slice.documents = [];
        //     slice.pageInfo = initialState.pageInfo;
        // },
        replaceRequiredActionsMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.requiredActions.minMaxLoadedPage = payload;
        },
        eraseRequiredActionsList: (slice) => {
            slice.requiredActions.tasks = [];
            slice.requiredActions.pageInfo = defaultPageInfo;
        },
        clearSelectedRequiredActionRequestAction: (slice) => {
            slice.requiredActions.selected = null;
        },

        ...addSetAllDialogActions(initialState),
    },
    extraReducers: (builder) => {
        builder

            //portalGetRequiredActionsWithFilterPagination
            .addCase(portalGetRequiredActionsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.requiredActionsList = true;

                if (clean) {
                    slice.requiredActions.tasks = initialState.requiredActions.tasks;
                    slice.requiredActions.pageInfo = initialState.requiredActions.pageInfo;
                    slice.requiredActions.minMaxLoadedPage = initialState.requiredActions.minMaxLoadedPage;
                }
            })
            .addCase(portalGetRequiredActionsWithFilterPaginationAction.rejected, (slice) => {
                slice.loadings.requiredActionsList = false;
            })
            .addCase(portalGetRequiredActionsWithFilterPaginationAction.fulfilled, (slice, {payload}) => {
                slice.loadings.requiredActionsList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<PortalRequiredActionModel>(
                    slice.requiredActions.tasks,
                    slice.requiredActions.pageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.requiredActions.minMaxLoadedPage.minLoadedPage,
                    slice.requiredActions.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.requiredActions.tasks = results;
                slice.requiredActions.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.requiredActions.pageInfo = payload.pageInfo;

            })

            //portalRequiredActionsPerformActionProvideFilesAction
            .addCase(portalRequiredActionsPerformActionProvideFilesAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.performProvideFiles = true;
            })
            .addCase(portalRequiredActionsPerformActionProvideFilesAction.rejected, (slice, {payload}) => {
                slice.loadings.performProvideFiles = false;
            })
            .addCase(portalRequiredActionsPerformActionProvideFilesAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.performProvideFiles = false;
                slice.dialogs.performProvideFiles = initialState.dialogs.performProvideFiles;

                slice.requiredActions.tasks = slice.requiredActions.tasks.map((task) => task.id === meta.arg.data.data.taskId ? {
                    ...task,
                    status: RequiredActionsStatus.Performed,
                    performDate: new Date().toISOString(),
                    performerComment: meta.arg.data.data.performerComment,
                } : task);
            })

        //portalRequiredActionsGetTaskByIdAction
            .addCase(portalRequiredActionsGetTaskByIdAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.requiredActionsExact = true;
            })
            .addCase(portalRequiredActionsGetTaskByIdAction.rejected, (slice) => {
                slice.loadings.requiredActionsExact = false;
            })
            .addCase(portalRequiredActionsGetTaskByIdAction.fulfilled, (slice, {payload}) => {
                slice.loadings.requiredActionsExact = false;
                slice.requiredActions.selected = payload;
            })


    }
});

export const RequiredActionsReducer = RequiredActionsSlice.reducer;

export const {
    replaceRequiredActionsMinMaxLoadedPage,
    eraseRequiredActionsList,
    // clearSelectedRequiredActionRequestAction,

    setPerformProvideFilesAction,
} = RequiredActionsSlice.actions;
const selectSelf = (state: AppState): TRequiredActionsSlice => state.requiredActions;

export const requiredActionsSelector = createSelector(selectSelf, state => state.requiredActions);

export const loadingsSelector = createSelector(selectSelf, state => state.loadings);
export const dialogsSelector = createSelector(selectSelf, state => state.dialogs);

