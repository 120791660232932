import {ActionReducerMapBuilder, createAsyncThunk} from "@reduxjs/toolkit";
import {TWithOptions} from "../types";
import {AppState} from "../redux/rootReducer";
import {getActionsData} from "../redux";
import {saveAs} from "file-saver";
import {b64toBlob} from "../utils/base64/base64";
import {getFile} from "../components/docViewer/api";

export const downloadFileAction = createAsyncThunk(
    'Common/downloadFile',
    async (data: TWithOptions<{fileId: string, fileName: string, dontSave?: boolean}, {file: string, filename: string}>, {dispatch, getState}) => {
        const state = getState() as AppState;
        const {workspaceId} = getActionsData(state);

        const resp = await getFile(data.data.fileId, data.data.fileName, workspaceId);
        !data.data.dontSave && saveAs(b64toBlob(resp.file), resp.filename);
        data.onSuccess && data.onSuccess(data.data, resp);
        return {
            data: data.data,
            resp
        }
    },
);

export const addDownloadFileActionCases = <TSlice extends {loadings: {isDownloadingFile: string[]} & Record<string, any>}>(
    builder: ActionReducerMapBuilder<TSlice>,
) => {
    builder
        .addCase(downloadFileAction.pending, (slice, {meta: {arg: {data: {fileId}}}}) => {
            slice.loadings.isDownloadingFile.push(fileId);
        })
        .addCase(downloadFileAction.rejected, (slice, {meta: {arg: {data: {fileId}}}}) => {
            slice.loadings.isDownloadingFile = slice.loadings.isDownloadingFile.filter(id => id !== fileId);
        })
        .addCase(downloadFileAction.fulfilled,(slice, {meta: {arg: {data: {fileId}}}}) => {
            slice.loadings.isDownloadingFile = slice.loadings.isDownloadingFile.filter(id => id !== fileId);
        })
}