// Core
import {combineReducers} from 'redux';

// Reducers
import {I18NextReducer} from "../hooks/useMainTranslationHooks/slice";
import {snackReducer} from "../../newDomain/barsEnvironment/snack/store/slice";
import {leftMenuReducerReducer} from "../../newDomain/barsEnvironment/leftMenu/store/slice";
import {breadcrumbsReducer} from "../../newDomain/barsEnvironment/breadcrumbs/store/slice";
import {messageDialogReducerReducer} from "../../newDomain/barsEnvironment/MessageDialog/store/store";
import {settingsProfileReducer} from "../../newDomain/authWorkspacesCookies/settingsProfile/reducer";
import {PDFViewReducer} from "../components/docViewer/store/slice";
import {SettingsReducer} from "../../newDomain/authWorkspacesCookies/settings/store/slice";
import {
    SharedWorkspacesAndOrganizationsReducer
} from "../../newDomain/authWorkspacesCookies/cookies/store/sharedWorkspacesAndOrganizations/slice";
import {SigninReducer} from "../../newDomain/authWorkspacesCookies/signIn/store/slice";
import {genericFiltersReducer} from "../components/genericFilter/store/slice";
import {SpaceReducer} from "../../newDomain/components/workerSpace/store/slice";
import {DocumentsReducer} from "../../newDomain/components/documents/store/slice";
import {HRReducer} from "../../newDomain/components/hr/store/slice";
import {AssetsReducer} from "../../newDomain/components/assets/store/slice";
import {RequiredActionsReducer} from "../../newDomain/components/requiredActions/store/slice";
import {contactUsReducer} from "../../newDomain/components/contactUs/store/slice";
import {TrainingsReducer} from "../../newDomain/components/trainings/store/slice";

export const rootReducer = combineReducers({
    auth: SigninReducer,
    snack: snackReducer,
    I18Next: I18NextReducer,
    settingsProfile: settingsProfileReducer,
    SharedWorkspacesAndOrganizations: SharedWorkspacesAndOrganizationsReducer,
    Settings: SettingsReducer,
    leftMenu: leftMenuReducerReducer,
    breadcrumbs: breadcrumbsReducer,
    messageDialog: messageDialogReducerReducer,
    pdfView: PDFViewReducer,
    genericFilters: genericFiltersReducer,
    Space: SpaceReducer,
    documents: DocumentsReducer,
    hr: HRReducer,
    requiredActions: RequiredActionsReducer,
    assets: AssetsReducer,
    contactUs: contactUsReducer,
    trainings: TrainingsReducer,
});


export type AppState = ReturnType<typeof rootReducer>;
