import React, {FC} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";


export const Assets: FC = () => {


    //Routes
    return (
        <LeftSecondMenu>
            <Routes />
        </LeftSecondMenu>
    )
}
