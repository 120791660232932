import {useStartTrainingDialog} from "../../../hooks/dialogs/useStartTrainingDialog";
import {
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Typography
} from "@mui/material";
import {Flex} from "../../../../../../newShared/components/Layouts";
import colors from "../../../../../../newShared/theme/colors";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import AddTaskIcon from '@mui/icons-material/AddTask';
import {SectionDetails} from "./components/sectionDetails";
import {MenuIcon} from "./components/menuIcon";
import {LeaveDialog} from "./components/leaveDialog";
import {FinishTrainingDialog} from "./components/finishTrainingDialog";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import React from "react";
import {SectionsMenu} from "./components/sectionsMenu";
import {TrainingInfo} from "./components/trainingInfo";


export const StartTrainingDialog = ({refetchAssignment}: {refetchAssignment: () => void}) => {
    const {isOpen, training, actions, loadings, sectionsMenu, trainingInfo, sectionDetails, leaveDialog, finishDialog} = useStartTrainingDialog({refetchAssignment});

    return(
        <Dialog open={isOpen} onClose={actions.handleClose} fullScreen fullWidth sx={{'.MuiPaper-root': {height: '100vh', width: '100vw'}}}>
            <DialogTitle>
                <Flex w={'100%'} jc={'space-between'} ai={'center'}>
                    <Typography variant={'*h3'} color={colors.grayText}>{training?.trainingName}</Typography>

                    <Flex jc={'space-between'} ai={'center'} gap={'16px'}>
                        <Button variant={'outlined'} size={'small'} onClick={actions.handleClose}>
                            {('Close')}
                        </Button>

                        <LoadingButton onClick={() => actions.isLastSection ? actions.handleFinishTraining() : actions.handleGoToNextSection()}
                                       startIcon={<AddTaskIcon />}
                                       variant={'contained'}
                                       size={'small'}
                                       disabled={actions.isLastSection && finishDialog.isAllComplete ? !finishDialog.isActive : false}
                                       loading={loadings.finishTraining || loadings.finishTrainingSection}
                        >
                            {actions.isLastSection ? ('Done!') : ('Done! Next section')}
                        </LoadingButton>
                    </Flex>
                </Flex>
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%'}}>
                <FlexRow width={'100%'} height={'100%'} gap={'30px'} overflow={'hidden'} maxWidth={'min(1550px, 90%)'} sx={{marginInline: 'auto'}}>

                    <FlexRow sx={{maxWidth: 'max(30%, 290px)', height: '100%', flexGrow: 1}}>
                        <Collapse in={sectionsMenu.isMenuOpened} orientation={'horizontal'}
                                  sx={{
                                      '.MuiCollapse-wrapperInner': {width: '100%', display: 'flex', flexDirection: 'column', paddingBottom: '16px'},
                                  }}>
                            <FlexColumn sx={{width: '100%', overflow: 'hidden', flexGrow: 1, gap: '16px'}}>
                                <SectionsMenu {...sectionsMenu} />
                                {/**/}
                                <TrainingInfo {...trainingInfo}/>
                            </FlexColumn>
                        </Collapse>
                    </FlexRow>

                    <FlexRow position={'relative'} sx={{flexGrow: 1}}>
                        <Divider orientation={'vertical'} flexItem/>
                        <MenuIcon isOpen={sectionsMenu.isMenuOpened} onClick={sectionsMenu.toggleMenu} />
                    </FlexRow>

                    <FlexRow sx={{width: '100%', height: '100%', flexGrow: 1, overflow: 'hidden'}}>
                        {loadings.isLoadingGetTraining ?
                            <FlexColumn sx={{gap: '16px', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                <CircularProgress size={48}/>
                            </FlexColumn>
                            :
                            <SectionDetails section={sectionDetails.section} />
                        }

                    </FlexRow>
                </FlexRow>
            </DialogContent>

            <LeaveDialog {...leaveDialog}/>
            <FinishTrainingDialog {...finishDialog}/>
        </Dialog>
    )
}