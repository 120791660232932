import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {dialogsSelector, loadingsSelector, setPerformProvideFilesAction} from "../../../store/slice";
import * as yup from "yup";
import {useFormik} from "formik";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {FormikHelpers} from "formik/dist/types";
import {uploadFile} from "../../../../workerSpace/api";
import {portalRequiredActionsPerformActionProvideFilesAction} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useRequiredActionExact} from "../../useRequiredActionExact";
import {PortalRequiredActionModel} from "../../../../../../newShared/GQLTypes";
import {uuid} from "../../../../../../newShared/utils";

export const useRequiredActionsPerformProvideFilesDialog = ({refreshTable}: {refreshTable?: () => void}) => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathRequiredActions.dialogs'});
    const {setMessage} = useMessageDialog();
    const dispatch = useDispatch();

    const {actions: {handleClose: handleCloseDetailsView}} = useRequiredActionExact({refreshTable: () => {}});
    const {performProvideFiles} = useSelector(loadingsSelector);
    const {task} = useSelector(dialogsSelector).performProvideFiles;
    const isOpen = !!task;

    const handleOpen = (task: PortalRequiredActionModel) => {
        dispatch(setPerformProvideFilesAction({task}));
    };
    const handleClose = () => {
        dispatch(setPerformProvideFilesAction({clear: true}));
    };

    type TFormikValues = {
        comment: string, attachments: {base64?: string, fileId: string, fileName: string}[],
        attachmentsOrComment?: never,
    };
    const handleSubmit = (values: TFormikValues, formikHelpers: FormikHelpers<TFormikValues>) => {
        task && dispatch(portalRequiredActionsPerformActionProvideFilesAction({
            data: {
                data: {
                    taskId: task.id,
                    performerComment: values.comment?.trim() || undefined,
                    data: {
                        attachedFiles: values.attachments.map(({fileId, fileName}) => ({fileId, fileName}))
                    }
                }
            },
            onSuccess: () => {
                handleCloseDetailsView();
                setMessage({
                    title: tCommon('Completed successfully'),
                    message: t('Action has been performed.'),
                });
            },
            onError: (request, error, addictiveData) => {
                const error409 = error.e409?.[0];
                const error404 = error.e404?.[0];

                if (error409?.type === 'STATUS_CHANGED') {
                    // const status = error409?.values.find(({parameter}) => parameter === 'status')?.value || '';
                    setMessage({
                        title: t('Action conflict error'),
                        message: t('Action not applicable! Required action status was changed.'),
                    });
                }

                if (error404) {
                    setMessage({
                        title: t('File attachment'),
                        message: t('Required action with id {{id}} not found.', {id: request.data.taskId}),
                    });
                    handleClose();

                    handleCloseDetailsView();
                    refreshTable?.();
                }
            }
        }))
    };

    const validationSchema = yup
        .object({
            attachments: yup.array()
                .of(yup.object({
                    base64: yup.string(),
                    fileId: yup.string().required().notOneOf(['uploading']),
                    fileName: yup.string().required(),
                }))
                .max(25, tCommon('You can upload up to {{count}} files', {count: 25})),
            comment: yup.string()
                .nullable()
                .max(1000, tCommon('Comment should be less than {{count}} symbols', {count: 1000})),

            ////// just for testing
            attachmentsOrComment: yup.mixed().test(
                'attachmentsOrComment',
                t('You must provide at least one file or a comment'),
                function () {
                    const { attachments, comment } = this.parent as TFormikValues;
                    return !!attachments?.length || !!comment?.trim()?.length;
                }
            ),
        })
    ;

    const [files, setFiles] = useState<TFormikValues['attachments']>([]);
    const formik = useFormik<TFormikValues>({
        initialValues: {comment: '', attachments: []},
        validationSchema,
        onSubmit: handleSubmit,
        isInitialValid: false,
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        !formik.touched[e.target.name as keyof TFormikValues] && formik.setFieldTouched(e.target.name, true, false)
        !formik.touched.attachmentsOrComment && formik.setFieldTouched('attachmentsOrComment', true, false);

        formik.handleChange(e);
    };
    const formikOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        !formik.touched.attachmentsOrComment && formik.setFieldTouched('attachmentsOrComment', true, false);
        formik.handleBlur(e);
    };

    const handleFileUpload = async (files: {base64: string, fileName: string}[]) => {
        const notUploadedFiles = files.map(e => ({...e, fileId: 'uploading' + uuid()}));
        setFiles(prev => [...prev, ...notUploadedFiles]);

        try {
            const uploaded = await Promise.all(notUploadedFiles.map(({base64, fileName, fileId}) => uploadFile(base64).then(e => ({oldFileId: fileId, fileId: e, fileName}))));
            setFiles(values => values.map(e => uploaded.find(({oldFileId}) => oldFileId === e.fileId) || e));
        } catch (e) {

        } finally {
            setFiles(prev => prev.filter(e => !notUploadedFiles.some(({fileId}) => e.fileId === fileId)));
        }
    }

    const handleDeleteFile = (fileId: string) => () => {
        setFiles(attachments => attachments.filter(({fileId: id}) => id !== fileId));
    };

    useEffect(() => {
        if (isOpen) {
            setFiles([]);
            formik.resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        if (JSON.stringify(files) !== JSON.stringify(formik.values.attachments)) {
            formik.setFieldValue('attachments', files);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(files)])

    return {
        isOpen,
        isLoading: {
            performProvideFiles,
        },

        handleOpen,
        handleClose,

        formik: {...formik, handleChange: formikOnChange, handleBlur: formikOnBlur},
        handleDeleteFile,
        handleFileUpload,
    }
};