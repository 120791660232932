import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import * as S from '../../components/styled';
import {DynamicHeader} from "../../components/dynamicHeader";
import {availableColumnsSharedDocs} from "../../constants";
import {TableRowSkeleton} from "../../../../../newShared/components/tableRowSkeleton";
import React from "react";
import {useSharedDocs} from "../../hooks/useSharedDocs";
import {EmptyArrayImage} from "../../../../../newShared/components/EmptyArrayImage";
import EditorDialog from "../../../../../newShared/components/editorUnion";
import {Spinner} from "../../../../../newShared/components/Spinner";
import {SharedDocumentTableRow} from "../../components/shredDocumentTableRow";
import {DEFAULT_DATE_FORMAT} from "../../../../../newShared/utils/dateTools";
import {GenericFiltersArray} from "../../../../../newShared/components/genericFilter";

export const SharedDocs = () => {
    const {onScroll, scrollRef, total, hasMore, docs, handleRowClick, openRecipientsDialog, isNoDocs,
        selectedDocument: {document, documentExecutor}, loadings, actions, currentEmail, snack, filters
    } = useSharedDocs();

    return(
        <S.PageContainer>
            <PageTitleAndActions
                title={'Shared documents'}
                actions={[]}
            />

            <GenericFiltersArray
                isAddFilters={false}
                configs={filters.configs}
                fetchResultWithSelectedFilters={filters.handleFetch}
                dateFilterFormat={DEFAULT_DATE_FORMAT}
            />

            <S.DocsTableContainer onScroll={onScroll} ref={scrollRef}>
                {isNoDocs ?
                    <EmptyArrayImage
                        type={'documents'}
                        text={'No shared documents yet'}
                        withoutAdd
                        isShowing
                    />
                    :
                    <S.InboxTable>
                        <DynamicHeader columnConfig={availableColumnsSharedDocs} total={total}/>
                        <S.InboxTableBody>
                            {docs.map((e) => <SharedDocumentTableRow key={e.id} file={e} openFile={handleRowClick}
                                                                 openRecipientsDialog={openRecipientsDialog}/>)}
                            {(hasMore) && <TableRowSkeleton columns={availableColumnsSharedDocs.length}/>}
                        </S.InboxTableBody>
                    </S.InboxTable>
                }
            </S.DocsTableContainer>

            {loadings.isLoadingExact && <Spinner />}
            {document?.status && (
                <EditorDialog isOpen={true}
                              documentTitle={document.name}
                              content={document.data?.editor || []}
                              variables={document.data?.variables || []}
                              actors={{
                                  approvers: document?.approvers || [],
                                  recipients: document?.recipients || [],
                                  editors: document?.editors || [],
                              }}
                              pdfFileId={document.data?.pdfFileId}

                              currentEmail={currentEmail}
                              currentFileStatus={document.status}
                              execution={documentExecutor || undefined}

                              onSave={actions.handleSave}
                              onInvalidate={() => null}
                              onSkipApprover={() => null}
                              onClose={actions.handleCloseEditor}
                              onRejectSignStage={actions.onRejectSignStage}
                              onRejectApproveStage={actions.onRejectApproveStage}
                              onApproveApproveStage={actions.onApproveApproveStage}
                              onApproveSignStage={actions.onApproveSignStage}
                              onFillFields={actions.onFillFields}
                              onChangeEta={() => null}

                              onResendInviteApprover={() => null}
                              onResendInviteRecipient={() => null}

                              onSend={() => null}
                              onReturnToDraft={() => null}
                              onPreviewBeforeSend={() => null}
                              isLoading={{
                                  isPrev: false,
                                  isSave: loadings.isLoadingSave,
                                  isInvalidating: loadings.isLoadingInvalidate,
                                  isRejecting: loadings.isLoadingRejectApproveStageByStageId || loadings.isLoadingRejectSignStageByStageId,
                                  isApproving: loadings.isLoadingApproveApproveStageByStageId || loadings.isLoadingApproveSignStageByStageId,
                                  isReinviting: loadings.isLoadingRestartSignStageByStageIdAndGroupId || loadings.isLoadingRestartSignStageByStageIdAndRecipient,
                                  isSkipping: loadings.isLoadingSendToNextStep,
                                  isFillingField: loadings.isLoadingFillFillableFieldOnSignStage,
                                  isSending: false,
                                  isChangingEtaInProgress: false
                              }}
                              snack={snack}

                              actualEnvironment={"isPortal"}
                />
            )}
        </S.PageContainer>
    )
}