import {useState} from "react";
import {useTrainingsDialogs} from "../useTrainingsDialogs";

export const useExamResultDialog = () => {
    const {assignment, handleClose} = useTrainingsDialogs().examResultDialog;

    const [openedQuestions, setOpenedQuestions] = useState<string[]>([]);

    const isQuestionOpened = (questionId: string) => openedQuestions.some(e => e === questionId);

    const toggleQuestion = (questionId: string) => {
        if(isQuestionOpened(questionId)){
            setOpenedQuestions(openedQuestions.filter(e => e !== questionId))
        }else{
            setOpenedQuestions([...openedQuestions, questionId])
        }
    }

    const collapseAll = () => setOpenedQuestions([]);
    const expandAll = () => setOpenedQuestions(assignment?.postExam?.questions?.map(e => e.questionId) ?? []);

    return{
        isOpen: assignment !== null,
        handleClose,
        assignment,
        examPoints: {
            scored: assignment && assignment.postExam && assignment.postExam.questions ? assignment.postExam.questions.map(e => e.validation?.points ?? 0).reduce((a, b) => a + b, 0) : 0,
            passingPoints: assignment && assignment.postExam && assignment.postExam.questions ? assignment.postExam.examPassingPoints : 0,
            totalPoints: assignment && assignment.postExam && assignment.postExam.questions ? assignment.postExam.questions.map(e => e.points ?? 0).reduce((a, b) => a + b, 0) : 0,
        },
        questions: {
            isQuestionOpened,
            toggleQuestion,
            collapseAll,
            expandAll,
            isCollapseAllDisabled: !openedQuestions.length,
            isExpandAllDisabled: openedQuestions.length === assignment?.postExam?.questions?.length ?? 0,
        },
    }
}