import React, {FC, useEffect} from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {useRequestRequestAssetDialog} from "../../../hooks/useRequestRequestAssetDialog";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
//import {useForm} from "../../../../../../newShared/hooks/useForm";
import {useDispatch, useSelector} from "react-redux";
import {portalAssetsCreateRequestRequestAssetAction} from "../../../store/actions";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {settingsSelector} from "../../../store/slice";
import * as yup from "yup";
import {useFormik} from "formik";

export const RequestRequestAssetDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathAssets.dialogs'});
    const {isLoading, isOpen, handleClose} = useRequestRequestAssetDialog();
    const {setMessage} = useMessageDialog();
    const {manufacture, model, category} = useSelector(settingsSelector);


    const dispatch = useDispatch();
    const handleRequest = () => {
        dispatch(portalAssetsCreateRequestRequestAssetAction({
            data: {data: formik.values},
            onSuccess: (request, response, addictiveData) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: [
                        t('Your request has been submitted. Request id {{requestId}}', {requestId: response.id}),
                        t("You can find this request at 'My asset requests' section.")
                    ]
                })
            },
        }))
    }

    const validationSchema = yup.object({
        model: yup.string()
            .nullable(),
        category: yup.string()
            .nullable()
            .required(t('Category field is required')),
        manufacture: yup.string()
            .nullable(),
        comment: yup.string()
            .required(t('Comment field is required'))
            .max(1000, t('Comment should be less than 1000 symbols')),
    });

    const formik = useFormik({
        initialValues: {model: '', category: '',manufacture: '', comment: ''},
        validationSchema,
        onSubmit: handleRequest,
        isInitialValid: false
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    useEffect(() => {
        if (!isOpen) formik.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{t('Request new asset')}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <Typography variant={'*bodyText2'}>
                    {t('This request is intended for a new additional asset provisioning.')}
                </Typography>

                <Autocomplete<string>
                    value={formik.values.category || null}
                    size={"small"}
                    options={category || []}
                    onChange={(e, selected) => {
                        formik.setFieldTouched('category');
                        formik.setFieldValue('category', selected)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} onBlur={formik.handleBlur}
                                   label={t('Select category')} helperText={formik.touched.category && formik.errors.category} error={formik.touched.category && Boolean(formik.errors.category)} name={'category'} required/>
                    )}
                />

                <Autocomplete<string>
                    value={formik.values.model || null}
                    size={"small"}
                    options={model || []}
                    onChange={(e, selected) => {
                        formik.setFieldTouched('model');
                        formik.setFieldValue('model', selected)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={t('Select model')} name={'model'}/>
                    )}
                />

                <Autocomplete<string>
                    value={formik.values.manufacture || null}
                    size={"small"}
                    options={manufacture || []}
                    onChange={(e, selected) => {
                        formik.setFieldTouched('manufacture');
                        formik.setFieldValue('manufacture', selected)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label={t('Select manufacturer')} name={'manufacture'} />
                    )}
                />

                {/*<Typography gap={'8px'} variant={'*bodyText2_semibold'}>{t('Comment')}<span style={{color: 'red'}}> *</span></Typography>*/}
                <TextField name={'comment'}
                           required
                           label={'Comment'}
                           onBlur={formik.handleBlur}
                           multiline rows={4}
                           onChange={formikOnChange}
                           value={formik.values.comment}
                           error={formik.touched.comment && Boolean(formik.errors.comment)}
                           helperText={formik.touched.comment && formik.errors.comment} />
            </DialogContent>

            <DialogActions>
                <Button variant={"text"} onClick={handleClose}>{tCommon('Cancel')}</Button>
                <LoadingButton variant={"contained"} onClick={handleRequest} loading={isLoading}
                               disabled={!formik.isValid || !formik.dirty}
                >
                    {tCommon('Send')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
