import {useDispatch, useSelector} from "react-redux";
import {hrIsLoadingSelector, hrOrgTreeSelector, setTreeAction, THrSlice,} from "../../store/slice";
import {portalHrGetOrgTreeAction} from "../../store/actions";
import {Node, SetCenter} from "reactflow";
import {employeeTreeNodeHeight, employeeTreeNodeWidth} from "../../helpers";
import {addErrorSnack} from "../../../../barsEnvironment/snack/store/slice";


type TResponse = {
    isLoading: THrSlice["isLoading"],
    employees: THrSlice["tree"]["employees"],
    direction: THrSlice["tree"]["direction"],
    fullView: THrSlice["tree"]["fullView"],

    getTree: (signal?: AbortSignal) => void;
    setTree: (data: Partial<THrSlice["tree"] & {clear?: boolean}>) => void;

    focusNode: (nodes: Node[], id: string, setCenter: SetCenter, other?: {x: number, y: number, zoom: number}) => void;
    cycleDetectedError: () => void;
}

export const useEmployeesTree = (): TResponse => {
    const dispatch = useDispatch();

    const isLoading = useSelector(hrIsLoadingSelector);
    const {employees, fullView, direction} = useSelector(hrOrgTreeSelector);

    const getTree: TResponse["getTree"] = (signal) => {dispatch(portalHrGetOrgTreeAction({data: {}, signal}))};

    const setTree: TResponse["setTree"] = (data) => {
        dispatch(setTreeAction(data))
    }

    const focusNode: TResponse['focusNode'] = (nodes, id, setCenter, {x: xx, y: yy, zoom} = {x: 2, y: 2, zoom: 1.65}) => {
        if (nodes.length > 0) {
            const node = nodes.find(e => e.id === id);
            if (node) {
                const x = node.position.x + (node.width ?? employeeTreeNodeWidth) / xx;
                const y = node.position.y + (node.height ?? employeeTreeNodeHeight) / yy;

                setCenter(x, y, { zoom, duration: 1000 });
            }
        }
    };

    const cycleDetectedError = () => {
        dispatch(addErrorSnack('Employees Cycle Detected'))
    }

    return {
        isLoading,
        employees,
        direction,
        fullView,

        getTree,
        setTree,

        focusNode,
        cycleDetectedError
    }
}
