import {TLanguage} from "./types";

export const LABEL = window.config?.REACT_APP_LABEL || 'Regulait';
export const IS_REGULAIT = LABEL === 'Regulait';

export const SUPPORTED_LANGUAGES: TLanguage[] = [
    {
        name: 'English',
        code: 'en',
        countryCode: 'US',
        dir: 'ltr',
        locale: 'enUS',
        momentLocale: 'en-au'
    },
    {
        name: 'العربية',
        code: 'ar',
        countryCode: 'Bahrain',
        dir: 'rtl',
        locale: 'arSA',
        momentLocale: 'ar'
    },
    ...(IS_REGULAIT ? [{
        name: 'עברית',
        code: 'he',
        countryCode: 'IL',
        dir: 'rtl',
        locale: 'heIL',
        momentLocale: 'he'
    }] : []) as TLanguage[]
];

const screenSizes = {
    mobile: '390px',
    mobileL: '414px',
    desktopNarrow: '521px',
    tablet: '770px',
    desktop: '1366px',
    desktopM: '1440px',
    desktopL: '1920px',
}

export const device = {
    mobile: `@media screen and (max-width: ${screenSizes.mobile})`,
    mobileL: `@media screen and (max-width: ${screenSizes.mobileL})`,
    desktopNarrow: `@media screen and (max-width: ${screenSizes.desktopNarrow})`,
    tablet: `@media screen and (max-width: ${screenSizes.tablet})`,
    desktop: `@media screen and (max-width: ${screenSizes.desktop})`,
    desktopM: `@media screen and (max-width: ${screenSizes.desktopM})`,
    desktopL: `@media screen and (min-width: ${screenSizes.desktopL})`,
    generateMax: (width: number) => `@media screen and (max-width: ${width}px)`
};

export const MAIN_DATA_COOKIE_NAME = IS_REGULAIT ? 'regulaitUserData' : 'complicoUserData';

export const PATH_ARI = window.config?.REACT_APP_URI || '';
export const PATH_SERVER = window.config?.REACT_APP_PATH_SERVER || '';
export const PATH_WS_SERVER = window.config?.REACT_APP_PATH_WS_SERVER || '';
export const VERSION = window.config?.REACT_APP_VERSION || '';

// export const SHOW_LOGGER = PATH_ARI === 'http://localhost:3000' || PATH_ARI === 'https://dev.app.regulait.com';
// export const SHOW_EXTENDED_ERRORS = SHOW_LOGGER;
// export const SHOW_SKIP_BUTTON_IN_MFA = SHOW_LOGGER;
export const SHOW_LOGGER = PATH_ARI === 'http://localhost:3000' || window.config?.NODE_ENV === 'development';
export const SHOW_EXTENDED_ERRORS = PATH_ARI === 'http://localhost:3000' || window.config?.NODE_ENV === 'development';
export const SHOW_SKIP_BUTTON_IN_MFA = PATH_ARI === 'http://localhost:3000' || window.config?.NODE_ENV === 'development';
export const SHOW_CHANGE_LANGUAGE_IN_TOPBAR = PATH_ARI === 'http://localhost:3000'; //if development -> change language dialog will be visible in topBar menu

//ROUTER PATHS
export const PATH_LOCAL_LOGIN = "/login";
export const PATH_LOCAL_LOGIN_CONTACT_US = PATH_LOCAL_LOGIN + "/contact_us";
export const PATH_LOCAL_LOGOUT = "/logout";
export const PATH_LOCAL_2FA = '/mfa';
export const PATH_LOCAL_PASSWORD_RESET = '/password_reset';
export const PATH_LOCAL_VERIFY_EMAIL = '/email_verify';
export const PATH_LOCAL_INITIAL_MFA_CONFIGURE = '/initial_mfa';
export const PATH_LOCAL_MFA_RESET = '/mfa_reset';
export const PATH_LOCAL_HELP = "/support";
export const PATH_LOCAL_SETTINGS = "/settings";
export const PATH_LOCAL_SPACE = "/space";
export const DOCUMENTS_ROOT = '/documents';
export const MY_DOCUMENTS_PATH = `${DOCUMENTS_ROOT}/myDocuments`;
export const SHARED_DOCUMENTS_PATH = `${DOCUMENTS_ROOT}/sharedDocuments`;

export const HR_PATH = `/hr`;
export const HR_PROFILE_PATH = `${HR_PATH}/profile`;
export const HR_ORG_CHART_PATH = `${HR_PATH}/organization`;
export const HR_ISSUES_PATH = `${HR_PATH}/issues`;
export const HR_ISSUES_PATH_EXACT = `${HR_ISSUES_PATH}/:issueId`;
export const HR_REQUESTS_PATH = `${HR_PATH}/requests`;
export const HR_REQUESTS_PATH_EXACT = `${HR_REQUESTS_PATH}/:requestId`;
export const HR_TIME_OFF_PATH = `${HR_PATH}/my_timeoff`;
export const HR_TIME_OFF_PATH_EXACT = `${HR_TIME_OFF_PATH}/:timeoffId`;
export const HR_TIME_OFF_MANAGEMENT_PATH = `${HR_PATH}/incoming_timeoff`;
export const HR_TIME_OFF_MANAGEMENT_PATH_EXACT = `${HR_TIME_OFF_MANAGEMENT_PATH}/:timeoffId`;


//assets
export const ASSETS_PATH = `/assets`;
export const ASSETS_LIST_PATH = `${ASSETS_PATH}/list`;
export const ASSETS_EXACT_PATH = `${ASSETS_LIST_PATH}/:assetId`;
export const ASSETS_REQUESTS_PATH = `${ASSETS_PATH}/requests`;
export const ASSETS_REQUESTS_EXACT_PATH = `${ASSETS_REQUESTS_PATH}/:requestId`;

export const REQUIRED_ACTIONS_PATH = '/required-actions';
export const REQUIRED_ACTIONS_TASKS_PATH = `${REQUIRED_ACTIONS_PATH}/actions`;
export const REQUIRED_ACTIONS_TASKS_EXACT_PATH = `${REQUIRED_ACTIONS_TASKS_PATH}/:taskId`;
export const VENDORS_PATH = '/vendors';

export const TRAININGS_PATH = '/trainings';
export const TRAININGS_ASSIGNMENTS_PATH = `${TRAININGS_PATH}/assignments`;
export const TRAININGS_ASSIGNMENTS_EXACT_PATH = `${TRAININGS_ASSIGNMENTS_PATH}/:assignmentId`;

export const RISK_TREATMENT_PATH = '/riskTreatment';
export const INCIDENT_REPORTING_PATH = '/incidentReporting';

export const ACCEPT_IMAGE_PROP = 'image/*';
export const ACCEPT_IMAGE_PROP_WITHOUT_SVG = 'image/png, image/jpeg';
export const MAX_LIVE_COOKIE = 1000 * 60 * 60 * 24;

// export const NEXT_PATH_LOCAL_STORAGE = 'nextPath';
// export const SET_WORKSPACE = 'setWorkspace';
export const APP_FILES_LINK = window.config?.REACT_APP_FILES_LINK || '';

