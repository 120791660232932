import {dialogsSelector, setRemoveAssetAction} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {PortalPhysicalAssetModel} from "../../../../../newShared/GQLTypes";
import {useEffect} from "react";
import {portalAssetsCreateRequestRemoveAssetAction} from "../../store/actions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {ASSETS_LIST_PATH} from "../../../../../newShared/constants";
import {useHistory, useLocation} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";

export const useRemovePhysicalAssetDialog = () => {
    const dispatch = useDispatch();
    const {t} = useMainTranslation('', {keyPrefix: 'pathAssets.dialogs'});
    const {removeAsset: {isOpen, selected: asset, isLoading}} = useSelector(dialogsSelector);
    const {setMessage} = useMessageDialog();
    //const [comment, setComment] = useState<string>('');
    const history = useHistory();
    const location = useLocation();

    const validationSchema = yup.object({
        comment: yup.string()
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols')),
    });

    const formik = useFormik({
        initialValues: {comment: ''},
        validationSchema,
        onSubmit: handleConfirm
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    const openRemovePhysicalAsset = (asset: PortalPhysicalAssetModel) => {
        dispatch(setRemoveAssetAction({selected: asset}))
    }

    function handleConfirm(){
        asset && dispatch(portalAssetsCreateRequestRemoveAssetAction({
            data: { data: {
                    assetId: asset.assetId,
                    serial: asset.serial,
                    category: asset.category,
                    manufacture: asset.manufacture,
                    model: asset.model,
                    comment: formik.values.comment,
                }},
            onSuccess: (request, response) => {
                history.push(`${ASSETS_LIST_PATH}${location.search}`);
                setMessage({
                    title: t('Completed successfully'),
                    message: [
                        t('Your request has been submitted. Request id {{requestId}}', {requestId: response.id}),
                        t("You can find this request at 'My asset requests' section.")
                    ]
                })
            },
            onError: (request, error, addictiveData) => {
                const errors409 = error.e409;
                if (errors409?.length) {
                    history.push(`${ASSETS_LIST_PATH}${location.search}`);
                }
            },
        }))
    }

    const handleClose = () => {
        dispatch(setRemoveAssetAction({clear: true}))
    }

    useEffect(() => {
        isOpen && formik.resetForm();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return {
        isOpen,
        asset,
        //comment,
        //setComment,
        isLoading,
        openRemovePhysicalAsset,
        handleConfirm,
        handleClose,
        formik: {...formik, handleChange: formikOnChange},
    }
}
