import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
    assignmentsSelector,
    eraseAssignments,
    loadingsSelector,
    replaceAssignmentsMinMaxLoadedPage
} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    TRAININGS_ASSIGNMENTS_EXACT_PATH,
    TRAININGS_ASSIGNMENTS_PATH,
    TRAININGS_PATH
} from "../../../../../newShared/constants";
import {
    IdNameModel,
    PortalTrainingsShortAssignmentModel,
    TrainingAssignmentStatus,
    TrainingCollaboratorAutocompleteModel
} from "../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getTrainingAssignmentStatusChip} from "../../helpers";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {portalTrainingsGetAssignmentsWithFilterPaginationAction} from "../../store/actions";
import {defaultPageInfo} from "../../../hr/constants";
import {makeFirstLetterUpper} from "../../../../../newShared/utils/text";
import {
    portalTrainingGetAssignmentExamsAutocompleteApi,
    portalTrainingGetAssignmentRequestorsAutocompleteApi,
    portalTrainingGetAssignmentTrainingsAutocompleteApi
} from "../../api";

export const useAssignmentsListPage = () => {
    const {t, tMenu} = useMainTranslation('', {keyPrefix: 'pathTrainings.pathAssignmentsList'});
    const dispatch = useDispatch();
    const history = useHistory();

    //states

    //selectors
    const {assignments, pageInfo, minMaxLoadedPage} = useSelector(assignmentsSelector);
    const {assignmentsList: isLoadingList} = useSelector(loadingsSelector);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;


    //useEffects
    useSetBreadcrumbs([
        {
            title: tMenu('My trainings'),
            path: TRAININGS_PATH
        },
        {
            title: tMenu('Assignments'),
            path: TRAININGS_ASSIGNMENTS_PATH
        }
    ]);



    //actions
    const handleOpenExact = (selected: PortalTrainingsShortAssignmentModel) => {
        history.push(TRAININGS_ASSIGNMENTS_EXACT_PATH.replace(':assignmentId', selected.id));
    }

    //filter
    const requestorConfig: keyConfig<TrainingCollaboratorAutocompleteModel> = {
        type: "filter",
        key: "requestor",
        name: t("Requestor"),
        fetchOptions: (workspaceId, {page, count, search}) =>
            portalTrainingGetAssignmentRequestorsAutocompleteApi({workspaceId, pageRequest: {page, count}, search})
            .then(({result, pageInfo}) => ({values: result, pageInfo})),
        getOptionLabel: (a) => a.fullName,
        getFilterValue: (a) => a.collaboratorId,
        isOptionEqual: (a, b) => a.collaboratorId === b.collaboratorId,
        isOptionEqualToSearch: (a, search) =>
            a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: false,
    };

    const trainingsConfig: keyConfig<IdNameModel> = {
        type: 'filter',
        key: 'trainingName',
        name: t('Training'),
        fetchOptions: (workspaceId, {page, count, search}) => {
            return portalTrainingGetAssignmentTrainingsAutocompleteApi({ workspaceId, pageRequest: {page, count}, search})
                .then(({result, pageInfo}) => ({values: result, pageInfo}))
        },
        getOptionLabel: (a) => a.name,
        getFilterValue: (a) => a.name,
        isOptionEqual: (a, b) => a.name === b.name,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: false,
    }

    const examsConfig: keyConfig<IdNameModel> = {
        type: 'filter',
        key: 'examName',
        name: t('Exam'),
        fetchOptions: (workspaceId, {page, count, search}) => {
            return portalTrainingGetAssignmentExamsAutocompleteApi({ workspaceId, pageRequest: {page, count}, search})
                .then(({result, pageInfo}) => ({values: result, pageInfo}))
        },
        getOptionLabel: (a) => a.name,
        getFilterValue: (a) => a.name,
        isOptionEqual: (a, b) => a.name === b.name,
        isOptionEqualToSearch: (a, search) => a.name.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: false,
    }

    const statusConfig: keyConfig<TrainingAssignmentStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(TrainingAssignmentStatus).filter(a => a !== TrainingAssignmentStatus.Cancelled && a !== TrainingAssignmentStatus.Scheduled),
        OptionRenderer: (a) => getTrainingAssignmentStatusChip(a, true),
        default: true,
    }

    const typeConfig: keyConfig<string> = {
        type: 'filter',
        key: 'type',
        name: t('Type'),
        options: ['TRAINING', 'EXAM', 'MIXED'],
        getOptionLabel: makeFirstLetterUpper,
        getFilterValue: (a) => a.toUpperCase(),
        isOptionEqualToSearch: (a, search) => a.trim().toUpperCase().includes(search.trim().toUpperCase()),
        default: true,
    }


    const startDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'startDate',
        name: t('Start date'),
        default: true
    }

    const dueDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'dueDate',
        name: t('Due date'),
        default: true
    }

    return{
        assignments,
        isLoading: isLoadingList,
        handleOpenExact,

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceAssignmentsMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseAssignments()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, assignmentIdLike: string) => {
                    dispatch(portalTrainingsGetAssignmentsWithFilterPaginationAction({data: {pageRequest: {page, count: count || defaultPageInfo.count}, filter: {...filters, assignmentIdLike}}}));
                },
            },
            filters: {
                configs: [
                    typeConfig,
                    statusConfig,
                    startDateConfig,
                    dueDateConfig,
                    requestorConfig,
                    trainingsConfig,
                    examsConfig,
                ]
            }
        }

    }
};