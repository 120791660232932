import {FC} from 'react';
import {LeftSecondMenu} from "../../barsEnvironment/leftMenu/components/leftSecondMenu";
import {Routes} from "./routes";

export const Settings:FC = () => {
    return(
        <LeftSecondMenu>
            <Routes/>  
        </LeftSecondMenu>
    )
}
