import React, {useEffect, useState} from "react";
import {Flex} from "../../../../../newShared/components/Layouts";
import {Typo} from "../../../../../newShared/components/Typography";
import {getStatus} from "../../helpers";
import {Divider} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {loadings, selectedStage} from "../../store/slice";
import {UploadFileBTN} from "../../../../../newShared/components/FileBTN/UploadFileBTN";
import {usePDFView} from "../../../../../newShared/components/docViewer/hooks/usePDFView";
import DownloadIcon from '@mui/icons-material/Download';
import PageviewIcon from '@mui/icons-material/Pageview';
import {DownloadFile, UploadFile} from "../../store/actions";
import {Stage} from "../../types";
import {toBase64} from "../../../../../newShared/utils/base64/base64";
import {ReadConfirm} from "../readConfirm";
import {ReviewConfirm} from "../reviewConfirm";
import {DocViewerDialog} from "../../../../../newShared/components/docViewer";
import {SignConfirm} from "../signConfirm";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";

export const DocumentAutomationComponent = () => {
    const dispatch = useDispatch();
    const stage = useSelector(selectedStage);
    const {uploadFile, updateDocumentOnReview, approveDefaultDocStage} = useSelector(loadings);
    //FILE READ
    const [readFiles, setReadFiles] = useState<string[]>([]);
    const handleAddReadFile = (fileId: string | null, fileName: string | null) => {
        if(fileId) setReadFiles([...readFiles, fileId]);
    }
    const handleReplaceReadFile = (delFileId: string, addFileId: string) => {
        setReadFiles([...readFiles.filter(e => e !== delFileId), addFileId]);
    }
    const calculateIsRead = (stage: Stage): boolean => {
        if(stage && stage.stageDocument){
            // console.log(`calculateIsRead: stage and stage.stageDocument ok - \n readFiles: ${JSON.stringify(readFiles)}`)
            for(let i = 0; i < stage.stageDocument.links.length; i++ ){
                const e = stage.stageDocument.links[i];
                // console.log(`checking if ${e} is not in readFiles`);
                if(!readFiles.some(read => read === e.link)){
                    // console.log(`calculateIsRead: !readFiles.some(read => read === e.link): true`)
                    return false;
                }
            }
            return true;
        }
        // console.log(`calculateIsRead: !stage or !stage.stageDocument`)
        return false;
    }
    //
    useEffect(() => {
        if(!approveDefaultDocStage){
            setReadFiles([]);
            //if user has two different stages with same file he has read them one more time
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approveDefaultDocStage]);
    //
    const {handleOpen} = usePDFView();
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState<string>('');
    const [insteadId, setInsteadId] = useState<string>('');
    useEffect(() => {
        if(file && fileName){
            upload(insteadId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file, fileName]);

    const {t, revDir} = useMainTranslation('', {keyPrefix: 'workerSpace'});

    //---
    const openViewer = (fileId: string, fileName: string) => {
        handleOpen({fileId, fileName});
    }

    const download = (id: string, name: string) => {
        if(stage && stage.stageDocument && stage.stageDocument.type === 'link'){
            window.open(id);
        }else{
            dispatch(DownloadFile({fileId: id, fileName: name}));
        }
        handleAddReadFile(id, name);
    }

    const upload = async(fileId: string) => {
        if(!file) return;
        if(stage && stage.stageDocument && stage.stageDocument.links.find(e => e.link === fileName) === undefined){
            const fileBase64: string = await toBase64(file) as string;
            dispatch(UploadFile({file: fileBase64, fileName, isSignature: false, stageId: stage.stage.id, insteadFileLink: fileId, handleReplaceReadFile}));
            setFileName('');
            setFile(null);
        }
    }
    //---

    if(!stage || !stage.stageDocument) return <></>;

    return(
        <Flex direction={'column'} w={'74%'}>
            {/*TOPBAR*/}
            <Flex w={'100%'} direction={'column'} ai={'center'} m={'35px 0 0 0'}>
                <Flex w={'100%'} jc={'space-between'} ai={'center'} wrap={'wrap'}>
                    <Flex w={'80%'} ai={'center'} wrap={'wrap'}>
                        <Typo fontSize={'16px'} fontWeight={300} color={'#878BA1'} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'}>{t('Document Name')}: </Typo>
                        <Flex w={'60%'} overflow={'hidden'}>
                            <Typo fontSize={'16px'} fontWeight={300} color={'#1D1D1D'} margin={!revDir ? '0 15px 0 0' : '0 0 0 15px'} textOverflow={'ellipsis'} noWrap>{stage.stageDocument.links[0].fileName}</Typo>
                        </Flex>
                    </Flex>
                    <Typo fontSize={'16px'} fontWeight={300} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'} color={'#FFA000'}>{t(stage.stage.type.toUpperCase())}</Typo>
                </Flex>

                <Flex jc={'space-between'} w={'100%'} ai={'center'} wrap={'wrap'}>
                    <Flex ai={'center'}>
                        <Typo fontSize={'16px'} fontWeight={300} color={'#878BA1'} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'}>{t('Expiration Date')}: </Typo>
                        <Typo fontSize={'16px'} fontWeight={200} color={'#1D1D1D'} margin={!revDir ? '0 10px 0 0' : '0 0 0 10px'} textOverflow={'fade'}>{parseDateAuto(stage.stage.expirationDate)}</Typo>
                        {getStatus(stage.stage.actor.status)}
                    </Flex>

                    <Flex ai={'center'} wrap={'wrap'}>
                        <Typo fontSize={'16px'} fontWeight={300} color={'#878BA1'} margin={!revDir ? '0 5px 0 0' : '0 0 0 5px'}>{t('Date Received')}: </Typo>
                        <Typo fontSize={'16px'} fontWeight={200} color={'#1D1D1D'} textOverflow={'fade'}>{parseDateAuto(stage.stage.runDate)}</Typo>
                    </Flex>
                </Flex>

                <Divider flexItem style={{backgroundColor: colors.primary.blue, margin: '8px 0 0 0'}} />
            </Flex>
            {/*FILES*/}
            <Flex w={'100%'} direction={'column'} jc={'center'} m={'8px 0 0 0'}>
                <Flex jc={'space-between'} w={'100%'}>
                    <Typo color={'#80859F'} fontSize={'16px'} fontWeight={300}>{t('File name')}</Typo>
                    <Typo color={'#80859F'} fontSize={'16px'} fontWeight={300}>{t('Last Update')}</Typo>
                </Flex>
                {stage.stageDocument.links.map((e, id) => {
                    return(
                       <>
                           <div key={e.link}>
                               <Flex jc={'space-between'} w={'100%'} ai={'center'} m={id > 0 ? '18px 0 0 0' : ''}>
                                   <Flex ai={'center'}>
                                       <DownloadIcon onClick={() => download(e.link, e.fileName)} style={{color: colors.primary.blue, margin: !revDir ? '0 20px 0 0' : '0 0 0 20px', cursor: 'pointer'}}/>
                                       {e.fileName.endsWith('.pdf') && <PageviewIcon onClick={() => openViewer(e.link, e.fileName)} style={{color: colors.primary.blue, margin: !revDir ? '0 20px 0 0' : '0 0 0 20px', cursor: 'pointer'}}/>}
                                       <Typo color={'#80859F'} fontSize={'16px'} fontWeight={200}>{e.fileName}</Typo>
                                   </Flex>
                                   <Typo color={'#80859F'} fontSize={'16px'} fontWeight={300} textOverflow={'ellipsis'}>{parseDateAuto(e.lastUpdate)}</Typo>
                               </Flex>
                               {(stage.stage.type === 'review' && stage.stage.actor.status === 'inProgress' && stage.stageDocument && stage.stageDocument.type !== 'link') &&
                                   <>
                                       {(uploadFile || updateDocumentOnReview) ?
                                           <LoadingButton loading variant={'contained'} size={'small'}>
                                               {t('UPLOAD REVIEW')}
                                           </LoadingButton>
                                           :
                                           <UploadFileBTN small id={e.link} sx={{margin: '10px 0 0 0'}} text={t('UPLOAD REVIEW')} onChange={(event) => {
                                               setFile(event.currentTarget.files![0]);
                                               setFileName(event.currentTarget.files![0].name);
                                               setInsteadId(e.link);
                                           }}
                                           />
                                       }
                                   </>
                               }
                           </div>
                           {stage.stageDocument && stage.stageDocument.links.length > 0 && <Divider flexItem style={{borderColor: colors.primary.disable, margin: '8px 0 0 0'}} />}
                       </>
                )
                })}
            </Flex>
            {/*ACTIONS*/}
            {stage && stage.stage.actor.status === 'inProgress' && stage.stage.type === 'read' &&
                <ReadConfirm stage={stage} isRed={calculateIsRead(stage)}/>
            }
            {stage && stage.stage.actor.status === 'inProgress' && stage.stage.type === 'review' &&
                <ReviewConfirm stage={stage} isRed={calculateIsRead(stage)}/>
            }

            {stage && stage.stage.actor.status === 'inProgress' && stage.stage.type === 'sign' &&
                <SignConfirm stage={stage} isRed={calculateIsRead(stage)}/>
            }

            {/*DIALOGS*/}
            <DocViewerDialog/>
        </Flex>

        // <Flex direction={'column'} w={'63%'}>
        //     DocumentAutomationComponent
        //     {/*<StageComponent*/}
        //     {/*    calculateIsRead={calculateIsRead} //using to calculate if all files were viewed/downloaded*/}
        //     {/*    handleReplaceReadFile={handleReplaceReadFile} //using to replace file id if in review stage file was updated*/}
        //     {/*    handleAddReadFile={handleAddReadFile} //using to add fileId if file was downloaded*/}
        //     {/*/>*/}
        // </Flex>
    )
}