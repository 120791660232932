import {useDispatch, useSelector} from "react-redux";
import {dialogsSelector, setCancelRequestAction} from "../../store/slice";
import {portalAssetsCancelAssetRequestAction} from "../../store/actions";


export const useCancelAssetRequestDialog = () => {
    const dispatch = useDispatch();
    const {cancelRequest: {isOpen, isLoading, requestId}} = useSelector(dialogsSelector);

    const handleClose = () => {
        dispatch(setCancelRequestAction({clear: true}));
    }

    const handleConfirm = () => {
        requestId && dispatch(portalAssetsCancelAssetRequestAction({data: {requestId}, snack: 'success'}))

    }

    const openCancelRequest = (requestId: string) => {
        dispatch(setCancelRequestAction({requestId}))
    }

    return {
        isOpen,
        isLoading,
        requestId,
        handleClose,
        handleConfirm,
        openCancelRequest,
    }
}
