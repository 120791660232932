//Core
import React, {FC} from "react";

//Router
import {Redirect, Route, Switch} from 'react-router';
import {
    ASSETS_PATH,
    DOCUMENTS_ROOT,
    HR_PATH,
    INCIDENT_REPORTING_PATH,
    PATH_LOCAL_2FA,
    PATH_LOCAL_HELP,
    PATH_LOCAL_INITIAL_MFA_CONFIGURE,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_LOGOUT,
    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_SPACE,
    PATH_LOCAL_VERIFY_EMAIL,
    REQUIRED_ACTIONS_PATH,
    RISK_TREATMENT_PATH,
    TRAININGS_PATH,
    VENDORS_PATH,
} from "../../../newShared/constants";
import {ContactUs} from "../../components/contactUs";
import {Settings} from "../../authWorkspacesCookies/settings";
import {SETTING_ROOT_PATH} from "../../authWorkspacesCookies/settings/constants";
import {SignIn} from "../../authWorkspacesCookies/signIn";
import {WorkerSpace} from "../../components/workerSpace";
import {Documents} from "../../components/documents";
import {ForbiddenWrapper} from "../../authWorkspacesCookies/settings/pages/forbiddenWrapper";
import {HR} from "../../components/hr";
import {Hr403} from "../../components/hr/pages/hr403";
import {Assets} from "../../components/assets";
import {LeftSecondMenu} from "../leftMenu/components/leftSecondMenu";
import {EmptyArrayImage} from "../../../newShared/components/EmptyArrayImage";
import {Flex} from "../../../newShared/components/Layouts";
import {RequiredActions} from "../../components/requiredActions";
import {Trainings} from "../../components/trainings";

export const Routes: FC = () => {

    return (
        <Switch>
            <Route path="/" exact>
                <Redirect to={DOCUMENTS_ROOT}/>
            </Route>

            {/*INIT*/}
            <Route exact path={[
                PATH_LOCAL_LOGIN,
                PATH_LOCAL_2FA,

                PATH_LOCAL_MFA_RESET,
                PATH_LOCAL_PASSWORD_RESET,

                PATH_LOCAL_VERIFY_EMAIL,
                PATH_LOCAL_INITIAL_MFA_CONFIGURE,

                PATH_LOCAL_LOGIN_CONTACT_US,
            ]} component={SignIn}/>

            <Route path={SETTING_ROOT_PATH}>
                <Settings />
            </Route>

            <Route exact path={PATH_LOCAL_LOGOUT}>
                <Redirect to={PATH_LOCAL_LOGIN}/>
            </Route>

            {/*PAGES*/}
            <Route path={DOCUMENTS_ROOT}>
                <ForbiddenWrapper path={DOCUMENTS_ROOT} pathName={'Documents'}>
                    <Documents />
                </ForbiddenWrapper>
            </Route>

            <Route path={HR_PATH}>
                <ForbiddenWrapper path={HR_PATH} pathName={'HR'} custom403={<Hr403/>}>
                    <HR />
                </ForbiddenWrapper>
            </Route>

            <Route path={ASSETS_PATH}>
                <ForbiddenWrapper path={ASSETS_PATH} pathName={'Assets'}>
                    <Assets />
                </ForbiddenWrapper>
            </Route>

            <Route path={PATH_LOCAL_SPACE}>
                <ForbiddenWrapper path={PATH_LOCAL_SPACE} pathName={'Staff (Deprecated)'}>
                    <WorkerSpace />
                </ForbiddenWrapper>
            </Route>

            <Route path={REQUIRED_ACTIONS_PATH}>
                <ForbiddenWrapper path={REQUIRED_ACTIONS_PATH} pathName={'Required actions'}>
                    <RequiredActions />
                </ForbiddenWrapper>
            </Route>



            <Route path={VENDORS_PATH}>
                <ForbiddenWrapper path={VENDORS_PATH} pathName={'Vendors'}>
                    <LeftSecondMenu>
                        <Flex w={'100%'}>
                            <EmptyArrayImage
                                type={'builderDev'}
                                isShowing={true}
                                text={'Vendors in development'}
                                withoutAdd
                            />
                        </Flex>
                    </LeftSecondMenu>
                </ForbiddenWrapper>
            </Route>

            <Route path={TRAININGS_PATH}>
                <ForbiddenWrapper path={TRAININGS_PATH} pathName={'Trainings'}>
                    <Trainings/>
                </ForbiddenWrapper>
            </Route>

            <Route path={RISK_TREATMENT_PATH}>
                <ForbiddenWrapper path={RISK_TREATMENT_PATH} pathName={'Risk treatment'}>
                    <LeftSecondMenu>
                        <Flex w={'100%'}>
                            <EmptyArrayImage
                                type={'builderDev'}
                                isShowing={true}
                                text={'Risk treatment in development'}
                                withoutAdd
                            />
                        </Flex>
                    </LeftSecondMenu>
                </ForbiddenWrapper>
            </Route>

            <Route path={INCIDENT_REPORTING_PATH}>
                <ForbiddenWrapper path={INCIDENT_REPORTING_PATH} pathName={'Incident reporting'}>
                    <LeftSecondMenu>
                        <Flex w={'100%'}>
                            <EmptyArrayImage
                                type={'builderDev'}
                                isShowing={true}
                                text={'Incident reporting in development'}
                                withoutAdd
                            />
                        </Flex>
                    </LeftSecondMenu>
                </ForbiddenWrapper>
            </Route>



            {/*Settings*/}
            <Route path={SETTING_ROOT_PATH}>
                <Settings />
            </Route>

            <Route exact path={PATH_LOCAL_HELP} component={ContactUs}/>

            {/*REDIRECT*/}
            <Redirect to={DOCUMENTS_ROOT}/>
        </Switch>
    );
}
