import {Box, styled} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";

export const PageWrapper = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));

export const StatusBox = styled(Box,  {
    shouldForwardProp: (propName) => (
        !["bgColor"].includes(propName.toString())
    )})(({bgColor, margin}:{bgColor: string, margin?: string}) => ({
    minWidth: '104px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 14px',
    borderRadius: '15px',
    backgroundColor: bgColor,
    margin,
}));

export const StatusTitle = styled('span')(({color}:{color: string}) => ({
    fontSize: '14px',
    fontWeight: 500,
    color: color
}));

export const SpanRed = styled('span')(() => ({
    color: colors.text.critical
}));

export const SpanGrey = styled('span')(() => ({
    color: colors.text.grey
}));

export const RequestTitleSpan = styled("span")(() => ({
    color: colors.text.dark,
}));

export const DescriptionRow = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '10px',
}))

export const Hr403Wrapper = styled(Box)({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
});

export const Hr403MessageWrapper = styled(Box)({
    maxWidth: '800px',
    border: `1px solid ${colors.text.grey_light_1}`,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '32px',
    gap: '22px',
    margin: '40px auto'
});

export const DescriptionRowTitle = styled(Box)(() => ({
    minWidth: '160px',
    width: 'fit-content',
    alignSelf: 'flex-start'
}))

