import axiosSync from "../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../newShared/constants";
import {buildCommonHeader} from "../../../newShared/utils/builders";
import {AxiosResponse} from "axios";

export const generateNewToken = async (organizationId: string, workspaceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation generateNewToken($organizationId:String!, $workspaceId:String!){
                  generateNewToken(organizationId:$organizationId, workspaceId:$workspaceId) {
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.generateNewToken?.message;
        }catch (ex: any){
            throw new Error(ex.message);
        }
    })
)