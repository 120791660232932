import React, {FC, useEffect} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {MicroTableWithoutBorder} from "../../../../../newShared/components/microTableWithoutBorder";
import {ASSETS_REQUESTS_PATH} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";
import {useAssetRequestExact} from "../../hooks/useAssetRequestExact";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {AssetsRequestStatus, AssetsRequestType} from "../../../../../newShared/GQLTypes";
import {getAssetRequestStatusChip} from "../../helpers";
import {assetRequestTypeTitleMapper} from "../../constants";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {useAssetsRequestError} from "../../hooks/dialogs/useAssetsRequestError";

export const AssetRequestExactDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathAssets.pathRequestsList'})
    const {requestId} = useParams<{requestId?: string}>();

    const history = useHistory();
    const location = useLocation();

    const {
        request,
        isLoading,
        isLoadingCancelRequest,
        getRequest,
        cleanSelected,
        handleCancelRequest
    } = useAssetRequestExact();


    const handleClose = () => {
        history.push(`${ASSETS_REQUESTS_PATH}${location.search}`);
    }

    useEffect(() => {
        if (requestId) {
            getRequest(requestId);
        } else {
            cleanSelected();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId]);

    const {isOpen: isOpenErrorDialog} = useAssetsRequestError();

    return (
        <Dialog open={!!requestId && !isOpenErrorDialog}>
            <DialogTitle>{`${t('Request')} ${requestId}`}</DialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto', gap: '16px'}}>
                <Typography variant={'*bodyText1_semibold'}>{`${t('Request details')}: `}</Typography>
                <MicroTableWithoutBorder isLoading={isLoading}
                                         rows={[
                                             {
                                                 label: `${t('Status')}: `,
                                                 text: request ? getAssetRequestStatusChip(request.status) : '',
                                             },
                                             {
                                                 label: `${t('Request type')}: `,
                                                 text: request ? assetRequestTypeTitleMapper[request.type] : '',
                                             },
                                             {
                                                 label: `${t('Request date')}: `,
                                                 text: request?.requestDate ? parseDateAuto(request?.requestDate) : '-',
                                             },
                                             request?.type === AssetsRequestType.Remove ? {
                                                 label: `${t('ID')}: `,
                                                 text: request?.data?.find((e) => e.fieldName === 'assetId')?.fieldValue || '-',
                                             } : null,
                                             request?.type === AssetsRequestType.AddAsset || request?.type === AssetsRequestType.Remove ? {
                                                 label: `${t('Serial')}: `,
                                                 text: request?.data?.find((e) => e.fieldName === 'serial')?.fieldValue || '-',
                                             } : null,
                                             {
                                                 label: `${t('Model')}: `,
                                                 text: request?.data?.find((e) => e.fieldName === 'model')?.fieldValue || '-',
                                             },
                                             {
                                                 label: `${t('Category')}: `,
                                                 text: request?.data?.find((e) => e.fieldName === 'category')?.fieldValue || '-',
                                             },
                                             {
                                                 label: `${t('Manufacturer')}: `,
                                                 text: request?.data?.find((e) => e.fieldName === 'manufacture')?.fieldValue || '-',
                                             },
                                             {
                                                 label: `${t('Comment')}: `,
                                                 text: request?.comment,
                                             },

                                         ]}
                />
                {(request?.status === AssetsRequestStatus.Approved || request?.status === AssetsRequestStatus.Rejected) &&
                    <>
                        <Divider flexItem sx={{borderColor: colors.stroke.grey}}/>
                        {request?.status === AssetsRequestStatus.Approved ? <Typography variant={'*bodyText1_semibold'}>{`${t('Approved info')}: `}</Typography>
                        :
                        <Typography variant={'*bodyText1_semibold'}>{`${t('Rejected info')}: `}</Typography>}
                        <MicroTableWithoutBorder isLoading={isLoading}
                                                 rows={[
                                                     {
                                                         label: `${t('Resolver')}: `,
                                                         text: request?.resolver?.fullName || '-',
                                                     },
                                                     {
                                                         label: `${t('Resolved date')}: `,
                                                         text: request?.resolveDate ? parseDateAuto(request?.resolveDate) : '-',
                                                     },
                                                     {
                                                         label: `${t('Comment')}: `,
                                                         text: request?.resolution || '-',
                                                     },

                                                 ]}
                        />
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{(tCommon('Close'))}</Button>
                {request?.status === AssetsRequestStatus.Created &&
                    <LoadingButton variant={"contained"} onClick={handleCancelRequest} loading={isLoadingCancelRequest} disabled={!requestId} size={'small'}>
                        {t('Cancel request')}
                    </LoadingButton>
                }
            </DialogActions>
        </Dialog>
    )
}
