import {useDispatch, useSelector} from "react-redux";
import {loadings, openDeclineVerifyStepDialog, openShowPermissions, selectedStage} from "../../store/slice";
import {calculateIfDeadlinePassed} from "../../helpers";
import {COMPLETE_STATUS, TERMINATED_STATUS} from "../../constants";
import {VerifyActorChanges} from "../../store/actions";

export const useCampaignReviewVerifyStep = () => {
    const dispatch = useDispatch();
    const stage = useSelector(selectedStage);
    const {campaignReviewUpdatingRowsWithIds, verifyChanges} = useSelector(loadings);
    const verifyStep = stage?.stageCampaignReview ?? null;

    return{
        onRoleClick:(roleName: string, rolePermissions: string[]) => {
            rolePermissions.length > 0 && dispatch(openShowPermissions({rolePermissions, roleName}));
        },
        commonSelectors: {
            isLoadingVerify: verifyChanges,
            loadingIds: campaignReviewUpdatingRowsWithIds,
        },
        stage: verifyStep,
        isDeadlinePassed: calculateIfDeadlinePassed(verifyStep),
        isComplete: verifyStep?.status === COMPLETE_STATUS,
        isTerminated: verifyStep?.status === TERMINATED_STATUS,
        handleVerifyChanges: (email: string) => {
            if(verifyStep && stage){
                dispatch(VerifyActorChanges({
                    data: {
                        executorId: stage.stage.executorId,
                        verifyComment: null, //stage managers - set null
                        stageId: verifyStep.id,
                        signatureId: null
                    }
                }));
            }
        },
        handleDeclineChanges: (email: string) => {
            if(verifyStep){
                const isLastVerify: boolean = !verifyStep.actors.filter(e => e.email !== email).some(e => e.status !== COMPLETE_STATUS);
                dispatch(openDeclineVerifyStepDialog({
                    email,
                    stageId: verifyStep.id,
                    isLastVerify
                }))
            }
        }
    }
}