import {useDispatch, useSelector} from "react-redux";
import {dialogs, hideDeclineVerifyStepDialog, loadings, selectedStage} from "../../../../store/slice";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React, {useEffect} from "react";
import {useForm} from "../../../../../../../newShared/hooks/useForm";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {VerifyActorChanges} from "../../../../store/actions";
import { DialogContentWrapper } from "../../../styled";

export const DeclineVerifyStepDialog = () => {
    const dispatch = useDispatch();
    const {revDir} = useMainTranslation();

    const {
        isOpen,
        stageId,
        email,
    } = useSelector(dialogs).declineVerifyStepDialog;
    const isLoading = useSelector(loadings).verifyChanges
    const stage = useSelector(selectedStage);

    useEffect(() => {
        if(isOpen){
            cleanUp();
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        dispatch(hideDeclineVerifyStepDialog());
    }

    const handleSubmit = () => {
        if(isOk() && stage && email && stageId){
            dispatch(VerifyActorChanges({
                data: {
                    executorId: stage.stage.executorId,
                    signatureId: null,
                    verifyComment: form.comment,
                    stageId,
                }
            }));
        }
    }

    const {form, handleChange, setForm} = useForm<{comment: string}>({comment: ''});

    const cleanUp = () => setForm({comment: ''});

    const isOk = () => form.comment.trim().length > 0;

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{(`Decline changes`)}</DialogTitle>

            <DialogContent>
                <DialogContentWrapper>
                    <TextField
                        autoFocus
                        size={'small'}
                        sx={{margin: '16px 0 0 0'}}
                        value={form.comment}
                        name={'comment'}
                        onChange={handleChange}
                        label={'Decline reason'}
                        required
                    />
                </DialogContentWrapper>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{('Cancel')}</Button>
                <LoadingButton
                    sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                    loading={isLoading}
                    onClick={handleSubmit}
                >
                    {('Decline changes')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}