import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {importCsvFragmentFields, performCsvFragment} from "../../../../newShared/graphQl/fragments";

export const changeUserProfileInfoGQL = print(gql`
    mutation changeUserProfileInfoGQL($data: ChangeNameInput!) {
        changeName(data: $data) {
            message
        }
    }
`)

export const validate2faCode = print(gql`
    mutation validate2faCode($code: String!, $secret: String!) {
        initialValidateGoogleAuthorization(code: $code, secret: $secret)
    }
`)

export const reconfigureGoogleAuthInSettings = print(gql`
    mutation reconfigureGoogleAuthInSettings {
        reconfigureGoogleAuth {
            qrCode
            secret
        }
    }
`)

export const disableGoogleAuth = print(gql`
    mutation disableGoogleAuth {
        disableGoogleAuth {
            message
        }
    }
`)

export const importCsvTemplateCollaborators = print(gql`
    query importCsvTemplatecollaborators($workspaceId: String!){
        importCsvTemplateCollaborators(workspaceId: $workspaceId)
    }
`)

export const getCsvFieldsCollaborators = gql`
    mutation getCsvFieldsCollaborators($workspaceId: String!, $file: String!, $name: String!){
        getCsvFieldsCollaborators(workspaceId: $workspaceId, file: $file, name: $name) {
            fileId
            fields {
                ...importCsvFragmentFields
            }
        }
    }
    ${importCsvFragmentFields}
`

export const performCsvCollaborators = gql`
    mutation performCsvCollaborators($workspaceId: String!, $fileId: String!, $fields: [ImportCsvFieldInput!]!){
        performCsvCollaborators(workspaceId: $workspaceId, fileId: $fileId, fields: $fields) {
            ...performCsvFragment
        }
    }
    ${performCsvFragment}
`

