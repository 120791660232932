import React, {FC, useEffect, useState} from "react";
import {useHrProfile} from "../../hooks/useHrProfile";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {getName} from "../../../../../newShared/utils/text";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {HR_ORG_CHART_PATH, HR_PATH} from "../../../../../newShared/constants";
import {Tab, Tabs} from "@mui/material";
import TabContext from "@mui/lab/TabContext/TabContext";
import {GeneralTab} from "./components/generalTab";
import {JobInfoTab} from "./components/jobInfoTab";
import {FlexColumn} from "../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {HrCreateEditRequestDialog} from "../../components/dialogs/hrCreateEditRequestDialog";
import {AccountTree, Edit} from "@mui/icons-material";
import {useHrCreateEditRequestDialog} from "../../hooks/dialogs/useHrCreateEditRequestDialog";
import {useHistory} from "react-router-dom";

export const HrProfile: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathHrProfile'});
    const {
        employee,
        getMyHrProfile,
    } = useHrProfile();

    const history = useHistory();
    const {handleOpenEditRequest} = useHrCreateEditRequestDialog();
    const handleOrgTree = () => {
        history.push(`${HR_ORG_CHART_PATH}?id=${employee?.id}`)
    }

    const [tab, setTab] = useState<'general' | 'jobInfo'>('general');
    const handleChangeTab = (event: React.SyntheticEvent, newValue: 'general' | 'jobInfo') => setTab(newValue);

    const {tMenu} = useMainTranslation('', {keyPrefix: ''});
    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: HR_PATH
        },
        {
            title: tMenu('My profile'),
        },
    ]);

    useEffect(() => {
        getMyHrProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FlexColumn sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%'}}>
            <PageTitleAndActions
                title={employee ? getName(employee.firstName, employee.lastName) : undefined}
                actions={[
                    {
                        title: t('Create edit request'),
                        onClick: handleOpenEditRequest,
                        icon: <Edit/>,
                        size: 'small'
                    },
                    {
                        title: t('Org tree'),
                        onClick: handleOrgTree,
                        icon: <AccountTree/>,
                        size: 'small',
                        variant: 'text'
                    },
                ]}
            />

            <TabContext value={tab}>
                <FlexColumn gap={'16px'} width={'100%'} overflow={'hidden'}>
                    <Tabs variant={'scrollable'} value={tab} onChange={handleChangeTab}>
                        <Tab label={t("General")} value={'general'}/>
                        <Tab label={t("Job info")} value={'jobInfo'}/>
                    </Tabs>

                    <GeneralTab/>
                    <JobInfoTab/>
                </FlexColumn>
            </TabContext>


            <HrCreateEditRequestDialog/>
        </FlexColumn>
    );
};