import {useDispatch, useSelector} from "react-redux";
import {dialogsSelector, setAssetsRequestErrorDialogAction} from "../../../store/slice";


export const useAssetsRequestError = () => {
    const dispatch = useDispatch();
    const {errorDialog: {message, title}} = useSelector(dialogsSelector);
    
    const handleClose = () => {
        dispatch(setAssetsRequestErrorDialogAction({clear: true}));
    }

    return {
        isOpen: !!message.length,
        title,
        message,
        handleClose,
    }
}
