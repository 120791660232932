import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import {loadingsSelector} from "../../../../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {portalUpdateTrainingDurationAction} from "../../../../../store/actions";
import {LoadingButton} from "../../../../../../../../newShared/components/Basic/CommonButtons";
import {useMessageDialog} from "../../../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {Flex} from "../../../../../../../../newShared/components/Layouts";

type LeaveDialogPropsType = {
    isOpen: boolean,
    handleClose: () => void,
    handleLeave: () => void,
    duration: number,
    assignmentId: string,
    limitMinDuration: number
}
export const LeaveDialog = ({isOpen, handleLeave, handleClose, duration, assignmentId, limitMinDuration}: LeaveDialogPropsType) => {
    const dispatch = useDispatch();
    const {setMessage} = useMessageDialog();
    const isLoading = useSelector(loadingsSelector).interruptTraining;

    const handleInterruptTraining = () => {
        dispatch(portalUpdateTrainingDurationAction({
            data: {duration, assignmentId},
            onSuccess: handleLeave,
            onError: (_, error) => {
                const error409 = error.e409?.[0];
                if (error409?.type === 'STATUS_CHANGED') {
                    setMessage({
                        title: ('Action conflict error'),
                        message: ('Action not applicable! Assignment status was changed.'),
                    });
                }
                if (error409?.type === 'TRAINING_NOT_EXISTS') {
                    setMessage({
                        title: ('Action conflict error'),
                        message: ('Action not applicable! Training not found.'),
                    });
                }
            }
        }))
    }

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Close training')}</DialogTitle>

            <DialogContent>
                <Flex direction={'column'} gap={'8px'}>
                    <Typography variant={'*bodyText2'} color={colors.grayText}>
                        {`Are you sure you want to close training?`}
                    </Typography>

                    {limitMinDuration > 0 &&
                        <Typography variant={'*bodyText2'} color={colors.grayText}>
                            {`The training requires a minimum time duration of ${limitMinDuration} hours.`}
                        </Typography>
                    }

                    {limitMinDuration > 0 &&
                        <Typography variant={'*bodyText2'} color={colors.grayText}>
                            {`If you close the training your current duration progress of ${(duration / 60).toFixed()} minutes will be paused.`}
                        </Typography>
                    }
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{('Close')}</Button>
                <LoadingButton onClick={handleInterruptTraining}
                               variant={'contained'}
                               size={'small'}
                               loading={isLoading}
                >
                    {('Close training')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}