import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    PATH_LOCAL_2FA,
    PATH_LOCAL_INITIAL_MFA_CONFIGURE,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_VERIFY_EMAIL
} from "../../../newShared/constants";
import {BlockSignIn} from "./pages/blockSignIn";
import {BlockSignInMfa} from "./pages/blockSignInMfa";
import {BlockSignInRestoreMfa} from "./pages/blockSignInRestoreMfa";
import {BlockSignInRestorePassword} from "./pages/blockSignInRestorePassword";
import {BlockContactUs} from "./pages/blockContactUs";
import {BlockEmailVerification} from "./pages/blockEmailVerification";
import {BlockInitialMfaApp} from "./pages/blockInitialMfaApp";

export const Routes: FC = () => {
    
    return (
        <Switch>
            <Route exact path={PATH_LOCAL_LOGIN} component={BlockSignIn}/>
            <Route exact path={PATH_LOCAL_2FA} component={BlockSignInMfa}/>

            <Route exact path={PATH_LOCAL_VERIFY_EMAIL} component={BlockEmailVerification}/>

            <Route exact path={PATH_LOCAL_PASSWORD_RESET} component={BlockSignInRestorePassword}/>
            <Route exact path={PATH_LOCAL_MFA_RESET} component={BlockSignInRestoreMfa}/>

            <Route exact path={PATH_LOCAL_INITIAL_MFA_CONFIGURE} component={BlockInitialMfaApp}/>

            <Route exact path={PATH_LOCAL_LOGIN_CONTACT_US} component={BlockContactUs}/>

            <Redirect to={PATH_LOCAL_LOGIN}/>
        </Switch>
    )
}