import {useDispatch, useSelector} from "react-redux";
import {
    eraseRequestList,
    hrIsLoadingSelector,
    hrMyRequestsSelector,
    openCancelRequest,
    openRequestView,
    replaceRequestMinMaxLoadedPage
} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {HR_PROFILE_PATH, HR_REQUESTS_PATH, HR_REQUESTS_PATH_EXACT} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {getPortalHrRequestsWithFilterPaginationAction} from "../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getRequestStatusChip} from "../../helpers";
import {HrRequestStatus, PortalHrRequestModel} from "../../../../../newShared/GQLTypes";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";


export const useHrRequests = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathMyHrRequests'});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {requests, pageInfo, minMaxLoadedPage} = useSelector(hrMyRequestsSelector);
    const {requestList: isLoadingList} = useSelector(hrIsLoadingSelector);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;

    useSetBreadcrumbs([
        {
            title: tMenu('HR Management'),
            path: HR_PROFILE_PATH
        },
        {
            title: tMenu('My HR requests'),
            path: HR_REQUESTS_PATH
        },
    ]);

    const handleOpenRequestView = (selected: PortalHrRequestModel) => {
        selected && history.push(`${HR_REQUESTS_PATH_EXACT.replace(':requestId', selected?.id)}${location.search}`);
        dispatch(openRequestView(selected.id));
    }

    const handleOpenCancelRequest = (selected: PortalHrRequestModel) => {
        dispatch(openCancelRequest(selected.id));
    }

    //filters
    const requestDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'requestDate',
        name: t('Request date'),
        default: true
    }
    const statusConfig: keyConfig<HrRequestStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(HrRequestStatus),
        OptionRenderer: (a) => getRequestStatusChip(a, true),
        default: true,
    }

    // const requestTypeConfig: keyConfig<RequestType> = {
    //     type: 'filter',
    //     key: 'status',
    //     name: t('Status'),
    //     options: [RequestType.EditProfile],
    //     // OptionRenderer: (a) => getStatusChip(a),
    //     getOptionLabel: (a) => a,
    //     default: true
    // }



    return {
        requests,
        pageInfo,

        handleOpenRequestView,
        handleOpenCancelRequest,

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceRequestMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseRequestList()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, requestIdLike: string) => {
                    dispatch(getPortalHrRequestsWithFilterPaginationAction({data: {pageRequest: {page, count}, filter: {...filters, requestIdLike}}}));
                },
            },
            filters: {
                configs: [statusConfig, requestDateConfig]
            }
        }
    }
}
