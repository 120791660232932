import React, {FC} from "react";
import {useCustomFormik} from "../../../../../../../../newShared/hooks/useCustomFormik";
import {
    PortalTrainingsAssignmentsExamQuestionModel,
    TrainingExamQuestionType
} from "../../../../../../../../newShared/GQLTypes";
import {Checkbox, Radio, TextField, Typography} from "@mui/material";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../../../../newShared/theme/colors";
import {ArrowCircleLeftOutlined, ArrowCircleRightOutlined, TaskAltOutlined} from "@mui/icons-material";
import {makeFirstLetterUpper} from "../../../../../../../../newShared/utils/text";

type PassingExamAnswerBlockProps = {
    questionIndex: number;
    masterFormik: ReturnType<typeof useCustomFormik<{questions: PortalTrainingsAssignmentsExamQuestionModel[]}>>;
    disabled?: boolean;
}
export const PassingExamAnswerBlock: FC<PassingExamAnswerBlockProps> = ({masterFormik: formik, questionIndex, disabled}) => {
    const {t, revDir} = useMainTranslation();

    const question = formik.values.questions[questionIndex];

    const error0 = formik.getFieldMeta(`questions[${questionIndex}]answer[0]`).error;
    const touch0 = formik.getFieldMeta(`questions[${questionIndex}]answer[0]`).touched || undefined;

    const answered = question.status === 'ANSWERED';
    const skipped = question.status === 'SKIPPED';

    const Icon = (
        <>
            {answered && <TaskAltOutlined sx={{color: colors.stroke.blue, fontSize: '24px'}}/>}
            {skipped && (!revDir ? <ArrowCircleRightOutlined sx={{color: colors.stroke.dark_grey, fontSize: '24px'}}/> : <ArrowCircleLeftOutlined sx={{color: colors.stroke.dark_grey, fontSize: '24px'}}/>)}
        </>
    );

    return (
        <FlexColumn sx={{
            gap: '8px', p: '20px', width: '100%', height: 'fit-content',
            border: `1px solid ${colors.stroke.table}`,
            borderRadius: '6px',
        }}>
            <FlexRow sx={{alignItems: 'center', justifyContent: 'space-between', gap: '16px'}}>
                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey}>{`${t('Description')}:`}</Typography>
                {(answered || skipped) &&
                    <FlexRow sx={{alignItems: 'center', gap: '8px'}}>
                        {Icon}
                        <Typography variant={'*bodyText2'}>{makeFirstLetterUpper(question.status)}</Typography>
                    </FlexRow>
                }
            </FlexRow>
            <Typography variant={'*bodyText1'} color={colors.text.dark}>{question.description}</Typography>

            <Typography variant={'*bodyText2_semibold'} color={colors.text.grey} sx={{mt: '12px'}}>{`${t('Question')}:`}</Typography>
            <Typography variant={'*h3'} sx={{mb: '12px'}}>{question.question}</Typography>

            {question.type === TrainingExamQuestionType.Paragraph && (
                <TextField multiline fullWidth rows={4} variant={'outlined'} label={t('Type your answer')}
                           name={`questions[${questionIndex}]answer[0]`}
                           value={formik.values.questions[questionIndex].answer?.[0] || ''}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={Boolean(touch0 && error0)}
                           helperText={touch0 && error0}
                           autoFocus={!disabled}
                           disabled={disabled}
                />
            )}

            {question.type === TrainingExamQuestionType.ShortAnswer && (
                <TextField fullWidth size={"small"} variant={'outlined'} label={t('Type your answer')}
                           name={`questions[${questionIndex}]answer[0]`}
                           value={formik.values.questions[questionIndex].answer?.[0] || ''}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={Boolean(touch0 && error0)}
                           helperText={touch0 && error0}
                           autoFocus={!disabled}
                           disabled={disabled}
                />
            )}

            {(question.type === TrainingExamQuestionType.MultipleChoice || question.type === TrainingExamQuestionType.Checkboxes) && (
                <FlexColumn gap={'6px'}>
                    {question.options.map((o) => {
                        const error = formik.getFieldMeta(`questions[${questionIndex}]answer`).error;
                        const touch = formik.getFieldMeta(`questions[${questionIndex}]answer`).touched || undefined;

                        const onChange = (_: any, checked: boolean) => {
                            if (question.type === TrainingExamQuestionType.MultipleChoice) {
                                formik.setFieldValue(`questions[${questionIndex}]answer`, [o.option]);
                            } else {
                                const answer = formik.values.questions[questionIndex].answer || [];
                                formik.setFieldValue(`questions[${questionIndex}]answer`, checked ? [...answer, o.option] : answer.filter(a => a !== o.option))
                            }
                        };

                        const checked = formik.values.questions[questionIndex].answer?.includes(o.option);

                        return (
                            (
                                <FormControlLabel key={o.option + checked} label={o.option} checked={checked}
                                                  disabled={disabled}
                                                  sx={{
                                                      // '.MuiRadio-root > span': {
                                                      //     color: disabled ? 'inherit !important' : undefined
                                                      // },
                                                      'span.MuiTypography-root': {
                                                          mt: '-1px',
                                                      }
                                                  }}
                                                  control={
                                                      question.type === TrainingExamQuestionType.MultipleChoice
                                                          ? <Radio sx={{color: touch && error ? colors.text.critical : undefined, p: 0, marginInline: '10px'}}/>
                                                          : <Checkbox sx={{color: touch && error ? colors.text.critical : undefined, p: 0, marginInline: '10px'}}/>
                                                  }
                                                  onChange={onChange}
                                />
                            )
                        )
                    })}
                </FlexColumn>
            )}
        </FlexColumn>
    )
};