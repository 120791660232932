import {FC} from "react";
import * as S from "../../components/styled";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {PortalHrIssueModel} from "../../../../../newShared/GQLTypes";
import {getIssueStatusChip} from "../../helpers";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {useHrIssues} from "../../hooks/useHrIssues";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import colors from "../../../../../newShared/theme/colors";
import {CreateIssueDialog} from "../../components/dialogs/createIssue";
import {HrIssueViewDialog} from "../../components/dialogs/hrIssueView";

export const HrIssues: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathMyHrIssues'});
    const {
        issues,
        genericTable,
        handleOpenAddIssue,
        handleOpenIssue,
    } = useHrIssues();
    const {isMobile} = useMedia();

    return (
        <S.PageWrapper>
            <PageTitleAndActions
                title={t('My Issues')}
                actions={[
                    {
                        title: t('New issue'),
                        onClick: handleOpenAddIssue,
                        icon: <AddIcon />,
                        size: 'small'
                    },
                ]}
            />

            <GenericTable<PortalHrIssueModel>
                id={'MyHrIssues'}
                columnsConfig={{totalName: 'Total issues', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    rows: issues,
                    // customCellPaddings: '8px',
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenIssue,
                    // rowActionsConfig: [
                    //     {
                    //         title: t('Cancel request'),
                    //         onClick: handleOpenCancelRequest,
                    //         icon: <CancelScheduleSendIcon fontSize={'small'} style={{color: colors.primary.blue}}/>,
                    //         dontShow: (row) => row.status !== RequestStatus.Created
                    //     },
                    // ],
                    columnsConfigs: [
                        {key: "id", name: t('Issue ID'), default: true, },
                        {key: 'issueType', name: t('Issue type'), default: true, valueGetter: (row) => t(row.issueType)},
                        {key: 'offender', name: t('Offender'), default: true, valueGetter: (row) => row.offender?.fullName ?? '-'},
                        {key: 'reportedDate', name: t('Reported date'), default: true, valueGetter: (row) => parseDateAuto(row.requestDate)},
                        {key: 'isAnonymous', name: t('Is anonymous'), default: true, valueRenderer: (row) => row.anonymous ? <CheckIcon style={{color: colors.text.success}} /> : ''},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getIssueStatusChip(row.status)},
                        {key: 'resolver', name: t('Resolver'), default: true, valueGetter: (row) => row.resolver ? row.resolver.employeeFullName || row.resolver.collaboratorFullName : '-'},
                        {key: 'resolveDate', name: t('Resolve date'), default: true, valueGetter: (row) => parseDateAuto(row.resolveDate ?? '')},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'issueIdLike',
                    searchAsArray: true,
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('No issues to display')}}}
            />


            {/*DIALOGS*/}
            <HrIssueViewDialog />
            <CreateIssueDialog />

        </S.PageWrapper>
    );
};
