import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {TPageInfo} from "../../../../newShared/types";
import {
    AssetsRequestStatus,
    PortalAssetsGetPhysicalAssetsSettingsForFiltersQuery,
    PortalAssetsRequestModel,
    PortalPhysicalAssetModel
} from "../../../../newShared/GQLTypes";
import {minMaxLoadedPageType} from "../../../../newShared/components/genericTable/types";
import {defaultPageInfo} from "../../hr/constants";
import {minMaxLoadedPageDefault} from "../../../../newShared/components/genericTable/constants";
import {handlePagination} from "../../../../newShared/components/genericTable/helpers";
import {
    portalAssetsCancelAssetRequestAction,
    portalAssetsCreateRequestAddAssetAction,
    portalAssetsCreateRequestRemoveAssetAction,
    portalAssetsCreateRequestRequestAssetAction,
    portalAssetsGetPhysicalAssetByIdAction,
    portalAssetsGetPhysicalAssetsSettingsForFiltersAction,
    portalAssetsGetPhysicalAssetsWithFilterPaginationAction,
    portalAssetsGetRequestByIdAction,
    portalAssetsGetRequestsWithFilterPaginationAction,
    portalGetAssetConfigsAction
} from "./actions";
import {getErrorsByCode} from "../../../../newShared/utils/asyncThunk/helpers";

export type TAssetsSlice = {
    assets: {
        assets: PortalPhysicalAssetModel[],
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType,
        selected: PortalPhysicalAssetModel | null,
        filterOptions: PortalAssetsGetPhysicalAssetsSettingsForFiltersQuery["portalAssetsGetPhysicalAssetsSettingsForFilters"] | null,
    },
    requests: {
        requests: PortalAssetsRequestModel[],
        pageInfo: TPageInfo,
        minMaxLoadedPage: minMaxLoadedPageType,
        selected: PortalAssetsRequestModel | null,
    },
    loadings: {
        assetList: boolean,
        assetExact: boolean,
        requestList: boolean,
        requestExact: boolean,
        settings: boolean,
        isLoadingSendAddAsset: boolean,
        isLoadingGetSettings: boolean,
    },
    dialogs: {
        requestAsset: {
            isOpen: boolean,
            isLoading: boolean,
        },
        addAsset: {
            isOpen: boolean,
            isLoading: boolean,
        },
        removeAsset: {
            isOpen: boolean,
            isLoading: boolean,
            selected: PortalPhysicalAssetModel | null,
        },
        cancelRequest: {
            isOpen: boolean,
            isLoading: boolean,
            requestId: string | null,
        },
        errorDialog: {
            title: string,
            message: string[],
        },
    }
    settings: {
        model: string[] | null,
        category: string[] | null,
        manufacture: string[] | null,
    },

}

const initialState: TAssetsSlice = {
    assets: {
        assets: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selected: null,
        filterOptions: null,
    },
    requests: {
        requests: [],
        pageInfo: defaultPageInfo,
        minMaxLoadedPage: minMaxLoadedPageDefault,
        selected: null,
    },
    loadings: {
        assetList: false,
        assetExact: false,
        requestList: false,
        requestExact: false,
        settings: false,
        isLoadingSendAddAsset: false,
        isLoadingGetSettings: false,
    },
    dialogs: {
        requestAsset: {
            isOpen: false,
            isLoading: false,
        },
        addAsset: {
            isOpen: false,
            isLoading: false,
        },
        removeAsset: {
            isOpen: false,
            isLoading: false,
            selected: null,
        },
        cancelRequest: {
            isOpen: false,
            isLoading: false,
            requestId: null,
        },
        errorDialog: {
            title: '',
            message: [],
        },
    },
    settings: {
        model: null,
        category: null,
        manufacture: null,
    }


}

const Slice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        openAddAssetDialog: (slice)=> {
            slice.dialogs.addAsset.isOpen = true;
        },
        hideAddAssetDialog: (slice)=> {
            slice.dialogs.addAsset.isOpen = false;
        },
        eraseAssetsList: (slice) => {
            slice.assets.assets = [];
            slice.assets.pageInfo = defaultPageInfo;
        },
        replaceAssetsMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.assets.minMaxLoadedPage = payload;
        },
        eraseRequestList: (slice) => {
            slice.requests.requests = [];
            slice.requests.pageInfo = defaultPageInfo;
        },
        replaceRequestMinMaxLoadedPage: (slice, {payload}: {payload: minMaxLoadedPageType}) => {
            slice.requests.minMaxLoadedPage = payload;
        },
        eraseAssetsFilterOptions: (slice) => {
            slice.assets.filterOptions = null;
        },

        setRequestAssetAction: (slice, {payload}: {payload: Partial<TAssetsSlice["dialogs"]["requestAsset"]> & {clear?: boolean}}) => {
            if (payload.isOpen !== undefined) slice.dialogs.requestAsset.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.requestAsset.isLoading = payload.isLoading;

            if (payload.clear) slice.dialogs.requestAsset = initialState.dialogs.requestAsset;
        },
        setAddAssetAction: (slice, {payload}: {payload: Partial<TAssetsSlice["dialogs"]["addAsset"]> & {clear?: boolean}}) => {
            if (payload.isOpen !== undefined) slice.dialogs.addAsset.isOpen = payload.isOpen;
            if (payload.isLoading !== undefined) slice.dialogs.addAsset.isLoading = payload.isLoading;

            if (payload.clear) slice.dialogs.addAsset = initialState.dialogs.addAsset;
        },
        setRemoveAssetAction: (slice, {payload}: {payload: Partial<TAssetsSlice["dialogs"]["removeAsset"]> & {clear?: boolean}}) => {
            if (payload.selected !== undefined) slice.dialogs.removeAsset = {isOpen: true, isLoading: false, selected: payload.selected }
            if (payload.clear) slice.dialogs.removeAsset = initialState.dialogs.removeAsset;
        },
        setCancelRequestAction: (slice, {payload}: {payload: Partial<TAssetsSlice["dialogs"]["cancelRequest"]> & {clear?: boolean}}) => {
            if (payload.requestId !== undefined) slice.dialogs.cancelRequest = {isOpen: true, isLoading: false, requestId: payload.requestId};
            if (payload.clear) slice.dialogs.cancelRequest = initialState.dialogs.cancelRequest;
        },

        setAssetsRequestErrorDialogAction: (slice, {payload}: {payload: Partial<TAssetsSlice["dialogs"]["errorDialog"]> & {clear?: boolean}}) => {
            if (payload.title !== undefined) slice.dialogs.errorDialog.title = payload.title;
            if (payload.message !== undefined) slice.dialogs.errorDialog.message = payload.message;

            if (payload.clear) slice.dialogs.errorDialog = initialState.dialogs.errorDialog;
        },

        cleanSelectedAssetAction: (slice) => {
            slice.assets.selected = null;
        },
        cleanSelectedRequestAction: (slice) => {
            slice.requests.selected = null;
        },

    },
    extraReducers: builder => {
        builder
            .addCase(portalAssetsGetPhysicalAssetsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.assetList = true;

                if (clean) {
                    slice.assets.assets = initialState.assets.assets;
                    slice.assets.pageInfo = initialState.assets.pageInfo;
                    slice.assets.minMaxLoadedPage = initialState.assets.minMaxLoadedPage;
                }
            })
            .addCase(portalAssetsGetPhysicalAssetsWithFilterPaginationAction.rejected, (slice) => {
                slice.loadings.assetList = false;
            })
            .addCase(portalAssetsGetPhysicalAssetsWithFilterPaginationAction.fulfilled, (slice, {payload}) => {
                slice.loadings.assetList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<PortalPhysicalAssetModel>(
                    slice.assets.assets,
                    slice.assets.pageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.assets.minMaxLoadedPage.minLoadedPage,
                    slice.assets.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.assets.assets = results;
                slice.assets.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.assets.pageInfo = payload.pageInfo;
            })

            .addCase(portalAssetsGetPhysicalAssetByIdAction.pending, (slice) => {
                slice.loadings.assetExact = true;
            })
            .addCase(portalAssetsGetPhysicalAssetByIdAction.rejected, (slice) => {
                slice.loadings.assetExact = false;
            })
            .addCase(portalAssetsGetPhysicalAssetByIdAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.assetExact = false;
                slice.assets.selected = payload;
            })

            .addCase(portalAssetsGetPhysicalAssetsSettingsForFiltersAction.pending, (slice) => {
                slice.loadings.settings = true;
            })
            .addCase(portalAssetsGetPhysicalAssetsSettingsForFiltersAction.rejected, (slice) => {
                slice.loadings.settings = false;
            })
            .addCase(portalAssetsGetPhysicalAssetsSettingsForFiltersAction.fulfilled, (slice, {payload}) => {
                slice.loadings.settings = false;
                slice.assets.filterOptions = payload;
            })
            .addCase(portalAssetsCreateRequestAddAssetAction.pending, (slice) => {
                slice.loadings.isLoadingSendAddAsset = true;
            })
            .addCase(portalAssetsCreateRequestAddAssetAction.rejected, (slice, {payload}) => {
                slice.loadings.isLoadingSendAddAsset = false;

                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length) {
                    slice.dialogs.errorDialog = {
                        title: 'Duplicate request error',
                        message: ['You have already created a request to add an asset with the same serial number.'],
                    }
                }
            })
            .addCase(portalAssetsCreateRequestAddAssetAction.fulfilled, (slice) => {
                slice.loadings.isLoadingSendAddAsset = false;
                slice.dialogs.addAsset = initialState.dialogs.addAsset;
            })
            .addCase(portalGetAssetConfigsAction.pending, (slice) => {
                slice.loadings.isLoadingGetSettings = true;
            })
            .addCase(portalGetAssetConfigsAction.rejected, (slice) => {
                slice.loadings.isLoadingGetSettings = false;
            })
            .addCase(portalGetAssetConfigsAction.fulfilled, (slice, {payload}) => {
                slice.settings.model = payload.model;
                slice.settings.manufacture = payload.manufacture;
                slice.settings.category = payload.category;
                slice.loadings.isLoadingGetSettings = false;
            })

            .addCase(portalAssetsCreateRequestRequestAssetAction.pending, (slice) => {
                slice.dialogs.requestAsset.isLoading = true;
            })
            .addCase(portalAssetsCreateRequestRequestAssetAction.rejected, (slice) => {
                slice.dialogs.requestAsset.isLoading = false;
            })
            .addCase(portalAssetsCreateRequestRequestAssetAction.fulfilled, (slice, {payload}) => {
                slice.dialogs.requestAsset.isLoading = false;
                slice.dialogs.requestAsset = initialState.dialogs.requestAsset;
            })

            .addCase(portalAssetsCreateRequestRemoveAssetAction.pending, (slice) => {
                slice.dialogs.removeAsset.isLoading = true;
            })
            .addCase(portalAssetsCreateRequestRemoveAssetAction.rejected, (slice, {payload}) => {
                slice.dialogs.removeAsset.isLoading = false;

                const errors409 = getErrorsByCode<string>(409, payload?.all || []);
                if (errors409.length) {
                    slice.dialogs.errorDialog = {
                        title: 'Duplicate request error',
                        message: ['You already created request for remove asset with same id.'],
                    }
                }
            })
            .addCase(portalAssetsCreateRequestRemoveAssetAction.fulfilled, (slice, {payload}) => {
                slice.dialogs.removeAsset.isLoading = false;
                slice.dialogs.removeAsset = initialState.dialogs.removeAsset;
            })

            .addCase(portalAssetsGetRequestsWithFilterPaginationAction.pending, (slice, {meta: {arg: {clean}}}) => {
                slice.loadings.requestList = true;

                if (clean) {
                    slice.requests.requests = initialState.requests.requests;
                    slice.requests.pageInfo = initialState.requests.pageInfo;
                    slice.requests.minMaxLoadedPage = initialState.requests.minMaxLoadedPage;
                }
            })
            .addCase(portalAssetsGetRequestsWithFilterPaginationAction.rejected, (slice) => {
                slice.loadings.requestList = false;
            })
            .addCase(portalAssetsGetRequestsWithFilterPaginationAction.fulfilled, (slice, {payload}) => {
                slice.loadings.requestList = false;

                const {results, maxLoadedPage, minLoadedPage} = handlePagination<PortalAssetsRequestModel>(
                    slice.requests.requests,
                    slice.requests.pageInfo,
                    payload.result,
                    payload.pageInfo,
                    slice.requests.minMaxLoadedPage.minLoadedPage,
                    slice.requests.minMaxLoadedPage.maxLoadedPage,
                    'id'
                );

                slice.requests.requests = results;
                slice.requests.minMaxLoadedPage = {minLoadedPage, maxLoadedPage};
                slice.requests.pageInfo = payload.pageInfo;
            })

            .addCase(portalAssetsGetRequestByIdAction.pending, (slice) => {
                slice.loadings.requestExact = true;
            })
            .addCase(portalAssetsGetRequestByIdAction.rejected, (slice) => {
                slice.loadings.requestExact = false;
                slice.requests.selected = initialState.requests.selected;
            })
            .addCase(portalAssetsGetRequestByIdAction.fulfilled, (slice, {payload, meta}) => {
                slice.loadings.requestExact = false;
                slice.requests.selected = payload;
                slice.requests.requests = slice.requests.requests.map(e => e.id === meta.arg.data.requestId ? ({...e, ...payload}) : e);
            })

            .addCase(portalAssetsCancelAssetRequestAction.pending, (slice) => {
                slice.dialogs.cancelRequest.isLoading = true;
            })
            .addCase(portalAssetsCancelAssetRequestAction.rejected, (slice) => {
                slice.dialogs.cancelRequest.isLoading = false;
                slice.requests.selected = initialState.requests.selected;
            })
            .addCase(portalAssetsCancelAssetRequestAction.fulfilled, (slice, {payload, meta}) => {
                slice.dialogs.cancelRequest.isLoading = false;
                slice.dialogs.cancelRequest = initialState.dialogs.cancelRequest;
                slice.requests.requests = slice.requests.requests.map(e => e.id === meta.arg.data.requestId ? ({...e, status: AssetsRequestStatus.Cancelled}) : e);
                slice.requests.selected = null;
            })
    }
});

export const AssetsReducer = Slice.reducer;

export const {
    eraseAssetsList,
    replaceAssetsMinMaxLoadedPage,
    eraseRequestList,
    eraseAssetsFilterOptions,
    replaceRequestMinMaxLoadedPage,
    setRequestAssetAction,
    setAddAssetAction,
    setRemoveAssetAction,
    setCancelRequestAction,

    cleanSelectedAssetAction,
    cleanSelectedRequestAction,
    openAddAssetDialog,
    hideAddAssetDialog,

    setAssetsRequestErrorDialogAction,
} = Slice.actions;

const selectSelf = (state: AppState): TAssetsSlice => state.assets;

export const assetsSelector = createSelector(selectSelf, state => state.assets);
export const requestsSelector = createSelector(selectSelf, state => state.requests);
export const assetRequestsSelector = createSelector(selectSelf, state => state.requests);
export const loadingsSelector = createSelector(selectSelf, state => state.loadings);
export const dialogsSelector = createSelector(selectSelf, state => state.dialogs);
export const settingsSelector = createSelector(selectSelf, state => state.settings);


export { };
