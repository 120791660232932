import {useDispatch, useSelector} from "react-redux";
import {
    eraseIncomingTimeOffList,
    hrIncomingTimeOffSelector,
    hrIsLoadingSelector,
    hrTimeOffSettingsSelector,
    replaceIncomingTimeOffMinMaxLoadedPage,
} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {
    HR_PROFILE_PATH,
    HR_TIME_OFF_MANAGEMENT_PATH_EXACT,
    HR_TIME_OFF_PATH,
} from "../../../../../newShared/constants";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    portalHrGetTimeOffRequestsSettingsAction,
    portalHrGetTimeOffRequestsWithFilterPaginationAction,
} from "../../store/actions";
import {CommonFilterType, minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getTimeOffStatusChip} from "../../helpers";
import {HrRequestRequestorModel, HrTimeOffStatus, PortalHrTimeOffRequestModel} from "../../../../../newShared/GQLTypes";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {portalHrGetTimeOffEmployeesAutocompleteApi} from "../../api";
import {resultToValues} from "../../../../../newShared/components/genericFilter/helpers";


export const useHrTimeOffManagement = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathTimeOff'});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {requests, pageInfo, minMaxLoadedPage} = useSelector(hrIncomingTimeOffSelector);
    const {incomingTimeOffList: isLoadingList} = useSelector(hrIsLoadingSelector);
    const settings = useSelector(hrTimeOffSettingsSelector);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;


    useSetBreadcrumbs([
        {
            title: tMenu('HR'),
            path: HR_PROFILE_PATH
        },
        {
            title: tMenu('Time off management'),
            path: HR_TIME_OFF_PATH
        },
    ]);

    useEffect(() => {
        if(!settings) dispatch(portalHrGetTimeOffRequestsSettingsAction({ data: {}}));

        //eslint-disable-next-line
    }, []);

    const handleOpenTimeOff = (selected: PortalHrTimeOffRequestModel) => {
        selected && history.push(`${HR_TIME_OFF_MANAGEMENT_PATH_EXACT.replace(':timeoffId', selected?.id)}${location.search}`);
        // dispatch(openIssueView());
    }


    //filters
    const timeOffTypeConfig: keyConfig<string> = {
        type: 'filter',
        key: 'type',
        name: t('Time off type'),
        options: settings?.timeOffType ?? [],
        getOptionLabel: (a) => t(a),
        default: true
    }

    const statusConfig: keyConfig<HrTimeOffStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(HrTimeOffStatus).filter(e => e !== HrTimeOffStatus.Cancelled),
        OptionRenderer: (a) => getTimeOffStatusChip(a, true),
        default: true,
    }

    const employeeConfig: keyConfig<HrRequestRequestorModel> = {
        type: 'filter',
        key: 'employee',
        name: t('Requestor'),
        fetchOptions: resultToValues<HrRequestRequestorModel>(portalHrGetTimeOffEmployeesAutocompleteApi),
        getOptionLabel: (a) => a.fullName,
        getFilterValue: (a) => a.employeeId,
        isOptionEqual: (a, b) => a.employeeId === b.employeeId,
        isOptionEqualToSearch: (a, search) => a.fullName.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true,
    }

    const startDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'startDate',
        name: t('Start date'),
        default: true
    }

    const endDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'endDate',
        name: t('End date'),
        default: true
    }

    const requestDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'requestDate',
        name: t('Request date'),
        default: true
    }


    return {
        requests,
        pageInfo,

        handleOpenTimeOff,

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceIncomingTimeOffMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseIncomingTimeOffList()),
                fetchPaging: (page: number, count: number, filters: CommonFilterType, timeOffIdLike: string) => {
                    dispatch(portalHrGetTimeOffRequestsWithFilterPaginationAction({data: {pageRequest: {page, count}, filter: {...filters, timeOffIdLike}}}));
                },
            },
            filters: {
                configs: [
                    employeeConfig,
                    startDateConfig,
                    endDateConfig,
                    timeOffTypeConfig,
                    statusConfig,
                    requestDateConfig,
                ]
            }
        }
    }
}
