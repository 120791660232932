// import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {assetRequestFragment, portalAssetFragment} from "./fragments";

export const portalAssetsGetPhysicalAssetsWithFilterPagination = gql`
    query portalAssetsGetPhysicalAssetsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: PortalAssetsFilter!) {
        portalAssetsGetPhysicalAssetsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {...portalAssetFragment}
            pageInfo {
                count
                page
                total
                sort
            }
        }
    }
    ${portalAssetFragment}
`

export const portalAssetsGetPhysicalAssetById = gql`
    query portalAssetsGetPhysicalAssetById($workspaceId: String!, $assetId: String!) {
        portalAssetsGetPhysicalAssetById(workspaceId: $workspaceId, assetId: $assetId) {
            ...portalAssetFragment
        }
    }
    ${portalAssetFragment}
`

export const portalAssetsGetPhysicalAssetsSettingsForFilters = gql`
    query portalAssetsGetPhysicalAssetsSettingsForFilters($workspaceId: String!) {
        portalAssetsGetPhysicalAssetsSettingsForFilters(workspaceId: $workspaceId) {
            model
            category
            manufacture
        }
    }
`
export const portalAssetsCreateRequestAddAsset = gql`
    mutation portalAssetsCreateRequestAddAsset($workspaceId: String!, $data: RequestAddAssetInput!) {
        portalAssetsCreateRequestAddAsset(workspaceId: $workspaceId, data: $data) {
            id
            message
        }
}
`;
export const portalAssetsGetPhysicalAssetsSettings = gql`
    query portalAssetsGetPhysicalAssetsSettings($workspaceId: String!) {
        portalAssetsGetPhysicalAssetsSettings(workspaceId: $workspaceId) {
            model
            category
            manufacture
            
        }
    }
`;


export const portalAssetsCreateRequestRequestAsset = gql`
    mutation portalAssetsCreateRequestRequestAsset($workspaceId: String!, $data: RequestRequestAssetInput!) {
        portalAssetsCreateRequestRequestAsset(workspaceId: $workspaceId, data: $data) {
            id
            message
        }
    }
`

export const portalAssetsCreateRequestRemoveAsset = gql`
    mutation portalAssetsCreateRequestRemoveAsset($workspaceId: String!, $data: RequestRemoveAssetInput!) {
        portalAssetsCreateRequestRemoveAsset(workspaceId: $workspaceId, data: $data) {
            id
            message
        }
    }
`

export const portalAssetsGetRequestsWithFilterPagination = gql`
    query portalAssetsGetRequestsWithFilterPagination($workspaceId: String!, $pageRequest: SharedPaginationInput!, $filter: PortalAssetsRequestsFilters!) {
        portalAssetsGetRequestsWithFilterPagination(workspaceId: $workspaceId, pageRequest: $pageRequest, filter: $filter) {
            result {
                ... assetRequestFragment
            }
            pageInfo {
                page
                count
                total
                sort
            }
        }
    }
    ${assetRequestFragment}
`

export const portalAssetsGetRequestById = gql`
    query portalAssetsGetRequestById($workspaceId: String!, $requestId: String!) {
        portalAssetsGetRequestById(workspaceId: $workspaceId, requestId: $requestId) {
            ... assetRequestFragment
        }
    }
    ${assetRequestFragment}
`

export const portalAssetsCancelAssetRequest = gql`
    mutation portalAssetsCancelAssetRequest($workspaceId: String!, $requestId: String!) {
        portalAssetsCancelAssetRequest(workspaceId: $workspaceId, requestId: $requestId) {
            message
        }
    }
`

