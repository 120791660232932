import React, {FC} from "react";
import {TabPanel} from "@mui/lab";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {Home, Person, Phone} from "@mui/icons-material";
import {CategoryTitleCard} from "../../../../components/categoryTitleCard";
import colors from "../../../../../../../newShared/theme/colors";
import {useHrProfile} from "../../../../hooks/useHrProfile";

export const GeneralTab: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathHrProfile'});

    const {employee} = useHrProfile();

    const personalInfo: {title: string, value?: string}[] = [
        {title: t('First name'), value: !employee ? undefined : employee.firstName || '-'},
        {title: t('Middle name'), value: !employee ? undefined : employee.middleName || '-'},
        {title: t('Last name'), value: !employee ? undefined : employee.lastName || '-'},
        {title: t('Nickname'), value: !employee ? undefined : employee.nickname || '-'},
        {title: t('Birth date'), value: !employee ? undefined : employee.birthDay || '-'},
        {title: t('Work email'), value: !employee ? undefined : employee.workEmail || '-'},
    ];

    const addressInfo: {title: string, value?: string}[] = [
        {title: t('Country'), value: !employee ? undefined : employee.country || '-'},
        {title: t('City'), value: !employee ? undefined : employee.city || '-'},
        {title: t('Street'), value: !employee ? undefined : employee.street || '-'},
        {title: t('ZIP code'), value: !employee ? undefined : employee.zip || '-'},
    ];

    const contactsInfo: {title: string, value?: string}[] = [
        {title: t('Work mobile phone'), value: !employee ? undefined : employee.workMobilePhone || '-'},
    ];

    return (
        <TabPanel value={'general'} sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto', width: '100%', p: 0, gap: '16px'}}>
            <CategoryTitleCard title={t('Personal information')} icon={<Person sx={{color: colors.text.blue}}/>}
                               valuePairs={personalInfo}/>

            <CategoryTitleCard title={t('Address')} icon={<Home sx={{color: colors.text.blue}}/>}
                               valuePairs={addressInfo}/>

            <CategoryTitleCard title={t('Contacts')} icon={<Phone sx={{color: colors.text.blue}}/>} countPerRow={1}
                               valuePairs={contactsInfo}/>
        </TabPanel>
    )
}
