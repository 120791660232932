import {
    portalGetRequiredActionsWithFilterPagination,
    portalRequiredActionsPerformActionProvideFiles,
    portalRequiredActionsGetTaskById
} from "../api/query";
import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {
    PortalGetRequiredActionsWithFilterPaginationQuery,
    PortalGetRequiredActionsWithFilterPaginationQueryVariables,
    PortalRequiredActionsGetTaskByIdQuery,
    PortalRequiredActionsGetTaskByIdQueryVariables,
    PortalRequiredActionsPerformActionProvideFilesMutation,
    PortalRequiredActionsPerformActionProvideFilesMutationVariables
} from "../../../../newShared/GQLTypes";

export const portalGetRequiredActionsWithFilterPaginationAction =
    createCommonAsyncThunk<PortalGetRequiredActionsWithFilterPaginationQueryVariables, PortalGetRequiredActionsWithFilterPaginationQuery["portalGetRequiredActionsWithFilterPagination"]>(
        'portal', 'portalGetRequiredActionsWithFilterPagination', true, false, portalGetRequiredActionsWithFilterPagination
    )

export const portalRequiredActionsGetTaskByIdAction =
    createCommonAsyncThunk<PortalRequiredActionsGetTaskByIdQueryVariables, PortalRequiredActionsGetTaskByIdQuery["portalRequiredActionsGetTaskById"]>(
        'portal', 'portalRequiredActionsGetTaskById', true, false, portalRequiredActionsGetTaskById
    )


export const portalRequiredActionsPerformActionProvideFilesAction =
    createCommonAsyncThunk<PortalRequiredActionsPerformActionProvideFilesMutationVariables, PortalRequiredActionsPerformActionProvideFilesMutation["portalRequiredActionsPerformActionProvideFiles"]>(
        'portal', 'portalRequiredActionsPerformActionProvideFiles', true, false, portalRequiredActionsPerformActionProvideFiles
    )
