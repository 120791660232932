import React, {FC, useEffect} from "react";
import {useMainTranslation} from "../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useHrProfile} from "../../../../hooks/useHrProfile";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import colors from "../../../../../../../newShared/theme/colors";
import {TableRowSkeleton} from "../../../../../../../newShared/components/tableRowSkeleton";

export const JobHistoryTable: FC = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathHrProfile'});

    const {
        jobHistory: {data, pageInfo},
        loading: {isLoadingJobHistory},
        getJobHistory
    } = useHrProfile();

    useEffect(() => {
        // get only first page with 999999 items one time
        getJobHistory(pageInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TableContainer
            sx={{
                width: '100%',
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px !important',
                border: `1px solid #EAECF0`,
            }}
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{whiteSpace: 'nowrap'}}>{t('Effective date')}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap'}}>{t('Department')}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap'}}>{t('Employment type')}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap'}}>{t('Job title')}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap'}}>{t('Manager')}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap'}}>{t('Division')}</TableCell>
                        <TableCell sx={{whiteSpace: 'nowrap'}}>{t('Location')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody sx={{
                    '& .MuiTableRow-root:last-child td': {
                        borderBottom: `1px solid ${colors.primary.disable}`
                    }
                }}>
                    {data.map(row => row && (
                        <TableRow key={JSON.stringify(row)}>
                            <TableCell>{row.effectiveDate   || '-'}</TableCell>
                            <TableCell>{row.department      || '-'}</TableCell>
                            <TableCell>{row.employmentType  || '-'}</TableCell>
                            <TableCell>{row.jobTitle        || '-'}</TableCell>
                            <TableCell>{row.manager         || '-'}</TableCell>
                            <TableCell>{row.division        || '-'}</TableCell>
                            <TableCell>{row.location        || '-'}</TableCell>
                        </TableRow>
                    ))}

                    {isLoadingJobHistory && [...Array(3)].fill('1').map((_, index) => (
                        <TableRowSkeleton key={index} columns={7}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}