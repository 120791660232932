import {useDispatch, useSelector} from "react-redux";
import {hrDialogsSelector, setCreateEditRequestDialogAction} from "../../../store/slice";
import {portalHrGetSettingsCountriesAction} from "../../../store/actions";

export const useHrCreateEditRequestDialog = () => {

    const dispatch = useDispatch();
    const {createEditRequest} = useSelector(hrDialogsSelector);

    const handleOpenEditRequest = () => {
        dispatch(setCreateEditRequestDialogAction({isOpen: true}))
    };

    const handleCloseEditRequest = () => {
        dispatch(setCreateEditRequestDialogAction({clear: true}))
    };

    const handleLoadOptions = () => {
        dispatch(portalHrGetSettingsCountriesAction({data: {}}))
    };

    return {
        ...createEditRequest,

        handleOpenEditRequest,
        handleCloseEditRequest,
        handleLoadOptions,
    }
}