import {FC} from "react";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import {PortalTrainingsShortAssignmentModel} from "../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {useAssignmentsListPage} from "../../hooks/useAssignmentsListPage";
import {getTrainingAssignmentStatusChip} from "../../helpers";

export const AssignmentsList: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathTrainings.pathAssignmentsList'});
    const {assignments, genericTable, handleOpenExact} = useAssignmentsListPage();
    const {isMobile} = useMedia();

    return (
        <PageWrapper>
            <PageTitleAndActions margin={'0 0 8px 0'} title={t('Assignments')} actions={[
                // {
                //     title: t('Request asset'),
                //     onClick: requestAssetDialog.handleOpen,
                //     size: "small"
                // },
                // {
                //     title: t('Add asset'),
                //     onClick: addAssetDialog.handleOpen,
                //     variant: 'outlined',
                //     icon: <AddIcon />,
                //     size: "small"
                // },
            ]} />

            <GenericTable<PortalTrainingsShortAssignmentModel>
                id={'assignments'}
                columnsConfig={{totalName: 'Total assignments', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    customCellPaddings: '8px',
                    rows: assignments,
                    getRowUniqueId: (row) => row.id,
                    onRowClick: handleOpenExact,
                    rowActionsConfig: [
                        // {
                        //     title: t('Remove asset'),
                        //     onClick: (row) => removePhysicalAssetDialog.openRemovePhysicalAsset(row),
                        // },
                    ],
                    columnsConfigs: [
                        {key: "id", name: t('ID'), default: true, },
                        {key: 'requestor', name: t('Requestor'), default: true},
                        {key: 'trainingName', name: t('Training name'), default: true},
                        {key: 'examName', name: t('Exam name'), default: true},
                        {key: 'status', name: t('Status'), default: true, valueRenderer: (row) => getTrainingAssignmentStatusChip(row.status)},
                        {key: 'startDate', name: t('Start date'), default: true, valueGetter: (row) => parseDateAuto(row.startDate)},
                        {key: 'endDate', name: t('Due date'), default: true, valueGetter: (row) => parseDateAuto(row.endDate)},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'assignmentIdLike',
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: true,
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('You do not have any assignments yet'), secondaryText: ''}}}
            />

        </PageWrapper>
    );
};
