import {
    MainTrainingAssignmentPostExamAnswerModel,
    TrainingAssignmentExamAnswerStatus,
    TrainingExamQuestionType
} from "../../../../../../../../newShared/GQLTypes";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {Checkbox, Collapse, IconButton, Radio, Typography} from "@mui/material";
import React, {FC} from "react";
import Divider from "@mui/material/Divider";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FlakyIcon from '@mui/icons-material/Flaky';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import colors from "../../../../../../../../newShared/theme/colors";
import BeenhereIcon from '@mui/icons-material/Beenhere';
import FormControlLabel from "@mui/material/FormControlLabel";
import {
    FlexColumn,
    FlexRow
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";

export type ExamResultQuestionBlockPropsType = {
    isOpen: boolean;
    toggleOpen: (qId: string) => void,
    question: MainTrainingAssignmentPostExamAnswerModel,
    order: number
}

const QuestionAnswerStatus: FC<{status: TrainingAssignmentExamAnswerStatus}> = ({status}) => {
    switch (status){
        case TrainingAssignmentExamAnswerStatus.Passed: return (
            <Flex gap={'8px'} ai={'center'} jc={'space-between'}>
                <CheckCircleOutlineIcon sx={{color: colors.text.success}} fontSize={'small'}/>
                <Typography variant={'*bodyText2'} color={colors.text.success}>{'Correct'}</Typography>
            </Flex>
        )
        case TrainingAssignmentExamAnswerStatus.PartiallyPassed: return (
            <Flex gap={'8px'} ai={'center'} jc={'space-between'}>
                <FlakyIcon sx={{color: colors.text.blue}} fontSize={'small'}/>
                <Typography variant={'*bodyText2'} color={colors.text.blue}>{'Partially correct'}</Typography>
            </Flex>
        )
        case TrainingAssignmentExamAnswerStatus.Failed: return (
            <Flex gap={'8px'} ai={'center'} jc={'space-between'}>
                <HighlightOffIcon sx={{color: colors.text.critical}} fontSize={'small'}/>
                <Typography variant={'*bodyText2'} color={colors.text.critical}>{'Incorrect'}</Typography>
            </Flex>
        )
        case TrainingAssignmentExamAnswerStatus.Skipped: return (
            <Flex gap={'8px'} ai={'center'} jc={'space-between'}>
                <ArrowCircleRightOutlinedIcon sx={{color: colors.text.critical}} fontSize={'small'}/>
                <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{'Skipped'}</Typography>
            </Flex>
        )
        default: return <></>;
    }
}

export const ExamResultQuestionBlock: FC<ExamResultQuestionBlockPropsType> = ({isOpen, question, toggleOpen, order}) => {
    return(
        <FlexColumn sx={{flexShrink: 0, gap: '4px'}}>
            <Flex
                w={'100%'}
                jc={'space-between'}
                ai={'center'}
                br={'6px'}
                border={`1px solid ${colors.stroke.table}`}
                box_shadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                cursor={'pointer'}
                p={'16px 20px'}
                onClick={() => toggleOpen(question.questionId)}
            >
                <Flex ai={'center'} gap={'8px'}>
                    <IconButton size={'small'}>
                        {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon/>}
                    </IconButton>

                    <Typography variant={'*bodyText2_semibold'}>{(`Question ${order + 1}`)}</Typography>
                </Flex>

                <Flex ai={'center'} gap={'16px'}>
                    <Divider orientation={'vertical'} flexItem/>

                    <QuestionAnswerStatus status={question.status}/>

                    {question.status === TrainingAssignmentExamAnswerStatus.Passed &&
                        <>
                            <Divider orientation={'vertical'} flexItem/>

                            <Flex gap={'8px'} ai={'center'} jc={'space-between'}>
                                <BeenhereIcon sx={{color: colors.text.success}} fontSize={'small'}/>
                                <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{question.validation?.points ?? 0} {'points'}</Typography>
                            </Flex>
                        </>
                    }

                    {question.status === TrainingAssignmentExamAnswerStatus.PartiallyPassed &&
                        <>
                            <Divider orientation={'vertical'} flexItem/>

                            <Flex gap={'8px'} ai={'center'} jc={'space-between'}>
                                <BeenhereIcon sx={{color: colors.text.blue}} fontSize={'small'}/>
                                <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{question.validation?.points ?? 0} {'of'} {question.points ?? 0} {'points'}</Typography>
                            </Flex>
                        </>
                    }
                </Flex>
            </Flex>

            <Collapse in={isOpen} sx={{flexShrink: 0}}>
                <Flex
                    w={'100%'}
                    br={'6px'}
                    border={`1px solid ${colors.stroke.table}`}
                    box_shadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                    p={'16px 20px'}
                    direction={'column'}
                >
                    <Typography variant={'*bodyText1'} color={colors.grayText} sx={{margin: '8px 0 0 0', whiteSpace: 'break-spaces'}}>{(question.description)}</Typography>
                    <Typography variant={'*bodyText1'} color={colors.grayText} sx={{margin: '8px 0 16px 0'}}>{(question.question)}</Typography>

                    {(question.type === TrainingExamQuestionType.Paragraph || question.type === TrainingExamQuestionType.ShortAnswer) &&
                        <>
                            {question.status === TrainingAssignmentExamAnswerStatus.Passed &&
                                <>
                                    <Flex br={'6px'} p={'12px 16px'} background={colors.backgrounds.light_green}>
                                        <Typography variant={'*bodyText1'} color={colors.grayText} sx={{whiteSpace: 'break-spaces'}}>{question.answer}</Typography>
                                    </Flex>
                                </>
                            }

                            {question.status === TrainingAssignmentExamAnswerStatus.Failed &&
                                <>
                                    <Flex br={'6px'} p={'12px 16px'} background={colors.backgrounds.light_red}>
                                        <Typography variant={'*bodyText1'} color={colors.grayText} sx={{whiteSpace: 'break-spaces'}}>{question.answer}</Typography>
                                    </Flex>

                                    {question.validation?.correctAnswer?.[0]?.trim().length &&
                                        <Flex br={'6px'} m={'24px 0 0 0'} direction={'column'} p={'12px 16px'} background={colors.primary.disable2}>
                                            <Typography variant={'*bodyText1_semibold'}>{('Reviewer’s answer')}</Typography>
                                            <Typography variant={'*bodyText1'} color={colors.grayText} sx={{whiteSpace: 'break-spaces'}}>{(question.validation.correctAnswer[0] ?? '')}</Typography>
                                        </Flex>
                                    }
                                </>
                            }

                            {question.status === TrainingAssignmentExamAnswerStatus.PartiallyPassed &&
                                <>
                                    <Flex br={'6px'} p={'12px 16px'} background={colors.backgrounds.optional}>
                                        <Typography variant={'*bodyText1'} color={colors.grayText} sx={{whiteSpace: 'break-spaces'}}>{question.answer}</Typography>
                                    </Flex>

                                    {question.validation?.correctAnswer?.[0]?.trim().length &&
                                        <Flex br={'6px'} m={'24px 0 0 0'} direction={'column'} p={'12px 16px'} background={colors.primary.disable2}>
                                            <Typography variant={'*bodyText1_semibold'}>{('Reviewer’s answer')}</Typography>
                                            <Typography variant={'*bodyText1'} color={colors.grayText} sx={{whiteSpace: 'break-spaces'}}>{(question.validation.correctAnswer[0] ?? '')}</Typography>
                                        </Flex>
                                    }
                                </>
                            }

                            {question.status === TrainingAssignmentExamAnswerStatus.Skipped &&
                                <>
                                    <Flex br={'6px'} p={'12px 16px'} background={colors.backgrounds.optional}>
                                        <Typography variant={'*bodyText1'} color={colors.grayText}>{('You did not answer this question')}</Typography>
                                    </Flex>

                                    {question.validation?.correctAnswer?.[0]?.trim().length &&
                                        <Flex br={'6px'} m={'24px 0 0 0'} direction={'column'} p={'12px 16px'} background={colors.primary.disable2}>
                                            <Typography variant={'*bodyText1_semibold'}>{('Reviewer’s answer')}</Typography>
                                            <Typography variant={'*bodyText1'} color={colors.grayText}>{(question.validation.correctAnswer[0] ?? '')}</Typography>
                                        </Flex>
                                    }
                                </>
                            }
                        </>
                    }

                    {[TrainingExamQuestionType.MultipleChoice, TrainingExamQuestionType.Checkboxes].includes(question.type) &&
                        <Flex direction={'column'} gap={'8px'} style={{paddingInlineStart: '16px'}}>
                            {question.options?.map(e => {
                                const isCorrectOption = e.correct;
                                const isCheckedAnswer = (question.answer ?? []).some(s => s === e.option);

                                let backgroundColor = 'inherit';
                                let controlColor = 'inherit';

                                if(isCorrectOption && isCheckedAnswer){
                                    backgroundColor = colors.backgrounds.light_green;
                                    controlColor = colors.text.success;
                                }
                                if(isCorrectOption && !isCheckedAnswer){
                                    backgroundColor = colors.backgrounds.green;
                                    controlColor = colors.text.success;
                                }
                                if(!isCorrectOption && isCheckedAnswer){
                                    backgroundColor = colors.backgrounds.light_red;
                                    controlColor = colors.text.critical;
                                }

                                return (
                                    <FormControlLabel checked={isCheckedAnswer} disableTypography
                                                      label={(
                                                          <FlexRow sx={{
                                                              justifyContent: 'space-between', alignItems: 'center', gap: '10px', width: '100%', paddingInlineEnd: '8px', paddingBlock: '10px',
                                                          }}>
                                                              <Typography variant={"*bodyText1"}>{e.option}</Typography>
                                                              {isCorrectOption && <Typography variant={"*bodyText1"} sx={{whiteSpace: 'nowrap'}}>{`${e.points} points`}</Typography>}
                                                          </FlexRow>
                                                      )}
                                                      control={
                                                          question.type === TrainingExamQuestionType.Checkboxes
                                                              ? <Checkbox size={'small'} disableRipple sx={{'& > svg': {color: `${controlColor} !important`}, cursor: 'default'}}/>
                                                              : <Radio size={'small'} disableRipple sx={{'& > span': {color: `${controlColor} !important`}, cursor: 'default'}}/>
                                                      }
                                                      sx={{
                                                          paddingInline: '10px', borderRadius: '6px', cursor: 'auto', backgroundColor: backgroundColor,
                                                          marginBlock: !isCorrectOption && !isCheckedAnswer ? '-2px' : undefined,
                                                          opacity: !isCheckedAnswer ? 0.4 : undefined,
                                                      }}/>
                                )
                            })}
                        </Flex>
                    }
                </Flex>
            </Collapse>
        </FlexColumn>
    )
}