import axiosSync from "../../../../newShared/utils/axios/axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {AxiosResponse} from "axios";
import {
    checkPaymentResponse,
    cookieShortOrganization,
    cookieShortWorkspace,
    countryJsonType,
    createOrganizationType,
    createUpdateRoleRequestInput,
    ForbiddenWrapperOrganization,
    getWorkspaceCollabsResponseType,
    InvitationSignUpFormType,
    TBilling,
    TBillingInfo,
    TChangeUserInfo,
    TChangeUserPass,
    TInvitationPageInviteType,
    TNotCompletedOrganization,
    TOrganization,
    TSmallWorkspace,
    TWorkspaceInvite,
    TWorkspaceWithShortCollabs
} from "../types";
import {changeUserProfilePassGQL} from "../../settingsProfile/api/queries";
import {
    changeUserProfileInfoGQL,
    disableGoogleAuth,
    importCsvTemplateCollaborators,
    reconfigureGoogleAuthInSettings,
    validate2faCode
} from "./query";
import {
    AddOrganizationBusinessWithBillingMutation,
    CreateOrganizationDto,
    DisableGoogleAuthMutation,
    DisableGoogleAuthMutationVariables,
    ReconfigureGoogleAuthInSettingsMutation,
    ReconfigureGoogleAuthInSettingsMutationVariables,
    SendSupportMessageMutation,
    SendSupportMessageMutationVariables,
    Validate2faCodeMutation,
    Validate2faCodeMutationVariables
} from "../../../../newShared/GQLTypes";
import {TPageInfo} from "../../../../newShared/types";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";


export const getInvites = async (): Promise<TWorkspaceInvite[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getInvites{
                  getInvites{
                    id
                    inviteStatus
                    roles
                    expirationDate
                    activationDate
                    creationDate
                    organizationName
                    emailAddress
                    sender{
                      firstName
                      lastName
                      email
                    }
                    workspaceId
                    workspaceName
                    organizationId
                  }
                }
                `,
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getInvites;
    })
)

export const acceptInvite = async (inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation acceptInvite($inviteId:String!){
                  acceptInvite(inviteId:$inviteId){
                    message
                  }
                }
                `,
                variables: {
                    inviteId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.acceptInvite.message;
    })
)

export const declineInvite = async (inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation declineInvite($inviteId:String!){
                  declineInvite(inviteId:$inviteId){
                    message
                  }
                }
                `,
                variables: {
                    inviteId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.declineInvite.message;
    })
)

//user profile

export const changeCurrentUserInfo = async (data: TChangeUserInfo) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changeUserProfileInfoGQL,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changeName as {message: string}
    })
)


//SECURITY
export const changeCurrentUserPass = async (data: TChangeUserPass) => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: changeUserProfilePassGQL,
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.changePassword as {message: string}
    })
)

export const validate2faCodeApi = async (data: Validate2faCodeMutationVariables): Promise<Validate2faCodeMutation["initialValidateGoogleAuthorization"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: validate2faCode,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.initialValidateGoogleAuthorization
    })
)

export const reconfigureGoogleAuthApi = async (data: ReconfigureGoogleAuthInSettingsMutationVariables): Promise<ReconfigureGoogleAuthInSettingsMutation["reconfigureGoogleAuth"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: reconfigureGoogleAuthInSettings,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.reconfigureGoogleAuth
    })
)

export const disableGoogleAuthApi = async (data: DisableGoogleAuthMutationVariables): Promise<DisableGoogleAuthMutation["disableGoogleAuth"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: disableGoogleAuth,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.disableGoogleAuth
    })
)

export const getOrganizations = async (page: number, count: number, name: string): Promise<{organizations: TOrganization[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getOrganizations($pageInfo:PaginationInput!){
                        getOrganizations(pageInfo:$pageInfo){
                            organizations{
                                id
                                name
                                logo
                                collaborators{
                                    publicId
                                    role
                                }
                                subscription
                                subscriptionExpiry
                                trialUsed
                                configuration{
                                    availableWizards
                                    availableWorkspaceCount
                                    availableCollaboratorsCount
                                    availableCollaboratorsRules{
                                        email
                                        type
                                        count
                                    }
                                    availableTargetsVulnerabilityScan
                                    availableKycVerificationCount
                                }
                                blocked
                                blockedReason
                                workspaceCount
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: {
                    pageInfo: {
                        page,
                        count,
                        filters: {
                            name: name.trim().length > 0 ? [name] : []
                        }
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getOrganizations
    })
)

export const getOrganizationById = async (id: string): Promise<TOrganization> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getOrganizationById($id:String!){
                        getOrganizationById(id:$id){
                            id
                            name
                            logo
                            collaborators{
                                publicId
                                role
                            }
                            subscription
                            subscriptionExpiry
                            trialUsed
                            configuration{
                                availableWizards
                                availableWorkspaceCount
                                availableCollaboratorsCount
                                availableCollaboratorsRules{
                                    email
                                    type
                                    count
                                }
                                availableTargetsVulnerabilityScan
                                availableKycVerificationCount
                            }
                            blocked
                            blockedReason
                            workspaceCount
                        }
                    }
                `),
                variables: {
                    id
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getOrganizationById
    })
)


export const getWorkspaces = async (page: number, count: number, name: string, id: string): Promise<{workspaces: TSmallWorkspace[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getWorkspaces($pageInfo:PaginationInput!, $id:String!){
                        getWorkspaces(pageInfo:$pageInfo, id:$id){
                            workspaces{
                                id
                                name
                                collaboratorsCount
                                enabledKycLimit
                            }
                            pageInfo{
                                page
                                count
                                total
                                sort
                            }
                        }
                    }
                `),
                variables: {
                    id,
                    pageInfo: {
                        page,
                        count,
                        filters: {
                            name: name.trim().length > 0 ? [name] : []
                        }
                    }
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getWorkspaces
    })
)

// export const updateOrganizationByLicenseCode = async (organizationId: string, licenseCode: string): Promise<updateOrganizationByLicenseCodeResponse> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation updateOrganizationByLicenseCode($licenseCode:String!, $organizationId:String!){
//                   updateOrganizationByLicenseCode(licenseCode:$licenseCode, organizationId:$organizationId){
//                     subscription
//                     subscriptionExpiry
//                     configuration{
//                       availableWizards
//                       availableWorkspaceCount
//                       availableCollaboratorsCount
//                     }
//                     id
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     licenseCode
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.updateOrganizationByLicenseCode;
//     })
// )

export const sendSupportMessageAPI = async (variables: SendSupportMessageMutationVariables): Promise<SendSupportMessageMutation["sendSupportMessage"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation sendSupportMessage($data:SupportMessageInput!, $wid: String!){
                        sendSupportMessage(data: $data, workspaceId: $wid){
                            isSent
                        }
                    }
                `),
                variables,
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.sendSupportMessage;
    })
)


// export const getOrganizationCollaborators = async (id: string): Promise<{ collaborators: TExtendedCollaborator[]}> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 query getOrganizationCollaborators($id:String!){
//                   getOrganizationCollaborators(id:$id){
//                     collaborators{
//                       createDate
//                       lastName
//                       firstName
//                       lastLoginDate
//                       status
//                       email
//                       role
//                       publicId
//                     }
//                   }
//                 }
//                 `,
//                 variables: {
//                     id
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getOrganizationCollaborators;
//     })
// )

// export const cancelOrganizationInvite = async (organizationId: string, email: string, inviteId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation cancelOrganizationInvite($organizationId: String!, $email:String!, $inviteId: String!){
//                   cancelOrganizationInvite(organizationId:$organizationId, email:$email, inviteId:$inviteId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     email,
//                     inviteId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.cancelOrganizationInvite.message;
//     })
// )

// export const resendOrganizationInvite = async (organizationId: string, email: string, inviteId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation resendOrganizationInvite($organizationId: String!, $email:String!, $inviteId: String!){
//                   resendOrganizationInvite(organizationId:$organizationId, email:$email, inviteId:$inviteId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     email,
//                     inviteId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.resendOrganizationInvite.message;
//     })
// )

// export const updateInviteRole = async (organizationId: string, email: string, newRole: string, inviteId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation changeInviteRole($organizationId:String!, $email:String!, $newRole:String!, $inviteId:String!){
//                   updateInviteRole(organizationId:$organizationId, email:$email, newRole:$newRole, inviteId:$inviteId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     email,
//                     newRole,
//                     inviteId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.updateInviteRole.message;
//     })
// )


export const updateCollabRole = async (organizationId: string, publicId: string, newRole: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation changeCollabRole($organizationId:String!, $publicId:String!, $newRole:String!){
                  updateCollaboratorRole(organizationId:$organizationId, publicId:$publicId, newRole:$newRole){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    publicId,
                    newRole
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateCollaboratorRole.message;
    })
)

export const createWorkspace = async (organizationId: string, workspaceName: string): Promise<TWorkspaceWithShortCollabs> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation createWS($workspaceName:String!, $organizationId: String!){
                  addWorkspace(organizationId:$organizationId, workspaceName: $workspaceName){
                    id
                    name
                    organizationId
                    status
                    subscription
                    collaborators{
                      publicId
                      role
                    }
                  }
                }
                `,
                variables: {
                    organizationId,
                    workspaceName,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addWorkspace;
    })
)


export const updateOrganizationName = async (organizationId: string, newName: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateOrganizationName($organizationId:String!, $newName:String!){
                  updateOrganizationName(organizationId:$organizationId, newName:$newName){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    newName
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationName.message;
    })
)

export const updateOrganizationLogo = async (organizationId: string, newLogo: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateOrganizationLogo($organizationId:String!, $newLogo:String!){
                  updateOrganizationLogo(organizationId:$organizationId, newLogo:$newLogo){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    newLogo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationLogo.message;
    })
)

// export const inviteMemberIntoOrg = async (organizationId: string, email: string, role: string, senderPublicId: string): Promise<OrganizationInviteType> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation inviteMemberIntoOrg($organizationId:String!, $email:String!, $senderPublicId:String!, $role:String!){
//                   inviteMemberIntoOrg(organizationId:$organizationId, email:$email, senderPublicId:$senderPublicId, role:$role){
//                     id
//                     emailAddress
//                     expirationDate
//                     activationDate
//                     role
//                     sender{
//                       role
//                       publicId
//                     }
//                     inviteStatus
//
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     email,
//                     role,
//                     senderPublicId,
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.inviteMemberIntoOrg;
//     })
// )

export const transferOrganizationOwnership = async (organizationId: string, transferToPublicId: string, transferFromPublicId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation transferOrganizationOwnership($transferToPublicId:String!,$organizationId:String!, $transferFromPublicId:String!){
                  transferOrganizationOwnership(transferToPublicId:$transferToPublicId, organizationId:$organizationId, transferFromPublicId:$transferFromPublicId){
                    message
                  }
                }
                `,
                variables: {
                    organizationId,
                    transferToPublicId,
                    transferFromPublicId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.transferOrganizationOwnership.message;
    })
)

// export const deleteOrganizationCollaborator = async (organizationId: string, publicId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation deleteOrganizationCollab($publicId:String!,$organizationId:String!){
//                   deleteOrganizationCollaborator(publicId:$publicId, organizationId:$organizationId){
//                     message
//                   }
//                 }
//                 `,
//                 variables: {
//                     organizationId,
//                     publicId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.deleteOrganizationCollaborator.message;
//     })
// )


export const getWorkspaceById = async (organizationId: string, workspaceId: string): Promise<{workspace: TSmallWorkspace, organization: TOrganization}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getRedesignWorkspaceById($workspaceId:String!, $organizationId:String!){
                        getRedesignWorkspaceById(workspaceId:$workspaceId, organizationId:$organizationId){
                            workspace{
                                id
                                name
                                collaboratorsCount
                                enabledKycLimit
                            }
                            organization{
                                id
                                name
                                logo
                                collaborators{
                                    publicId
                                    role
                                }
                                subscription
                                subscriptionExpiry
                                trialUsed
                                configuration{
                                    availableWizards
                                    availableWorkspaceCount
                                    availableCollaboratorsCount
                                    availableCollaboratorsRules{
                                        email
                                        type
                                        count
                                    }
                                    availableTargetsVulnerabilityScan
                                }
                                blocked
                                blockedReason
                                workspaceCount
                            }

                        }
                    }
                `),
                variables: {
                    organizationId,
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getRedesignWorkspaceById;
    })
)

export const getWorkspaceCollabs = async (workspaceId: string): Promise<getWorkspaceCollabsResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getWorkspaceUsersById($workspaceId:String!){
                  getWorkspaceUsersById(workspaceId:$workspaceId){
                    workspaceRoles{
                      id
                      name
                      workspaceId
                      permissions
                      createDate
                      lastUpdateDate
                      system
                      origin
                    }
                    collabs{
                      createDate
                      lastLoginDate
                      email
                      firstName
                      lastName
                      status
                      publicId
                      roles{
                        id
                        name
                        workspaceId
                        permissions
                        createDate
                        lastUpdateDate
                        origin
                        system
                      }
                    }
                    invite{
                      activationDate
                      creationDate
                      emailAddress
                      expirationDate
                      id
                      inviteStatus
                      sender{
                        firstName
                        lastName
                        email
                      }
                      roles
                      workspaceId
                      workspaceName
                      organizationId
                      organizationName
                    }
                    availablePermissions
                  }
                }
                `,
                variables: {
                    workspaceId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getWorkspaceUsersById;
    })
)

export const deleteWorkspaceCollaborator = async (workspaceId: string, publicId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteWSCollab($workspaceId: String!, $publicId:String!){
                    deleteWorkspaceCollaborator(workspaceId:$workspaceId, publicId:$publicId){
                        message
                    }
                }
                `,
                variables: {
                    workspaceId,
                    publicId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspaceCollaborator.message;
    })
)

export const updateWorkspaceName = async (workspaceId: string, newName: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateWorkspaceName($workspaceId:String!, $newName:String!){
                  updateWorkspaceName(workspaceId:$workspaceId, newName:$newName){
                    message
                  }
                }
                `,
                variables: {
                    workspaceId,
                    newName
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateWorkspaceName.message;
    })
)

export const deleteWorkspace = async (workspaceId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteWS($workspaceId: String!,){
                    deleteWorkspace(workspaceId:$workspaceId){
                        message
                    }
                }
                `,
                variables: {
                    workspaceId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspace.message;
    })
)

export const inviteMemberIntoWorkspace = async (workspaceId: string, email: string, roles: string[], senderPublicId: string): Promise<getWorkspaceCollabsResponseType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation inviteMemberIntoWorkspace($workspaceId:String!, $email:String!, $senderPublicId:String!, $roles:RolesArrayInput!){
                  inviteMemberIntoWorkspace(workspaceId:$workspaceId, email:$email, senderPublicId:$senderPublicId, roles:$roles){
                    workspaceRoles{
                      id
                      name
                      workspaceId
                      permissions
                      createDate
                      lastUpdateDate
                      system
                      origin
                    }
                    collabs{
                      createDate
                      lastLoginDate
                      email
                      firstName
                      lastName
                      status
                      publicId
                      roles{
                        id
                        name
                        workspaceId
                        permissions
                        createDate
                        lastUpdateDate
                        origin
                        system
                      }
                    }
                    invite{
                      activationDate
                      creationDate
                      emailAddress
                      expirationDate
                      id
                      inviteStatus
                      sender{
                        firstName
                        lastName
                        email
                      }
                      roles
                      workspaceId
                      workspaceName
                      organizationId
                      organizationName
                    }
                    availablePermissions
                  }
                }
                `,
                variables: {
                    workspaceId,
                    email,
                    roles: {roles},
                    senderPublicId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        try{
            return result.data.data.inviteMemberIntoWorkspace;
        }catch (e){
            throw new Error(result.data.errors[0].message);
        }
    })
)

export const updateUserRole = async (workspaceId: string, publicId: string, roles: string[]): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateUserRole($publicId:String!, $workspaceId:String!, $roles:RolesArrayInput!){
                  updateUserRole(publicId:$publicId, workspaceId:$workspaceId, roles:$roles){
                    message
                  }
                }
                `,
                variables: {
                    workspaceId,
                    publicId,
                    roles: {roles}
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateUserRole.message;
    })
)

export const resendWorkspaceInvite = async (workspaceId: string, email: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation resendWSInvite($workspaceId: String!, $email:String!, $inviteId: String!){
                    resendWorkspaceInvite(workspaceId:$workspaceId, email:$email, inviteId:$inviteId){
                        message
                    }
                }
                `,
                variables: {
                    workspaceId,
                    email,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.resendWorkspaceInvite.message;
    })
)

export const cancelWorkspaceInvite = async (workspaceId: string, email: string, inviteId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation cancelOrganizationInvite($workspaceId: String!, $email:String!, $inviteId: String!){
                    deleteWorkspaceInvite(workspaceId:$workspaceId, email:$email, inviteId:$inviteId){
                        message
                    }
                }
                `,
                variables: {
                    workspaceId,
                    email,
                    inviteId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteWorkspaceInvite.message;
    })
)

// export const changeWSInviteRole = async (workspaceId: string, email: string, newRole: string, inviteId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: `
//                 mutation changeWSInviteRole($workspaceId:String!, $email:String!, $newRole:String!, $inviteId:String!){
//                     updateWorkspaceInviteRole(workspaceId:$workspaceId, email:$email, newRole:$newRole, inviteId:$inviteId){
//                         message
//                     }
//                 }
//                 `,
//                 variables: {
//                     workspaceId,
//                     email,
//                     newRole,
//                     inviteId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.updateWorkspaceInviteRole.message;
//     })
// )


export const createRole = async (data: createUpdateRoleRequestInput): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createRole($data:createUpdateRoleRequestInput!){
                        createRole(data:$data){
                            message
                        }
                    }
                `),
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createRole.message;
    })
)




export const deleteRole = async (workspaceId: string, roleId: string): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation deleteRole($roleId:String!, $workspaceId:String!){
                        deleteRole(roleId:$roleId, workspaceId:$workspaceId){
                            message
                        }
                    }
                `),
                variables: {
                    workspaceId,
                    roleId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteRole.message;
    })
)


export const updateRole = async (data: createUpdateRoleRequestInput): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql(`
                mutation editRole($data:createUpdateRoleRequestInput!){
                  editRole(data:$data){
                    message
                  }
                }
                `)),
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.editRole.message;
    })
)

export const getInviteByIdForAccept = async (inviteId: string): Promise<TInvitationPageInviteType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getInviteByIdForAccept($inviteId:String!){
                        getInviteByIdForAccept(inviteId:$inviteId){
                            id
                            inviteStatus
                            sender{
                                firstName
                                lastName
                                email
                            }
                            roles
                            creationDate
                            expirationDate
                            activationDate
                            emailAddress
                            isUserExists
                            workspaceId
                            workspaceName
                            organizationId
                            organizationName
                        }
                    }
                `),
                variables: {
                    inviteId,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getInviteByIdForAccept;
    })
)

export const createAccountByInvitation = async (data: InvitationSignUpFormType): Promise<{message: boolean, qrCode: string, secret: string}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation createAccountByInvitation($data:CreateAccountByInviteDto!){
                        createAccountByInvitation(data:$data){
                            message
                        }
                    }
                `),
                variables: {
                    data
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.createAccountByInvitation;
    })
)


export const getSmallOrgsAndWorkspacesForSelect = async (): Promise<ForbiddenWrapperOrganization[]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getSmallOrgsAndWorkspacesForSelect{
                        portalGetSmallOrgsAndWorkspacesForSelect{
                            organizations{
                                workspaces{
                                    id
                                    collaboratorsCount
                                    name
                                    enabledKycLimit
                                }
                                id
                                name
                                logo
                                subscription
                                subscriptionExpiry
                                blocked
                                configuration{
                                    availableWizards
                                    availableWorkspaceCount
                                    availableCollaboratorsCount
                                    availableTargetsVulnerabilityScan
                                }
                            }
                        }
                    }
                `),
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalGetSmallOrgsAndWorkspacesForSelect.organizations;
    })
)

//used in automations
// export const getOrganizationMembers = async (organizationId: string): Promise<TExtendedCollaborator[]> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     query getOrganizationMembers($organizationId:String!){
//                         getProfilesOfOrganizationMembers(organizationId: $organizationId){
//                             firstName
//                             lastName
//                             email
//                             publicId
//                             role
//                             status
//                             lastLoginDate
//                             createDate
//                         }
//                     }
//                 `),
//                 variables: {
//                     organizationId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.getProfilesOfOrganizationMembers;
//     })
// )

// export const joinWorkspaceByOrgAdmin = async (workspaceId: string): Promise<string> => (
//     await axiosSync.then(
//         (axios) => axios.post(
//             PATH_SERVER,
//             {
//                 query: print(gql`
//                     mutation joinWorkspaceByOrgAdmin($workspaceId:String!){
//                         joinWorkspaceByOrgAdmin(workspaceId:$workspaceId){
//                             message
//                         }
//                     }
//                 `),
//                 variables: {
//                     workspaceId
//                 }
//             },
//             {
//                 headers: buildCommonHeader()
//             }
//         )
//     ).then((result: AxiosResponse<any>) => {
//         return result.data.data.joinWorkspaceByOrgAdmin.message;
//     })
// )

export const getCreateOrganizationData = async (): Promise<{notCompletedOrganization: TNotCompletedOrganization | null, countries: countryJsonType[], industries: string[]}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getCreateOrganizationData{
                      getCreateOrganizationData{
                        countries{
                          name
                          code
                          fields{
                            fieldKey
                            fieldLabel
                            fieldPlaceholder
                          }
                        }
                        industries
                        notCompletedOrganization{
                          name
                          logo
                          subscription
                          organizationDetails
                          billing{
                            transactionNumber
                            currentTear{
                              variant
                              vatPercentage
                              discountPercentage
                              items{
                                name
                                quantity
                                cost
                                type
                                subscriptionType
                                currency
                              }
                            }
                            billingInfo{
                              firstName
                              lastName
                              email
                              address
                              zip
                              country
                              additionalData
                            }
                          }
                        }
                      }
                    }
                `),
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getCreateOrganizationData;
    })
)

export const addOrganizationFree = async (data: createOrganizationType): Promise<{workspace: cookieShortWorkspace, organization: cookieShortOrganization}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation addOrganizationFree($data:CreateOrganizationDto!){
                  addOrganizationFree(data: $data){
                    workspace{
                      id
                      name
                      organizationId
                    }
                    organization{
                      id
                      name
                      logo
                      subscription
                      subscriptionExpiry
                      blocked
                      blockedReason
                    }
                  }
                }
                `,
                variables: {
                    data: {...data, name: data.name.trim()},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addOrganizationFree;
    })
)

export const addOrganizationEnterprise = async (data: createOrganizationType): Promise<{workspace: cookieShortWorkspace, organization: cookieShortOrganization}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation addOrganizationEnterprise($data:CreateOrganizationDto!){
                  addOrganizationEnterprise(data: $data){
                    workspace{
                      id
                      name
                      organizationId
                    }
                    organization{
                      id
                      name
                      logo
                      subscription
                      subscriptionExpiry
                      blocked
                      blockedReason
                    }
                  }
                }
                `,
                variables: {
                    data: {...data, name: data.name.trim()},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addOrganizationEnterprise;
    })
)

export const addOrganizationBusinessWithoutPayment = async (data: createOrganizationType): Promise<{workspace: cookieShortWorkspace, organization: cookieShortOrganization}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addOrganizationBusinessWithoutPayment($data:CreateOrganizationDto!){
                        addOrganizationBusinessWithoutPayment(data: $data){
                            workspace{
                                id
                                name
                                organizationId
                            }
                            organization{
                                id
                                name
                                logo
                                subscription
                                subscriptionExpiry
                                blocked
                                blockedReason
                            }
                        }
                    }
                `),
                variables: {
                    data: {...data, name: data.name.trim()},
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addOrganizationBusinessWithoutPayment;
    })
)

export const addOrganizationBusinessWithBilling = async (data: CreateOrganizationDto, calculatedTotal: number, billingData: TBillingInfo): Promise<AddOrganizationBusinessWithBillingMutation["addOrganizationBusinessWithBilling"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation addOrganizationBusinessWithBilling($data:CreateOrganizationDto!, $billingData:CreateOrganizationBillingInfoInput!, $calculatedTotal: Float!){
                        addOrganizationBusinessWithBilling(data:$data, billingData:$billingData, calculatedTotal:$calculatedTotal){
                            name
                            logo
                            subscription
                            organizationDetails
                            billing{
                                transactionNumber
                                currentTear{
                                    variant
                                    vatPercentage
                                    discountPercentage
                                    items{
                                        name
                                        quantity
                                        cost
                                        type
                                        subscriptionType
                                        currency
                                    }
                                }
                                billingInfo{
                                    firstName
                                    lastName
                                    email
                                    address
                                    zip
                                    country
                                    additionalData
                                }
                            }
                        }
                    }
                `),
                variables: {
                    data: {...data, name: data.name.trim()},
                    billingData,
                    calculatedTotal
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.addOrganizationBusinessWithBilling;
    })
)

//
export const deleteNotCompletedOrganization = async (): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation deleteNotCompletedOrganization{
                  deleteNotCompletedOrganization{
                    message
                  }
                }
                `
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.deleteNotCompletedOrganization.message;
    })
)


export const checkTransactionStatus = async (transactionNumber: string): Promise<checkPaymentResponse> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query checkTransactionStatus($transactionNumber:String!){
                  checkTransactionStatus(transactionNumber:$transactionNumber){
                    status
                    organizationId
                    workspaceId
                  }
                }
                `,
                variables: {
                    transactionNumber
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.checkTransactionStatus;
    })
)

export const getOrganizationBilling = async (organizationId: string): Promise<TBilling> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                query getOrganizationBilling($organizationId:String!){
                  getOrganizationBilling(organizationId:$organizationId){
                    transactionNumber
                    paymentPeriodStart
                    currentTear{
                      variant
                      items{
                        name
                        type
                        subscriptionType
                        quantity
                        currency
                        cost
                      }
                      vatPercentage
                      discountPercentage
                    }
                    paymentMethod{
                      lastFourDigits
                      paymentMethodName
                      cardExpiration
                      cardMask
                      cardType
                    }
                    billingInfo{
                      firstName
                      lastName
                      address
                      country
                      zip
                      email
                      additionalData
                    }
                    futurePayments{
                      items{
                        name
                        type
                        subscriptionType
                        quantity
                        currency
                        cost
                      }
                      futurePaymentDate
                      retryCount
                      vatPercentage
                      discountPercentage
                      variant
                      periodStart
                      periodEnd
                    }
                    paymentHistory{
                      items{
                        name
                        type
                        subscriptionType
                        quantity
                        currency
                        cost
                      }
                      futurePaymentDate
                      retryCount
                      vatPercentage
                      discountPercentage
                      variant
                      periodStart
                      periodEnd
                      status
                      statusChangedDate
                      transactionNumber
                      receiptFileId
                      invoiceFileId
                    }
                  }
                }
                `,
                variables: {
                    organizationId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getOrganizationBilling;
    })
)

export const updateOrganizationBillingInfo = async (orgId: string, billingData: TBillingInfo): Promise<TBilling> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: `
                mutation updateOrganizationBillingInfo($orgId:String!, $billingData:CreateOrganizationBillingInfoInput!){
                  updateOrganizationBillingInfo(orgId:$orgId, billingData:$billingData){
                    transactionNumber
                    paymentPeriodStart
                    currentTear{
                      variant
                      items{
                        name
                        type
                        subscriptionType
                        quantity
                        currency
                        cost
                      }
                      vatPercentage
                      discountPercentage
                    }
                    paymentMethod{
                      lastFourDigits
                      paymentMethodName
                      cardMask
                      cardExpiration
                      cardType
                    }
                    billingInfo{
                      firstName
                      lastName
                      address
                      country
                      zip
                      email
                      additionalData
                    }
                    futurePayments{
                      items{
                        name
                        type
                        subscriptionType
                        quantity
                        currency
                        cost
                      }
                      futurePaymentDate
                      retryCount
                      vatPercentage
                      discountPercentage
                      variant
                      periodStart
                      periodEnd
                    }
                    paymentHistory{
                      items{
                        name
                        type
                        subscriptionType
                        quantity
                        currency
                        cost
                      }
                      futurePaymentDate
                      retryCount
                      vatPercentage
                      discountPercentage
                      variant
                      periodStart
                      periodEnd
                      status
                      statusChangedDate
                      transactionNumber
                      receiptFileId
                      invoiceFileId
                    }
                  }
                }
                `,
                variables: {
                    orgId,
                    billingData
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateOrganizationBillingInfo;
    })
)

export const importCsvTemplateCollaboratorsApi = async (data: {workspaceId: string}): Promise<string> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: importCsvTemplateCollaborators,
                variables: data
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.importCsvTemplateCollaborators;
    })
)

