import React, {FC} from "react";
import {PortalTrainingsAssignmentsExamQuestionModel} from "../../../../../../../../newShared/GQLTypes";
import {
    FlexRow
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";
import {CircularProgress, Skeleton, Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import {ArrowCircleLeftOutlined, ArrowCircleRightOutlined, TaskAltOutlined} from "@mui/icons-material";
import {useMainTranslation} from "../../../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

type PassingExamMenuItemProps = {
    question: PortalTrainingsAssignmentsExamQuestionModel | null;
    onClick?: () => void;

    answered?: boolean;
    skipped?: boolean;
    selected?: boolean;
    // error?: boolean;
    touched?: boolean;
    loading?: boolean;
};
export const PassingExamMenuItem: FC<PassingExamMenuItemProps> = ({question, onClick, answered, skipped, selected, touched, loading}) => {
    const {revDir} = useMainTranslation();

    const Icon = (
        <>
            {answered && <TaskAltOutlined sx={{color: colors.stroke.blue, fontSize: '19px'}}/>}
            {skipped && (!revDir ? <ArrowCircleRightOutlined sx={{color: colors.stroke.dark_grey, fontSize: '19px'}}/> : <ArrowCircleLeftOutlined sx={{color: colors.stroke.dark_grey, fontSize: '19px'}}/>)}
        </>
    );

    return (
        <FlexRow id={'question' + question?.questionId}
                 onClick={onClick} sx={{
            alignItems: 'center',
            overflow: 'hidden', width: '100%', p: '16px', borderRadius: '6px', gap: '10px',
            border: `1px solid ${colors.stroke.table}`,
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            backgroundColor: selected ? colors.backgrounds.blue_light_1 : undefined,
            cursor: onClick && 'pointer',
            flexShrink: 0,
            borderColor: touched
                // ? error ? colors.stroke.red : undefined
                ? selected ? colors.stroke.blue : undefined
                : undefined,
            '&:hover': {
                borderColor: colors.stroke.blue,
                backgroundColor: colors.backgrounds.optional,
            },
        }}>
            <Typography variant={'*bodyText2_semibold'} noWrap sx={{width: '100%'}}>{question ? question.question : <Skeleton/>}</Typography>
            {!loading ? (answered || skipped) && Icon
                : <CircularProgress size={19} sx={{color: colors.stroke.blue, flexShrink: 0}}/>
            }
        </FlexRow>
    )
};