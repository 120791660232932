import {useDispatch, useSelector} from "react-redux";
import {hideCreateIssue, hrDialogsSelector, hrMyIssuesSelector} from "../../../store/slice";
import {useForm} from "../../../../../../newShared/hooks/useForm";
import {
    HrRequestRequestorModel,
    PortalHrCreateIssueInputDto,
    PortalHrGetEmployeeAutocompleteQuery,
    PortalHrGetEmployeeAutocompleteQueryVariables
} from "../../../../../../newShared/GQLTypes";
import {useCallback, useEffect, useState} from "react";
import {portalHrCreateIssueAction, portalHrGetIssuesWithFilterPaginationAction} from "../../../store/actions";
import {useLazyQuery} from "@apollo/client";
import {portalHrGetEmployeeAutocomplete} from "../../../api/query";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../../authWorkspacesCookies/cookies/hooks/useManageWorkspacesAndOrganizations";
import debounce from "lodash.debounce";
import {currentFilterSelector} from "../../../../../../newShared/components/genericFilter/store/slice";
import {useMessageDialog} from "../../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";


export const useCreateIssueDialog= () => {
    const dispatch = useDispatch();
    const {workspaceId} = UseManageWorkspacesAndOrganizations();
    const {pageInfo} = useSelector(hrMyIssuesSelector);
    const {filters, search} = useSelector(currentFilterSelector);
    const {setMessage} = useMessageDialog();
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathMyHrIssues'})
    

    const {createIssue: {isOpen, isLoading}} = useSelector(hrDialogsSelector);

    const defaultIssue: PortalHrCreateIssueInputDto = {
        anonymous: true,
        offender: null,
        issueType: '',
        description: '',
    }

    const {form, setForm, handleChange} = useForm<PortalHrCreateIssueInputDto>(defaultIssue);

    const [inputValue, setInputValue] = useState<string>('');


    const handleClose = () => {
        dispatch(hideCreateIssue());
    }

    const handleConfirm = () => {
        dispatch(portalHrCreateIssueAction({
            data: {issue: form},
            onSuccess: () => {
                setMessage({title: tCommon('Completed successfully'), message: [t('Your issue has been submitted.')]});
                onSuccess();
            }}));
    }

    const handleToggleAnonymous = (isAnonymous: boolean) => {
        setForm({...form, anonymous: isAnonymous});
    }

    const handleSetIssueType = (issueType: string) => {
        setForm({...form, issueType})
    }

    const handleSetOffender = (offender: HrRequestRequestorModel) => {
        setForm({...form, offender})
    }

    const isOk = form.description.trim().length && form.issueType.trim().length


    useEffect(() => {
        if (isOpen)  fetch0(inputValue);
        else {
            fetch0.cancel();
            setInputValue('');
            setForm(defaultIssue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(inputValue), isOpen]);

    // useEffect(() => {
    //     return () => {
    //         fetch0.cancel();
    //         setInputValue('');
    //         setForm(defaultIssue);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    const [fetchMoreLoading, setFetchMoreLoading] = useState<boolean>(false);

    const [
        getData,
        {loading: _loading, data: _data, fetchMore: _fetchMore, variables}
    ] = useLazyQuery<PortalHrGetEmployeeAutocompleteQuery, PortalHrGetEmployeeAutocompleteQueryVariables>(portalHrGetEmployeeAutocomplete, {
        variables: {
            workspaceId: workspaceId ?? '',
            pageRequest: {page: 0, count: 10},
            search: ''
        },
        fetchPolicy: 'cache-and-network',
    });

    const data =_data?.portalHrGetEmployeeAutocomplete;
    const hasMore = data && data.pageInfo.total > data.result.length;
    const loading = _loading || fetchMoreLoading;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetch0 = useCallback(
        debounce((search: string) => getData({variables: {
                workspaceId: workspaceId ?? '',
                pageRequest: {page: 0, count: 10},
                search: ''
            }}), 300), []);

    const fetchMore = () => {
        if (!data || !hasMore) return;

        setFetchMoreLoading(true);
        _fetchMore({
            variables: {
                workspaceId: workspaceId ?? '',
                pageRequest: {page: data?.pageInfo.page + 1 || 0, count: 10},
                search: variables?.search,
            },
            updateQuery: (prev, {fetchMoreResult}) => {
                return {
                    portalHrGetEmployeeAutocomplete: {
                        result: [
                            ...prev?.portalHrGetEmployeeAutocomplete?.result,
                            ...fetchMoreResult?.portalHrGetEmployeeAutocomplete?.result || [],
                        ],
                        pageInfo: fetchMoreResult?.portalHrGetEmployeeAutocomplete?.pageInfo,
                    },
                };
            },
        }).finally(() => {
            setFetchMoreLoading(false);
        })
    }

    const onSuccess = () => {
        dispatch(portalHrGetIssuesWithFilterPaginationAction({
            data: {
                pageRequest: {
                    page: pageInfo.page,
                    count: pageInfo.count
                }, filter: {...filters, issueIdLike: search}
            }
        }));
    }


    return {
        isOpen,
        isLoading,
        isOk,
        isLoadingEmployees: loading,
        hasMore,
        fetchMore,
        data,
        setInputValue,

        form,
        handleChange,
        handleClose,
        handleConfirm,
        handleToggleAnonymous,
        handleSetIssueType,
        handleSetOffender
    }
}
