import {TPageInfo} from "../../../newShared/types";
import {HrTimeOffStepsStatus, HrTimeOffStepsType, RequestType} from "../../../newShared/GQLTypes";

export const defaultPageInfo: TPageInfo = {
    page: 0,
    count: 25,
    total: 0,
    sort: ''
}

export const statusTypeTitleMapper: Record<RequestType, string> = {
    EDIT_PROFILE: 'Edit profile',
}

export const issueTypeVariants: string[] = [
    'Sexual harassment',
    'Discrimination',
    'Money laundering',
    'Theft',
    'Fraud',
    'ESG Violation',
    'Conflict of interest',
    'Other',
];


export const hrFieldsMapper: Record<string, string> = {
    firstName: 'First name',
    lastName: 'Last name',
    workEmail: 'Work email',
    hireDate: 'Hire date',
    employeeStatus: 'Employee status',
    jobTitle: 'Job title',
    department: 'Department',
    employmentType: 'Employment type',
    location: 'Location',
    division: 'Division',
    middleName: 'Middle name',
    nickname: 'Nickname',
    maritalStatus: 'Marital status',
    gender: 'Gender',
    ethnicity: 'Ethnicity',
    contacts: 'Contacts',
    street: 'Street',
    city: 'City',
    zip: 'ZIP code',
    country: 'Country',
    emergencyContacts: 'Emergency contacts',
    birthDay: 'Birth date',
    activeDutyWartimeOrCampaignBadgeVeteran: 'Active duty wartime or campaign badge veteran',
    armedForcesServiceMedalVeteran: 'Armed Forces service medal veteran',
    disabledVeteran: 'Disabled veteran',
    recentlySeparatedVeteran: 'Recently separated veteran',
    education: 'Education',
    manager: 'Manager',
    employeeId: 'ID',
    workMobilePhone: 'Work mobile phone',
}

export const hrTimeOffStepTypeTitleMapper: Record<HrTimeOffStepsType, string> = {
    [HrTimeOffStepsType.Creation]: 'Request created',
    [HrTimeOffStepsType.ManagerApproval]: 'Manager approval',
    [HrTimeOffStepsType.HrManagerApproval]: 'HR approval',
    [HrTimeOffStepsType.ManagerAcknowledgement]: 'Manager acknowledgement',
    [HrTimeOffStepsType.HrManagerAcknowledgement]: 'HR acknowledgement',
}

export const hrTimeOffStepStatusTitleMapper: Record<HrTimeOffStepsStatus, string> = {
    [HrTimeOffStepsStatus.Approved]: 'Approved',
    [HrTimeOffStepsStatus.Rejected]: 'Rejected',
    [HrTimeOffStepsStatus.Pending]: 'Pending',
    [HrTimeOffStepsStatus.Created]: 'Created',
    [HrTimeOffStepsStatus.Skipped]: 'Skipped',
    [HrTimeOffStepsStatus.InProgress]: 'In progress',
    [HrTimeOffStepsStatus.Acknowledged]: 'Acknowledged',
}



