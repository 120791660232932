import {useDispatch, useSelector} from "react-redux";
import {hideCancelRequest, hrDialogsSelector, hrIsLoadingSelector} from "../../../store/slice";
import {portalHrCancelRequestAction} from "../../../store/actions";


export const useCancelRequestDialog = () => {

    const dispatch = useDispatch();
    const {cancelRequest: {isOpen, selectedId}} = useSelector(hrDialogsSelector);
    const {cancelRequest} = useSelector(hrIsLoadingSelector);

    const handleClose = () => {
        dispatch(hideCancelRequest());
    }

    const handleCancelRequest = () => {
        selectedId && dispatch(portalHrCancelRequestAction({data: {requestId: selectedId}}));
    }

    return {
        isOpen,
        isLoading: cancelRequest,
        handleClose,
        handleCancelRequest,
    }

}
