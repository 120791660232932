import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    REQUIRED_ACTIONS_TASKS_EXACT_PATH,
    REQUIRED_ACTIONS_TASKS_PATH
} from "../../../newShared/constants";
import {RequiredActionList} from "./pages/requiredActionList";


export const Routes: FC = () => {

    return (
        <Switch>
            <Route exact path={[REQUIRED_ACTIONS_TASKS_PATH, REQUIRED_ACTIONS_TASKS_EXACT_PATH]} component={RequiredActionList}/>
            <Redirect to={REQUIRED_ACTIONS_TASKS_PATH}/>
        </Switch>
    );
};
