import {OnSubscriptionDataOptions, useSubscription} from "@apollo/client";
import {subscriptionMessage} from "../../types";
import {WORKSPACE_SUBSCRIPTION_GQL} from "../../gql";
import {Logging} from "../../../../../newShared/utils/logs/log";


export const useWorkspaceSubscription = (workspaceId: string, token: string, publicId: string, organizationId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onNewDataReceived = (data: OnSubscriptionDataOptions<{ subscribeToWorkspace: subscriptionMessage }>) => {
        const newData: subscriptionMessage | undefined = data?.subscriptionData?.data?.subscribeToWorkspace;
        if(newData){
            switch (newData.type){
                case "SEND_CHANGED_EXECUTOR_AUTOMATION_PIPELINE": {
                    try{
                        if(newData){
                            // const payload = JSON.parse(newData.payload) as automationHistoryHotReload;
                            // dispatch(automationsHotReloadHistory(payload));
                        }
                        return;
                    }catch (ex){
                        Logging.log('SEND_CHANGED_EXECUTOR_AUTOMATION_PIPELINE error: ', ex);
                        return;
                    }
                }
                default: {
                    Logging.log(`useWorkspaceSubscription: ${newData.type} type is not defined!`);
                }

            }
        }
    }

    //Gregory Sheygam asked on 9.01.24 to close any pubsub messages - disabled received data handling
    // useSubscription<{ subscribeToWorkspace: subscriptionMessage }>(WORKSPACE_SUBSCRIPTION_GQL, {onSubscriptionData: onNewDataReceived, variables: {workspaceId, token, publicId, organizationId}, fetchPolicy: 'no-cache'});
    useSubscription<{ subscribeToWorkspace: subscriptionMessage }>(WORKSPACE_SUBSCRIPTION_GQL, {onSubscriptionData: () => null, variables: {workspaceId, token, publicId, organizationId}, fetchPolicy: 'no-cache'});
}
