import React from "react";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Skeleton,
    Typography
} from "@mui/material";
import {getIssueStatusChip} from "../../../helpers";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import colors from "../../../../../../newShared/theme/colors";
import {DescriptionRow, DescriptionRowTitle} from "../../styled";
import {useIssueViewDialog} from "../../../hooks/dialogs/useIssueView";
import {HrIssuesStatus} from "../../../../../../newShared/GQLTypes";


export const HrIssueViewDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathMyHrIssues'});
    const {
        isOpen,
        isLoading,
        selected,
        handleClose,
    } = useIssueViewDialog();


    return (
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{`${t('Issue')} ${!isLoading && selected?.id}`}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                {isLoading ? <Skeleton width={'50%'} /> :
                    selected?.anonymous ? <Typography variant={'body2'}>{t('This issue report is anonymous.')}</Typography> : (
                        <Typography variant={'body2'}>{`${t('This issue report is')} `}<span style={{fontWeight: 600}}>{t('not')}</span>{` ${t('anonymous')}.`}</Typography>
                    )
                }

                <DescriptionRow>
                   <DescriptionRowTitle><Typography variant={'body2'}>{`${t('Status')}: `}</Typography></DescriptionRowTitle>
                   {isLoading ? <Skeleton width={'200px'} /> : <Box>{selected?.status ? getIssueStatusChip(selected?.status) : ''}</Box>}
                </DescriptionRow>
                {selected?.status === HrIssuesStatus.Resolved &&
                    <>
                        <DescriptionRow>
                            <DescriptionRowTitle><Typography variant={'body2'}>{`${t('Resolve date')}: `}</Typography></DescriptionRowTitle>
                            {isLoading ? <Skeleton width={'200px'} /> : <Typography variant={'body2'}>{parseDateAuto(selected?.resolveDate)}</Typography>}
                        </DescriptionRow>
                        <DescriptionRow>
                            <DescriptionRowTitle><Typography variant={'body2'}>{`${t('Resolver')} `}</Typography></DescriptionRowTitle>
                            {isLoading ? <Skeleton width={'200px'} /> : <Typography variant={'body2'}>{selected?.resolver?.employeeFullName ?? selected?.resolver?.collaboratorFullName}</Typography>}
                        </DescriptionRow>
                        <DescriptionRow>
                            <DescriptionRowTitle><Typography variant={'body2'}>{`${t('Resolution')}: `}</Typography></DescriptionRowTitle>
                            {isLoading ? <Skeleton width={'200px'} /> : <Typography variant={'body2'}>{selected?.resolution}</Typography>}
                        </DescriptionRow>
                        <Divider flexItem sx={{bgcolor: colors.text.grey_light_2, flexShrink: 0}}/>
                    </>
                }
                <DescriptionRow>
                    <DescriptionRowTitle><Typography variant={'body2'}>{`${t('Type of issue')}: `}</Typography></DescriptionRowTitle>
                    {isLoading ? <Skeleton width={'200px'} /> : <Typography variant={'body2'}>{selected?.issueType}</Typography>}
                </DescriptionRow>
                <DescriptionRow>
                    <DescriptionRowTitle><Typography variant={'body2'}>{`${t('Offender name')}: `}</Typography></DescriptionRowTitle>
                    {isLoading ? <Skeleton width={'200px'} /> : <Typography variant={'body2'}>{selected?.offender?.fullName ?? '-'}</Typography>}
                </DescriptionRow>
                <DescriptionRow>
                    <DescriptionRowTitle><Typography variant={'body2'}>{`${t('What happened?')} `}</Typography></DescriptionRowTitle>
                    {isLoading ? <Skeleton width={'200px'} /> : <Typography variant={'body2'}>{selected?.description}</Typography>}
                </DescriptionRow>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
            </DialogActions>
        </Dialog>
    )

}
