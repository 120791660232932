import {FC} from "react";
import {PageWrapper} from "../../../../../newShared/components/Layouts";
import {PhysicalAssetExactDialog} from "../physicalAssetExactDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useAssetListPage} from "../../hooks/useAssetListPage";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {PageTitleAndActions} from "../../../../../newShared/components/pageTitleAndActions";
import AddIcon from "@mui/icons-material/Add";
import {PortalPhysicalAssetModel} from "../../../../../newShared/GQLTypes";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {GenericTable} from "../../../../../newShared/components/genericTable";
import {useRequestRequestAssetDialog} from "../../hooks/useRequestRequestAssetDialog";
import {useRequestAddAssetDialog} from "../../hooks/useRequestAddAssetDialog";
import {RequestRequestAssetDialog} from "../../components/dialogs/requestRequestAssetDialog";
import {useRemovePhysicalAssetDialog} from "../../hooks/useRequestRemoveAsset";
import {RemovePhysicalAssetDialog} from "../../components/dialogs/removePhysicalAssetDialog";
import {AddPhysicalAssetDialog} from "../../components/dialogs/addPhysicalAssetDialog";
import {AssetsRequestErrorDialog} from "../../components/dialogs/assetsRequestErrorDialog";

export const AssetList: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'pathAssets.pathAssetsList'});
    const {assets, genericTable, actions} = useAssetListPage();
    const {isMobile} = useMedia();

    const requestAssetDialog = useRequestRequestAssetDialog();
    const addAssetDialog = useRequestAddAssetDialog();
    const removePhysicalAssetDialog = useRemovePhysicalAssetDialog();

    return (
        <PageWrapper>
            <PageTitleAndActions margin={'0 0 8px 0'} title={t('My Assets')} actions={[
                {
                    title: t('Request asset'),
                    onClick: requestAssetDialog.handleOpen,
                    size: "small"
                },
                {
                    title: t('Add asset'),
                    onClick: addAssetDialog.handleOpen,
                    variant: 'outlined',
                    icon: <AddIcon />,
                    size: "small"
                },
            ]} />

            <GenericTable<PortalPhysicalAssetModel>
                id={'MyAssets'}
                columnsConfig={{totalName: 'Total assets', disableColumnsSettings: true, forceShowCardsWhen: isMobile}}
                paging={genericTable.paging}
                rowsConfig={{
                    customCellPaddings: '8px',
                    rows: assets,
                    getRowUniqueId: (row) => row.id,
                    onRowClick: actions.handleOpenAssetView,
                    rowActionsConfig: [
                        {
                            title: t('Remove asset'),
                            onClick: (row) => removePhysicalAssetDialog.openRemovePhysicalAsset(row),
                        },
                    ],
                    columnsConfigs: [
                        {key: "assetId", name: t('ID'), default: true, },
                        {key: 'serial', name: t('Serial'), default: true},
                        {key: 'model', name: t('Model'), default: true},
                        {key: 'category', name: t('Category'), default: true},
                        {key: 'manufacture', name: t('Manufacturer'), default: true},
                        {key: 'assigned', name: t('Assigned date'), default: true, valueGetter: (row) => parseDateAuto(row.assigned ?? '')},
                    ],
                }}
                filtersConfig={{
                    searchCustomFilterKeyName: 'nameLike',
                    dontSendSearchIfEmpty: true,
                    genericFilterProps: {
                        configs: genericTable.filters.configs,
                        fetchResultWithSelectedFilters: () => null,
                        isAddFilters: false
                    }
                }}
                emptyArrayImageProps={{type: 'any', filterNotApplied: {text: t('You do not have any assigned assets yet'), secondaryText: ''}}}
            />

            <PhysicalAssetExactDialog/>
            <RequestRequestAssetDialog/>
            <RemovePhysicalAssetDialog />
            <AddPhysicalAssetDialog/>

            <AssetsRequestErrorDialog/>
        </PageWrapper>
    );
};
