import React, {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useCancelAssetRequestDialog} from "../../../hooks/useCancelAssetRequestDialog";
import {useAssetsRequestError} from "../../../hooks/dialogs/useAssetsRequestError";

export const CancelAssetRequestDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathAssets.pathRequestsList'});

    const {
        isOpen,
        isLoading,
        requestId,
        handleClose,
        handleConfirm,
    } = useCancelAssetRequestDialog();


    const {isOpen: isOpenErrorDialog} = useAssetsRequestError();

    return (
        <Dialog open={isOpen && !isOpenErrorDialog} onClose={handleClose}>
            <DialogTitle>{t('Cancel request')}</DialogTitle>

            <DialogContent>
                <Typography variant={'body2'}>{`${t('Are you sure you want to cancel request')} ${requestId ?? ''}?`}</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{(tCommon('Cancel'))}</Button>
                <LoadingButton variant={"contained"} onClick={handleConfirm} loading={isLoading} disabled={!requestId} size={'small'}>
                    {t('Cancel request')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
