import {useDispatch, useSelector} from "react-redux";
import {dialogsSelector, setRequestAssetAction, TAssetsSlice} from "../../store/slice";

export const useRequestRequestAssetDialog = () => {
    const dispatch = useDispatch();
    const {requestAsset: {isLoading, isOpen}} = useSelector(dialogsSelector);


    const handleOpen = () => {
        dispatch(setRequestAssetAction({isOpen: true}))
    }

    const handleClose = () => {
        dispatch(setRequestAssetAction({clear: true}))
    }

    const setDialog = (payload: Partial<TAssetsSlice["dialogs"]["requestAsset"]> & {clear?: boolean}) => {
        dispatch(setRequestAssetAction(payload))
    }

    return {
        isOpen,
        isLoading,

        handleOpen,
        handleClose,
        setDialog,
    }
}