import React, {FC} from "react";
import {Skeleton, Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";
import {
    FlexColumn,
    FlexRow
} from "../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";

interface ICategoryTitle {
    title: string,
    icon: React.ReactNode,
    valuePairs?: {title: string, value?: string}[],
    countPerRow?: number,
    children?: React.ReactNode,
    fullChildren?: React.ReactNode,
}
export const CategoryTitleCard: FC<ICategoryTitle> = ({title, icon, valuePairs, countPerRow = 3, children, fullChildren}) => {

    return (
        <FlexColumn sx={{gap: '10px', flexShrink: 0}}>
            <FlexRow sx={{alignItems: "center", gap: '10px'}}>
                {icon}

                <Typography variant={'*bodyText2_semibold'} color={colors.text.grey_dark}>{title}</Typography>
            </FlexRow>

            {fullChildren || (
                <FlexRow sx={{
                    padding: '20px 16px',
                    gap: '10px',
                    alignItems: 'stretch',

                    borderRadius: '6px',
                    backgroundColor: colors.backgrounds.grey_light,
                    border: `1px solid ${colors.stroke.grey}`,

                    flexWrap: 'wrap',
                }}>
                    {children || valuePairs?.map(e => (
                        <Typography variant={"*bodyText1"} color={colors.text.grey_dark} key={JSON.stringify(e)} sx={{
                            minWidth: `calc(100% / ${countPerRow} - 10px)`,
                            whiteSpace: 'break-spaces', maxWidth: '100%'
                        }}>
                            {`${e.title}: `}
                            {e.value ? <span style={{color: colors.text.dark}}>{e.value}</span> : (
                                <Skeleton variant={"text"} width={100}/>
                            )}
                        </Typography>
                    ))}
                </FlexRow>
            )}
        </FlexColumn>
    )
}