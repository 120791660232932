import React, {FC, useEffect} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {usePhysicalAssetExact} from "../../hooks/usePhysicalAssetExact";
import {MicroTableWithoutBorder} from "../../../../../newShared/components/microTableWithoutBorder";
import {ASSETS_LIST_PATH} from "../../../../../newShared/constants";
import {useRemovePhysicalAssetDialog} from "../../hooks/useRequestRemoveAsset";
import {useAssetsRequestError} from "../../hooks/dialogs/useAssetsRequestError";

export const PhysicalAssetExactDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathAssets.pathAssetsList'})
    const {assetId} = useParams<{assetId?: string}>();

    const history = useHistory();
    const location = useLocation();

    const {
        asset,
        isLoading,
        getPhysicalAsset, cleanSelected
    } = usePhysicalAssetExact();
    const {isOpen: isOpenRemoveRequest, openRemovePhysicalAsset} = useRemovePhysicalAssetDialog();

    const handleClose = () => {
        history.push(`${ASSETS_LIST_PATH}${location.search}`);
    }

    useEffect(() => {
        if (assetId) {
            getPhysicalAsset(assetId);
        } else {
            cleanSelected();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId]);

    const {isOpen: isOpenErrorDialog} = useAssetsRequestError();

    if (!isLoading && !asset && assetId) {
        return (
            <Dialog open={!!assetId} onClose={handleClose}>
                <DialogTitle>{t('Asset not found')}</DialogTitle>

                <DialogContent>
                    <Typography variant={'*bodyText2'}>
                        {/*{t('Asset with ID {{assetId}} not assigned to your account.', {assetId})}*/}
                        {t('Asset not exists or not assigned to your account.')}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} variant={'text'}>{(tCommon('Close'))}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Dialog open={!!assetId && !isOpenRemoveRequest && !isOpenErrorDialog}>
            <DialogTitle sx={{display: 'flex', gap: '8px'}}>
                {`${t('Asset')} ${asset?.assetId || ''}`}
                {isLoading && <Skeleton variant={'text'} width={100} />}
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto'}}>
                <MicroTableWithoutBorder isLoading={isLoading}
                                         rows={[
                                             {
                                                 label: `${t('ID')}: `,
                                                 text: asset?.assetId,
                                             },
                                             {
                                                 label: `${t('Serial')}: `,
                                                 text: asset?.serial,
                                             },
                                             {
                                                 label: `${t('Model')}: `,
                                                 text: asset?.model,
                                             },
                                             {
                                                 label: `${t('Category')}: `,
                                                 text: asset?.category,
                                             },
                                             {
                                                 label: `${t('Manufacturer')}: `,
                                                 text: asset?.manufacture,
                                             },
                                             ...Object.entries(asset?.customFields || []).map(([key, value]) => ({
                                                 label: key,
                                                 text: value
                                             }))
                                         ]}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{(tCommon('Close'))}</Button>
                <Button onClick={() => asset && openRemovePhysicalAsset(asset)} disabled={!asset} size={'small'}>{(t('Remove asset'))}</Button>
            </DialogActions>
        </Dialog>
    )
}
