import {useDispatch, useSelector} from "react-redux";
import {hrDialogsSelector, setTimeOffHistoryDialogAction} from "../../../store/slice";
import {PortalHrTimeOffRequestModel} from "../../../../../../newShared/GQLTypes";


export const useHrTimeOffHistory = () => {

    const dispatch = useDispatch();
    const {timeOffHistory: {isOpen, selected}} = useSelector(hrDialogsSelector);

    const handleBack = () => {
        dispatch(setTimeOffHistoryDialogAction({clear: true}))
    }

    const handleOpenTimeOffHistory = (selected: PortalHrTimeOffRequestModel) => {
        dispatch(setTimeOffHistoryDialogAction({isOpen: true, selected}))
    }

    return {
        isOpen,
        selected,
        handleBack,
        handleOpenTimeOffHistory,
    }

}
