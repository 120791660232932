import {createCommonAsyncThunk} from "../../../../newShared/utils/asyncThunk";
import {
    portalAssetsCancelAssetRequest,
    portalAssetsCreateRequestAddAsset,
    portalAssetsCreateRequestRemoveAsset,
    portalAssetsCreateRequestRequestAsset,
    portalAssetsGetPhysicalAssetById,
    portalAssetsGetPhysicalAssetsSettings,
    portalAssetsGetPhysicalAssetsSettingsForFilters,
    portalAssetsGetPhysicalAssetsWithFilterPagination,
    portalAssetsGetRequestById,
    portalAssetsGetRequestsWithFilterPagination,
} from "../api/query";
import {
    PortalAssetsCancelAssetRequestMutation,
    PortalAssetsCancelAssetRequestMutationVariables,
    PortalAssetsCreateRequestAddAssetMutation,
    PortalAssetsCreateRequestAddAssetMutationVariables,
    PortalAssetsCreateRequestRemoveAssetMutation,
    PortalAssetsCreateRequestRemoveAssetMutationVariables,
    PortalAssetsCreateRequestRequestAssetMutation,
    PortalAssetsCreateRequestRequestAssetMutationVariables,
    PortalAssetsGetPhysicalAssetByIdQuery,
    PortalAssetsGetPhysicalAssetByIdQueryVariables,
    PortalAssetsGetPhysicalAssetsSettingsForFiltersQuery,
    PortalAssetsGetPhysicalAssetsSettingsForFiltersQueryVariables,
    PortalAssetsGetPhysicalAssetsSettingsQuery,
    PortalAssetsGetPhysicalAssetsSettingsQueryVariables,
    PortalAssetsGetPhysicalAssetsWithFilterPaginationQuery,
    PortalAssetsGetPhysicalAssetsWithFilterPaginationQueryVariables,
    PortalAssetsGetRequestByIdQuery,
    PortalAssetsGetRequestByIdQueryVariables,
    PortalAssetsGetRequestsWithFilterPaginationQuery,
    PortalAssetsGetRequestsWithFilterPaginationQueryVariables,
} from "../../../../newShared/GQLTypes";

export const portalAssetsGetPhysicalAssetsWithFilterPaginationAction =
    createCommonAsyncThunk<PortalAssetsGetPhysicalAssetsWithFilterPaginationQueryVariables, PortalAssetsGetPhysicalAssetsWithFilterPaginationQuery["portalAssetsGetPhysicalAssetsWithFilterPagination"]>(
        'assets', 'portalAssetsGetPhysicalAssetsWithFilterPagination', true, false, portalAssetsGetPhysicalAssetsWithFilterPagination
    )

export const portalAssetsGetPhysicalAssetsSettingsForFiltersAction =
    createCommonAsyncThunk<PortalAssetsGetPhysicalAssetsSettingsForFiltersQueryVariables, PortalAssetsGetPhysicalAssetsSettingsForFiltersQuery["portalAssetsGetPhysicalAssetsSettingsForFilters"]>(
        'assets', 'portalAssetsGetPhysicalAssetsSettingsForFilters', true, false, portalAssetsGetPhysicalAssetsSettingsForFilters
    )

export const portalAssetsGetPhysicalAssetByIdAction =
    createCommonAsyncThunk<PortalAssetsGetPhysicalAssetByIdQueryVariables, PortalAssetsGetPhysicalAssetByIdQuery["portalAssetsGetPhysicalAssetById"]>(
        'assets', 'portalAssetsGetPhysicalAssetById', true, false, portalAssetsGetPhysicalAssetById
    )

export const portalAssetsCreateRequestAddAssetAction =
    createCommonAsyncThunk<PortalAssetsCreateRequestAddAssetMutationVariables, PortalAssetsCreateRequestAddAssetMutation['portalAssetsCreateRequestAddAsset']>(
        'assets', 'portalAssetsCreateRequestAddAsset', true, false, portalAssetsCreateRequestAddAsset
    )

export const portalGetAssetConfigsAction =
    createCommonAsyncThunk<PortalAssetsGetPhysicalAssetsSettingsQueryVariables, PortalAssetsGetPhysicalAssetsSettingsQuery['portalAssetsGetPhysicalAssetsSettings']>(
        'assets', 'portalAssetsGetPhysicalAssetsSettings', true, false, portalAssetsGetPhysicalAssetsSettings
    )

export const portalAssetsCreateRequestRequestAssetAction =
    createCommonAsyncThunk<PortalAssetsCreateRequestRequestAssetMutationVariables, PortalAssetsCreateRequestRequestAssetMutation["portalAssetsCreateRequestRequestAsset"]>(
        'assets', 'portalAssetsCreateRequestRequestAsset', true, false, portalAssetsCreateRequestRequestAsset
    )

export const portalAssetsCreateRequestRemoveAssetAction =
    createCommonAsyncThunk<PortalAssetsCreateRequestRemoveAssetMutationVariables, PortalAssetsCreateRequestRemoveAssetMutation["portalAssetsCreateRequestRemoveAsset"]>(
        'assets', 'portalAssetsCreateRequestRemoveAsset', true, false, portalAssetsCreateRequestRemoveAsset
    )


export const portalAssetsGetRequestsWithFilterPaginationAction =
    createCommonAsyncThunk<PortalAssetsGetRequestsWithFilterPaginationQueryVariables, PortalAssetsGetRequestsWithFilterPaginationQuery["portalAssetsGetRequestsWithFilterPagination"]>(
        'assets', 'portalAssetsGetRequestsWithFilterPagination', true, false, portalAssetsGetRequestsWithFilterPagination
    )

export const portalAssetsGetRequestByIdAction =
    createCommonAsyncThunk<PortalAssetsGetRequestByIdQueryVariables, PortalAssetsGetRequestByIdQuery["portalAssetsGetRequestById"]>(
        'assets', 'portalAssetsGetRequestById', true, false, portalAssetsGetRequestById
    )

export const portalAssetsCancelAssetRequestAction =
    createCommonAsyncThunk<PortalAssetsCancelAssetRequestMutationVariables, PortalAssetsCancelAssetRequestMutation["portalAssetsCancelAssetRequest"]>(
        'assets', 'portalAssetsCancelAssetRequest', true, false, portalAssetsCancelAssetRequest
    )
