// import React, {ComponentType} from 'react';
import {Handle, Position, useReactFlow} from 'reactflow';
import React, {ComponentType} from 'react';
import {employeeTreeNodeHeight, employeeTreeNodeWidth, TEmployeeNodeData} from "../../../helpers";
import {Avatar, Box, Typography} from "@mui/material";
import {NodeProps} from "@reactflow/core/dist/esm/types/nodes";
import colors from '../../../../../../newShared/theme/colors';
import {getAvatarInitials} from "../../../../../../newShared/utils/text";
import {useEmployeesTree} from "../../../hooks/useEmployeesTree";
import {useMedia} from "../../../../../../newShared/hooks/useMedia";
import {MailOutline, PhoneOutlined} from "@mui/icons-material";
import {Flex} from "../../../../../../newShared/components/Layouts";


export const EmployeeTreeNode: ComponentType<NodeProps<TEmployeeNodeData>> = ({ id, data, targetPosition, sourcePosition}) => {

    const {isMobile} = useMedia();

    const {
        focusNode,
    } = useEmployeesTree();
    const deleted: string[] = [];

    const {
        setCenter,
        getNodes,
    } = useReactFlow();

    const isDeleted = deleted.includes(id);

    const handleToggleOpen = (id: string) => {
        focusNode(getNodes(), id, setCenter, {x: 1.85, y: 0.75, zoom: 0.9});
        // toggleOpenModal();
    }

    // const [isHovered, setIsHovered] = useState<boolean>(false);
    const isNoEditHovered = false;

    // const handleMouseEnter = () => {setIsHovered(true)};
    // const handleMouseLeave = () => {setIsHovered(false)};

    // const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    //     // event.stopPropagation();
    //     // setTree({deleted: isDeleted ? deleted.filter(e => e !== id) : [...deleted, id]});
    //     // setEdges(edges => edges.filter(edge => (edge.target !== id && edge.source !== id)))
    // }

    const dx = '-4px';
    const handlerSize = '8px';

    return (
        <Box
            // onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
             boxShadow={'0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)'}
             sx={{ background: colors.backgrounds.white, '&:hover': {background: colors.backgrounds.grey_light}}}
             borderRadius={'8px'} p={'12px 16px'} width={employeeTreeNodeWidth} height={employeeTreeNodeHeight}>
            <Handle
                isValidConnection={(connection) => connection.target !== connection.source}
                type={"target"} style={{
                width: handlerSize, height: handlerSize,
                ...(targetPosition === Position.Right ? {right: dx}
                        : targetPosition === Position.Left ? {left: dx}
                            : targetPosition === Position.Top ? {top: dx}
                                : targetPosition === Position.Bottom ? {bottom: dx}
                                    : {}
                )}}
                position={targetPosition || Position.Right}
            />

            { isMobile
                ? (
                    <>
                        <Box overflow={'hidden'} display={'flex'} onClick={() => handleToggleOpen(data.id)} flexDirection={"column"} alignItems={"center"} height={'100%'}>
                            <Avatar src={data.logo || undefined} sx={{ width: 48, height: 48, fontSize: '16px' }}>{getAvatarInitials(data.name)}</Avatar>
                            <Typography variant={"subtitle2"}
                                        textAlign={"center"}
                                        // sx={{flexGrow: 1, mt: '4px', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', maxHeight: '100%', "-webkit-line-clamp": '2'}}
                                        sx={{
                                            flexGrow: 1, mt: '4px',
                                            overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', maxHeight: '100%',
                                            '-webkit-box-orient': 'vertical', "-webkit-line-clamp": '2', display: '-webkit-box',
                                            // whiteSpace: 'nowrap',
                                        }}
                            >{data.name}</Typography>
                            <Typography noWrap variant={"subtitle1"} textAlign={"center"} sx={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', maxHeight: '100%'}}>{data.jobTitle}</Typography>
                        </Box>
                        {/*<Modal open={openModal} onClose={handleCloseModal}>*/}
                        {/*    <Box width={'177px'} mt={'10px'} height={'172px'} position={'absolute'} top={'57%'} left={'25%'} bgcolor={colors.backgrounds.white} borderRadius={'8px'} p={'16px'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>*/}
                        {/*        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{cursor: 'pointer', gap: '22px'}}*/}
                        {/*             onClick={() => handleEditEmployee(data.id)} */}
                        {/*        >*/}
                        {/*            <Typography variant={'body2'}>{t('Edit')}</Typography>*/}
                        {/*            <IconButton size={'medium'} color={'primary'} >*/}
                        {/*                <EditIcon />*/}
                        {/*            </IconButton>*/}
                        {/*        </Box>*/}
                        {/*        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{cursor: 'pointer'}}*/}
                        {/*             onClick={() => goToId(data.id)} >*/}
                        {/*            <Typography variant={'body2'}>{t('Full information')}</Typography>*/}
                        {/*            <IconButton size={'medium'} color={'primary'}>*/}
                        {/*                <FileSearchIcon />*/}
                        {/*            </IconButton>*/}
                        {/*        </Box>*/}
                        {/*        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{cursor: 'pointer'}}*/}
                        {/*             onClick={() => handleDeleteEmployee(data.id, data.name)}>*/}
                        {/*            <Typography color={colors.text.critical} variant={'body2'}>{t('Delete')}</Typography>*/}
                        {/*            <IconButton size={'medium'} color={'error'}>*/}
                        {/*                <DeleteForeverIcon sx={{color: colors.text.critical}} />*/}
                        {/*            </IconButton>*/}
                        {/*        </Box>*/}
                        {/*    </Box>*/}
                        {/*</Modal>*/}
                    </>
                ) : (
                    <>
                        <Box display={!isNoEditHovered ? "flex" : 'none'} alignItems={"flex-start"} height={'100%'} gap={'10px'} position={'relative'}>
                            {/*{isEditMode && (*/}
                            {/*    <Box position={'absolute'} top={'-10px'} right={'-8px'}>*/}
                            {/*        <IconButton onClick={handleDelete} size={'small'}*/}
                            {/*                    sx={{color: isDeleted ? colors.primary.blue_dark : colors.text.critical}}>*/}
                            {/*            {isDeleted ? <SettingsBackupRestoreIcon/> : <DeleteForeverIcon/>}*/}
                            {/*        </IconButton>*/}
                            {/*    </Box>*/}
                            {/*)}*/}
                            <Avatar src={data.logo || undefined} sx={{opacity: isDeleted ? 0.3 : undefined, width: 40, height: 40, fontSize: '16px' }}>{getAvatarInitials(data.name)}</Avatar>

                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px', overflow: 'hidden'}}>
                                <Typography variant={"*bodyText2_semibold"} color={colors.text.dark} sx={{opacity: isDeleted ? 0.3 : undefined, maxWidth: '100%'}} noWrap>{data.name}</Typography>
                                <Typography variant={"*bodySmall"} color={colors.primary.blue_dark} sx={{opacity: isDeleted ? 0.3 : undefined, maxWidth: '100%'}} noWrap>{data.jobTitle}</Typography>

                                <Flex gap={'4px'} ai={"center"} style={{color: colors.text.grey_dark, opacity: isDeleted ? 0.3 : undefined, maxWidth: '100%', overflow: 'hidden'}}>
                                    <PhoneOutlined sx={{maxHeight: '16px', maxWidth: '16px'}}/>
                                    <Typography variant={"*bodySmall"} noWrap sx={{maxWidth: '100%'}}>
                                        {data.workMobilePhone}
                                    </Typography>
                                </Flex>
                                <Flex gap={'4px'} ai={"center"} style={{color: colors.text.grey_dark, opacity: isDeleted ? 0.3 : undefined, maxWidth: '100%', overflow: 'hidden'}}>
                                    <MailOutline sx={{maxHeight: '16px', maxWidth: '16px'}}/>
                                    <Typography variant={"*bodySmall"} noWrap sx={{maxWidth: '100%'}}>
                                        {data.workEmail}
                                    </Typography>
                                </Flex>
                            </Box>
                        </Box>

                        {/*<Fade in={isNoEditHovered && !isDeleted} timeout={500}>*/}
                        {/*    <Box display={isNoEditHovered ? "flex" : 'none'}  alignItems={"center"} justifyContent={'center'} gap={'12px'} height={'100%'} width={'100%'}>*/}
                        {/*        <IconButton onClick={() => handleEditEmployee(data.id)} size={'medium'} color={'primary'} >*/}
                        {/*            <EditIcon />*/}
                        {/*        </IconButton>*/}
                        {/*        <IconButton onClick={() => goToId(data.id)} size={'medium'} color={'primary'}>*/}
                        {/*            <FileSearchIcon />*/}
                        {/*        </IconButton>*/}
                        {/*        <IconButton onClick={() => handleDeleteEmployee(data.id, data.name)} size={'medium'} color={'error'}>*/}
                        {/*            <DeleteForeverIcon />*/}
                        {/*        </IconButton>*/}
                        {/*    </Box>*/}
                        {/*</Fade>*/}
                    </>
                )
            }

            <Handle
                type={"source"} style={{
                width: handlerSize, height: handlerSize,
                ...(sourcePosition === Position.Right ? {right: dx}
                        : sourcePosition === Position.Left ? {left: dx}
                            : sourcePosition === Position.Top ? {top: dx}
                                : sourcePosition === Position.Bottom ? {bottom: dx}
                                    : {}
                )}}
                position={sourcePosition || Position.Left}
            />
        </Box>
    );
};
