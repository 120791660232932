import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router";
import {eraseRequestList, loadingsSelector, replaceRequestMinMaxLoadedPage, requestsSelector} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {ASSETS_LIST_PATH, ASSETS_REQUESTS_EXACT_PATH, ASSETS_REQUESTS_PATH} from "../../../../../newShared/constants";
import {AssetsRequestStatus, AssetsRequestType, PortalAssetsRequestsFilters} from "../../../../../newShared/GQLTypes";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {minMaxLoadedPageType} from "../../../../../newShared/components/genericTable/types";
import {portalAssetsGetRequestsWithFilterPaginationAction} from "../../store/actions";
import {getAssetRequestStatusChip} from "../../helpers";
import {assetRequestTypeTitleMapper} from "../../constants";


export const useRequestsListPage = () => {
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const {t} = useMainTranslation('', {keyPrefix: 'pathAssets.pathRequestsList'});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {requests, pageInfo, minMaxLoadedPage} = useSelector(requestsSelector);
    const {requestList: isLoadingList} = useSelector(loadingsSelector);
    const {minLoadedPage, maxLoadedPage} = minMaxLoadedPage;

    useSetBreadcrumbs([
        {
            title: tMenu('Assets'),
            path: ASSETS_LIST_PATH
        },
        {
            title: tMenu('My asset requests'),
            path: ASSETS_REQUESTS_PATH
        },
    ]);


    // useEffect(() => {
    //     !filterOptions && dispatch(portalAssetsGetPhysicalAssetsSettingsForFiltersAction({data: {workspaceId: ''}}));
    //
    //     return () => {
    //         dispatch(eraseAssetsFilterOptions());
    //     }
    //
    //     //eslint-disable-next-line
    // }, []);

    const handleOpenRequestView = (id: string) => {
        history.push(`${ASSETS_REQUESTS_EXACT_PATH.replace(':requestId', id)}${location.search}`);
        // dispatch(openAssetView(selected.id));
    }

    const typeConfig: keyConfig<AssetsRequestType> = {
        type: 'filter',
        key: 'type',
        name: t('Request type'),
        options: Object.values(AssetsRequestType),
        getOptionLabel: option => assetRequestTypeTitleMapper[option],
        isOptionEqual: (option, value) => option === value,
        default: true
    }

    const statusConfig: keyConfig<AssetsRequestStatus> = {
        type: 'filter',
        key: 'status',
        name: t('Status'),
        options: Object.values(AssetsRequestStatus),
        OptionRenderer: (a) => getAssetRequestStatusChip(a, true),
        isOptionEqual: (option, value) => option === value,
        default: true
    }

    const requestDateConfig: keyConfig<string> = {
        type: 'date',
        key: 'requestDate',
        name: t('Request date'),
        default: true
    }

    return {
        requests,
        isLoadingList,

        actions: {
            handleOpenRequestView,
        },

        genericTable: {
            paging: {
                pageInfo,
                isLoading: isLoadingList,
                minLoadedPage,
                maxLoadedPage,
                setMinMaxLoadedPage: (data: minMaxLoadedPageType) => dispatch(replaceRequestMinMaxLoadedPage(data)),
                clearRows: () => dispatch(eraseRequestList()),
                fetchPaging: (page: number, count: number, filters: PortalAssetsRequestsFilters, requestIdLike: string) => {
                    dispatch(portalAssetsGetRequestsWithFilterPaginationAction({data: {pageRequest: {page, count}, filter: {...filters, requestIdLike}}}));
                },
            },
            filters: {
                configs: [
                    typeConfig,
                    requestDateConfig,
                    statusConfig,
                ]
            }
        }

    }
}
