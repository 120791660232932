import {styled as styledMui} from "@mui/material/styles";
import {Box} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";

export const AssignmentExactHeader = styledMui(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '20px',
    flexWrap: 'wrap',
    borderRadius: '8px',
    border: `1px solid ${colors.stroke.table}`,
    marginTop: '20px',
    gap: '60px'
}));

export const AssignmentExactHeaderElement = styledMui(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6px'
}));

export const AssignmentExactContent = styledMui(Box)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '24px',
    gap: '16px',
    overflow: 'auto'
}));

export const AssignmentTrainingCard = styledMui(Box, {
    shouldForwardProp: (propName) => (
        !["isLessThen1050"].includes(propName.toString())
    )})(({isLessThen1050}: {isLessThen1050: boolean}) => ({
    width: isLessThen1050 ? '100%' : '49%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    border: `1px solid ${colors.stroke.table}`,
    padding: '16px',
    gap: '16px'
}))