import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {useAddAssetDialog} from "../../../hooks/useAddAssetDialog";
import React from "react";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {useSelector} from "react-redux";
import {settingsSelector} from "../../../store/slice";
import {useAssetsRequestError} from "../../../hooks/dialogs/useAssetsRequestError";

export const AddPhysicalAssetDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAssets.dialogs'})
    const {formik, isOpen, handleClose, handleSend, isLoadingSendAddAsset} = useAddAssetDialog();
    const {manufacture, model, category} = useSelector(settingsSelector);

    const {isOpen: isOpenErrorDialog} = useAssetsRequestError();
    return (
        <Dialog open={isOpen && !isOpenErrorDialog} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{t('Request to add asset')}</DialogTitle>
            <DialogContent style={{display: 'flex', gap: '16px', flexDirection: 'column'}}>
                <Typography variant={'*bodyText2'}>{t('This request is for adding an asset you already have that was not previously accounted.')}</Typography>

                <TextField
                    label={t('Serial')} required size={'small'} name={'serial'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.serial}
                    error={formik.touched.serial && Boolean(formik.errors.serial)}
                    helperText={formik.touched.serial && formik.errors.serial}
                />

                <Autocomplete<string>
                    size={"small"}
                    options={category || []}
                    value={formik.values.category || null}
                    onChange={(e, selected) => {
                        formik.setFieldTouched('category');
                        formik.setFieldValue('category', selected);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} onBlur={formik.handleBlur}
                                   label={t('Select category')}
                                   helperText={formik.touched.category && formik.errors.category}
                                   error={formik.touched.category && Boolean(formik.errors.category)} name={'category'} required/>
                    )}
                />

                <Autocomplete<string>
                    size={"small"}
                    options={model || []}
                    value={formik.values.model || null}
                    onChange={(e, selected) => {
                        formik.setFieldTouched('model');
                        formik.setFieldValue('model', selected);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} onBlur={formik.handleBlur}
                                   label={t('Select model')}
                                   helperText={formik.touched.model && formik.errors.model}
                                   error={formik.touched.model && Boolean(formik.errors.model)} name={'model'} required/>
                    )}
                />

                <Autocomplete<string>
                    size={"small"}
                    options={manufacture || []}
                    value={formik.values.manufacture || null}
                    onChange={(e, selected) => {
                        formik.setFieldTouched('manufacture');
                        formik.setFieldValue('manufacture', selected);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} onBlur={formik.handleBlur}
                                   label={t('Select manufacturer')}
                                   helperText={formik.touched.manufacture && formik.errors.manufacture}
                                   error={formik.touched.manufacture && Boolean(formik.errors.manufacture)} name={'manufacture'} required/>
                    )}
                />

                <TextField
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comment}
                    name={'comment'}
                    multiline
                    label={'Comment'}
                    rows={4}
                    error={formik.touched.comment && Boolean(formik.errors.comment)}
                    helperText={formik.touched.comment && formik.errors.comment}
                />

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{t('Cancel')}</Button>
                <LoadingButton
                    onClick={handleSend}
                    disabled={!formik.isValid || !formik.dirty}
                    loading={isLoadingSendAddAsset}
                >
                    {t('Send')}
                </LoadingButton>
            </DialogActions>
        </Dialog>

    )
}
