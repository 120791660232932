import {Flex} from "../../../../../newShared/components/Layouts";
import {RequiredActionsStatus} from "../../../../../newShared/GQLTypes";
import {Typography} from "@mui/material";
import colors from "../../../../../newShared/theme/colors";

export const RequiredActionsStatusChips = ({status}: {status: RequiredActionsStatus}) => {
    switch (status){
        case RequiredActionsStatus.Created: return <Flex
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.status.violet_1}
        >
            <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{('Created')}</Typography>
        </Flex>;

        case RequiredActionsStatus.Approved: return <Flex
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.decorative.background_3}
        >
            <Typography variant={'*bodyText2'} color={colors.text.success}>{('Approved')}</Typography>
        </Flex>;


        case RequiredActionsStatus.Declined: return <Flex
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.decorative.background_2}
        >
            <Typography variant={'*bodyText2'} color={colors.textStatus.negative}>{('Declined')}</Typography>
        </Flex>;

        case RequiredActionsStatus.Performed: return <Flex
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.status.turquoise_1}
        >
            <Typography variant={'*bodyText2'} color={colors.textStatus.positive}>{('Performed')}</Typography>
        </Flex>;

        default: return <Flex
            p={'6px 14px'}
            br={'12px'}
            ai={'center'}
            jc={'center'}
            background={colors.status.violet_1}
        >
            <Typography variant={'*bodyText2'} color={colors.text.grey_dark}>{status}</Typography>
        </Flex>;
    }
}
