import {AssetsRequestStatus} from "../../../newShared/GQLTypes";
import {StatusBox, StatusTitle} from "./components/styled";
import colors from "../../../newShared/theme/colors";


export const getAssetRequestStatusChip = (status: AssetsRequestStatus, isFilter?: boolean) => {
    switch (status) {
        case AssetsRequestStatus.Created: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.decorative.background_5}><StatusTitle color={colors.decorative.lilac}>{'Created'}</StatusTitle></StatusBox>;
        case AssetsRequestStatus.Approved: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.decorative.background_3}><StatusTitle color={colors.text.success}>{'Approved'}</StatusTitle></StatusBox>;
        case AssetsRequestStatus.Rejected: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.decorative.background_2}><StatusTitle color={colors.text.critical}>{'Rejected'}</StatusTitle></StatusBox>;
        case AssetsRequestStatus.Cancelled: return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.text.grey}>{'Cancelled'}</StatusTitle></StatusBox>;
        default:  return <StatusBox margin={isFilter ? '8px 0 0 0' : undefined} bgColor={colors.backgrounds.grey_light}><StatusTitle color={colors.text.grey}>{status}</StatusTitle></StatusBox>;
    }
}
