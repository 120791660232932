import {
    PATH_LOCAL_2FA,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_VERIFY_EMAIL
} from "../../../../newShared/constants";
// import {unprotectedPaths} from "../../../../domain/leftSideBar/constants";

export const unprotectedPaths = [
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_LOGIN,
    // PATH_LOCAL_WORKSPACES,
    // LICENSE_PATH,
    // FLOWS_TEST,
    PATH_LOCAL_2FA,
    // (pathname: string) => pathname.indexOf(PATH_LOCAL_PRE_MANAGE_WORKSPACES) > - 1, PATH_LOCAL_SHARE_REPORTS,
    // ...unprotectedTopBarPaths
];

export const unprotectedTopBarPaths: (string | { (pathname: string): boolean })[]  = [];

export const hideProtectedTopBarPaths: (string | { (pathname: string): boolean })[] = [
    ...unprotectedTopBarPaths,PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_2FA,
    PATH_LOCAL_MFA_RESET,
    PATH_LOCAL_LOGIN_CONTACT_US,
    PATH_LOCAL_VERIFY_EMAIL,
];

export const showProtectedTopBar = (pathname: string) => !hideProtectedTopBarPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
export const showUnProtectedTopBar = (pathname: string) => unprotectedTopBarPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
export const hideTopBar = (pathname: string) => !(showProtectedTopBar(pathname) || showUnProtectedTopBar(pathname));
export const isUnProtectedPaths = (pathname: string) => unprotectedPaths.some(el => el === pathname);
