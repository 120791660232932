import React, {FC, forwardRef, HTMLProps} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import {useCreateIssueDialog} from "../../../hooks/dialogs/useCreateIssue";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import CommonCheckbox from "../../../../../../newShared/components/Basic/CommonCheckBox";
import {SpanGrey, SpanRed} from "../../styled";
import {Autocomplete} from "@mui/lab";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {issueTypeVariants} from "../../../constants";
import {HrRequestRequestorModel} from "../../../../../../newShared/GQLTypes";
import {CommonAvatar} from "../../../../../../newShared/components/Basic/CommonAvatar";


export const CreateIssueDialog: FC = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathMyHrIssues'});
    const {
        isOpen,
        isLoading,
        isOk,
        isLoadingEmployees,

        data,
        hasMore,
        fetchMore,
        setInputValue,

        form,
        handleChange,
        handleClose,
        handleConfirm,
        handleToggleAnonymous,
        handleSetIssueType,
        handleSetOffender,
    } = useCreateIssueDialog();

    const renderOption =
        (props: React.HTMLAttributes<HTMLLIElement>, option: HrRequestRequestorModel ) => (
            <li {...props} key={JSON.stringify(option)} style={{...props.style, display: "flex", gap: '8px', alignItems: 'center', zIndex: '999999', position: 'relative'}}
                onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onMouseUp={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                <CommonAvatar size={'d32'} name={option.fullName}/>
                <Typography variant={'body2'}>{option.fullName}</Typography>
                {/*<Box display={'flex'} flexDirection={'column'}>*/}
                {/*    <Typography variant={'body2'}>{option.fullName}</Typography>*/}
                {/*    <Typography variant={'body2'}>{option.employeeId}</Typography>*/}
                {/*</Box>*/}
            </li>
        )



    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                {t('New issue')}
            </DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>

                <CommonCheckbox disableRipple disabled={isLoading} checked={form.anonymous}
                                label={t('Report an issue anonymously')}
                                onChange={() => handleToggleAnonymous(!form.anonymous)} />

                <Typography variant={'*bodyText2_semibold'}>{t('What type of issue?')}<SpanRed>*</SpanRed></Typography>

                <Autocomplete
                    options={issueTypeVariants}
                    value={form.issueType}
                    getOptionLabel={(option) => option.length ? t(option) : option}
                    onChange={(e, newValue) => newValue && handleSetIssueType(newValue)}
                    renderInput={(params) => <TextField {...params} label={t('Select an issue')} size={'small'} required/>}
                />

                <Typography variant={'*bodyText2_semibold'}>{t('Offender name')}<SpanGrey>{` (${t('optional')})`}</SpanGrey></Typography>

                <Autocomplete<HrRequestRequestorModel>
                    size={'small'}
                    selectOnFocus={false}
                    clearOnBlur={false}
                    openOnFocus
                    loading={isLoadingEmployees}

                    
                    // getOptionLabel={option => `${option.fullName} (${option.employeeId})`}
                    getOptionLabel={option => option.fullName}
                    renderOption={renderOption}

                    renderInput={(params) =>
                        <TextField {...params} label={t('Select an employee')}
                                   fullWidth
                                   InputProps={{
                                       ...params.InputProps,
                                       endAdornment: (
                                           <>
                                               {isLoadingEmployees && <CircularProgress color="inherit" size={20}/>}
                                               {params.InputProps.endAdornment}
                                           </>
                                       ),
                                   }}
                        />
                    }
                    ListboxComponent={forwardRef<HTMLUListElement, HTMLProps<HTMLUListElement>>(({children, ...props}, ref) => (
                        <ul {...props} ref={ref}>
                            {children}

                            {(hasMore || isLoadingEmployees) && (
                                <Box display={'flex'} sx={{alignItems: 'center', justifyContent: 'center', height: '34px'}}>
                                    {isLoadingEmployees ? <CircularProgress size={20}/> : (
                                        hasMore && <Button fullWidth variant={"text"} size={"small"} onClick={fetchMore}>{'Load more'}</Button>
                                    )}
                                </Box>
                            )}
                        </ul>
                    ))}

                    options={data?.result || []}
                    value={form.offender}
                    onChange={(_, value) => handleSetOffender(value as HrRequestRequestorModel)}
                    onInputChange={(_, newInputValue, reason) => {
                        setInputValue(newInputValue);
                    }}
                />

                <Typography variant={'*bodyText2_semibold'}>{t('What happened?')}<SpanRed>*</SpanRed></Typography>

                <TextField value={form.description} onChange={handleChange} name={'description'} multiline minRows={3} />

            </DialogContent>


            <DialogActions>
                <Button variant={"text"} onClick={handleClose}>{tCommon('Cancel')}</Button>
                <LoadingButton loading={isLoading} onClick={handleConfirm} size={'small'} disabled={!isOk}>
                    {t('Report issue')}
                </LoadingButton>
            </DialogActions>

        </Dialog>
    )
}
