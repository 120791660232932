import {
    applicationAccessDataWithoutAccounts,
    campaignReviewExecutorAccountFillableType,
    SpaceInitState,
    Stage
} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    ConfirmSignStage,
    DownloadFile,
    GetCampaignReviewStageById, GetFinalApproverByStageIdAndExcId,
    GetStages,
    SignStage,
    UpdateCampaignReviewRows,
    UpdateDocument,
    UploadFile,
    VerifyActorChanges
} from "./actions";
import {COMPLETE_STATUS} from "../constants";

const initialState: SpaceInitState = {
    pageInfo: {
        page: 0,
        count: 10,
        total: 0,
        sort: 'default'
    },
    stages: [],
    selectedStage: null,
    finalApprover: null,

    loadings: {
        isLoadingFinalApprover: false,
        list: false,
        downloadFile: false,
        verifyChanges: false,
        campaignReviewUpdatingRowsWithIds: [],
        approveDefaultDocStage: false,
        approveDocSignStage: false,
        uploadFile: false,
        updateDocumentOnReview: false,
        campaignReviewStage: false
    },

    dialogs: {
        showPermissions: {
            isOpen: false,
            roleName: null,
            rolePermissions: []
        },
        addOrEditJustificationDialog: {
            isOpen: false,
            isManager: false,
            app: null,
            account: null,
            stageId: null,
            actorEmail: null,
        },

        viewJustification: {
            isOpen: false,
            email: null,
            justification: null,
        },
        verifyChangesDialog: false,
        declineVerifyStepDialog: {
            isOpen: false,
            email: null,
            stageId: null,
            isLastVerify: false,
        }
    }
}

export const SpaceSlice = createSlice({
    name: 'WorkerSpace',
    initialState,
    reducers: {
        selectStage: (state, {payload}: {payload: Stage}) => {
            state.selectedStage = payload;
            state.finalApprover = null;
        },
        // openDocViewer: (state, {payload}: {payload: {fileId: string, fileName: string}}) => {
        //     state.dialogs.docViewer.isOpen = true;
        //     state.dialogs.docViewer.fileId = payload.fileId;
        //     state.dialogs.docViewer.fileName = payload.fileName;
        // },
        // hideDocViewer: (state) => {
        //     state.dialogs.docViewer = {isOpen: false, fileName: null, fileId: null}
        // },
        //
        openAddOrEditJustificationDialog: (slice, {payload}: {payload: {app: applicationAccessDataWithoutAccounts, account: campaignReviewExecutorAccountFillableType, stageId: string, actorEmail: string, isManager: boolean}}) => {
            slice.dialogs.addOrEditJustificationDialog = {
                isOpen: true,
                isManager: payload.isManager,
                app: payload.app,
                account: payload.account,
                stageId: payload.stageId,
                actorEmail: payload.actorEmail,
            }
        },
        hideAddOrEditJustificationDialog: (slice) => {
            slice.dialogs.addOrEditJustificationDialog = {
                isOpen: false,
                isManager: true,
                app: null,
                account: null,
                stageId: null,
                actorEmail: null,
            }
        },

        openViewJustification: (slice, {payload}: {payload: {email: string, justification: string}}) => {
            slice.dialogs.viewJustification = {
                isOpen: true,
                ...payload,
            };
        },
        hideViewJustification: (slice) => {
            slice.dialogs.viewJustification = {
                isOpen: false,
                email: null,
                justification: null
            };
        },

        openShowPermissions: (slice, {payload}: {payload: {roleName: string, rolePermissions: string[]}}) => {slice.dialogs.showPermissions = {...payload, isOpen: true}},
        hideShowPermissions: (slice) => {slice.dialogs.showPermissions = {roleName: null, rolePermissions: [], isOpen: false}},

        //
        openDeclineVerifyStepDialog: (slice, {payload}: {payload: {email: string, stageId: string, isLastVerify: boolean}}) => {
            slice.dialogs.declineVerifyStepDialog = {
                isOpen: true,
                ...payload,
            };
        },
        hideDeclineVerifyStepDialog: (slice) => {
            slice.dialogs.declineVerifyStepDialog = {
                isOpen: false,
                email: null,
                stageId: null,
                isLastVerify: false
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetStages.pending, (slice) => {
                slice.loadings.list = true;
            })
            .addCase(GetStages.rejected, (slice) => {
                slice.loadings.list = false;
            })
            .addCase(GetStages.fulfilled, (slice, {payload}) => {
                if(payload && payload.stages && payload.pageInfo){
                    slice.pageInfo = payload.pageInfo;
                    slice.stages = payload.stages;
                    // if(payload.stages.length > 0 ){
                    //     slice.selectedStage = payload.stages[0];
                    // }
                }
                slice.loadings.list = false;
            })
        //DownloadFile
            .addCase(DownloadFile.pending, (slice) => {
                slice.loadings.downloadFile = true;
            })
            .addCase(DownloadFile.rejected, (slice) => {
                slice.loadings.downloadFile = false;
            })
            .addCase(DownloadFile.fulfilled, (slice, {payload}) => {
                slice.loadings.downloadFile = false;
            })
        //SignStage
            .addCase(SignStage.pending, (slice) => {
                slice.loadings.approveDefaultDocStage = true;
            })
            .addCase(SignStage.rejected, (slice) => {
                slice.loadings.approveDefaultDocStage = false;
            })
            .addCase(SignStage.fulfilled, (slice, {payload}) => {
                slice.loadings.approveDefaultDocStage = false;
                const updateData = {
                    status: 'completed',
                    statusChangedDate: new Date(Date.now()).toISOString(),
                }
                slice.stages =
                    slice.stages.map((e: Stage )=> e.stage.id === payload
                        ?
                        {...e, stage: {...e.stage, actor: {...e.stage.actor, ...updateData}}}
                        :
                        e
                    );

                if(slice.selectedStage){
                    //updateData
                    slice.selectedStage.stage.actor.status = 'completed';
                    slice.selectedStage.stage.actor.statusChangedDate = new Date(Date.now()).toISOString();
                }
            })
        //UploadFile
            .addCase(UploadFile.pending, (slice) => {
                slice.loadings.uploadFile = true;
            })
            .addCase(UploadFile.rejected, (slice) => {
                slice.loadings.uploadFile = false;
            })
            .addCase(UploadFile.fulfilled, (slice, {payload}) => {
                slice.loadings.uploadFile = false;
                if(!payload.isSignature){
                    slice.loadings.approveDocSignStage = false;
                }
                //here when user sign i dispatching one action - upload file
                //then upload file signs the stage
                //thats why if its signature we dont stop isLoading - signStage will do it in a moment
                // if(slice.selectedStage && payload.isSignature){
                //     slice.selectedStage.stage.actor.signatureId = payload.fileId;
                // }
            })
        //ConfirmSignStage
            .addCase(ConfirmSignStage.pending, (slice) => {
                slice.loadings.approveDocSignStage = true;
            })
            .addCase(ConfirmSignStage.rejected, (slice) => {
                slice.loadings.approveDocSignStage = false;
            })
            .addCase(ConfirmSignStage.fulfilled, (slice, {payload}) => {
                slice.loadings.approveDocSignStage = false;
                //todo
                slice.stages =
                    slice.stages.map((e: Stage )=> e.stage.id === payload
                        ?
                        {...e, stage: {...e.stage, actor: {...e.stage.actor, status: 'completed', statusChangedDate: new Date(Date.now()).toISOString()}}}
                        :
                        e
                    );
                //
                if(slice.selectedStage){
                    slice.selectedStage.stage.actor.status = 'completed';
                    slice.selectedStage.stage.statusChangedDate = new Date(Date.now()).toISOString();
                }
            })
        //UpdateDocument
            .addCase(UpdateDocument.pending, (slice) => {
                slice.loadings.updateDocumentOnReview = true;
            })
            .addCase(UpdateDocument.rejected, (slice) => {
                slice.loadings.updateDocumentOnReview = false;
            })
            .addCase(UpdateDocument.fulfilled, (slice, {payload}) => {
                slice.loadings.updateDocumentOnReview = false;
                slice.stages = slice.stages.map(e => e.stage.id === payload.stageId ?
                    {...e, stageDocument: payload.doc, stage: {...e.stage, documentName: payload.doc.links[0]?.fileName ?? e.stage.documentName}}
                    :
                    e
                )
                if(slice.selectedStage && slice.selectedStage.stageDocument){
                    slice.selectedStage.stageDocument = payload.doc;
                }
            })
        //GetCampaignReviewStageById
            .addCase(GetCampaignReviewStageById.pending, (slice) => {
                slice.loadings.campaignReviewStage = true;
            })
            .addCase(GetCampaignReviewStageById.rejected, (slice) => {
                slice.loadings.campaignReviewStage = false;
            })
            .addCase(GetCampaignReviewStageById.fulfilled, (slice, {payload}) => {
                slice.loadings.campaignReviewStage = false;
                if(slice.selectedStage){
                    slice.selectedStage.stageCampaignReview = payload;
                }
            })
        //


            .addCase(UpdateCampaignReviewRows.pending, (slice, {meta}) => {
                const loadingIds = meta.arg.data.apps.flatMap(e => e.data).map(e => e.id);
                slice.loadings.campaignReviewUpdatingRowsWithIds = Array.from(new Set([...slice.loadings.campaignReviewUpdatingRowsWithIds, ...loadingIds]));
            })
            .addCase(UpdateCampaignReviewRows.rejected, (slice, {meta}) => {
                const rejectedLoadingIds = meta.arg.data.apps.flatMap(e => e.data).map(e => e.id);
                //removing ids of fields than not loading anymore
                slice.loadings.campaignReviewUpdatingRowsWithIds = slice.loadings.campaignReviewUpdatingRowsWithIds.filter(e => !rejectedLoadingIds.some(r => r === e));
            })
            .addCase(UpdateCampaignReviewRows.fulfilled, (slice, {payload}) => {
                const finishedLoadingIds = payload.apps.flatMap(e => e.data).map(e => e.id);

                if(finishedLoadingIds.some(e => e === slice.dialogs.addOrEditJustificationDialog.account?.id)){
                    //if user added justification - and loading for this row ended - closing add/edit justification dialog
                    slice.dialogs.addOrEditJustificationDialog = {
                        isOpen: false,
                        isManager: true,
                        app: null,
                        account: null,
                        stageId: null,
                        actorEmail: null,
                    }
                }
                //removing ids of fields than not loading anymore
                slice.loadings.campaignReviewUpdatingRowsWithIds = slice.loadings.campaignReviewUpdatingRowsWithIds.filter(e => !finishedLoadingIds.some(r => r === e));
                if(slice.selectedStage && slice.selectedStage.stageCampaignReview){
                    //searching for needed stage
                    slice.selectedStage.stageCampaignReview = {
                            ...slice.selectedStage.stageCampaignReview,
                            actors: slice.selectedStage.stageCampaignReview.actors.map(a => {
                                if(a.appsData){
                                    a.appsData = a.appsData.map(app => {
                                        const updatedApp = payload.apps.find(pApp => pApp.applicationAccessId === app.applicationAccessId);
                                        if(updatedApp){
                                            return {
                                                ...app,
                                                //searching for needed accounts
                                                data: app.data.map(dat => {
                                                    const replacementDat = updatedApp.data.find(upDat => upDat.id === dat.id);
                                                    if(replacementDat){
                                                        //returning updated account if it was found in updated upp
                                                        return replacementDat;
                                                    }
                                                    return dat;
                                                })
                                            }
                                        }
                                        return app;
                                    })

                                }
                                return a;
                            })
                        }
                }
            })
        //
            .addCase(VerifyActorChanges.pending, (slice) => {
                slice.loadings.verifyChanges = true;
            })
            .addCase(VerifyActorChanges.rejected, (slice) => {
                slice.loadings.verifyChanges = false;
            })
            .addCase(VerifyActorChanges.fulfilled, (slice, {payload}) => {
                slice.loadings.verifyChanges = false;
                slice.dialogs.declineVerifyStepDialog = {
                    isOpen: false,
                    email: null,
                    isLastVerify: false,
                    stageId: null,
                }

                const updatableData = {
                    status: COMPLETE_STATUS,
                    statusChangedDate: new Date(Date.now()).toISOString(),
                }
                slice.stages = slice.stages.map(e => e.stage.id === payload.stageId ? {...e, stage: {...e.stage, ...updatableData, actor: {...e.stage.actor, ...updatableData}}} : e);

                if(slice.selectedStage && slice.selectedStage.stageCampaignReview){
                    slice.selectedStage.stage.actor = {...slice.selectedStage.stage.actor, ...updatableData};
                    slice.selectedStage.stage = {...slice.selectedStage.stage, ...updatableData, actor: {...slice.selectedStage.stage.actor, ...updatableData}};
                    slice.selectedStage.stageCampaignReview = {
                        ...slice.selectedStage.stageCampaignReview,
                        actors: slice.selectedStage.stageCampaignReview.actors.map(e => {
                            return {
                                ...e,
                                status: COMPLETE_STATUS,
                                statusChangedDate: new Date(Date.now()).toISOString(),
                            }
                        }),
                        ...updatableData
                    }
                }
            })
        //GetFinalApproverByStageIdAndExcId
            .addCase(GetFinalApproverByStageIdAndExcId.pending, (slice) => {
                slice.loadings.isLoadingFinalApprover = true;
            })
            .addCase(GetFinalApproverByStageIdAndExcId.rejected, (slice) => {
                slice.loadings.isLoadingFinalApprover = false;
            })
            .addCase(GetFinalApproverByStageIdAndExcId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFinalApprover = false;
                slice.finalApprover = payload;
            })
    }
});

export const SpaceReducer = SpaceSlice.reducer;

export const {
    selectStage,
    openAddOrEditJustificationDialog,
    hideAddOrEditJustificationDialog,

    openShowPermissions,
    hideShowPermissions,

    openViewJustification,
    hideViewJustification,

    openDeclineVerifyStepDialog,
    hideDeclineVerifyStepDialog
} = SpaceSlice.actions;

//selectors
const selectSelf = (state: AppState):SpaceInitState => state.Space as SpaceInitState;

export const loadings = createSelector(selectSelf, state => state.loadings);
export const stages = createSelector(selectSelf, state => state.stages);
export const selectedStage = createSelector(selectSelf, state => state.selectedStage);
export const pageInfo = createSelector(selectSelf, state => state.pageInfo);
export const dialogs = createSelector(selectSelf, state => state.dialogs);
export const finalApprover = createSelector(selectSelf, state => state.finalApprover);


