import Typography from "@mui/material/Typography";
import {Flex} from "../../../../../../newShared/components/Layouts";
import {useCampaignReviewManagerStep} from "../../../hooks/useCampaignReviewManagerStep";
import colors from "../../../../../../newShared/theme/colors";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import {
    ACCOUNT_DELETE_STATUS,
    ACCOUNT_KEEP_STATUS,
    ACCOUNT_REVOKE_STATUS,
    IN_PROGRESS_STATUS
} from "../../../constants";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {Button, Checkbox, FormGroup, Radio, RadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
    getColorAndTextByAccountStatus,
    groupByEmployee,
    mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest
} from "../../../helpers";
import {CustomTableHeader} from "../customTableHeader";
import {CustomTableRow} from "../customTableRow";
import {TableRowItemWrapper} from "../../styled";

export const CampaignReviewManager = () => {
    const stageManagers = useCampaignReviewManagerStep();
    const {revDir} = useMainTranslation();

    return(
        <Flex w={'100%'} direction={'column'}>
            {stageManagers.isTerminated ?
                <>
                    <Flex m={'16px 0 0 0'}>
                        <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>Stage managers was terminated on </Typography>
                        <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{parseDateAuto(stageManagers.stage?.statusChangedDate)}</Typography>
                    </Flex>
                </>
                :
                <>
                    {stageManagers.stage && stageManagers.stage.status === IN_PROGRESS_STATUS &&
                        <Flex w={'100%'} ai={'center'} m={'8px 0'}>
                            <Typography variant={'body1'} color={colors.lightGrayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>Deadline for Stage Managers</Typography>
                            <Typography variant={'body1'} color={colors.grayText} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0', lineHeight: 'normal'}}>{parseDateAuto(stageManagers.stage?.expirationDate)}</Typography>
                            {stageManagers.isDeadlinePassed && <Typography variant={'body1'} color={colors.decorative.orange}>{'The deadline has arrived'}</Typography>}
                        </Flex>
                    }

                    {stageManagers.stage && stageManagers.stage.actors.map((actor, id) => {
                        const isEditable: boolean = actor.status === IN_PROGRESS_STATUS && !actor.mainControl;
                        const isStageCanBeVerified = !((actor.appsData ?? []).flatMap(e => e.data).some(e => e.statusManager === null)) && isEditable;
                        const columnWidths = (isEditable ?
                            [{name: 'Employee', width: '18%'}, {name: 'Account', width: '18%'}, {name: 'Permissions', width: '19%'}, {name: 'Status', width: '45%'}]
                            :
                            [{name: 'Employee', width: '25%'}, {name: 'Account', width: '25%'}, {name: 'Permissions', width: '35%'}, {name: 'Status', width: '15%'}]);

                        return(
                            // MANAGER ARRAY
                            <Flex w={'100%'} maxw={'1400px'} direction={'column'} m={id > 0 ? '20px 0 0 0' : ''}>
                                {/*TOP*/}
                                <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={'16px 2px'}>
                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0'}} fontWeight={600}>{'Manager'}</Typography>
                                    <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 32px 0'}}>{actor.firstName} {actor.lastName}</Typography>
                                    {isStageCanBeVerified &&
                                        <LoadingButton
                                            variant={'contained'}
                                            size={'small'}
                                            onClick={() => stageManagers.handleVerifyActorChanges(actor.email)}
                                            loading={stageManagers.commonSelectors.isLoadingVerify} //is loading verify changes for current user
                                        >{('Verify changes')}
                                        </LoadingButton>
                                    }
                                </Flex>

                                {isEditable &&
                                    <Flex ai={'center'} m={'16px 0'}>
                                        <FormGroup
                                            sx={{margin: !revDir ? '0 16px' : '0 8px 0 16px'}}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size={'small'}
                                                        checked={stageManagers.isSelectedAllManagersAccounts(actor.email)}
                                                        onChange={(e) => e.target.checked ? stageManagers.handleSelectAllAccounts(actor.email) : stageManagers.handleDeselectAllAccounts()}
                                                    />}
                                                label="Select all"
                                            />
                                        </FormGroup>

                                        <Button
                                            variant={'outlined'}
                                            sx={{margin: !revDir ? '0 16px 0 0' : '0 16px 0 0'}}
                                            size={'small'}
                                            onClick={stageManagers.handleKeepAllSelectedManagerAccounts}
                                            disabled={!stageManagers.selectedManagerAccounts.length || stageManagers.commonSelectors.loadingIds.length > 0}
                                        >
                                            {`Keep all ${stageManagers.selectedManagerAccounts.length ?? ''}`}
                                        </Button>

                                        <Button
                                            variant={'outlined'}
                                            sx={{margin: !revDir ? '0 16px 0 0' : '0 16px 0 0'}}
                                            size={'small'}
                                            onClick={stageManagers.handleRevokeAllSelectedManagerAccounts}
                                            disabled={!stageManagers.selectedManagerAccounts.length || stageManagers.commonSelectors.loadingIds.length > 0}
                                        >
                                            {`Revoke all ${stageManagers.selectedManagerAccounts.length ?? ''}`}
                                        </Button>

                                        <Button
                                            variant={'outlined'}
                                            size={'small'}
                                            onClick={stageManagers.handleDeleteAllSelectedManagerAccounts}
                                            disabled={!stageManagers.selectedManagerAccounts.length || stageManagers.commonSelectors.loadingIds.length > 0}
                                        >
                                            {`Delete all ${stageManagers.selectedManagerAccounts.length ?? ''}`}
                                        </Button>
                                    </Flex>
                                }

                                {groupByEmployee(actor.appsData ?? []).map(employee => {
                                    return(
                                        <Flex direction={'column'} m={!revDir ? '16px 0 0 0' : '16px 0 0 0'}>
                                            <Flex w={'100%'} ai={'center'} background={colors.backgrounds.grey_light} p={'16px 2px'}>
                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 8px 0 0' : '0 0 8px 0'}} fontWeight={600}>{'Employee'}</Typography>
                                                <Typography color={colors.grayText} variant={'body1'} sx={{margin: !revDir ? '0 32px 0 0' : '0 0 32px 0'}}>{employee.employee}</Typography>
                                            </Flex>

                                            <CustomTableHeader
                                                columns={columnWidths}
                                                hideIf={false}
                                                padding={'8px 0 12px 0 '}
                                                width={'100%'}
                                            />

                                            {employee.accounts.map(account => {
                                                const status = getColorAndTextByAccountStatus(account.statusManager);
                                                const isLoading = stageManagers.commonSelectors.loadingIds.some(e => e === account.id);

                                                return(
                                                    <CustomTableRow divider disableHover innerWrapperWidth={'100%'}>
                                                        <TableRowItemWrapper width={columnWidths[0]?.width ?? '25%'}>
                                                            {isEditable &&
                                                                <Checkbox
                                                                    // disabled={(stageManagers.selectingAccountsOfActorEmail !== null && stageManagers.selectingAccountsOfActorEmail !== actor.email)}
                                                                    size={'small'}
                                                                    checked={stageManagers.isSelectedManagerAccount(account.id)}
                                                                    onChange={(e) => e.target.checked ? stageManagers.handleSelectManagerAccount(account.id, actor.email) : stageManagers.handleDeselectManagerAccount(account.id, actor.email)}
                                                                    sx={{margin: !revDir ? '0 8px 0 0' : '0 0 0 8px'}}
                                                                />
                                                            }
                                                            <Typography sx={{color: colors.darkText}} variant={'body2'}>{account.applicationAccessName ?? '- - -'}</Typography>
                                                        </TableRowItemWrapper>

                                                        <TableRowItemWrapper width={columnWidths[1]?.width ?? '25%'}>
                                                            <Typography sx={{color: colors.darkText}} variant={'body2'}>{account.account}</Typography>
                                                        </TableRowItemWrapper>

                                                        <TableRowItemWrapper width={columnWidths[2]?.width ?? '25%'}>
                                                            <Flex w={'90%'} direction={'column'}>
                                                                {account.roles.map(e => {
                                                                    const moreThen0Roles = e.rolePermissions.length > 0;
                                                                    return(
                                                                        <Flex key={e.roleName} w={'100%'} ai={'center'} jc={'space-between'}>
                                                                            <Typography sx={{color: colors.darkText, width: '50%',  cursor: 'pointer'}} variant={'body2'}>{e.roleName}</Typography>
                                                                            <Typography
                                                                                sx={{color: moreThen0Roles ? colors.primary.blue : colors.darkText, cursor: moreThen0Roles ? 'pointer' : 'default', textDecoration: moreThen0Roles ? 'underline' : ''}}
                                                                                variant={'body2'}
                                                                                onClick={() => stageManagers.onRoleClick(e.roleName, e.rolePermissions)}
                                                                            >{e.rolePermissions.length} permissions</Typography>
                                                                        </Flex>
                                                                    )
                                                                })}
                                                            </Flex>
                                                        </TableRowItemWrapper>

                                                        <TableRowItemWrapper width={columnWidths[3]?.width ?? '25%'}>
                                                            {isEditable ?
                                                                <Flex w={'100%'}>
                                                                    <RadioGroup
                                                                        row
                                                                        value={account.statusManager ?? ''}
                                                                        sx={{flexWrap: 'nowrap'}}
                                                                        onChange={(e, value) => {
                                                                            const data = mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest(account);
                                                                            stageManagers.handleChangeSingleStatus(data.app, data.account, stageManagers.stage!.id, actor.email, value)
                                                                        }}
                                                                    >
                                                                        <FormControlLabel disabled={isLoading} value={ACCOUNT_KEEP_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{'Keep'}</Typography>} />
                                                                        <FormControlLabel disabled={isLoading} value={ACCOUNT_REVOKE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{'Revoke'}</Typography>} />
                                                                        <FormControlLabel disabled={isLoading} value={ACCOUNT_DELETE_STATUS} control={<Radio size={'small'} />} label={<Typography variant={'body2'}>{'Delete account'}</Typography>} />
                                                                    </RadioGroup>

                                                                    <Button
                                                                        variant={'text'}
                                                                        size={'small'}
                                                                        disabled={account.statusManager === null}
                                                                        onClick={() => {
                                                                            const data = mapCampaignReviewExecutorAccountFillableTypeWithApplicationForSingleUpdateRequest(account);
                                                                            stageManagers.editJustification(data.app, data.account, stageManagers.stage!.id, actor.email);
                                                                        }}
                                                                    >
                                                                        {(account.commentManager ?? '').length > 0 ? 'View justification' : 'Add justification'}
                                                                    </Button>
                                                                </Flex>
                                                                :
                                                                <Flex w={'100%'} direction={'column'}>
                                                                    <Typography color={status.color} variant={'body2'}>{status.text}</Typography>
                                                                    {(account.commentManager ?? '').length > 0 && <Typography
                                                                        color={colors.primary.blue}
                                                                        variant={'body2'}
                                                                        sx={{margin: '8px 0 0 0', cursor: 'pointer'}}
                                                                        onClick={() => stageManagers.viewJustification(actor.email, account.commentManager)}
                                                                    >{'View justification'}</Typography>
                                                                    }
                                                                </Flex>
                                                            }
                                                        </TableRowItemWrapper>
                                                    </CustomTableRow>
                                                )
                                            })}
                                        </Flex>
                                    )
                                })}
                            </Flex>
                        )
                    })}
                </>
            }
        </Flex>
    )
}