import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import {loadingsSelector} from "../../../../../store/slice";
import {useSelector} from "react-redux";
import {LoadingButton} from "../../../../../../../../newShared/components/Basic/CommonButtons";
import {Flex} from "../../../../../../../../newShared/components/Layouts";

type FinishTrainingDialogPropsType = {
    isOpen: boolean,
    handleClose: () => void,
    duration: number,
    limitMinDuration: number,
    finishTrainingAction: () => void
}
export const FinishTrainingDialog = ({isOpen, finishTrainingAction, handleClose, duration, limitMinDuration}: FinishTrainingDialogPropsType) => {
    const isLoading = useSelector(loadingsSelector).finishTraining;
    const isLimit = limitMinDuration > 0;
    const isOverLimit = isLimit ? duration >= limitMinDuration * 60 * 60 : true;
    const toWaitInMins = ((limitMinDuration * 60 * 60 - duration) / 60).toFixed();

    //limit - over/not over
    //no limit

    return(
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{('Finish training')}</DialogTitle>

            <DialogContent>
                <Flex direction={'column'} gap={'8px'}>
                    <Typography variant={'*bodyText2'} color={colors.grayText}>
                        {isLimit ?
                            (isOverLimit ? 'All training sections were completed. Do you want to finish training?' : `All training sections were completed.`)
                            :
                            'All training sections were completed. Do you want to finish training?'
                        }
                    </Typography>

                    {isLimit && !isOverLimit &&
                        <Typography variant={'*bodyText2'} color={colors.grayText}>
                            {`But the training requires a minimum time duration of ${limitMinDuration} hours. You should study this topic for another ${toWaitInMins} minutes.`}
                        </Typography>
                    }
                </Flex>
            </DialogContent>

            <DialogActions>
                <Button variant={'text'} size={'small'} onClick={handleClose}>{('Close')}</Button>

                {(isLimit ? isOverLimit : true) &&
                    <LoadingButton onClick={finishTrainingAction}
                                   variant={'contained'}
                                   size={'small'}
                                   loading={isLoading}
                    >
                        {('Finish training')}
                    </LoadingButton>
                }
            </DialogActions>
        </Dialog>
    )
}