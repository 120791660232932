import React from "react";
import {useRequestViewDialog} from "../../../hooks/dialogs/useRequestView";
import {useMainTranslation} from "../../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {getRequestStatusChip} from "../../../helpers";
import {hrFieldsMapper, statusTypeTitleMapper} from "../../../constants";
import {parseDateAuto} from "../../../../../../newShared/utils/dateTools";
import colors from "../../../../../../newShared/theme/colors";
import {DescriptionRow, DescriptionRowTitle, RequestTitleSpan} from "../../styled";
import {LoadingButton} from "../../../../../../newShared/components/Basic/CommonButtons";
import {HrRequestStatus, PortalHrEmployeeModel} from "../../../../../../newShared/GQLTypes";
import {TableRowSkeleton} from "../../../../../../newShared/components/tableRowSkeleton";


export const HrRequestViewDialog = () => {
    const {t, tCommon} = useMainTranslation('', {keyPrefix: 'pathMyHr.pathHrProfile'});
    const {
        isOpen,
        loadings,
        selected,
        current,
        handleClose,
        handleCancelRequest,
    } = useRequestViewDialog();


    return (
        <Dialog open={isOpen} onClose={handleClose} scroll={'paper'}>
            <DialogTitle>{`${t('Request')} ${selected?.id ?? ''}`}</DialogTitle>

            <DialogContent sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                <DescriptionRow>
                    <DescriptionRowTitle><Typography variant={'body2'}>{t('Status')}</Typography></DescriptionRowTitle>
                    {loadings.requestExact ? <Skeleton width={'200px'} /> : <Box>{selected?.status ? getRequestStatusChip(selected?.status) : ''}</Box>}
                </DescriptionRow>
                <DescriptionRow>
                    <DescriptionRowTitle><Typography variant={'body2'}>{t('Request type')}</Typography></DescriptionRowTitle>
                    {loadings.requestExact ? <Skeleton width={'200px'} /> : <Box>{selected?.type ? statusTypeTitleMapper[selected?.type] ?? selected?.type : ''}</Box>}
                </DescriptionRow>
                <DescriptionRow>
                    <DescriptionRowTitle><Typography variant={'body2'}>{t('Request date')}</Typography></DescriptionRowTitle>
                    {loadings.requestExact ? <Skeleton width={'200px'} /> : <Box>{parseDateAuto(selected?.requestDate ?? '')}</Box>}
                </DescriptionRow>

                { selected?.resolveDate &&
                    <>
                        {/*<Divider flexItem sx={{bgcolor: colors.text.grey_light_2, flexShrink: 0}}/>*/}
                        <DescriptionRow>
                            <DescriptionRowTitle><Typography variant={'body2'}>{t('Resolve date')}</Typography></DescriptionRowTitle>
                            {loadings.requestExact ? <Skeleton width={'200px'} /> : <Box>{parseDateAuto(selected?.resolveDate ?? '')}</Box>}
                        </DescriptionRow>
                        <DescriptionRow>
                            <DescriptionRowTitle><Typography variant={'body2'}>{t('Resolver')}</Typography></DescriptionRowTitle>
                            {loadings.requestExact ? <Skeleton width={'200px'} /> : <Box>{selected?.resolver?.employeeFullName ?? selected?.resolver?.collaboratorFullName}</Box>}
                        </DescriptionRow>
                    </>

                }

                { selected?.status === HrRequestStatus.Created ?
                    <>
                        <Divider flexItem sx={{bgcolor: colors.text.grey_light_2, flexShrink: 0}}/>

                        <Typography variant={'body1'}>{t('Changes requested:')}</Typography>

                        <Table stickyHeader >
                            <TableHead>
                                <TableRow >
                                    <TableCell>{t('Current')}</TableCell>
                                    <TableCell>{t('Requested')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selected?.data.editProfile.map(field => (
                                    <TableRow key={field.fieldName}>
                                        <TableCell>
                                            <Typography variant={'body2'}>{`${hrFieldsMapper[field.fieldName] ? t(hrFieldsMapper[field.fieldName]) : field.fieldName}: `}
                                                <RequestTitleSpan >
                                                    {field.fieldName === 'birthDay'
                                                        ? parseDateAuto(current?.[field.fieldName as keyof PortalHrEmployeeModel] as string || '')
                                                        : current?.[field.fieldName as keyof PortalHrEmployeeModel] || '-'
                                                    }
                                                </RequestTitleSpan>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'body2'}>{`${hrFieldsMapper[field.fieldName] ? t(hrFieldsMapper[field.fieldName]) : field.fieldName}: `}
                                                <RequestTitleSpan >
                                                    {field.fieldName === 'birthDay'
                                                        ? parseDateAuto(field.fieldValue as string || '')
                                                        : field.fieldValue || '-'
                                                    }
                                                </RequestTitleSpan>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {loadings.requestExact && <TableRowSkeleton columns={2} />}
                            </TableBody>

                        </Table>
                    </>
                    :
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow >
                                <TableCell>{t('Requested')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selected?.data.editProfile.map(field => (
                                <TableRow key={field.fieldName}>
                                    <TableCell>
                                        <Typography variant={'body2'}>
                                            {`${hrFieldsMapper[field.fieldName] ? t(hrFieldsMapper[field.fieldName]) : field.fieldName}: `}
                                            <RequestTitleSpan >
                                                {field.fieldName === 'birthDay'
                                                    ? parseDateAuto(field.fieldValue as string || '')
                                                    : field.fieldValue || '-'
                                                }
                                            </RequestTitleSpan>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loadings.requestExact && <TableRowSkeleton columns={1} />}
                        </TableBody>

                    </Table>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant={'text'}>{tCommon('Close')}</Button>
                {selected?.status === HrRequestStatus.Created &&
                    <LoadingButton
                        // sx={{margin: !revDir ? '0 16px 0 0' : '0 0 0 16px'}}
                        size={'small'}
                        loading={loadings.cancelRequest}
                        onClick={handleCancelRequest}
                        disabled={!selected}
                    >
                        {t('Cancel request')}
                    </LoadingButton>
                }


            </DialogActions>
        </Dialog>
    )

}
