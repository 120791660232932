import React, {FC, useEffect} from "react";
import {ConnectionLineType, Edge, Node, ReactFlowState, useReactFlow, useStore} from "reactflow";
import {employeeTreeNodeHeight, employeeTreeNodeWidth, getLayoutedElements, TEmployeeNodeData} from "../../../helpers";
import {Box} from "@mui/material";
import {useEmployeesTree} from "../../../hooks/useEmployeesTree";
import dagre, {graphlib} from "dagre";


export const EmployeesBarUnderOrgTree: FC = () => {
    const {
        employees,
        setTree, direction,
    } = useEmployeesTree();

    const {
        setNodes, setEdges,
    } = useReactFlow<TEmployeeNodeData>();

    useEffect(() => {
        init(new dagre.graphlib.Graph({compound: true}).setDefaultEdgeLabel(() => ({})))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees, direction]);

    const someEditEdgeSelector = (state: ReactFlowState) => state.edges.some(e => e.id.startsWith('EDIT'));
    const someEditEdge = useStore(someEditEdgeSelector);
    useEffect(() => {
        setTree({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [someEditEdge]);

    const init = (_dagreGraph: graphlib.Graph<{}>) => {
        setTree({});
        const edges: Edge[] = [];
        employees.forEach(employee => {
            if (employee.manager && employee.manager.trim() !== '') {
                edges.push(({
                    id: `STATIC::${employee.id}::${employee.manager}`, source: employee.manager, target: employee.id,
                    type: ConnectionLineType.Step
                }))
            }
        })

        const nodes: Node<TEmployeeNodeData>[] = [];
        employees.forEach(employee => {
            nodes.push(({
                id: employee.id,
                data: {
                    id: employee.id,
                    name: employee.name,
                    logo: null,

                    jobTitle: employee.jobTitle,
                    department: 'Department',
                    manager: employee.manager,

                    workEmail: employee.workEmail,
                    workMobilePhone: employee.workMobilePhone,

                    isDeleted: false,
                },
                height: employeeTreeNodeHeight, width: employeeTreeNodeWidth, position: {x: 0, y: 0},
                type: 'employeeTreeNode',
            }));
        });

        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            _dagreGraph,
            nodes,
            edges,
            direction,
        );

        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    }

    return (
        <Box display={"flex"} alignItems={"center"} width={'100%'}>

        </Box>
    );
}