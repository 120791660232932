import {AxiosResponse} from "axios";
import {print} from "graphql";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {PATH_SERVER} from "../../../../newShared/constants";
import {portalRequiredActionsRequestorAutocomplete, portalRequiredActionsResolverAutocomplete} from "./query";
import {
    PortalRequiredActionsRequestorAutocompleteQuery,
    PortalRequiredActionsRequestorAutocompleteQueryVariables,
    PortalRequiredActionsResolverAutocompleteQuery,
    PortalRequiredActionsResolverAutocompleteQueryVariables
} from "../../../../newShared/GQLTypes";


export const portalRequiredActionsResolverAutocompleteApi = async (data: PortalRequiredActionsResolverAutocompleteQueryVariables, signal?: AbortSignal): Promise<PortalRequiredActionsResolverAutocompleteQuery["portalRequiredActionsResolverAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(portalRequiredActionsResolverAutocomplete),
                variables: data,
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalRequiredActionsResolverAutocomplete;
    })
)


export const portalRequiredActionsRequestorAutocompleteApi = async (data: PortalRequiredActionsRequestorAutocompleteQueryVariables, signal?: AbortSignal): Promise<PortalRequiredActionsRequestorAutocompleteQuery["portalRequiredActionsRequestorAutocomplete"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(portalRequiredActionsRequestorAutocomplete),
                variables: data,
            },
            {
                headers: buildCommonHeader(),
                // signal
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.portalRequiredActionsRequestorAutocomplete;
    })
)
