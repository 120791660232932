import {useDispatch, useSelector} from "react-redux";
import {dialogsSelector, setAddAssetAction, TAssetsSlice} from "../../store/slice";

export const useRequestAddAssetDialog = () => {
    const dispatch = useDispatch();
    const {addAsset: {isOpen, isLoading}} = useSelector(dialogsSelector);

    const handleOpen = () => {
        dispatch(setAddAssetAction({isOpen: true}))
    }

    const handleClose = () => {
        dispatch(setAddAssetAction({clear: true}))
    }

    const setDialog = (payload: Partial<TAssetsSlice["dialogs"]["addAsset"]> & {clear?: boolean}) => {
        dispatch(setAddAssetAction(payload))
    }

    return {
        isOpen,
        isLoading,

        handleOpen,
        handleClose,
        setDialog,
    }
}