import {useDispatch, useSelector} from "react-redux";
import {dialogsSelector, hideAddAssetDialog, loadingsSelector} from "../../store/slice";
import {useEffect} from "react";
import {portalAssetsCreateRequestAddAssetAction} from "../../store/actions";
import {useMessageDialog} from "../../../../barsEnvironment/MessageDialog/hooks/useMessageDialog";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as yup from "yup";
import {useFormik} from "formik";

export const useAddAssetDialog = () => {
    const {t} = useMainTranslation('', {keyPrefix: 'pathAssets.dialogs'})
    const {setMessage} = useMessageDialog();
    const dispatch = useDispatch();
    const {addAsset: {isOpen}} = useSelector(dialogsSelector);
    const {isLoadingSendAddAsset} = useSelector(loadingsSelector);

    const handleClose = () => {
        dispatch(hideAddAssetDialog());
    }

    const handleSend = () => {
        dispatch(portalAssetsCreateRequestAddAssetAction({
            data: {data: formik.values},
            onSuccess: (request, response, addictiveData) => {
                setMessage({
                    title: t('Completed successfully'),
                    message: [
                        t('Your request has been submitted. Request id {{requestId}}', {requestId: response.id}),
                        t("You can find this request at 'My asset requests' section.")
                    ]
                });
            },
            onError: (request, error, addictiveData) => {
                const errors409 = error.e409;
                if (errors409?.length) {

                }
            },
        }));
    };

    const cleanValues = () => {
        formik.resetForm();
    }

    const validationSchema = yup.object({
        serial: yup.string()
            .required(t('Serial field is required'))
            .min(3, t('Serial should be more than 3 symbols'))
            .matches(/^[a-zA-Z0-9-_]{3,50}$/, 'Alphanumeric characters allowed, along with \'-\' and \'_\'')
            .max(50, t('Serial should be less than 50 symbols')),
        model: yup.string()
            .nullable()
            .required(t('Model field is required')),
        category: yup.string()
            .nullable()
            .required(t('Category field is required')),
        manufacture: yup.string()
            .nullable()
            .required(t('Manufacturer field is required')),
        comment: yup.string()
            .nullable()
            .max(1000, t('Comment should be less than 1000 symbols')),
    });

    const formik = useFormik({
        initialValues: {serial: '', model: '', category: '',manufacture: '', comment: ''},
        validationSchema,
        onSubmit: handleSend,
        isInitialValid: false,
    })
    const formikOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldTouched(e.target.name, true, false);
        formik.handleChange(e);
    };

    useEffect(() => {
        if (!isOpen) cleanValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return {
        isOpen,
        handleClose,
        handleSend,
        isLoadingSendAddAsset,
        formik: {...formik, handleChange: formikOnChange},
    }

}
