//types
import {TMenuItem} from "./types";
import {
    ReactComponent as DocumentsIcon
} from "../../../newShared/images/menu/svg-optimized/file-document-multiple-outline.svg"; // fill
import {ReactComponent as ReportsIcon} from "../../../newShared/images/menu/svg-optimized/chart-box-outline.svg";
import {ReactComponent as MyHRIcon} from "../../../newShared/images/menu/svg-optimized/myHR.svg";
import {ReactComponent as Assets} from "../../../newShared/images/menu/svg-optimized/assets.svg";
import {ReactComponent as RisksIcon} from "../../../newShared/images/menu/svg-optimized/Frame 5.svg"; // stroke
import {ReactComponent as VendorsIcon} from "../../../newShared/images/menu/svg-optimized/vendors management.svg"; // fill
import {ReactComponent as TrainingsIcon} from "../../../newShared/images/menu/trainings.svg"; // fill
import {
    ReactComponent as IncidentReportingIcon
} from "../../../newShared/images/menu/svg-optimized/Incident_reporting.svg"; // fill
import {ReactComponent as RequiredActionsIcon} from "../../../newShared/images/menu/svg-optimized/required_actions.svg"; // fill
import {
    ASSETS_LIST_PATH,
    ASSETS_PATH,
    ASSETS_REQUESTS_PATH,
    DOCUMENTS_ROOT,
    HR_ISSUES_PATH,
    HR_ORG_CHART_PATH,
    HR_PATH,
    HR_PROFILE_PATH,
    HR_REQUESTS_PATH,
    HR_TIME_OFF_MANAGEMENT_PATH,
    HR_TIME_OFF_PATH,
    INCIDENT_REPORTING_PATH,
    MY_DOCUMENTS_PATH,
    PATH_LOCAL_SPACE,
    REQUIRED_ACTIONS_PATH,
    REQUIRED_ACTIONS_TASKS_PATH,
    RISK_TREATMENT_PATH,
    SHARED_DOCUMENTS_PATH,
    TRAININGS_ASSIGNMENTS_PATH,
    TRAININGS_PATH,
    VENDORS_PATH
} from "../../../newShared/constants";
import {
    SETTING_ROOT_PATH,
    SETTINGS_LANGUAGE_PATH,
    SETTINGS_PROFILE_PATH,
    SETTINGS_SECURITY_PATH
} from "../../authWorkspacesCookies/settings/constants";
import {myHrTitle} from "./titles";

const settingsProfile: TMenuItem = {title: 'Profile', path: SETTINGS_PROFILE_PATH, child: []};
const settingsLanguage: TMenuItem = {title: 'Language', path: SETTINGS_LANGUAGE_PATH, child: []};
const settingsSecurity: TMenuItem = {title: 'Security', path: SETTINGS_SECURITY_PATH, child: []};
const settingsMain: TMenuItem = {title: 'Settings', path: SETTING_ROOT_PATH, dontShowInMenu: true, child: [settingsProfile, settingsLanguage, settingsSecurity]};


const workerSpace: TMenuItem = {title: 'Staff (Deprecated)', path: PATH_LOCAL_SPACE, icon: ReportsIcon, child: []};
const myDocuments: TMenuItem = {title: 'My documents', path: MY_DOCUMENTS_PATH, child: []};
const sharedDocuments: TMenuItem = {title: 'Shared documents', path: SHARED_DOCUMENTS_PATH, child: []};
const documents: TMenuItem = {title: 'Documents', path: DOCUMENTS_ROOT, icon: DocumentsIcon, child: [myDocuments, sharedDocuments]};


const hrProfile: TMenuItem = {title: 'My profile', path: HR_PROFILE_PATH, child: []};
const hrOrgChart: TMenuItem = {title: 'Organization tree', path: HR_ORG_CHART_PATH, child: []};
const hrIssues: TMenuItem = {title: 'My issues', path: HR_ISSUES_PATH, child: []};
const hrMyTimeOff: TMenuItem = {title: 'My time off requests', path: HR_TIME_OFF_PATH, child: []};
const hrTimeOffManagement: TMenuItem = {title: 'Time off management', path: HR_TIME_OFF_MANAGEMENT_PATH, child: []};
const hrRequests: TMenuItem = {title: 'My HR requests', path: HR_REQUESTS_PATH, child: []};
// const hrTimeOff: TMenuItem = {title: 'Time Off', path: HR_TIMEOFF_PATH, child: []};
const myHR: TMenuItem = {title: myHrTitle, path: HR_PATH, icon: MyHRIcon, child: [hrProfile, hrOrgChart, hrMyTimeOff, hrTimeOffManagement, hrRequests, hrIssues], failedCheckText: 'No HR profile in this workspace'};
//assets
const myAssets: TMenuItem = {title: 'My assets', path: ASSETS_LIST_PATH, child: []};
const assetRequests: TMenuItem = {title: 'My asset requests', path: ASSETS_REQUESTS_PATH, child: []};
const assets: TMenuItem = {title: 'Assets', path: ASSETS_PATH, icon: Assets, child: [myAssets, assetRequests]};

const requiredActionsTasks: TMenuItem = {title: 'Required actions', path: REQUIRED_ACTIONS_TASKS_PATH, child: []};
const requiredActions: TMenuItem = {title: 'Required actions', path: REQUIRED_ACTIONS_PATH, icon: RequiredActionsIcon, child: [requiredActionsTasks]};

const vendors: TMenuItem = {title: 'Vendors', path: VENDORS_PATH, icon: VendorsIcon, child: []};

const trainingsAssignments: TMenuItem = {title: 'Assignments', path: TRAININGS_ASSIGNMENTS_PATH, child: []};
const trainings: TMenuItem = {title: 'My trainings', path: TRAININGS_PATH, icon: TrainingsIcon, child: [trainingsAssignments]};

const riskTreatment: TMenuItem = {title: 'Risk treatment', path: RISK_TREATMENT_PATH, icon: RisksIcon, child: []};
const incidentReporting: TMenuItem = {title: 'Incident reporting', path: INCIDENT_REPORTING_PATH, icon: IncidentReportingIcon, child: []};

export const leftMenuItems: readonly TMenuItem[] = [
    documents,
    workerSpace,
    settingsMain,
    // inboxSpace,
    myHR,
    assets,

    requiredActions,
    vendors,
    trainings,
    riskTreatment,
    incidentReporting,
] as const;

export type TMenuTitle = typeof leftMenuItems[number]['title'] & typeof leftMenuItems[number]['child'][number]['title'];
