import {PortalTrainingContentModel} from "../../../../../../../../newShared/GQLTypes";
import {Flex} from "../../../../../../../../newShared/components/Layouts";
import {Typography} from "@mui/material";
import colors from "../../../../../../../../newShared/theme/colors";
import {TrainingSection} from "../trainingSection";
import {
    FlexColumn
} from "../../../../../../../../newShared/components/editorUnion/components/editorTitleWithActionsRow/styled";

export const SectionDetails = ({section}: {section: PortalTrainingContentModel | null}) => {
    return(
        <FlexColumn sx={{
            gap: '8px', p: '20px', width: '100%', height: '100%',
            overflow:'hidden'
        }}>
            {section &&
                <Flex w={'100%'} direction={'column'} gap={'20px'} h={'100%'}>
                    <Typography variant={'*h2'} color={colors.grayText}>{(section.sectionName)}</Typography>

                    <Flex overflow={'hidden'} grow={'1'} h={'100%'} w={'100%'}>
                        <TrainingSection section={section}/>
                    </Flex>
                </Flex>
            }
        </FlexColumn>
    )
}