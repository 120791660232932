import {
    assetRequestsSelector,
    cleanSelectedRequestAction,
    dialogsSelector,
    loadingsSelector,
    TAssetsSlice
} from "../../store/slice";
import {useDispatch, useSelector} from "react-redux";
import {portalAssetsCancelAssetRequestAction, portalAssetsGetRequestByIdAction} from "../../store/actions";
import {ASSETS_REQUESTS_PATH} from "../../../../../newShared/constants";
import {useHistory, useLocation} from "react-router-dom";

type TResponse = {
    request: TAssetsSlice['requests']['selected'],
} & Record<string, any>;

export const useAssetRequestExact = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const {selected: request} = useSelector(assetRequestsSelector);
    const {requestExact: isLoading} = useSelector(loadingsSelector);
    const {cancelRequest: {isLoading: isLoadingCancelRequest}} = useSelector(dialogsSelector);

    const handleClose = () => {
        history.push(`${ASSETS_REQUESTS_PATH}${location.search}`);
    };

    const getRequest = (requestId: string) => {
        dispatch(portalAssetsGetRequestByIdAction({
            data: {requestId},
            onError: () => {
                handleClose()
            },
        }))
    }

    const cleanSelected = () => {
        dispatch(cleanSelectedRequestAction())
    }

    const handleCancelRequest = () => {
        request && dispatch(portalAssetsCancelAssetRequestAction({
            data: {requestId: request.id},
            snack: 'success',
            onSuccess: () => {
                handleClose();
            }
        }))
    }


    return {
        request,
        isLoading,
        isLoadingCancelRequest,

        getRequest,
        cleanSelected,
        handleCancelRequest,
    } satisfies TResponse
}
